import React, { useState } from "react"
import { NavLink } from "react-router-dom"
import { observer } from "mobx-react-lite"
import { useProjStore } from "../../../stores/proj-store/proj-store.provider"
import Icon from "@mdi/react"
import { mdiChevronDown, mdiChevronUp } from "@mdi/js"
// WARNING:
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Collapse from "@material-ui/core/Collapse"
import List from "@material-ui/core/List"
import projMenuIconList from "../../../temporary-data/project-side/default-proj-menu-list/proj-menu-icon-list"
import { DLTooltip } from "@datalobby/components"
import keyboardJS from "keyboardjs"
import { DLProjMenuGroups } from "../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"

export default observer(function SidebarMenuForProj(props: any) {
	const projStore = useProjStore()
	const defaultUrl = projStore.checkin.defaultUrl

	const { menuInfo, i18n } = props
	const { id, title, subMenus, url } = menuInfo

	const [subMenuOpen, setSubMenuOpen] = useState<boolean>(false)

	const handleSubmenuExpand = () => {
		setSubMenuOpen(!subMenuOpen)
	}

	const handleMenuSelect = (item: any) => {
		keyboardJS.pause()
		projStore.menuTabs.setCurrentMainMenu(item.id)
		projStore.menuTabs.addOpenedMenu(item)
	}

	const renderIcon = (menuId: string) => {
		return projMenuIconList.find((element) => element?.menuId === menuId)
			?.icon
	}

	if (
		id === DLProjMenuGroups.pbc ||
		id === DLProjMenuGroups.fs ||
		id === DLProjMenuGroups.fs_data ||
		id === DLProjMenuGroups.fs_transaction ||
		id === DLProjMenuGroups.fs_adjustments ||
		id === DLProjMenuGroups.fs_excel_import
	) {
		return null
	}

	return (
		<>
			{subMenus.length === 0 ? (
				// when the menu has no submenu
				<List className="sidebar-menu-item">
					<NavLink
						to={defaultUrl + url}
						className="link FR"
						activeClassName={"active-menu"}
					>
						<DLTooltip title={title} elePlacement="right" arrow>
							<ListItem
								button
								onClick={() => handleMenuSelect(menuInfo)}
								data-testid={`proj-menu-${id}`}
							>
								<ListItemIcon className="menu-icon FR JC">
									<>{renderIcon(id)}</>
								</ListItemIcon>
								<ListItemText
									className="menu-text"
									primary={
										i18n[id] !== undefined
											? i18n[id]
											: title
									}
								/>
							</ListItem>
						</DLTooltip>
					</NavLink>
				</List>
			) : (
				// when the menu has submenus
				<List className="sidebar-menu-item">
					<DLTooltip title={title} elePlacement="right" arrow>
						<ListItem
							button
							onClick={() => handleSubmenuExpand()}
							data-testid={`proj-menu-${id}`}
						>
							<ListItemIcon className="menu-icon FR JC">
								<>{renderIcon(id)}</>
							</ListItemIcon>
							<ListItemText
								className="menu-text"
								primary={
									i18n[id] !== undefined ? i18n[id] : title
								}
							/>
							{subMenus.length === 0 ? (
								<div />
							) : (
								<Icon
									path={
										subMenuOpen
											? mdiChevronUp
											: mdiChevronDown
									}
									size={1}
									data-testid="menuGroup-onoff-chevron"
									className={
										"menuGroup-onoff-for-cypress " +
										(subMenuOpen
											? "opened-for-cypress"
											: "closed-for-cypress")
									}
								/>
							)}
						</ListItem>
					</DLTooltip>
					{/* sub menu rendering */}
					<Collapse in={subMenuOpen} timeout="auto" unmountOnExit>
						<List
							component="div"
							disablePadding
							data-testid={
								subMenuOpen
									? "opened-subMenus"
									: "closed-subMenus"
							}
							className={
								"subMenu-list-for-cypress " +
								(subMenuOpen ? "opened" : "closed")
							}
						>
							{subMenus.map((subMenu: any) => {
								return (
									<NavLink
										to={
											defaultUrl +
											"/" +
											url +
											"/" +
											subMenu.url
										}
										className="link FR"
										activeClassName="active-menu"
										key={subMenu.id}
									>
										<DLTooltip
											title={subMenu.title}
											elePlacement="right"
											arrow
										>
											<ListItem
												button
												className="nested-menu"
												onClick={() =>
													handleMenuSelect(subMenu)
												}
												data-testid={`proj-menu-${subMenu.id}`}
											>
												<ListItemIcon className="menu-icon FR JC">
													{/* <>
														{renderIcon(subMenu.id)}
													</> */}
													<div className="sub-menu-line"></div>
												</ListItemIcon>
												<ListItemText
													className="menu-text"
													primary={
														i18n[subMenu.id] !==
														undefined
															? i18n[subMenu.id]
															: subMenu.title
													}
												/>
											</ListItem>
										</DLTooltip>
									</NavLink>
								)
							})}
						</List>
					</Collapse>
				</List>
			)}
		</>
	)
})
