import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { FileScreenDialog } from "../../../../../service-modules/file-module/data-models/dl-file-control-model"
import {
	DLButton,
	DLDialog,
	DLTextArea,
	InputWithLabel,
} from "../../../../../components/basic-elements"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import styled from "styled-components"
import { getProjIdFromUrl } from "../../../../../library/api-requests/request-get-others"
import { useAsync } from "../../../../../library/useAsync"
import { getFileSize } from "../../workpaper-files/store/wp.apis"
import {
	IdType,
	convertId,
	idToNumber,
	idToString,
} from "../../../../../library/converters/id-converter"
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"

const ExportProjZipFolderZipDialog = observer(
	({ partialStore }: { partialStore: any }) => {
		const projStore = useProjStore()
		const projId = getProjIdFromUrl()

		const dialogName = FileScreenDialog.exportProjZipFolderZipDialog
		const downloadType = partialStore.downloadType

		const objectId = partialStore.selectedItems
		const menuId = partialStore.storeName

		let folderInfo
		if (downloadType === "Folder") {
			folderInfo = partialStore.getItemInfo(objectId[0].id)
		}

		const [{ data, isLoading }, getSize] = useAsync(getFileSize)

		const [zipReqReason, setZipReqReason] = useState("")

		const {
			clientName,
			projAliasId,
			projTitle,
			periodName,
			financialYear,
			engPartner,
			clientAliasId,
		} = projStore.projInfo.projInfo

		useEffect(() => {
			let folderId
			if (downloadType === "Folder") {
				folderId = idToNumber(objectId[0].id, IdType.folder)
			}
			getSize({ menuId, folderId })
		}, [])

		const open = partialStore.fileTreeDialogOpenStatus[dialogName]

		const projectDetails = {
			periodName,
			financialYear,
			clientName,
			title: projTitle,
			aliasId: projAliasId,
			epUsers: engPartner,
		}

		const size: any = data?.FileSize

		const projectSize =
			size && size !== null && size !== ""
				? parseFloat(size?.match(/[\d.]+/)[0])
				: 0
		const overSize = projectSize * 1024 >= 4096 * 1024

		const handleZipExport = () => {
			if (downloadType === "Folder") {
				const params = {
					description: zipReqReason,
					folderId: convertId(objectId[0].id, IdType.folder),
					folderType: menuId.toLowerCase(),
					mediaType: 1,
				}
				partialStore.initiateFolderZipExport(params)
			} else {
				partialStore.initiateProjectZipExport(zipReqReason)
			}
		}

		return (
			<DLDialog
				eleTestId="export-proj-zip-folder-zip-dialog"
				isOpen={open}
				setIsOpen={() => partialStore.setFileTreeDialogOpen(dialogName)}
				showOpenBtn={false}
				showCloseBtn={true}
				dialogTitle={
					downloadType === "Folder"
						? "Folder Zip Export"
						: "Project Zip Export"
				}
				dialogContents={
					<StyledExport>
						<div>
							<b>File Size:</b> {data?.FileSize}
						</div>
						<div className="mt-12">
							<span className="warning">WARNING:</span> If the
							file size is above 4 GB (4096 MB), it cannot be
							exported through this process. Please use the
							individual file download option
						</div>

						{downloadType === "Folder" && (
							<div className="mt-20">
								<h4 className="mb-2">Folder Info</h4>
								<InfoRow
									label="Folder Name"
									value={folderInfo.title}
									testId="folder-name"
								/>
							</div>
						)}

						<div className="mt-20">
							<ProjectInfoContainer
								projectDetails={projectDetails}
							/>
						</div>

						<div className="input-section">
							<InputWithLabel
								label="Zip Request Reason"
								eleClassName="zip-request-reason"
							>
								<DLTextArea
									eleTestId="zipReqReason_input_area"
									eleFullWidth
									eleName="zipReqReason"
									eleValue={zipReqReason}
									eleHandleChange={(e: any) => {
										if (e.target.value.length <= 200) {
											setZipReqReason(e.target.value)
										}
									}}
									warningMsg={
										zipReqReason.length > 200
											? "Max 200 characters"
											: undefined
									}
									eleFieldHeight="5"
									eleHelperText="Max 200 characters"
									elePlaceholder="Enter reason for zip request"
								/>
							</InputWithLabel>
						</div>
					</StyledExport>
				}
				openBtn={<div />}
				cancelBtnText="Cancel"
				actionReady={true}
				actionBtn={
					<DLButton
						eleTestId="add-new-row"
						color="primary"
						variant="contained"
						disabled={overSize}
						clickHandler={handleZipExport}
					>
						Zip Request
					</DLButton>
				}
				// handleAction={downloadPDF}
				maxWidth="sm"
				showSpinner={isLoading}
			/>
		)
	}
)

export default ExportProjZipFolderZipDialog

const ProjectInfoContainer = ({ projectDetails }: { projectDetails: any }) => {
	return (
		<div>
			<h4 className="mb-2">Project Info</h4>
			<InfoRow
				label={"Period Type"}
				value={projectDetails.periodName}
				testId="periodType"
			/>

			<InfoRow
				label={"Year"}
				value={projectDetails.financialYear}
				testId="financialYear"
			/>

			<InfoRow
				label={"Client"}
				value={projectDetails.clientName}
				testId="client"
			/>

			<InfoRow
				label={"Project Name"}
				value={projectDetails.title}
				testId="proj-title"
			/>
			<InfoRow
				label={"Project ID"}
				value={projectDetails.aliasId}
				testId="proj-alias-id"
			/>
			<InfoRow
				label={"E.P."}
				value={projectDetails.epUsers}
				testId="ep"
			/>
		</div>
	)
}

const InfoRow = ({
	label,
	value,
	testId,
}: {
	label: string
	value: any
	testId: string
}) => {
	return (
		<div className="info-row">
			<div className="label-area">{label} :</div>
			<div className="value-area" data-testid={`projDetails-${testId}`}>
				{value}
			</div>
		</div>
	)
}

const StyledExport = styled.div`
	.mt-2 {
		margin-top: 2px;
	}
	.mt-12 {
		margin-top: 12px;
	}
	.mt-20 {
		margin-top: 20px;
	}
	.mb-2 {
		margin-bottom: 2px;
	}
	.warning {
		color: orange;
		font-weight: bold;
	}
	.info-row {
		display: flex;
		padding: 0.3rem 0 0.3rem 1rem;
		:hover {
			background-color: ${(props) => props.theme.secondary};
		}
		.label-area {
			min-width: 8rem;
		}
		.value-area {
		}
	}
	.partition {
		height: 1.8rem;
	}

	.input-section {
		margin-bottom: 1.5rem;
		.label {
			min-width: 3rem;
		}
		.input-area {
			/* width: calc(100% - 9rem); */
			min-width: 20rem;
		}

	.zip-request-reason {
		font-weight: bold;
		padding: 0 !important;
		margin-top: 0.5rem;
	}
`
