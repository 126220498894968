import React from "react"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../../../stores/org-store/org-store.provider"
import {
	DLTextArea,
	InputWithLabel,
	DLInputField,
	DLSystemMsg,
} from "../../../../../../components/basic-elements"
import { DLI18nProps } from "../../../../../../common-models/types/common-props"
import styled from "styled-components"
import { MessageColorType } from "../../../../../../common-models/enumerations/common-enums"

export default observer(function CreateArchivePolicyForm({
	inputs,
	handleInputs,
	i18n,
	actionName,
}: {
	inputs: { title: string; description: string }
	handleInputs: (props: any) => void
	i18n: DLI18nProps
	actionName: string
}) {
	const orgStore = useOrgStore()

	return (
		<StyledArchivePolicy>
			<div className="input-section FR">
				<InputWithLabel required label={i18n.twTitle}>
					<DLInputField
						autoFocus
						eleTestId="title_input"
						eleFullWidth
						eleName="title"
						eleValue={inputs.title}
						eleHandleChange={handleInputs}
						eleRequired
						elePlaceholder="Please input the policy title."
					/>
					{actionName === "editArchivePolicy"
						? orgStore.archPolicy.isDuplicatedTitle(inputs.title) &&
						  orgStore.archPolicy.selectedPolicyInfo.title.toLowerCase() !==
								inputs.title.toLowerCase() && (
								<DLSystemMsg
									type={MessageColorType.red}
									msg="Title already exist."
								/>
						  )
						: orgStore.archPolicy.isDuplicatedTitle(
								inputs.title
						  ) && (
								<DLSystemMsg
									type={MessageColorType.red}
									msg="Title already exist."
								/>
						  )}
				</InputWithLabel>
			</div>
			<div className="input-section FR">
				<InputWithLabel label={i18n.twDesc}>
					<DLTextArea
						eleTestId="description_input_area"
						eleFullWidth
						eleName="description"
						eleValue={inputs.description}
						eleHandleChange={handleInputs}
						eleFieldHeight="5"
						elePlaceholder="(optional) Please input the policy description."
					/>
				</InputWithLabel>
			</div>
		</StyledArchivePolicy>
	)
})

const StyledArchivePolicy = styled.div`
	.input-section {
		margin-bottom: 1.5rem;
		.label {
			min-width: 9rem;
		}
		.input-area {
			/* width: calc(100% - 9rem); */
			min-width: 20rem;
		}
	}
`
