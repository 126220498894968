import { types, applySnapshot } from "mobx-state-tree"
import Responses from "../../../../common-models/responses"
import ResponseSnackbar from "../../../../common-models/response-snackbar"
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../common-models/common-view-model-actions"
import { ProjTrash } from "./data-models/project-setup-trash.data-models"
import GetProjTrashList from "./view-model-actions/get-proj-trash"
import RestoreProjTrash from "./view-model-actions/restore-proj-trash"
import RemoveProjTrash from "./view-model-actions/remove-proj-trash"
import { initialStore } from "./project-trash.provider"
import { formattedDateWithoutTzLabel } from "../../../../library/converters/date-utc-converter"
import { DnTFormatProps } from "../../../../common-models/types/common-props"
import {
	ClickPoint,
	ClickPointProps,
} from "../../../../common-models/types/dialog.props"
import RemoveProject from "./view-model-actions/remove-project"

const ProjectTrashViewModel = types
	.model({
		projTrashList: types.array(ProjTrash),
		clickPoint: ClickPoint,
		selectedItems: types.array(types.string),
		deleteTrashDialogOpen: false,
		restoreTrashDialogOpen: false,
		deleteAllTrashDialogOpen: false,
		// common parts
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		setClickPoint(clickPoint: ClickPointProps) {
			self.clickPoint = clickPoint
		},
		setDeleteTrashDialogOpen(request?: boolean) {
			self.deleteTrashDialogOpen = request ?? !self.deleteTrashDialogOpen
		},
		setRestoreTrashDialogOpen(request?: boolean) {
			self.restoreTrashDialogOpen =
				request ?? !self.restoreTrashDialogOpen
		},
		setDeleteAllTrashDialogOpen(request?: boolean) {
			self.deleteAllTrashDialogOpen =
				request ?? !self.deleteAllTrashDialogOpen
		},
		resetList() {
			self.projTrashList.length = 0
		},
		pushItemToList(item: any) {
			self.projTrashList.push(item)
		},
		deleteItem(itemId: string) {
			self.projTrashList.splice(
				self.projTrashList.findIndex((item: any) => item.id === itemId),
				1
			)
		},
		resetSelectedItems() {
			self.selectedItems.length = 0
		},
		setSelectedItem(item: string) {
			self.selectedItems.length = 0
			self.selectedItems.push(item)
		},
		pushOrSpliceSelectedItem(id: string) {
			const duplicationCheck = self.selectedItems.find(
				(item) => item === id
			)
			if (duplicationCheck) {
				const targetIndex = self.selectedItems.findIndex(
					(item) => item === id
				)
				self.selectedItems.splice(targetIndex, 1)
			} else {
				self.selectedItems.push(id)
			}
		},
		selectFirst10Files() {
			const existingSelectedItems = self.selectedItems
			if (existingSelectedItems.length > 0) {
				const filteredData = self.projTrashList.filter(
					(item) =>
						existingSelectedItems.includes(item.id) &&
						(item.type === "WorkpaperFolder" ||
							item.type === "PermanentFolder")
				)

				if (filteredData.length) {
					window.alert(
						"Other type items (file, review comments) with folder cannot be selected."
					)
					return
				}
			}

			const isOtherTypeExisted = self.projTrashList.filter(
				(item: any) =>
					item.type === "WorkpaperFile" ||
					item.type === "UploadFiles" ||
					item.type === "W.P.Comment List" ||
					item.type === "PermnentFile" ||
					item.type === "PermnentFileUploadFile" ||
					item.type === "P.F.Comment List" ||
					item.type === "PbcDocument"
			)
			if (isOtherTypeExisted.length === 0) {
				window.alert("No files to select")
				return
			}
			self.selectedItems.length = 0
			const first10Files = isOtherTypeExisted
				.slice(0, 10)
				.map((item: any) => item.id)
			self.selectedItems.push(...first10Files)
		},

		selectFirst2Folders() {
			const existingSelectedItems = self.selectedItems
			if (existingSelectedItems.length > 0) {
				const filteredData = self.projTrashList.filter(
					(item) =>
						existingSelectedItems.includes(item.id) &&
						item.type !== "WorkpaperFolder" &&
						item.type !== "PermanentFolder"
				)

				if (filteredData.length) {
					window.alert("Folder with other items cannot be selected")
					return
				}
			}
			const isFolderSelected = self.projTrashList.filter(
				(item: any) =>
					item.type === "WorkpaperFolder" ||
					item.type === "PermanentFolder"
			)
			if (isFolderSelected.length === 0) {
				window.alert("No folders to select")
				return
			}
			self.selectedItems.length = 0
			const first2Folders = isFolderSelected
				.slice(0, 2)
				.map((item: any) => item.id)
			self.selectedItems.push(...first2Folders)
		},
	}))
	.views((self) => ({
		viewProjTrashList(dntFormat: DnTFormatProps) {
			return self.projTrashList.map((item: any) => ({
				...item,
				deletedAt: item.deletedAt
					? formattedDateWithoutTzLabel({
							date: item.deletedAt,
							dntFormat,
					  })
					: "",
			}))
		},
		viewTrashItemType() {
			let trashType: string[] = []
			self.projTrashList.map((trash) => {
				if (!trashType.some((item: string) => item === trash.type)) {
					trashType.push(trash.type)
				}
			})
			return trashType
		},
	}))
	// AGER (Add, Get, Edit, Remove)
	.actions(GetProjTrashList)
	.actions(RestoreProjTrash)
	.actions(RemoveProjTrash)
	// Project Delete
	.actions(RemoveProject)
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	// .actions((self) => ({
	// 	refresh() {
	// 		self.initializeStore()
	// 		self.getProjTrashList()
	// 	},
	// }))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default ProjectTrashViewModel
