import React, { useState } from "react"
import { PageContainer } from "../../../../components/app-frame-elements"
import { ActionStatus } from "@datalobby/types"
import styled from "styled-components"
import ReactTableV8 from "../../../../components/basic-elements/tables/DLReactTable"
import { DLButton } from "../../../../components/basic-elements"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import {
	Button,
	FormControl,
	MenuItem,
	Select,
	TextField,
} from "@material-ui/core"
import { StyledHeader } from "../../../../components/app-frame-elements/header/StyledHeader"
import { Column, Table } from "@tanstack/react-table"
import { InputFieldForTableV8 } from "../../../../components/basic-elements/tables/InputFieldForTable"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { dummyData } from "../m_specific_data_properties/dummyData"
import MappingHeaderColumnCtxMenu from "./sub-components/MappingHeaderColumnCtxMenu"
import { observer } from "mobx-react-lite"
import { PermissionAsObjectProps } from "../../../../common-models/permission"

export default observer(function M_MappingHeaderColumn({
	partialStore,
	permission,
	actionStatus,
}: {
	partialStore: any
	permission: PermissionAsObjectProps
	actionStatus: any
}) {
	const store = useRootStore()

	const handleHeaderContextMenu = (e: any, column: any) => {
		console.log(column, "headerColumn")
		e.preventDefault()
		partialStore.setClickPoint({
			mouseX: e.clientX - 2,
			mouseY: e.clientY - 4,
		})
		partialStore.setSelectedColumn(column.id)
	}

	return (
		<PageContainer
			hasToolbar={false}
			pageTools={<div />}
			fullWidth
			isLoading={actionStatus === ActionStatus.loading}
		>
			<StyledPage>
				<h3>Mapping Header Column to Field Details</h3>

				<ReactTableV8
					tableColumns={columns()}
					data={dummyData}
					hasPagination={true}
					customFilters={(props: any) =>
						filterComponent({ ...props })
					}
					height={store.ui.contentsAreaHeight - 60}
					permission={permission}
					// pageHeader={pageHeader}
					menuId={DLProjSubMenus.m_setup_groups}
					showNewReorderDialog={true}
					handleHeaderContextMenu={handleHeaderContextMenu}
				/>

				<div className="FR JR" style={{ marginTop: 20 }}>
					<DLButton
						variant="contained"
						eleTestId="cancel"
						clickHandler={() => {}}
						color="primary"
					>
						Cancel
					</DLButton>
				</div>
			</StyledPage>

			{partialStore.clickPoint.mouseX && (
				<MappingHeaderColumnCtxMenu
					permission={permission}
					partialStore={partialStore}
				/>
			)}
		</PageContainer>
	)
})

const filterComponent = ({
	column,
	table,
}: {
	column: Column<any>
	table: Table<any>
}) => {
	switch (column.id) {
		case "actions":
			return

		default:
			return (
				<InputFieldForTableV8
					columnId={column.id}
					onChange={column.setFilterValue}
					value={column.getFilterValue()}
				/>
			)
	}
}

const columns = () => {
	return [
		{
			header: "Account Number",
			accessorKey: "accountNumber",
		},
		{
			header: "Description",
			accessorKey: "description",
		},
		{
			header: "Type",
			accessorKey: "type",
		},
		{
			header: "LS",
			accessorKey: "ls",
		},
		{
			header: "Ratio Class",
			accessorKey: "ratioClass",
		},
		{
			header: "Cur. P1 Balance",
			accessorKey: "curP1Balance",
		},
		{
			header: "PY1 P1 Balance",
			accessorKey: "py1P1Balance",
		},
		{
			header: "PY2 P1 Balance",
			accessorKey: "py2P1Balance",
		},
	]
}

const StyledPage = styled.div`
	padding-left: 1rem;
	padding-right: 1rem;
	.form-row {
		div:first-child {
			flex: 1;
		}
		div:last-child {
			flex: 3;
		}
	}
`
