import { StreamLanguage, LanguageSupport, Language } from "@codemirror/language"
import { html } from "@codemirror/lang-html"
import { htmlLanguage } from "@codemirror/lang-html"

// Define Liquid token types
const liquidTokens = {
	TAG: "liquid-tag",
	VARIABLE: "liquid-variable",
	COMMENT: "liquid-comment",
}

// Define the state type for the Liquid highlighter
interface LiquidState {}

// Create a basic Liquid highlighter that can be mixed with HTML
export function liquidMixedMode(): Language {
	const liquidHighlighter = StreamLanguage.define<LiquidState>({
		token(stream): string | null {
			// Liquid tags {% %} highlighting
			if (stream.match(/{%/)) {
				while (!stream.match(/%}/, false) && !stream.eol()) {
					stream.next()
				}
				stream.match(/%}/)
				return liquidTokens.TAG
			}

			// Liquid variables {{ }} highlighting
			if (stream.match(/{{/)) {
				while (!stream.match(/}}/, false) && !stream.eol()) {
					stream.next()
				}
				stream.match(/}}/)
				return liquidTokens.VARIABLE
			}

			// Move to next token for unrecognized content
			stream.next()
			return null
		},
		startState(): LiquidState {
			return {}
		},
	})

	// Return the Liquid highlighter directly as a Language
	return liquidHighlighter
}
