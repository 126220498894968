export const dummyData = [
	{
		mapNumber: "1.1.1.10",
		title: "Map title",
		behaviour: "Normal",
		name: "Assets",
		type: "Balance Sheet ",
		normalSign: "Credit",
		class: "TR",
		calculation: "calculation",
		flipType: "Individual",
		mapNoFlip: "Flip",
		units: "Tools ",
		gifiCanadaOnly: "Code",
		ls: "ls",
		lsFlip: "lsFlip1",
		group2to10: "Groupings",
		group2to10Flip: "Groupings2",
		showCurrencySymbol: "Yes",
		startNewPage: "Yes",
		columnPosition: "1",
		underline: "Yes",
		printFeatures: "Credit",
		accumultorTotal: "1 to 6",
		cashFlowCredits: "cashFlowCredits",
		cashFlowDebits: "cashFlowDebits",
		taxEntity: "Corporation",
		m3TaxEntity: "S Corporation",
		taxExportCode: "taxExportCode",
		m3Code: "m3Code",
	},
	{
		mapNumber: "1.1.1.20",
		title: "Map title2",
		behaviour: "Calculated",
		name: "Other Expense",
		type: "Income Statement",
		normalSign: "Debit",
		class: "QA",
		calculation: "calculation2",
		flipType: "Display if debit",
		mapNoFlip: "Flip2",
		units: "Options",
		gifiCanadaOnly: "Code2",
		ls: "ls",
		lsFlip: "lsFlip1",
		group2to10: "Groupings2",
		group2to10Flip: "Groupings20",
		showCurrencySymbol: "Yes",
		startNewPage: "Yes",
		columnPosition: "2",
		underline: "Yes",
		printFeatures: "Justify",
		accumultorTotal: "1 to 6",
		cashFlowCredits: "cashFlowCredits",
		cashFlowDebits: "cashFlowDebits",
		taxEntity: "Non-Profit",
		m3TaxEntity: "Partnership",
		taxExportCode: "taxExportCode",
		m3Code: "m3Code",
	},
]
