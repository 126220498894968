import { types, applySnapshot } from "mobx-state-tree"
// ---------- common models
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"
import { initialStore } from "./fs-setup-fs-groups-category.provider"

import {
	ClickPoint,
	ClickPointProps,
} from "../../../../../common-models/types/dialog.props"
import { GroupsCategoryTypeModel } from "./data-models/fs-setup-fs-groups-category.data-model"
import GetSetupFSGroupsCategory from "./view-model-actions/get-setup-fs-groups-category"
import { getTreeFromFlatData } from "../../../../../components/basic-elements/tree-list/getTreeFromFlatData"
import { DnTFormatProps } from "../../../../../common-models/types/common-props"
import { compareDesc } from "date-fns"
import { formattedDateWithoutTzLabel } from "../../../../../library/converters/date-utc-converter"

const FsSetupFsGroupsCategoryViewModel = types
	.model({
		groupsList: types.array(GroupsCategoryTypeModel),
		addDialogOpen: false,
		editDialogOpen: false,
		selectedItem: "",
		clickPoint: ClickPoint,
		// ---------- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar, // snackbar type 2
	})
	.actions((self) => ({
		setGroupsList(list: any) {
			self.groupsList = list
		},
		setClickPoint(clickPoint: ClickPointProps) {
			self.clickPoint = clickPoint
		},
		setSelectedItem(id: string) {
			self.selectedItem = id
		},
		setAddDialogOpen(request?: boolean) {
			self.addDialogOpen = request ?? !self.addDialogOpen
		},
		setEditDialogOpen(request?: boolean) {
			self.editDialogOpen = request ?? !self.editDialogOpen
		},
		updateGroupItem(
			id: string,
			modifiedByUserName: string,
			modifiedAt: string,
			name?: string,
			number?: string,
			sortOrder?: number,
			isActive?: boolean
		) {
			const target = self.groupsList.find((item) => item.id === id)
			if (!target) {
				console.error("No matched group ID")
				return
			}

			target.modifiedBy = modifiedByUserName
			target.modifiedAt = modifiedAt
			if (name !== undefined) target.name = name
			if (number !== undefined) target.number = number
			if (sortOrder !== undefined) target.sortOrder = sortOrder
			if (isActive !== undefined) {
				target.isActive = isActive
				if (isActive) {
					target.activatedBy = modifiedByUserName
					target.activatedAt = modifiedAt
				} else {
					target.deActivatedBy = modifiedByUserName
					target.deActivatedAt = modifiedAt
				}
			}
		},
	}))
	.actions(GetSetupFSGroupsCategory)
	.views((self) => ({
		isDuplicatedName(name: string) {
			return self.groupsList.some(
				(item: any) => item.name.toLowerCase() === name.toLowerCase()
			)
		},
		isDuplicatedNumber(number: string) {
			return self.groupsList.some(
				(item: any) =>
					item.number.toLowerCase() === number.toLowerCase()
			)
		},
		isDuplicatedSortOrder(order: number) {
			return self.groupsList.some((item: any) => item.sortOrder === order)
		},
		getGroupById(id: string) {
			return self.groupsList.find((element) => element.id === id)
		},
		formattedList(dntFormat: DnTFormatProps) {
			let formattedList: any[] = []
			// sort the origin list
			let sortedList = self.groupsList.slice().sort(function (a, b) {
				return compareDesc(b.sortOrder, a.sortOrder)
			})
			sortedList.map((engType) => {
				const formattedCreatedAt = engType.createdAt
					? formattedDateWithoutTzLabel({
							date: engType.createdAt,
							dntFormat,
					  })
					: ""
				const formattedModifiedAt = engType.modifiedAt
					? formattedDateWithoutTzLabel({
							date: engType.modifiedAt,
							dntFormat,
					  })
					: "-"
				const formattedActivatedAt = engType.activatedAt
					? formattedDateWithoutTzLabel({
							date: engType.activatedAt,
							dntFormat,
					  })
					: "-"
				const formattedDeActivatedAt = engType.deActivatedAt
					? formattedDateWithoutTzLabel({
							date: engType.deActivatedAt,
							dntFormat,
					  })
					: "-"
				formattedList.push({
					...engType,
					createdAt: formattedCreatedAt,
					modifiedAt: formattedModifiedAt,
					activatedAt: formattedActivatedAt,
					deActivatedAt: formattedDeActivatedAt,
				})
			})

			return formattedList
		},
		getActiveList() {
			return self.groupsList.filter((item) => item.isActive)
		},
		getInactiveList() {
			return self.groupsList.filter((item) => !item.isActive)
		},
	}))
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default FsSetupFsGroupsCategoryViewModel
