import { types } from "mobx-state-tree"
// model common action
import { CommonModelActions } from "../../../../../common-models/common-model-actions"
// TODO: Consider to merge with OrgProject (common project model)

import ReadAmUnarchivedProjectList from "./model-actions/read-am-unarchived-projects"
import DeleteAmUnarchivedProject from "./model-actions/delete-am-unarchived-project"
import { AmUnarchivedApiProps } from "./am-unarchived-projects.apis"
import ReadCabinetLocation from "../../../../project-side/project-information/store/model-actions/read-cabinet-location"

const ArchiveMgmtUnarchivedProjectsModel = ({
	apiRead,
	apiDelete,
	apiReadCabinetLocation,
}: AmUnarchivedApiProps) =>
	types
		.model({})
		.actions((self) => ({}))
		// CRUD actions
		.actions((self) => ReadAmUnarchivedProjectList(self, apiRead))
		.actions((self) => DeleteAmUnarchivedProject(self, apiDelete))
		.actions((self) => ReadCabinetLocation(self, apiReadCabinetLocation))
		// Common model actions
		.actions(CommonModelActions)

export default ArchiveMgmtUnarchivedProjectsModel
