import { flow } from "mobx-state-tree"
import { ProjDownloadCenterListApiProps } from "../proj-download-center-list.apis"

const DeleteProjDataMgmtZipFileById = (
	self: any,
	apiDelete: ProjDownloadCenterListApiProps["apiDeleteProjDataMgmtZipFileById"]
) => ({
	deleteProjDataMgmtZipFileById: flow(function* (zipFileId: number) {
		const actionName = "deleteProjDataMgmtZipFileById"
		try {
			const response = yield apiDelete(zipFileId)
			return response
		} catch (error) {
			self.handleModelError({ actionName, error, openSnackbar: true })
			return false
		}
	}),
})

export default DeleteProjDataMgmtZipFileById
