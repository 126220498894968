import React from "react"
import { observer } from "mobx-react-lite"
import {
	InputWithLabel,
	DLInputField,
	DLComboBox,
	DLDatepickerWithoutInput,
	DLSystemMsg,
	ConsoleLog,
} from "../../basic-elements"

import {
	formattedDateWithTz,
	getFormattedEOD3,
} from "../../../library/converters/date-utc-converter"

import BreadCrumbsAndOptions from "../postpone-to-classify/BreadCrumbsAndOptions"
import { CreateProjectSectionLeftProps } from "./create-project.comp-props"
import { createFilterOptions } from "@material-ui/lab/Autocomplete"
import parse from "autosuggest-highlight/parse"
import match from "autosuggest-highlight/match"
import { MessageColorType } from "../../../common-models/enumerations/common-enums"
import DLYearpicker from "../../basic-elements/date-picker/DLYearpicker"
import { getYear } from "date-fns"

export default observer(function CreateProjectSectionLeft({
	path,
	handleSelectPath,
	getLocationDetail,
	//
	inputs,
	handleInputs,
	handleComboBoxInput,
	handleDateInput,
	handleYearInput,
	// options
	breadCrumbOptions,
	clientList,
	engTypeList,
	periodTypeList,
	archPolicyPeriodList,
	raList,
	//
	dateFormat,
	timeFormat,
	timeZone,
	highlight,
	//
	i18n,
}: CreateProjectSectionLeftProps) {
	// const orgStore = useOrgStore()
	const pathLength = path.length
	// console.log("inputs on section1 ", inputs, archPolicyPeriodList)

	return (
		<div className={`left-area section-on`}>
			<BreadCrumbsAndOptions
				path={path}
				setPath={handleSelectPath}
				options={breadCrumbOptions}
				getLocationDetail={getLocationDetail}
			/>
			{/* TODO: Hide breacrumbs option when the user click the other field automatically */}
			{path.length === 0 && (
				<div className="location-warning" style={{ color: "red" }}>
					! Please select a group
				</div>
			)}
			{path.length === 1 && (
				<div className="location-warning" style={{ color: "red" }}>
					! Please select a cabinet
				</div>
			)}

			<div className="FR">
				<div className="input-section FR">
					<InputWithLabel
						label={i18n.twClient}
						required
						direction="vertical"
						eleClassName="with-label"
						highlight={highlight && inputs.clientId === ""}
					>
						{pathLength <= 1 ? (
							<div className="request-required-field">
								Please set location first
							</div>
						) : (
							<div>
								<DLComboBox
									eleTestId="client-input-field"
									withoutLabel
									placeholder={i18n.tsSelectClient}
									options={clientList}
									filterOptions={createFilterOptions({
										// matchFrom: 'start',
										stringify: (option: any) =>
											option.name + option.aliasId,
									})}
									// getOptionLabel={(option: any) =>
									// 	"(" +
									// 	option.aliasId +
									// 	") " +
									// 	option.name
									// }
									renderOption={(
										option: any,
										{ inputValue }: { inputValue: any }
									) => {
										const matches_name = match(
											option.name,
											inputValue
										)
										const parts_name = parse(
											option.name,
											matches_name
										)
										const matches_aliasId = match(
											option.aliasId,
											inputValue
										)
										const parts_aliasId = parse(
											option.aliasId,
											matches_aliasId
										)
										return (
											<div className="FR AC">
												<div
													style={{
														marginRight: "0.4rem",
													}}
												>
													{parts_name.map(
														(part, i) => (
															<span
																key={i}
																style={{
																	fontWeight:
																		part.highlight
																			? 700
																			: 400,
																}}
															>
																{part.text}
															</span>
														)
													)}
												</div>

												<div
													style={{
														marginRight: "1rem",
														opacity: 0.8,
													}}
												>
													(
													{parts_aliasId.map(
														(part, i) => (
															<span
																key={i}
																style={{
																	fontWeight:
																		part.highlight
																			? 700
																			: 400,
																}}
															>
																{part.text}
															</span>
														)
													)}
													)
												</div>
											</div>
										)
									}}
									onChange={(value: any) =>
										handleComboBoxInput("clientId", value)
									}
									eleValue={
										inputs.clientAliasId &&
										inputs.clientName
											? {
													aliasId:
														inputs.clientAliasId,
													name: inputs.clientName,
											  }
											: undefined
									}
								/>
							</div>
						)}
					</InputWithLabel>
				</div>

				<div className="input-section FR">
					<InputWithLabel
						label="Client ID"
						required
						eleClassName="with-label"
						direction="vertical"
						// highlight={highlight && inputs.aliasId === ""}
					>
						<DLInputField
							eleTestId="client-alias-id"
							eleName="aliasId"
							eleValue={inputs.clientAliasId}
							eleHandleChange={handleInputs}
							eleReadOnly
						/>
					</InputWithLabel>
				</div>
			</div>
			<div className="FR">
				<div className="input-section FR">
					<InputWithLabel
						label={i18n.twProjectTitle}
						required
						direction="vertical"
						eleClassName="with-label"
						highlight={highlight && inputs.title === ""}
					>
						<DLInputField
							eleTestId="project-title"
							eleName="title"
							eleValue={inputs.title}
							eleHandleChange={handleInputs}
						/>
					</InputWithLabel>
				</div>
				<div className="input-section FR">
					<InputWithLabel
						label={i18n.twProjectId}
						required
						direction="vertical"
						eleClassName="with-label"
						highlight={highlight && inputs.aliasId === ""}
					>
						<DLInputField
							eleTestId="project-alias-id"
							eleName="aliasId"
							eleValue={inputs.aliasId}
							eleHandleChange={handleInputs}
						/>
					</InputWithLabel>
				</div>
			</div>

			<div className="FR">
				<div className="input-section FR">
					<InputWithLabel
						label={i18n.twEngType}
						required
						direction="vertical"
						eleClassName="with-label"
						highlight={highlight && inputs.engTypeId === ""}
					>
						{pathLength <= 1 ? (
							<div className="request-required-field">
								Please set location first
							</div>
						) : (
							<DLComboBox
								eleTestId="engType-input-field"
								withoutLabel
								placeholder={i18n.tsSelectEngType}
								onChange={(value: any) => {
									handleComboBoxInput("engTypeId", value)
									if (!value) {
										handleComboBoxInput(
											"archPolicyPeriod",
											null
										)
									}
								}}
								getOptionLabel={(option: any) => option.title}
								options={engTypeList}
								defaultValue={{
									id: inputs.engTypeId,
									title: inputs.engTypeName,
								}}
							/>
						)}
					</InputWithLabel>
				</div>

				<div className="input-section FR">
					<InputWithLabel
						label={i18n.twRiskAssessment}
						required
						direction="vertical"
						eleClassName="with-label"
						highlight={highlight && inputs.riskAssessment === ""}
					>
						<DLComboBox
							eleTestId="risk-assessment-select"
							withoutLabel
							placeholder={i18n.tsSelectRiskAssessment}
							onChange={(value: any) =>
								handleComboBoxInput("riskAssessment", value)
							}
							getOptionLabel={(option: any) => option.title}
							options={raList}
							// defaultValue={{
							// 	id: inputs.archivePolicyPeriod,
							// 	// title: archPolicyPeriodList.find(
							// 	// 	(ele: any) =>
							// 	// 		ele.id === inputs.archivePolicyPeriod
							// 	// ).title,
							// }}
						/>
					</InputWithLabel>
				</div>
			</div>
			<div className="FR">
				<div className="input-section FR">
					<InputWithLabel
						label={i18n.twPeriodType}
						required
						direction="vertical"
						eleClassName="with-label"
						highlight={highlight && inputs.periodId === ""}
					>
						<DLComboBox
							eleTestId="period-input-field"
							withoutLabel
							placeholder={i18n.tsSelectPeriodType}
							onChange={(value: any) =>
								handleComboBoxInput("periodId", value)
							}
							getOptionLabel={(option: any) => option.title}
							options={periodTypeList}
							defaultValue={{
								id: inputs.periodId,
								title: inputs.periodName,
							}}
						/>
					</InputWithLabel>
				</div>

				<div className="input-section FR">
					<InputWithLabel
						label={i18n.twPeriodEndDate}
						required
						direction="vertical"
						eleClassName="with-label"
						highlight={highlight && inputs.periodEndDate === ""}
					>
						<div className="FR AC" style={{ paddingTop: 6 }}>
							<DLDatepickerWithoutInput
								eleTestId="period-end-input"
								onChange={(date: any) =>
									handleDateInput("periodEndDate", date)
								}
								autoOk
								format={dateFormat}
							/>
							<div
								className="date-info FR AC"
								data-testid="period-end-date-display"
								style={{ marginLeft: "1rem", opacity: 0.3 }}
							>
								{inputs.periodEndDate !== ""
									? getFormattedEOD3({
											date: inputs.periodEndDate,
											timeZone: timeZone.uiValue,
											timeFormat,
											dateFormat,
											tzLabel: timeZone.value,
									  })
									: "Please select a date"}
							</div>
						</div>
					</InputWithLabel>
				</div>
			</div>
			<div className="FR">
				<div className="input-section FR">
					<InputWithLabel
						label={
							i18n.twArchivePolicyPeriod ||
							"Archive Policy - Period"
						}
						required
						direction="vertical"
						eleClassName="with-label"
						highlight={highlight && inputs.archPolicyPeriod === ""}
					>
						<DLComboBox
							eleTestId="archive-policy-period-select"
							withoutLabel
							placeholder={i18n.tsSelectArchivePolicyPeriod}
							onChange={(value: any) =>
								handleComboBoxInput("archPolicyPeriod", value)
							}
							getOptionLabel={(option: any) => option.title}
							options={archPolicyPeriodList}
							eleDisabled={inputs.engTypeId === ""}
							defaultValue={
								inputs.archPolicyPeriod
									? archPolicyPeriodList.find(
											(i: any) =>
												i.id === inputs.archPolicyPeriod
									  )
									: undefined
							}
							eleValue={
								inputs.archPolicyPeriod
									? archPolicyPeriodList.find(
											(i: any) =>
												i.id === inputs.archPolicyPeriod
									  )
									: {}
							}
						/>

						{/* {inputs.expectedArchiveDate !== "" && (
							<div>
								Expected Archive Date:{" "}
								{getFormattedEOD3({
									date: inputs.expectedArchiveDate,
									timeZone: timeZone.uiValue,
									timeFormat,
									dateFormat,
									tzLabel: timeZone.value,
								})}
							</div>
						)} */}
					</InputWithLabel>
				</div>
				<div className="input-section FR">
					<InputWithLabel
						label={i18n.twFinancialYear}
						required
						direction="vertical"
						eleClassName="with-label"
						// highlight={highlight && inputs.expectedReportDate === ""}
					>
						<div className="FR AC" style={{ paddingTop: 6 }}>
							<DLYearpicker
								eleTestId="financial-year-select"
								eleName="year"
								selected={inputs.year}
								onChange={(date: any) => handleYearInput(date)}
							/>
						</div>
						{inputs.periodEndDate !== "" &&
							getYear(new Date(inputs.periodEndDate)) !==
								inputs.year && (
								<DLSystemMsg
									type={MessageColorType.orange}
									msg="Selected financial year is different from period end date"
								/>
							)}
					</InputWithLabel>
				</div>
			</div>

			<div className="FR">
				<div className="input-section FR">
					<InputWithLabel
						label={i18n.twExpectedReportDate}
						required
						direction="vertical"
						eleClassName="with-label"
						highlight={
							highlight && inputs.expectedReportDate === ""
						}
					>
						<div className="FR AC" style={{ paddingTop: 6 }}>
							<DLDatepickerWithoutInput
								eleTestId="expected-report-date-input"
								onChange={(date: any) =>
									handleDateInput("expectedReportDate", date)
								}
								autoOk
								format={dateFormat}
							/>
							<div
								className="date-info FR AC"
								data-testid="expected-report-date-display"
								style={{ marginLeft: "1rem", opacity: 0.3 }}
							>
								{inputs.expectedReportDate !== ""
									? getFormattedEOD3({
											date: inputs.expectedReportDate,
											timeZone: timeZone.uiValue,
											timeFormat,
											dateFormat,
											tzLabel: timeZone.value,
									  })
									: "Please select a date"}
							</div>
						</div>
						{inputs.periodEndDate !== "" &&
							inputs.expectedReportDate !== "" && (
								<>
									{new Date(inputs.periodEndDate).getTime() >
										new Date(
											inputs.expectedReportDate
										).getTime() && (
										<DLSystemMsg
											type={MessageColorType.orange}
											msg="Period End Date is late than Expected Report Date"
										/>
									)}
								</>
							)}
					</InputWithLabel>
				</div>
				<div className="input-section FR">
					<InputWithLabel
						label={i18n.twFinalReportDate}
						direction="vertical"
						eleClassName="with-label"
					>
						<div className="FR AC" style={{ paddingTop: 6 }}>
							<DLDatepickerWithoutInput
								eleTestId="final-report-date-input"
								onChange={(date: any) =>
									handleDateInput("finalReportDate", date)
								}
								autoOk
								format={dateFormat}
							/>
							<div
								className="date-info FR AC"
								data-testid="final-report-date-display"
								style={{ marginLeft: "1rem", opacity: 0.3 }}
							>
								{inputs.finalReportDate !== ""
									? getFormattedEOD3({
											date: inputs.finalReportDate,
											timeZone: timeZone.uiValue,
											timeFormat,
											dateFormat,
											tzLabel: timeZone.value,
									  })
									: "Please select a date"}
							</div>
						</div>
					</InputWithLabel>
				</div>
			</div>
			<div className="FR">
				<div className="input-section FR">
					<InputWithLabel
						label={i18n.twExpectedArchiveDeadlineDate}
						required
						direction="vertical"
						eleClassName="with-label"
						highlight={
							highlight && inputs.expectedReportDate === ""
						}
					>
						<div className="FR AC" style={{ paddingTop: 6 }}>
							<DLDatepickerWithoutInput
								eleTestId="expected-arch-deadline-date-input"
								onChange={(date: any) => {}}
								autoOk
								format={dateFormat}
								disabled
							/>
							<div
								className="date-info FR AC"
								data-testid="expected-arch-deadline-date-display"
								style={{ marginLeft: "1rem", opacity: 0.3 }}
							>
								{inputs.expectedArchiveDate !== ""
									? getFormattedEOD3({
											date: inputs.expectedArchiveDate,
											timeZone: timeZone.uiValue,
											timeFormat,
											dateFormat,
											tzLabel: timeZone.value,
									  })
									: "Please select a date"}
							</div>
						</div>
					</InputWithLabel>
				</div>
				<div className="input-section FR">
					<InputWithLabel
						label={i18n.twFinalArchiveDeadlineDate}
						direction="vertical"
						eleClassName="with-label"
					>
						<div className="FR AC" style={{ paddingTop: 6 }}>
							<DLDatepickerWithoutInput
								eleTestId="final-report-date-input"
								onChange={(date: any) => {}}
								autoOk
								format={dateFormat}
								disabled
							/>
							<div
								className="date-info FR AC"
								data-testid="final-report-date-display"
								style={{ marginLeft: "1rem", opacity: 0.3 }}
							>
								{inputs.finalArchDeadlineDate !== ""
									? getFormattedEOD3({
											date: inputs.finalArchDeadlineDate,
											timeZone: timeZone.uiValue,
											timeFormat,
											dateFormat,
											tzLabel: timeZone.value,
									  })
									: "Please select a date"}
							</div>
						</div>
					</InputWithLabel>
				</div>
			</div>
		</div>
	)
})
