import React, { useState } from "react"
import { PageContainer } from "../../../../components/app-frame-elements"
import { ActionStatus } from "@datalobby/types"
import styled from "styled-components"

import { DLButton } from "../../../../components/basic-elements"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import {
	Button,
	FormControl,
	MenuItem,
	Select,
	TextField,
} from "@material-ui/core"

const M_ImportFileSettings = ({
	actionStatus,
	permission,
}: {
	actionStatus: any
	permission: any
}) => {
	const [fileSettings, setFileSettings] = useState({
		componentsToImport: "",
		workSheet: "",
		periodDateSequence: "",
	})
	const [filePath, setFilePath] = useState("")
	const handleForm = (name: string, value: any) => {
		setFileSettings((prev: any) => ({
			...prev,
			[name]: value,
		}))
	}

	const handleSelectFilesFromLocal = (e: any) => {
		const localFile = e.target.files[0]
		setFilePath(localFile.name)
	}

	return (
		<PageContainer
			hasToolbar={false}
			pageTools={<div />}
			fullWidth
			isLoading={actionStatus === ActionStatus.loading}
		>
			<StyledPage>
				<h3>Import File Settings</h3>

				<div
					className="FR AC form-row"
					style={{ marginTop: 15, gap: 15 }}
				>
					<div>Components to import</div>
					<FormControl
						style={{ marginTop: 5 }}
						fullWidth
						size="small"
					>
						<Select
							labelId="componentsToImport-label"
							id="componentsToImport"
							value={fileSettings.componentsToImport}
							variant="outlined"
							displayEmpty
							placeholder="Select Components to import"
							onChange={(e) =>
								handleForm("componentsToImport", e.target.value)
							}
						>
							<MenuItem disabled value="">
								Select Components to import
							</MenuItem>
							<MenuItem value="1">
								Chart of Accounts & General Ledger Balances
							</MenuItem>
							<MenuItem value="2">
								General Ledger Details
							</MenuItem>
						</Select>
					</FormControl>
				</div>
				<div
					className="FR AC form-row"
					style={{ marginTop: 15, gap: 15 }}
				>
					<div>Excel file to import</div>
					<div className="FR" style={{ gap: 10 }}>
						<TextField
							variant="outlined"
							size="small"
							value={filePath}
							// onChange={(e) => setFilePath(e.target.value)}
							disabled
						/>
						<Button
							variant="contained"
							color="primary"
							component="label"
						>
							Browse...
							<input
								type="file"
								hidden
								onChange={handleSelectFilesFromLocal}
							/>
						</Button>
					</div>
				</div>
				<div
					className="FR AC form-row"
					style={{ marginTop: 15, gap: 15 }}
				>
					<div>Select a worksheet</div>
					<div className="FR">
						<TextField
							variant="outlined"
							size="small"
							type="number"
							value={fileSettings.workSheet}
							onChange={(e) =>
								handleForm("workSheet", e.target.value)
							}
						/>
					</div>
				</div>
				<div
					className="FR AC form-row"
					style={{ marginTop: 15, gap: 15 }}
				>
					<div>Record layout file (optional)</div>
					<div className="FR" style={{ gap: 10 }}>
						<TextField
							variant="outlined"
							size="small"
							value={filePath}
							// onChange={(e) => setFilePath(e.target.value)}
							disabled
						/>
						<Button
							variant="contained"
							color="primary"
							component="label"
						>
							Browse...
							<input
								type="file"
								hidden
								onChange={handleSelectFilesFromLocal}
							/>
						</Button>
					</div>
				</div>
				<div
					className="FR AC form-row"
					style={{ marginTop: 15, gap: 15 }}
				>
					<div>
						Period Date Sequence for importing period balances
					</div>
					<FormControl
						style={{ marginTop: 5 }}
						fullWidth
						size="small"
					>
						<Select
							labelId="periodDateSequence-label"
							id="periodDateSequence"
							value={fileSettings.periodDateSequence}
							variant="outlined"
							displayEmpty
							placeholder="Select Period Date Sequence"
							onChange={(e) =>
								handleForm("periodDateSequence", e.target.value)
							}
						>
							<MenuItem disabled value="">
								Select Period Date Sequence
							</MenuItem>
							<MenuItem value="monthly">Monthly</MenuItem>
							<MenuItem value="quarterly">Quarterly</MenuItem>
							<MenuItem value="half-yearly">Half-yearly</MenuItem>
							<MenuItem value="yearly">Yearly</MenuItem>
						</Select>
					</FormControl>
				</div>
				<div className="FR JR" style={{ marginTop: 20 }}>
					<DLButton
						variant="contained"
						eleTestId="cancel"
						clickHandler={() => {}}
						color="primary"
					>
						Cancel
					</DLButton>
				</div>
			</StyledPage>
		</PageContainer>
	)
}

export default M_ImportFileSettings

const StyledPage = styled.div`
	padding-left: 1rem;
	padding-right: 1rem;
	.form-row {
		div:first-child {
			flex: 1;
		}
		div:last-child {
			flex: 3;
		}
	}
`
