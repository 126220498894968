import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import {
	DLDialog,
	DLDialogHeader,
} from "../../../../../components/basic-elements"
import { GroupDetailTab } from "../../store/org-groups.ui-helper"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import { AssignStatus } from "../../../../../stores/org-sub-stores/org-clients-store/data-models/org-clients.data-models"
import ReactTableV8 from "../../../../../components/basic-elements/tables/DLReactTable"

export default observer(function GroupDetailDialog({
	i18n,
}: {
	i18n: DLI18nProps
}) {
	const orgStore = useOrgStore()
	const groupStore = orgStore.groups
	const setupGroupStore = orgStore.setupGroups

	const groupId = groupStore.rightClickTarget
	const groupName = groupStore.getNameById(groupId)
	const currentTab = groupStore.currentDetailTab

	useEffect(() => {
		if (currentTab === GroupDetailTab.users) {
			setupGroupStore.getAssignedUsersOfGroup(groupId)
		} else {
			setupGroupStore.getClientsByGroup(groupId, AssignStatus.assigned)
		}
	}, [])

	const actionStatus = setupGroupStore.getActionStatus
	return (
		<DLDialog
			eleTestId="group-detail-dialog"
			isOpen={groupStore.groupDetailDialogOpenStatus}
			setIsOpen={groupStore.setGroupDetailDialogOpenStatus}
			handleAction={() => {}}
			showCloseBtn={true}
			dialogTitle={
				<DLDialogHeader
					dialogName={i18n.twGroupInfo || "Group information"}
					targetName={groupName ? groupName : "(Unknown group)"}
				/>
			}
			dialogContents={
				<GroupDetailDialogContents
					tab={currentTab}
					data={
						currentTab === GroupDetailTab.users
							? setupGroupStore.viewAssignedUsersList("")
							: setupGroupStore.viewAssignedClientsList("")
					}
				/>
			}
			cancelBtnText={i18n.twCancel || "Cancel"}
			actionBtn=""
			maxWidth="md"
			fullWidth={true}
			showSpinner={
				actionStatus("getAssignedUsersOfGroup") === "LOADING" ||
				actionStatus("clientList" + AssignStatus.assigned) === "LOADING"
			}
			cannotUpdate={
				actionStatus("getAssignedUsersOfGroup") === "LOADING" ||
				actionStatus("clientList" + AssignStatus.assigned) === "LOADING"
			}
		/>
	)
})

const GroupDetailDialogContents = observer(
	({ tab, data }: { tab: GroupDetailTab; data: any }) => {
		return (
			<ReactTableV8
				tableColumns={TableColumns(tab)}
				showPageSetting={false}
				data={data}
				hasPagination={false}
				menuId="GroupDetailDialog"
			/>
		)
	}
)

const renderCustomCell = (props: any) => {
	const cellId = props.cell.column.id + "-cell"
	return <div data-testid={cellId}>{props.getValue()}</div>
}

const TableColumns = (tab: GroupDetailTab) => {
	let columns: any[] = []

	if (tab === GroupDetailTab.users) {
		columns = [
			{
				header: "Name",
				accessorKey: "name",
				cell: renderCustomCell,
			},
			{
				header: "Email",
				accessorKey: "email",
				cell: renderCustomCell,
			},
			{
				header: "Alias ID",
				accessorKey: "aliasId",
				cell: renderCustomCell,
			},
			{
				header: "Organization Role",
				accessorKey: "accessTypeId",
				cell: (props: any) => {
					const cellId = props.cell.column.id + "-cell"
					const value = props.getValue()
					return (
						<div data-testid={cellId}>
							{value === "1" ? (
								<span>Super Admin</span>
							) : value === "2" ? (
								<span>Group Admin</span>
							) : value === "3" ? (
								<span>Group Admin</span>
							) : (
								<span>External User</span>
							)}
						</div>
					)
				},
			},
			{
				header: "Status",
				accessorKey: "isGrayed",
				cell: (props: any) => {
					const cellId = props.cell.column.id + "-cell"
					return (
						<div data-testid={cellId}>
							{props.getValue() ? (
								<span>Suspended</span>
							) : (
								<span>Normal</span>
							)}
						</div>
					)
				},
			},
			{
				header: "Title",
				accessorKey: "title",
				cell: renderCustomCell,
			},
		]
	} else {
		columns = [
			{
				header: "Client Name",
				accessorKey: "name",
				cell: renderCustomCell,
			},
			{
				header: "Client Id",
				accessorKey: "clientAliasId",
				cell: renderCustomCell,
			},
		]
	}

	return columns
}
