import React, { useState, useEffect, useMemo } from "react"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import PageHeader from "../../../../components/app-frame-elements/sub-components/PageHeader"
import {
	DLBreadCrumbsWithUrl,
	ConsoleLog,
	DLSpinner,
} from "../../../../components/basic-elements"
import styled from "styled-components"

import { OrgRoleType } from "../../../../common-models/enumerations/org-related-enums"
import { Tabs, Tab } from "@material-ui/core"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { OrgI18n } from "../../../../common-models/enumerations/translation-sheets"
import { AssignedProjectsDialogContainer } from "./dialogs/AssignedProjectsDialog"
import { useParams } from "react-router-dom"

const breadcrumbList = [
	{
		title: "Users",
		url: "/organization/organization-setup/organization-user-list",
	},
]

const TABS = {
	userDetails: 0,
	assignedProjects: 1,
}

export default observer(function OrgSetupUserDetailContoller() {
	// re-rendering check
	ConsoleLog(" __________ OrgSetupUserDetail Contoller __________ ")
	const store = useRootStore()
	const orgStore = useOrgStore()
	const i18nSheet = OrgI18n.users
	const [selectedTab, setSelectedTab] = useState<
		"userDetails" | "assignedProjects"
	>("userDetails")
	let { id: userId } = useParams<{ id: string }>()

	const i18n = store.i18n.combineI18n(i18nSheet)
	const timeZone = store.global.timeZone.uiValue
	const dateFormat = store.global.dateFormat.value
	const dntFormat = store.global.getDntFormat
	const actionStatus = orgStore.setupUsers.getActionStatus("getUserList")

	useEffect(() => {
		if (!orgStore.setupUsers.selectedUser.id) {
			orgStore.setupUsers.getUserList()
		}
	}, [])

	const userInfo = useMemo(
		() => orgStore.setupUsers.userDetail(userId, dntFormat),
		[actionStatus]
	)

	return (
		<>
			{actionStatus === "LOADING" && (
				<div
					className="FR AC JC"
					style={{
						width: "100%",
						height: "100%",
						marginTop: "200px",
					}}
				>
					<DLSpinner />
				</div>
			)}
			{actionStatus === "SUCCESS" && (
				<>
					<PageHeader>
						<DLBreadCrumbsWithUrl
							items={breadcrumbList}
							currentLocation={
								userInfo ? userInfo.name : "User Detail"
							}
						/>
						<div className="FR AC">
							{/* <EditOrgUserDialog />
							<SuspendOrgUserDialog />
							{userInfo && userInfo.status === "suspended" && (
								<>
									<RestoreOrgUserDialog />
									<RemoveOrgUserDialog />
								</>
							)} */}
						</div>
					</PageHeader>

					<StyledContainer className="FC">
						<Tabs
							value={TABS[selectedTab]}
							onChange={(e: any, value: any) =>
								setSelectedTab(
									selectedTab === "userDetails"
										? "assignedProjects"
										: "userDetails"
								)
							}
							className="proj-info-tabs"
							indicatorColor="primary"
							textColor="primary"
						>
							<Tab
								label="User Details"
								data-testid="proj-info-tab"
								className={
									"proj-info-tab " +
									(selectedTab === "userDetails"
										? "selected"
										: "")
								}
							/>
							<Tab
								label="Assigned Projects"
								data-testid="proj-users-tab"
								className={
									"proj-info-tab " +
									(selectedTab === "assignedProjects"
										? "selected"
										: "")
								}
							/>
						</Tabs>
						{selectedTab === "userDetails" && (
							<>
								{userInfo && (
									<div className="FC user-detail-container">
										<div>Type: {userInfo.type} user</div>
										<div>Status: {userInfo.status}</div>
										<br />
										<br />
										<div>Name: {userInfo.name}</div>
										<div>
											User Alias ID: {userInfo.aliasId}
										</div>
										<div>Email: {userInfo.email}</div>
										<br />
										<br />
										<div>
											Organization Access Right:{" "}
											{userInfo.accessRight}
										</div>
										<div>
											Is Archive Admin?:
											{userInfo.isArchiveAdmin === "Yes"
												? " Yes"
												: " No"}
										</div>
										<div>
											Is Project Data Mgmt Admin?:
											{userInfo.isOrgProjectDataMgmtAdmin
												? " Yes"
												: " No"}
										</div>
										{/* <div>
											{userInfo.accessRight ===
												OrgRoleType.super &&
											userInfo.isArchiveAdmin
												? "Archive Admin"
												: ""}
										</div> */}
										<br />
										<br />
										<div>
											Assigned Groups (
											{userInfo.assignedGroups.length}):{" "}
										</div>
										<div>
											{userInfo.assignedGroups?.map(
												(group: any) => {
													return (
														<div
															key={group.replaceAll(
																" ",
																"-"
															)}
														>
															- {group}
														</div>
													)
												}
											)}
										</div>
										<br />
										<br />
										<div>
											Created By: {userInfo.createdBy}
										</div>
										<div>
											Created At: {userInfo.createdAt}
										</div>
										<br />
										<br />
										<div>
											Last Access: {userInfo.lastAccess}
										</div>
									</div>
								)}
							</>
						)}
						{selectedTab === "assignedProjects" && (
							<>
								<AssignedProjectsTab
									partialStore={orgStore.setupUsers}
									dateFormat={dateFormat}
									timeZone={timeZone}
									i18n={i18n}
								/>
							</>
						)}
					</StyledContainer>
				</>
			)}
		</>
	)
})

const AssignedProjectsTab = observer(
	({
		partialStore,
		dateFormat,
		timeZone,
		i18n,
	}: {
		partialStore: any
		dateFormat: any
		timeZone: any
		i18n: any
	}) => {
		const actionaName = "precheckSuspend"
		let { id: userId } = useParams<{ id: string }>()

		useEffect(() => {
			partialStore.precheckSuspend(userId)
		}, [])

		const actionStatus = partialStore.getActionStatus(actionaName)

		return (
			<>
				{actionStatus === "LOADING" && (
					<div
						className="FR AC JC"
						style={{
							width: "100%",
							height: "100%",
							marginTop: "200px",
						}}
					>
						<DLSpinner />
					</div>
				)}
				{actionStatus === "SUCCESS" && (
					<AssignedProjectsDialogContainer
						partialStore={partialStore}
						// dateFormat={dateFormat}
						// timeZone={timeZone}
						i18n={i18n}
					/>
				)}
				{actionStatus === "FAIL" && (
					<div className="heading FC AC">
						There are no projects assigned.
					</div>
				)}
			</>
		)
	}
)

const StyledContainer = styled.div`
	padding: 0 var(--spacing-xl);
	.user-detail-container {
		padding: 1rem;
	}
	.proj-info-tabs {
		min-height: 2rem;
		.proj-info-tab {
			min-width: 50%;
			border-top: 1px solid transparent;
			border-right: 1px solid transparent;
			border-left: 1px solid transparent;
			border-bottom: 1px solid ${(props) => props.theme.emphasis};
			min-height: 2rem;
			border-top-right-radius: 3px;
			border-top-left-radius: 3px;
			background-color: ${(props) => props.theme.shade05};
			font-size: 0.75rem;
			overflow: hidden;
			color: ${(props) => props.theme.textMediumEmphasis};
			&.selected {
				color: ${(props) => props.theme.emphasis};
				font-weight: ${(props) => props.theme.shared.fontBold};
				border-top: 1px solid ${(props) => props.theme.emphasis};
				border-right: 1px solid ${(props) => props.theme.emphasis};
				border-left: 1px solid ${(props) => props.theme.emphasis};
				border-bottom: 1px solid transparent;
				background-color: ${(props) => props.theme.background};
			}
			span {
				/* for the border bottom highlighter */
				background-color: transparent;
			}
		}
	}
	.heading {
		color: ${(props) => props.theme.shade40};
		margin-top: 13rem;
		font-weight: 2rem;
	}
`
