// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
import { idToString, IdType } from "../../../../library/converters/id-converter"
// ---------- response cases

const GetOrgUserList = (self: any) => ({
	getUserList() {
		// 0.
		const actionName = "getUserList"
		// TODO: This API can work without organization ID. Is this fine?

		// const orgId = localStorage.getItem("orgId")
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.

		self.readOrgUserList()
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					const userList = response.data.UserList
					self.setTotalUser(response.data.TotalCount)

					// initialize the existing user list of the store
					self.resetList()

					let usersList: any[] = []
					userList.map((user: any) => {
						let isArchiveAdmin = false
						if (user.ArchiveManager === 1) {
							isArchiveAdmin = true
						} else if (user.ArchiveManager === 0) {
							isArchiveAdmin = false
						} else {
							isArchiveAdmin = false
						}

						let userType
						if (user.UserType === 1) {
							userType = "internal"
						} else if (user.UserType === 2) {
							userType = "external"
						} else {
							userType = "TBD"
						}

						let status
						if (user.Status === 1) {
							status = "normal"
						} else if (user.Status === 2) {
							status = "suspended"
						} else {
							status = "TBD"
						}

						// let lastAccess
						// if (user.LastAccessedOn === null) {
						// 	lastAccess = null
						// } else {
						// 	lastAccess = user.LastAccessedOn.toString()
						// }

						// NOTE: Archive admin does not exist on the current application
						// it is a combined role: SA + Archive mgmt permission
						let accessRight
						if (user.AccessTypeID === 3) {
							accessRight = "User Access"
						} else if (user.AccessTypeID === 2) {
							accessRight = "Group Admin"
						} else if (user.AccessTypeID === 1) {
							accessRight = "Super Admin"
						} else if (user.AccessTypeID === 4) {
							accessRight = "External User"
						} else if (user.AccessTypeID === 0) {
							accessRight = "Undefined" // ??? Samduk has one undefined access right account. What's this..
						} else {
							console.error(
								actionName,
								"Not matched access right",
								user.AccessTypeID
							)
						}

						const reOrganizedUser = {
							id: idToString(user.UserId, IdType.user),
							type: userType,
							name: user.Name,
							aliasId: user.UserName,
							email: user.Email,
							title: user.Title,
							status: status,
							accessRight,
							isArchiveAdmin: isArchiveAdmin ? "Yes" : "No",
							isOrgProjectDataMgmtAdmin:
								user.isOrgProjectDataMgmtAdmin,
							assignedGroups: user.GroupName.split(","),
							createdAt: user.CreatedDate,
							createdBy:
								user.CreatedUser === null
									? "null"
									: user.CreatedUser,
							lastAccess: user.LastAccessedOn,
						}
						usersList.push(reOrganizedUser)
						// self.pushItemToList(reOrganizedUser)
					})
					self.setUserList(usersList)
					self.setNeedRefresh(false)

					// update success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage:
							"Success to get the organization user list ",
						open: true,
						autoHide: true,
					})
				} else {
					// update fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message || response.data.message,

						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default GetOrgUserList
