import { flow } from "mobx-state-tree"
import { ProjCheckinApiProps } from "../proj-checkin.apis"

const ReadCurrentUserOnProj = (
	self: any,
	readCurrentUserOnProj: ProjCheckinApiProps["apiReadCurrentUserOnProj"]
) => ({
	readCurrentUserOnProj: flow(function* () {
		const actionName = "readCurrentUserOnProj"
		try {
			const response = yield readCurrentUserOnProj()
			return response
		} catch (error) {
			self.handleModelError({
				actionName,
				error,
				open: true,
			})

			return false
		}
	}),
})

export default ReadCurrentUserOnProj
