import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import { formatISO, addDays, addYears, subDays } from "date-fns"
import {
	getProjDownloadCenterZipProgress,
	getProjDownloadCenterZipType,
} from "../data-models/proj-download-center.data-model"

const GetProjDownloadCenterList = (self: any) => ({
	getProjDownloadCenterList() {
		const actionName = "getProjDownloadCenterList"
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})

		self.readProjDownloadCenterList()
			.then((response: any) => {
				// if success
				if (response.status === 200) {
					const { data } = response.data
					const fetchedProjDownloadCenter = data.map((item: any) => {
						const reOrganizedEngType = {
							id: item.projectDataMgmtZipFileId,
							zipFileName: item.projectDataMgmtZipFileName,
							folderId: item.folderId,
							folderName: item.folderName,
							createdAt: item.createdAt,
							createdById: item.createdBy,
							createdByName: item.createdByName,
							zipType: getProjDownloadCenterZipType(item.zipType), //"Project Zip",
							projectDataType: item.projectDataType,
							size: item.size,
							zipProgress: getProjDownloadCenterZipProgress(
								item.status
							),
							zipStatus: "-",
							lastRequestedBy: item.lastRequestedBy,
							lastRequestedByName: item.lastRequestedByName,
							lastRequestedAt: item.lastRequestedAt,
							expiredAt: item.expiredAt, // 2025-03-17T11:50:53.943265+00:00
							isDeleted: !!item.deletedBy,
							deletedBy: item.deletedBy,
							deletedAt: item.deletedAt,
							zipRequestReason: item.description,
							isCompleted: item.isCompleted,
							isDownloadable: item.isDownloadable,
						}
						return reOrganizedEngType
					})
					self.setProjDownloadCenterList(fetchedProjDownloadCenter)
					self.setNeedRefresh(false)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage:
							"Success to get the proj download center list",
						open: true,
						autoHide: true,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message || response.data.message,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default GetProjDownloadCenterList
