import { types } from "mobx-state-tree"
import MapNumbersPresentationViewModel from "./map-numbers-presentation.view-model"
import MapNumbersPresentationModel from "./map-numbers-presentation.model"

export const MapNumbersPresentationStore = () =>
	types
		.compose(MapNumbersPresentationModel(), MapNumbersPresentationViewModel)
		.named("MapNumbersPresentationStore")

export const initialStore = {
	clickPoint: {
		mouseX: null,
		mouseY: null,
	},
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const MapNumbersPresentationStoreInstance =
	MapNumbersPresentationStore().create(initialStore)

export default MapNumbersPresentationStoreInstance
