import { flow } from "mobx-state-tree"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"
import { CreateFileFromTempProps } from "../../apis/file-system-file-apis"

export type CreateFileFromTempActionProps = {
	projectId: string
	userId: string
	parentId: string
	srcFileId: string
	fileName: string
	fileAliasId: string
	prepared: boolean
	reviewed: boolean
	userProjRoleId: string
}

const CreateFileFromTemp = (self: any, createFile: any) => ({
	createFileFromTemp: flow(function* (props: CreateFileFromTempActionProps) {
		const actionName = "createFileFromTemp"
		const {
			projectId,
			userId,
			parentId,
			srcFileId,
			fileName,
			fileAliasId,
			prepared,
			reviewed,
		} = props
		// 1. check the props existence
		if (
			projectId === undefined ||
			userId === undefined ||
			parentId === undefined ||
			srcFileId === undefined ||
			fileName === undefined ||
			fileAliasId === undefined
		) {
			alert(
				`(${actionName}) Undefined prop exist. ${parentId} ${srcFileId} ${fileName} ${fileAliasId} `
			)
			return
		}
		//
		//
		// 2. organize the params
		const projNumId = idToNumber(projectId, IdType.project)
		const userNumId = idToNumber(userId, IdType.user)

		const parentNumId = idToNumber(parentId, IdType.folder)
		const srcFileNumId = idToNumber(srcFileId, IdType.file)

		let signOffType: number[] = []
		if (prepared) {
			signOffType.push(1)
		}
		if (reviewed) {
			signOffType.push(2)
		}

		//
		//
		// 3. validate format
		if (
			typeof projNumId === "number" &&
			typeof userNumId === "number" &&
			typeof parentNumId === "number" &&
			typeof srcFileNumId === "number"
		) {
			// omitted: ClientID, FinancialYear
			const payload: CreateFileFromTempProps = {
				WPEF: fileAliasId,
				ParentMediaID: parentNumId,
				ClientID: projNumId,
				TemplateFileID: srcFileNumId,
				UserID: userNumId,
				WPDesc: fileName,
				signOffType,
			}

			try {
				const response = yield createFile(payload)
				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.log(actionName+ "__error "+ error)
				self.handleModelError({
					actionName,
					error,
					openSnackbar: true,
				})

				return false
			}
		} else {
			alert(
				`(${actionName}) Invalid props. ${parentNumId} ${srcFileNumId}`
			)
			return false
		}
	}),
})

export default CreateFileFromTemp
