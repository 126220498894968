import React, { useEffect, useMemo, useState } from "react"
import { observer } from "mobx-react-lite"
import { DLDialog } from "../../../../../components/basic-elements"
import styled from "styled-components"
import { DLSearchBoxBasic } from "../../../../../components/basic-elements/search/DLSearchBox"
import { AssignStatus } from "../../../../../stores/org-sub-stores/org-clients-store/data-models/org-clients.data-models"

const AssignedDetailsDialog = observer(
	({ partialStore }: { partialStore: any }) => {
		const [searchText, setSearchText] = useState("")

		// Get group info safely
		const groupInfo = useMemo(
			() => partialStore.getGroupInfoById(partialStore.selectedGroup),
			[partialStore.selectedGroup]
		)
		const groupId = groupInfo?.groupId

		// Check selected type
		const isSelectedTypeUsers = partialStore.selectedGroupType === "Users"
		const actionName = isSelectedTypeUsers
			? "getAssignedUsersOfGroup"
			: "clientListASSIGNED"
		const isLoading = partialStore.getActionStatus(actionName) === "LOADING"

		// Get assigned list
		const assignedList = isSelectedTypeUsers
			? partialStore.assignedUsersOfGroup.map(
					(item: any) => `[${item.aliasId}] ${item.name}`
			  )
			: partialStore.assignedClientList.map(
					(item: any) => `[${item.clientAliasId}] ${item.name}`
			  )

		// Filtered search list
		const filteredList = searchText
			? assignedList.filter((item: string) =>
					item.toLowerCase().includes(searchText.toLowerCase())
			  )
			: assignedList

		// Handle search input
		const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) =>
			setSearchText(e.target.value)

		// Close dialog action
		const handleClose = () => {
			partialStore.resetAssignedUserList()
			partialStore.resetAssignedClientList()
		}

		// Fetch assigned users/clients when groupId changes
		useEffect(() => {
			if (!groupId) return
			if (isSelectedTypeUsers) {
				partialStore.getAssignedUsersOfGroup(groupId)
			} else {
				partialStore.getClientsByGroup(groupId, AssignStatus.assigned)
			}
		}, [groupId, isSelectedTypeUsers])

		return (
			<DLDialog
				eleTestId="assigned-details-dialog"
				isOpen={partialStore.showAssignedDetailsDialog}
				setIsOpen={partialStore.setShowAssignedDetailsDialog}
				showOpenBtn={false}
				showCloseBtn
				maxWidth="xs"
				dialogTitle={`${
					groupInfo?.groupName || "Unknown Group"
				} - Assigned ${partialStore.selectedGroupType} (${
					assignedList.length
				})`}
				dialogContents={
					<StyledList>
						<DLSearchBoxBasic
							id="project-search"
							eleTestId="search_text_box"
							iconPlacement="end"
							onChange={handleSearch}
							eleValue={searchText}
							placeholder="Search"
							closeHandler={() => setSearchText("")}
						/>
						<div className="list-items">
							{filteredList.length === 0 && (
								<div className="no-items">No items found.</div>
							)}
							<ul>
								{filteredList.map((item: string) => (
									<li key={item}>{item}</li>
								))}
							</ul>
						</div>
					</StyledList>
				}
				cancelBtnText="Close"
				showSpinner={isLoading}
				cannotUpdate={isLoading}
				closeAction={handleClose}
			/>
		)
	}
)

const StyledList = styled.div`
	.list-items {
		height: 300px;
		overflow-y: scroll;
		li {
			padding: 5px;
		}
	}
	.no-items {
		padding: 10px;
		color: gray;
	}
`
export default AssignedDetailsDialog
