import React, { useEffect, useState, useCallback } from "react"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import {
	DLDialog,
	InputWithLabel,
	DLInputField,
	DLButton,
	DLIconButton,
	DLSystemMsg,
	DLSpinner,
	ConsoleLog,
} from "../../../../../components/basic-elements"
import Icon from "@mdi/react"
import { mdiClose, mdiFilePlusOutline } from "@mdi/js"
import Grid from "@material-ui/core/Grid"
import DLTextArea from "../../../../../components/basic-elements/input-elements/DLTextArea"
import sharedRegEx from "../../../../../library/sharedRegEx"
import useForm from "../../../../../library/use-form"
import { formatFileSize } from "@datalobby/common"
import { v4 as uuidv4 } from "uuid"
import UnarchiveProgressDialog from "./UnarchiveProgressDialog"
import {
	DLI18nProps,
	DnTFormatProps,
} from "../../../../../common-models/types/common-props"
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"
import {
	idToNumber,
	IdType,
} from "../../../../../library/converters/id-converter"
import { requestToken } from "../../../../project-side/proj-access-control/store/proj-checkin.apis"
import PreProcessPDF from "./PreProcessPDF"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import { allowedFormats } from "../../../../../service-modules/file-module/data-models/dl-file-control-model"

export default observer(function ArchivedProjectUnarchiveDialog({
	i18n,
}: {
	i18n: DLI18nProps
}) {
	const store = useRootStore()
	const orgStore = useOrgStore()

	const actionName = "unarchiveProject"

	const dnt = store.global.getDntFormat

	const projectInfo = orgStore.archiveMgmt.getClientInfoById(
		orgStore.archiveMgmt.selectedProj,
		dnt.dateFormat,
		dnt.timeZone,
		dnt.timeFormat
	)

	const inputsSchema = {
		email: { value: "", error: "", requestInput: false },
		reason: { value: "", error: "", requestInput: false },
	}
	const validationSchema = {
		email: {
			isRequired: true,
			validator: {
				regEx: sharedRegEx.email, // TODO: update is required
				error: "Invalid format",
			},
		},
		reason: {
			isRequired: true,
		},
	}

	const [hasTotalSizeErr, setHasTotalSizeErr] = useState(false)
	const [filesData, setFilesData] = useState<any>([])

	const open = orgStore.archiveMgmt.archivedProjectUnarchiveDialogOpen
	const projectId = orgStore.archiveMgmt.selectedProj

	useEffect(() => {
		// when open
		if (open) {
		}
	}, [open])

	useEffect(() => {
		let total = 0
		filesData.map((file: any) => {
			total = total + file.size
		})
		const fmtSize = formatFileSize(total).split(" ")
		setHasTotalSizeErr(
			fmtSize[1] === "MB"
				? Math.ceil(+fmtSize[0]) > 100
					? true
					: false
				: false
		)
		ConsoleLog("updateTotalSize " + hasTotalSizeErr)
	}, [filesData.length])

	// useEffect(() => {
	// 	const userNumId = idToNumber(orgStore.checkin.orgUserId, IdType.user)
	// 	const projNumId = idToNumber(projectId, IdType.project)

	// 	// to hide the pdf file list
	// 	orgStore.archiveMgmt.responses.setResponse("getPreConditionStatus", {
	// 		actionName: "getPreConditionStatus",
	// 		status: ActionStatus.standby,
	// 	})

	// 	ConsoleLog(userNumId, projNumId)

	// 	if (typeof projNumId === "number" && typeof userNumId === "number") {
	// 		requestToken({ UserId: userNumId, ClientId: projNumId }).then(
	// 			(response: any) => {
	// 				if (response.data.status.code === 200) {
	// 					localStorage.setItem(
	// 						"latestProjToken",//"dlProjToken",
	// 						JSON.stringify(response.data.data.access_token)
	// 					)
	// 				} else {
	// 					ConsoleLog(
	// 						"Cannot request the pre-process condition status. Failed to request project token"
	// 					)
	// 				}
	// 				// NOTE: After API update, remain only the below one line
	// 				orgStore.archiveMgmt.getPreConditionStatus()
	// 			}
	// 		)
	// 	} else {
	// 		ConsoleLog(
	// 			"Cannot request the pre-process condition status. Project ID or User ID is incorrect."
	// 		)
	// 	}
	// }, [])

	const handleChangeFile = (event: any) => {
		const choosedFiles = Array.from(event.target.files)
		choosedFiles.map((item: any) => {
			const { size } = item
			const formattedSize = formatFileSize(item.size, 2)
			const fileExtension = item.name.slice(item.name.lastIndexOf("."))
			const fileName = item.name.slice(0, item.name.lastIndexOf("."))
			const fmtSize = formattedSize.split(" ")
			const hasSizeErr =
				fileExtension === ".zip" ? size > 100000000 : size > 30000000
			setFilesData((filesData: any) => [
				...filesData,
				{
					tempId: uuidv4(),
					MediaType: fileExtension,
					WPEF: "",
					file: item,
					WPDesc: fileName,
					isChecked: false,
					size: size,
					formattedSize: formattedSize,
					isLoading: false,
					hasSizeErr: hasSizeErr,
				},
			])
		})
	}

	const removeFilefromList = useCallback((id: any) => {
		setFilesData((files: any) =>
			files.filter((file: any) => file.tempId !== id)
		)
	}, [])

	const urarchiveProject = () => {
		const reason = inputs.reason.value
		orgStore.archiveMgmt.setUnarchiveProgressDialogOpen(true)
		ConsoleLog(filesData)
		orgStore.archiveMgmt.unarchive(projectId, reason, filesData)
	}

	const {
		inputs,
		handleOnChange,
		handleOnSubmit,
		isReady,
		// initializeInputs
	} = useForm(inputsSchema, validationSchema, urarchiveProject, open)

	const userEmail = localStorage.getItem("lobbyUserId")
	const actionStatus = orgStore.archiveMgmt.getActionStatus(actionName)
	// const preProcessStatus =
	// 	orgStore.archiveMgmt.preConditionData.isPreProcessed

	return (
		<>
			<DLDialog
				eleTestId="archived-project-unarchive-dialog"
				isOpen={orgStore.archiveMgmt.archivedProjectUnarchiveDialogOpen}
				setIsOpen={
					orgStore.archiveMgmt.setArchivedProjectUnarchiveDialogOpen
				}
				handleAction={handleOnSubmit}
				showCloseBtn={true}
				dialogTitle={`${i18n.twUnarchive} - ${projectInfo?.title}`}
				dialogContents={
					actionStatus === ActionStatus.loading ? (
						<UnarchivingLoader />
					) : actionStatus === ActionStatus.success ? (
						<UnarchiveDetails
							partialStore={orgStore.archiveMgmt}
							dntFormat={dnt}
						/>
					) : (
						// <>
						// 	{preProcessStatus ? (
						<UnarchivedForm
							inputs={inputs}
							handleOnChange={handleOnChange}
							filesData={filesData}
							handleChangeFile={handleChangeFile}
							removeFilefromList={removeFilefromList}
							i18n={i18n}
						/>
						// 	) : (
						// 		<PreProcessPDF
						// 			partialStore={orgStore.archiveMgmt}
						// 		/>
						// 	)}
						// </>
					)
				}
				maxWidth="sm"
				actionReady={
					isReady &&
					filesData.length > 0 &&
					!hasTotalSizeErr &&
					userEmail === inputs.email.value &&
					filesData.every((i: any) => !i.hasSizeErr) &&
					actionStatus !== ActionStatus.loading &&
					actionStatus !== ActionStatus.success
				}
				fullWidth={true}
				actionBtn={
					actionStatus !== ActionStatus.success
						? i18n.twUnarchive
						: ""
				}
				cancelBtnText={
					actionStatus !== ActionStatus.success ? i18n.twCancel : ""
				}
				dialogError={
					hasTotalSizeErr
						? "Overall file size should not exceed 100MB"
						: undefined
				}
				showSpinner={
					orgStore.archiveMgmt.getActionStatus(
						"getPreConditionStatus"
					) === "LOADING"
				}
			/>
		</>
	)
})

const UnarchivedForm = observer(
	({
		inputs,
		handleOnChange,
		filesData,
		handleChangeFile,
		removeFilefromList,
		i18n,
	}: {
		inputs: any
		handleOnChange: any
		filesData: any
		handleChangeFile: any
		removeFilefromList: any
		i18n: DLI18nProps
	}) => {
		const userEmail = localStorage.getItem("lobbyUserId")
		return (
			<StyledUnarchiveForm>
				<p>{i18n.tsUnarchiveMsg}</p>
				<div className="input-section FR">
					<InputWithLabel required label={i18n.twEmail}>
						<DLInputField
							autoFocus
							eleTestId="unarchive_email_input"
							eleFullWidth
							eleName="email"
							eleValue={inputs.email.value}
							eleHandleChange={handleOnChange}
							eleRequired
							warningMsg={inputs.email.error}
							requestInput={inputs.email.requestInput}
						/>
						{inputs.email.value !== "" &&
							userEmail?.toLowerCase() !==
								inputs.email.value.toLowerCase() && (
								<DLSystemMsg
									eleTestId="msg-warning-email-match"
									type={MessageColorType.red}
									msg={
										i18n.twEmailDoesNotMatch ||
										"Email does not match"
									}
								/>
							)}
					</InputWithLabel>
				</div>
				<div className="input-section FR">
					<InputWithLabel required label={i18n.twReason2}>
						<DLTextArea
							eleTestId="reason_input_area"
							eleFullWidth
							eleName="reason"
							eleValue={inputs.reason.value}
							eleHandleChange={handleOnChange}
							eleRequired
							warningMsg={inputs.reason.error}
							requestInput={inputs.reason.requestInput}
							eleFieldHeight="5"
						/>
					</InputWithLabel>
				</div>
				<div>
					<Grid container spacing={4}>
						<Grid item xs={3}>
							{i18n.twFileType}
						</Grid>
						<Grid item xs={3}>
							{i18n.twFileSize}
						</Grid>
						<Grid item xs={2}>
							{i18n.twFileName}
						</Grid>
						<Grid item xs={4}>
							<DLButton
								eleTestId="choose-files-btn"
								startIcon={
									<Icon
										path={mdiFilePlusOutline}
										size={0.8}
									/>
								}
							>
								<label className="file-upload-btn-wrapper">
									{i18n.twChooseFiles}
									<input
										type="file"
										name="file"
										onChange={handleChangeFile}
										multiple
										data-testid="file-input"
										accept={allowedFormats}
									/>
								</label>
							</DLButton>
						</Grid>
					</Grid>
					<hr />
					{filesData.map((item: any) => {
						return (
							<div
								key={item.tempId}
								className={`${item.hasSizeErr && "file-error"}`}
							>
								<Grid container spacing={4}>
									<Grid item xs={3}>
										{item.MediaType}
									</Grid>
									<Grid item xs={3}>
										{item.formattedSize}
									</Grid>
									<Grid item xs={3}>
										{item.WPDesc}
									</Grid>
									<Grid item xs={3}>
										<DLIconButton
											clickHandler={() =>
												removeFilefromList(item.tempId)
											}
											color="warning"
											align="center"
											eleTestId="close-btn"
											tooltipText="Remove file"
											size="small"
										>
											<Icon path={mdiClose} size={1} />
										</DLIconButton>
									</Grid>
								</Grid>
								{item.hasSizeErr && (
									<>
										{item.MediaType === ".zip" ? (
											<p>
												ZIP file size should not exceed
												100MB
											</p>
										) : (
											<p>
												File size should not exceed 30MB
											</p>
										)}
									</>
								)}
							</div>
						)
					})}
					{filesData.length < 1 && (
						<div className="no-files">
							Please upload atleast one file
						</div>
					)}
				</div>
				<hr />
				<div className="notes-reason-docs">
					<p> Note: </p>
					<p>1). Each file size should not exceed 30MB</p>
					<p>2). ZIP file size should not exceed 100MB</p>
					<p>
						3). Overall file size should not exceed 100MB otherwise
						it might impact Archive Process
					</p>
				</div>
			</StyledUnarchiveForm>
		)
	}
)

const UnarchivingLoader = () => {
	return (
		<div>
			<h3>
				Project Unarchiving is in progress. Please do not press any
				key/button.
			</h3>
			<div
				className="FR AC JC"
				style={{
					width: "100%",
					height: "100%",
					marginTop: 70,
					marginBottom: 50,
				}}
			>
				<DLSpinner />
			</div>
		</div>
	)
}

const UnarchiveDetails = ({
	partialStore,
	dntFormat,
}: {
	partialStore: any
	dntFormat: DnTFormatProps
}) => {
	const unarchivedDetails = partialStore.viewUnarchivedDetails(dntFormat)
	return (
		<div
			className="unarchive-details"
			style={{
				alignItems: "center",
				textAlign: "center",
			}}
		>
			<p>
				<b>
					Successfully Unarchived. You will be shortly notified
					regarding the Unarchived project status through email
					notification
				</b>
			</p>
			<p>{unarchivedDetails.unarchivedDateTime}</p>
			<p>Engagement Partner: {unarchivedDetails.engagementPartner}</p>
			<p>Unarchived By: {unarchivedDetails.unarchivedBy}</p>
			<DLButton
				eleTestId="unarchive-btn-ok"
				clickHandler={() =>
					partialStore.setArchivedProjectUnarchiveDialogOpen(false)
				}
				color="primary"
			>
				OK
			</DLButton>
		</div>
	)
}

const StyledUnarchiveForm = styled.div`
	padding-left: 0.5rem;
	.input-section {
		margin-bottom: 1.5rem;
		.label {
			min-width: 9rem;
		}
		.input-area {
			/* width: calc(100% - 9rem); */
			min-width: 20rem;
		}
	}
	.no-files {
		height: 100px;
		text-align: center;
	}
	.file-upload-btn-wrapper {
		input[type="file"] {
			display: none;
		}
	}
	.file-error {
		color: red;
	}
`
