import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"
import CryptoJS from "crypto-js"
import { TOKEN_TYPE } from "../../../../../library/api-requests/shared-for-api-requests"

const selectedToken = (tokenType: TOKEN_TYPE) => {
	const tokenFromLocalStorage = localStorage.getItem(tokenType)
	if (tokenFromLocalStorage) {
		return JSON.parse(tokenFromLocalStorage)
		// } else if (tokenType !== "none" && !tokenFromLocalStorage) {
		// 	alert(`(${tokenType}) Cannot find the token`)
	}
	// return `Failed to find the ${tokenType}`
}
const version = process.env.REACT_APP_API_VERSION

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 *  - loading, success, fail
 */
const EditPassword = (self: any) => ({
	editPassword(password: { curPw: any; newPw: any }) {
		// 0.
		const actionName = "editPassword"
		// 1.
		// 2.
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 111,
			color: MessageColorType.green,
			open: true,
		})
		// 3.
		const default_key = process.env.REACT_APP_encryption_key
		const default_iv = process.env.REACT_APP_encryption_iv

		const key = default_key && CryptoJS.enc.Utf8.parse(default_key)
		const iv = default_iv && CryptoJS.enc.Utf8.parse(default_iv)

		const encryptedCurrentPw = CryptoJS.AES.encrypt(
			CryptoJS.enc.Utf8.parse(password.curPw.value.trim()),
			key,
			{
				keySize: 128 / 8,
				iv: iv,
				mode: CryptoJS.mode.CBC,
				padding: CryptoJS.pad.Pkcs7,
			}
		)
		const encryptedNewPw = CryptoJS.AES.encrypt(
			CryptoJS.enc.Utf8.parse(password.newPw.value.trim()),
			key,
			{
				keySize: 128 / 8,
				iv: iv,
				mode: CryptoJS.mode.CBC,
				padding: CryptoJS.pad.Pkcs7,
			}
		)
		const currentPw = encryptedCurrentPw.toString()
		const newPw = encryptedNewPw.toString()
		const emialId = localStorage.getItem("lobbyUserId")
		let payload = {
			EmailID: emialId,
			// UserID: 546,
			Password: currentPw,
			NewPwd: newPw,
			AWSAccessToken: selectedToken(TOKEN_TYPE.awsToken),
		}

		self.updatePassword(payload)
			.then((response: any) => {
				if (response) {
					let responseStatus = 0
					let serverMessage = ""
					// * ----- API version control
					const basicStatus = response.status
					const prevStatus = response.data?.Status
					//
					if (version === "v2") {
						responseStatus = basicStatus
						serverMessage =
							response.data.ValidationResults[0].ErrorMessage
						// NOTE: Need to update for 401 error
					} else {
						if (basicStatus === 200 && prevStatus === 200) {
							responseStatus = 200
						}

						const { Message, message } = response.data || {}
						serverMessage =
							Message ?? message ?? "Something wrong..."
					}

					if (responseStatus === 200) {
						// if success
						self.setChangePwDialogOpen(false)
						// update success case response
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200,
							color: MessageColorType.blue,
							open: true,
							autoHide: true,
							autoHideDuration: 2000,
							customMessage: serverMessage,
						})
					} else {
						self.handleResponse({
							actionName,
							status: ActionStatus.fail,
							code: 999,
							color: MessageColorType.orange,
							customMessage: serverMessage,
							open: true,
						})
					}
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default EditPassword
