import React from "react"
import Icon from "@mdi/react"
import {
	mdiViewDashboardOutline,
	mdiFolderInformationOutline,
	mdiClipboardCheckOutline,
	mdiCalendarClock,
	mdiCalculatorVariant,
	mdiSitemap,
	mdiFileOutline,
	mdiFile,
	mdiFileChart,
	mdiShieldLock,
	mdiSettingsOutline,
	mdiInformationOutline,
	mdiDomain,
	mdiFormatListBulleted,
	mdiViewColumn,
	mdiFileDocumentEditOutline,
	mdiTableEdit,
	mdiCheckboxMarkedOutline,
	mdiHistory,
	mdiFileDocumentBoxCheckOutline,
	mdiAccountMultiple,
	mdiTrashCanOutline,
	mdiHelpRhombusOutline,
	mdiBookOpenOutline,
	mdiAlertCircleCheckOutline,
	mdiMessageBulleted,
	mdiSettings,
	mdiFileMultipleOutline,
	mdiBullhorn,
	mdiContentCopy,
	mdiContentPaste,
	mdiFileExcel,
} from "@mdi/js"
import { DLProjMenuGroups, DLProjSubMenus } from "./proj-menus-enum"

/**
 * 1. Project Dashboard
 * 2. Informations
 * 3. Note and Comments
 * 4. PBC
 * 5. Task and Time
 * 6. Financial Statements
 * 7. Reconciliation
 * 8. SOX
 * 9. Workpapers
 * 10. Permanent Files
 * 11. Reports
 * 12. Archive
 * 13. Setup
 */
const projMenuIconList = [
	/**
	 * 1. Project Dashboard
	 * ---
	 **/
	{
		menuId: DLProjMenuGroups.dashboard,
		icon: <Icon path={mdiViewDashboardOutline} size={1} />,
	},

	/**
	 * 2. Informations
	 * --- Project informations
	 * --- Entity informations
	 * --- CoA List
	 **/
	{
		menuId: DLProjMenuGroups.info,
		icon: <Icon path={mdiFolderInformationOutline} size={1} />,
	},
	{
		menuId: DLProjSubMenus.proj_info,
		icon: <Icon path={mdiInformationOutline} size={0.8} />,
	},
	{
		menuId: DLProjSubMenus.entity_info,
		icon: <Icon path={mdiDomain} size={0.8} />,
	},
	{
		menuId: DLProjSubMenus.coa_list,
		icon: <Icon path={mdiFormatListBulleted} size={0.8} />,
	},

	/**
	 * 3. Note and Comments
	 * --- Note
	 * --- Comments
	 **/
	{
		menuId: DLProjMenuGroups.communication,
		icon: <Icon path={mdiMessageBulleted} size={1} />,
	},
	{
		menuId: DLProjSubMenus.board,
		icon: <Icon path={mdiBullhorn} size={0.8} />,
	},
	{
		menuId: DLProjSubMenus.note,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	{
		menuId: DLProjSubMenus.comments,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},

	/**
	 * 4. PBC
	 * ---
	 **/
	{
		menuId: DLProjMenuGroups.pbc,
		icon: <Icon path={mdiClipboardCheckOutline} size={1} />,
	},

	/**
	 * 5. Task and Time
	 * ---
	 **/
	{
		menuId: DLProjMenuGroups.task_mgmt,
		icon: <Icon path={mdiCalendarClock} size={1} />,
	},

	/**
	 * 6. Financial Statements
	 * --- Ledgers
	 * --- Trial Balance
	 * --- Closing (TBD)
	 * --- Leadsheet
	 * --- FS Papers
	 **/
	{
		menuId: DLProjMenuGroups.fs,
		icon: <Icon path={mdiCalculatorVariant} size={1} />,
	},
	{
		menuId: DLProjSubMenus.fs_setup,
		icon: <Icon path={mdiBookOpenOutline} size={0.8} />,
	},
	{
		menuId: DLProjSubMenus.fs_fs,
		icon: <Icon path={mdiCalculatorVariant} size={0.8} />,
	},
	{
		menuId: DLProjSubMenus.ledgers,
		icon: <Icon path={mdiBookOpenOutline} size={0.8} />,
	},
	{
		menuId: DLProjSubMenus.tb,
		icon: <Icon path={mdiTableEdit} size={0.8} />,
	},
	{
		menuId: DLProjSubMenus.closing,
		icon: <Icon path={mdiTableEdit} size={0.8} />,
	},
	{
		menuId: DLProjSubMenus.fs_ls,
		icon: <Icon path={mdiViewColumn} size={0.8} />,
	},
	{
		menuId: DLProjSubMenus.fs_papers,
		icon: <Icon path={mdiFileDocumentEditOutline} size={0.8} />,
	},

	/**
	 * 7. Reconciliation
	 * ---
	 **/
	{
		menuId: DLProjMenuGroups.reconcil,
		icon: <Icon path={mdiHelpRhombusOutline} size={1} />,
	},

	/**
	 * 8. SOX
	 * --- Planning
	 * --- Risks
	 * --- Process
	 * --- Controls
	 * --- Leadsheet
	 * --- Report
	 **/
	{
		menuId: DLProjMenuGroups.sox,
		icon: <Icon path={mdiSitemap} size={1} />,
	},
	{
		menuId: DLProjSubMenus.planning,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	{
		menuId: DLProjSubMenus.risk,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	{
		menuId: DLProjSubMenus.process,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	{
		menuId: DLProjSubMenus.controls,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	{
		menuId: DLProjSubMenus.sox_ls,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},
	{
		menuId: DLProjSubMenus.sox_report,
		icon: <Icon path={mdiHelpRhombusOutline} size={0.8} />,
	},

	/**
	 * X. Files
	 * ---
	 **/
	{
		menuId: DLProjMenuGroups.files,
		icon: <Icon path={mdiFileMultipleOutline} size={1} />,
	},
	{
		menuId: DLProjSubMenus.wp,
		icon: <Icon path={mdiFileOutline} size={1} />,
	},
	{
		menuId: DLProjSubMenus.pf,
		icon: <Icon path={mdiFile} size={1} />,
	},

	/**
	 * 11. Reports
	 * ---
	 **/
	{
		menuId: DLProjMenuGroups.reports,
		icon: <Icon path={mdiFileChart} size={1} />,
	},
	{
		menuId: DLProjSubMenus.report,
		icon: <Icon path={mdiFileChart} size={1} />,
	},
	{
		menuId: DLProjSubMenus.checklist,
		icon: <Icon path={mdiContentPaste} size={1} />,
	},

	/**
	 * 12. Archive
	 * --- Archive Diagnosis
	 * --- Sign off history
	 * --- Rationales
	 * --- Archive History
	 **/
	{
		menuId: DLProjMenuGroups.archive,
		icon: <Icon path={mdiShieldLock} size={1} />,
	},
	{
		menuId: DLProjSubMenus.arch_diagnosis,
		icon: <Icon path={mdiCheckboxMarkedOutline} size={1} />,
	},
	{
		menuId: DLProjSubMenus.signoff_history,
		icon: <Icon path={mdiFileDocumentBoxCheckOutline} size={1} />,
	},
	{
		menuId: DLProjSubMenus.rationales,
		icon: <Icon path={mdiAlertCircleCheckOutline} size={1} />,
	},
	{
		menuId: DLProjSubMenus.arch_history,
		icon: <Icon path={mdiHistory} size={1} />,
	},

	/**
	 * 13. Setup
	 * --- Users
	 * --- Permission
	 * --- Trash
	 **/
	{
		menuId: DLProjMenuGroups.setup,
		icon: <Icon path={mdiSettingsOutline} size={1} />,
	},
	{
		menuId: DLProjSubMenus.users,
		icon: <Icon path={mdiAccountMultiple} size={1} />,
	},
	{
		menuId: DLProjSubMenus.roles,
		icon: <Icon path={mdiSettings} size={1} />,
	},
	{
		menuId: DLProjSubMenus.trash,
		icon: <Icon path={mdiTrashCanOutline} size={1} />,
	},
	{
		menuId: DLProjSubMenus.notification,
		icon: <Icon path={mdiSettings} size={1} />,
	},
	{
		menuId: DLProjSubMenus.roll_forward,
		icon: <Icon path={mdiContentCopy} size={1} />,
	},
	/**
	 * 14. FS Data
	 * --- Import FS Datasets
	 * --- Assign FS Accounts
	 * --- FS Accounts
	 * --- Financial Group
	 **/
	{
		menuId: DLProjMenuGroups.fs_data,
		icon: <Icon path={mdiCalculatorVariant} size={1} />,
	},
	{
		menuId: DLProjMenuGroups.fs_transaction,
		icon: <Icon path={mdiCalculatorVariant} size={1} />,
	},
	{
		menuId: DLProjMenuGroups.fs_adjustments,
		icon: <Icon path={mdiCalculatorVariant} size={1} />,
	},
	{
		menuId: DLProjMenuGroups.fs_excel_import,
		icon: <Icon path={mdiFileExcel} size={1} />,
	},
	{
		menuId: DLProjMenuGroups.proj_data_mgmt,
		icon: <Icon path={mdiFileMultipleOutline} size={1} />,
	},
]

export default projMenuIconList
