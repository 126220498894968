export const EMAIL_TEMPLATE_DATA = [
	{
		id: "archivePolicyId1",
		title: "Success - en Set",
		description: "",
		parentId: null,
		expanded: true,
		type: "policy",
		archiveDatePolicyItems: [
			{
				ArchiveDatePolicyItemId: 1,
				DaysString: "15 Days",
				IsActive: true,
				ActivatedAtUtc: "2024-11-22T07:50:49.863046+00:00",
				ActivatedByUserName: null,
				ActivatedByUserId: 0,
				SortOrder: 1,
				DeActivatedAtUtc: null,
				DeActivatedByUserName: null,
				DeActivatedByUserId: null,
				CreatedAtUtc: "2024-11-22T07:49:37.792768+00:00",
				CreatedByUserName: null,
				CreatedByUserId: 0,
				ModifiedAtUtc: "2024-11-22T07:49:37.792768+00:00",
				ModifiedByUserName: null,
				ModifiedByUserId: 0,
			},
			{
				ArchiveDatePolicyItemId: 2,
				DaysString: "30 Days",
				IsActive: true,
				ActivatedAtUtc: "2024-11-22T07:50:49.863046+00:00",
				ActivatedByUserName: null,
				ActivatedByUserId: 0,
				SortOrder: 2,
				DeActivatedAtUtc: null,
				DeActivatedByUserName: null,
				DeActivatedByUserId: null,
				CreatedAtUtc: "2024-11-22T07:49:37.792768+00:00",
				CreatedByUserName: null,
				CreatedByUserId: 0,
				ModifiedAtUtc: "2024-11-22T07:49:37.792768+00:00",
				ModifiedByUserName: null,
				ModifiedByUserId: 0,
			},
			{
				ArchiveDatePolicyItemId: 4,
				DaysString: "45 Days",
				IsActive: true,
				ActivatedAtUtc: "2024-11-22T07:50:49.863046+00:00",
				ActivatedByUserName: null,
				ActivatedByUserId: 0,
				SortOrder: 3,
				DeActivatedAtUtc: null,
				DeActivatedByUserName: null,
				DeActivatedByUserId: null,
				CreatedAtUtc: "2024-11-22T07:49:37.792768+00:00",
				CreatedByUserName: null,
				CreatedByUserId: 0,
				ModifiedAtUtc: "2024-11-22T07:49:37.792768+00:00",
				ModifiedByUserName: null,
				ModifiedByUserId: 0,
			},
			{
				ArchiveDatePolicyItemId: 6,
				DaysString: "60 Days",
				IsActive: true,
				ActivatedAtUtc: "2024-11-22T07:50:49.863046+00:00",
				ActivatedByUserName: null,
				ActivatedByUserId: 0,
				SortOrder: 4,
				DeActivatedAtUtc: null,
				DeActivatedByUserName: null,
				DeActivatedByUserId: null,
				CreatedAtUtc: "2024-11-22T07:49:37.792768+00:00",
				CreatedByUserName: null,
				CreatedByUserId: 0,
				ModifiedAtUtc: "2024-11-22T07:49:37.792768+00:00",
				ModifiedByUserName: null,
				ModifiedByUserId: 0,
			},
			{
				ArchiveDatePolicyItemId: 3,
				DaysString: "40 Days",
				IsActive: false,
				ActivatedAtUtc: null,
				ActivatedByUserName: null,
				ActivatedByUserId: null,
				SortOrder: 5,
				DeActivatedAtUtc: "2024-11-22T07:50:46.933995+00:00",
				DeActivatedByUserName: null,
				DeActivatedByUserId: 0,
				CreatedAtUtc: "2024-11-22T07:49:37.792768+00:00",
				CreatedByUserName: null,
				CreatedByUserId: 0,
				ModifiedAtUtc: "2024-11-22T07:49:37.792768+00:00",
				ModifiedByUserName: null,
				ModifiedByUserId: 0,
			},
			{
				ArchiveDatePolicyItemId: 5,
				DaysString: "50 Days",
				IsActive: false,
				ActivatedAtUtc: null,
				ActivatedByUserName: null,
				ActivatedByUserId: null,
				SortOrder: 6,
				DeActivatedAtUtc: "2024-11-22T07:50:46.933995+00:00",
				DeActivatedByUserName: null,
				DeActivatedByUserId: 0,
				CreatedAtUtc: "2024-11-22T07:49:37.792768+00:00",
				CreatedByUserName: null,
				CreatedByUserId: 0,
				ModifiedAtUtc: "2024-11-22T07:49:37.792768+00:00",
				ModifiedByUserName: null,
				ModifiedByUserId: 0,
			},
		],
		isActive: true,
		isDefault: true,
		children: [
			{
				id: "engTypeId12",
				title: "Test Eng Type 2000",
				description: null,
				parentId: "archivePolicyId1",
				expanded: false,
				type: "engType",
				archiveDatePolicyItems: null,
				isActive: true,
				isDefault: true,
				children: [],
			},
		],
		isParent: true,
	},
	{
		id: "archivePolicyId3",
		title: "Success - kr Set",
		description: "Success Kr Set Description",
		parentId: null,
		expanded: true,
		type: "policy",
		archiveDatePolicyItems: [
			{
				ArchiveDatePolicyItemId: 13,
				DaysString: "15 Days",
				IsActive: true,
				ActivatedAtUtc: "2024-12-10T06:43:20.753864+00:00",
				ActivatedByUserName: "[BD] Bada Data",
				ActivatedByUserId: 7,
				SortOrder: 1,
				DeActivatedAtUtc: "2024-12-10T06:41:41.165338+00:00",
				DeActivatedByUserName: "[BD] Bada Data",
				DeActivatedByUserId: 7,
				CreatedAtUtc: "2024-11-18T10:36:53.540724+00:00",
				CreatedByUserName: "[BD] Bada Data",
				CreatedByUserId: 7,
				ModifiedAtUtc: "2024-12-10T06:43:35.957363+00:00",
				ModifiedByUserName: "[BD] Bada Data",
				ModifiedByUserId: 7,
			},
			{
				ArchiveDatePolicyItemId: 14,
				DaysString: "30 Days",
				IsActive: true,
				ActivatedAtUtc: "2024-11-15T08:46:19.05865+00:00",
				ActivatedByUserName: "[testep] Test_EP",
				ActivatedByUserId: 4,
				SortOrder: 2,
				DeActivatedAtUtc: null,
				DeActivatedByUserName: null,
				DeActivatedByUserId: null,
				CreatedAtUtc: "2024-11-15T08:46:19.05865+00:00",
				CreatedByUserName: "[testep] Test_EP",
				CreatedByUserId: 4,
				ModifiedAtUtc: "2024-11-15T08:46:19.05865+00:00",
				ModifiedByUserName: "[testep] Test_EP",
				ModifiedByUserId: 4,
			},
			{
				ArchiveDatePolicyItemId: 15,
				DaysString: "40 Days",
				IsActive: false,
				ActivatedAtUtc: "2024-11-21T02:44:34.466565+00:00",
				ActivatedByUserName: "[BD] Bada Data",
				ActivatedByUserId: 7,
				SortOrder: 3,
				DeActivatedAtUtc: "2024-11-21T02:44:50.68026+00:00",
				DeActivatedByUserName: "[BD] Bada Data",
				DeActivatedByUserId: 7,
				CreatedAtUtc: "2024-11-19T04:14:33.965533+00:00",
				CreatedByUserName: "[Support] SupportLemon",
				CreatedByUserId: 1,
				ModifiedAtUtc: "2024-11-21T02:44:50.68026+00:00",
				ModifiedByUserName: "[BD] Bada Data",
				ModifiedByUserId: 7,
			},
			{
				ArchiveDatePolicyItemId: 17,
				DaysString: "50 Days",
				IsActive: true,
				ActivatedAtUtc: "2024-11-21T02:53:40.315355+00:00",
				ActivatedByUserName: "[BD] Bada Data",
				ActivatedByUserId: 7,
				SortOrder: 5,
				DeActivatedAtUtc: "2024-11-21T01:59:20.561411+00:00",
				DeActivatedByUserName: "[BD] Bada Data",
				DeActivatedByUserId: 7,
				CreatedAtUtc: "2024-11-15T11:21:26.566921+00:00",
				CreatedByUserName: "[BD] Bada Data",
				CreatedByUserId: 7,
				ModifiedAtUtc: "2024-11-26T02:39:22.17162+00:00",
				ModifiedByUserName: "[BD] Bada Data",
				ModifiedByUserId: 7,
			},
			{
				ArchiveDatePolicyItemId: 16,
				DaysString: "45 Days",
				IsActive: true,
				ActivatedAtUtc: "2024-11-27T01:25:18.438942+00:00",
				ActivatedByUserName: "[BD] Bada Data",
				ActivatedByUserId: 7,
				SortOrder: 6,
				DeActivatedAtUtc: "2024-11-22T10:42:21.50342+00:00",
				DeActivatedByUserName: "[BD] Bada Data",
				DeActivatedByUserId: 7,
				CreatedAtUtc: "2024-11-12T13:13:44.908439+00:00",
				CreatedByUserName: "[BD] Bada Data",
				CreatedByUserId: 7,
				ModifiedAtUtc: "2024-11-27T01:25:18.438942+00:00",
				ModifiedByUserName: "[BD] Bada Data",
				ModifiedByUserId: 7,
			},
			{
				ArchiveDatePolicyItemId: 18,
				DaysString: "60 Days",
				IsActive: false,
				ActivatedAtUtc: "2024-11-21T01:58:25.084948+00:00",
				ActivatedByUserName: "[BD] Bada Data",
				ActivatedByUserId: 7,
				SortOrder: 7,
				DeActivatedAtUtc: "2024-11-21T02:53:44.647701+00:00",
				DeActivatedByUserName: "[BD] Bada Data",
				DeActivatedByUserId: 7,
				CreatedAtUtc: "2024-11-19T04:11:13.692578+00:00",
				CreatedByUserName: "[Support] SupportLemon",
				CreatedByUserId: 1,
				ModifiedAtUtc: "2024-11-26T01:50:33.908341+00:00",
				ModifiedByUserName: "[BD] Bada Data",
				ModifiedByUserId: 7,
			},
		],
		isActive: true,
		isDefault: false,
		children: [
			{
				id: "engTypeId19",
				title: "Test Eng Type 18",
				description: null,
				parentId: "archivePolicyId3",
				expanded: false,
				type: "engType",
				archiveDatePolicyItems: null,
				isActive: false,
				isDefault: false,
				children: [],
			},
		],
		isParent: true,
	},
	{
		id: "archivePolicyId4",
		title: "Fail - en Set",
		description: "Fail En Set Description",
		parentId: null,
		expanded: true,
		type: "policy",
		archiveDatePolicyItems: [
			{
				ArchiveDatePolicyItemId: 19,
				DaysString: "15 Days",
				IsActive: false,
				ActivatedAtUtc: "2024-11-27T01:16:12.439792+00:00",
				ActivatedByUserName: "[BD] Bada Data",
				ActivatedByUserId: 7,
				SortOrder: 1,
				DeActivatedAtUtc: "2024-11-27T01:16:15.788372+00:00",
				DeActivatedByUserName: "[BD] Bada Data",
				DeActivatedByUserId: 7,
				CreatedAtUtc: "2024-11-12T05:16:56.750155+00:00",
				CreatedByUserName: "[Support] SupportLemon",
				CreatedByUserId: 1,
				ModifiedAtUtc: "2024-11-27T01:16:15.788372+00:00",
				ModifiedByUserName: "[BD] Bada Data",
				ModifiedByUserId: 7,
			},
			{
				ArchiveDatePolicyItemId: 22,
				DaysString: "45 Days",
				IsActive: true,
				ActivatedAtUtc: "2024-11-13T01:31:57.690671+00:00",
				ActivatedByUserName: "[Support] SupportLemon",
				ActivatedByUserId: 1,
				SortOrder: 2,
				DeActivatedAtUtc: null,
				DeActivatedByUserName: null,
				DeActivatedByUserId: null,
				CreatedAtUtc: "2024-11-13T01:31:57.690671+00:00",
				CreatedByUserName: "[Support] SupportLemon",
				CreatedByUserId: 1,
				ModifiedAtUtc: "2024-11-13T01:31:57.690671+00:00",
				ModifiedByUserName: "[Support] SupportLemon",
				ModifiedByUserId: 1,
			},
			{
				ArchiveDatePolicyItemId: 20,
				DaysString: "30 Days",
				IsActive: true,
				ActivatedAtUtc: "2024-11-12T05:16:56.750155+00:00",
				ActivatedByUserName: "[Support] SupportLemon",
				ActivatedByUserId: 1,
				SortOrder: 3,
				DeActivatedAtUtc: null,
				DeActivatedByUserName: null,
				DeActivatedByUserId: null,
				CreatedAtUtc: "2024-11-12T05:16:56.750155+00:00",
				CreatedByUserName: "[Support] SupportLemon",
				CreatedByUserId: 1,
				ModifiedAtUtc: "2024-11-12T05:16:56.750155+00:00",
				ModifiedByUserName: "[Support] SupportLemon",
				ModifiedByUserId: 1,
			},
			{
				ArchiveDatePolicyItemId: 21,
				DaysString: "40 Days",
				IsActive: false,
				ActivatedAtUtc: null,
				ActivatedByUserName: null,
				ActivatedByUserId: null,
				SortOrder: 5,
				DeActivatedAtUtc: "2024-11-21T03:25:30.724042+00:00",
				DeActivatedByUserName: "[BD] Bada Data",
				DeActivatedByUserId: 7,
				CreatedAtUtc: "2024-11-13T01:23:19.644144+00:00",
				CreatedByUserName: "[Support] SupportLemon",
				CreatedByUserId: 1,
				ModifiedAtUtc: "2024-11-21T03:25:30.724042+00:00",
				ModifiedByUserName: "[BD] Bada Data",
				ModifiedByUserId: 7,
			},
			{
				ArchiveDatePolicyItemId: 23,
				DaysString: "50 Days",
				IsActive: false,
				ActivatedAtUtc: "2024-11-27T01:14:14.193315+00:00",
				ActivatedByUserName: "[BD] Bada Data",
				ActivatedByUserId: 7,
				SortOrder: 6,
				DeActivatedAtUtc: "2024-11-27T01:14:29.888596+00:00",
				DeActivatedByUserName: "[BD] Bada Data",
				DeActivatedByUserId: 7,
				CreatedAtUtc: "2024-11-14T12:29:53.655808+00:00",
				CreatedByUserName: "[BD] Bada Data",
				CreatedByUserId: 7,
				ModifiedAtUtc: "2024-11-27T01:14:51.140559+00:00",
				ModifiedByUserName: "[BD] Bada Data",
				ModifiedByUserId: 7,
			},
			{
				ArchiveDatePolicyItemId: 24,
				DaysString: "60 Days",
				IsActive: false,
				ActivatedAtUtc: "2024-11-12T05:16:56.750155+00:00",
				ActivatedByUserName: "[Support] SupportLemon",
				ActivatedByUserId: 1,
				SortOrder: 9,
				DeActivatedAtUtc: "2024-12-04T02:48:55.707843+00:00",
				DeActivatedByUserName: "[BD] Bada Data",
				DeActivatedByUserId: 7,
				CreatedAtUtc: "2024-11-12T05:16:56.750155+00:00",
				CreatedByUserName: "[Support] SupportLemon",
				CreatedByUserId: 1,
				ModifiedAtUtc: "2024-12-04T02:48:55.707843+00:00",
				ModifiedByUserName: "[BD] Bada Data",
				ModifiedByUserId: 7,
			},
		],
		isActive: true,
		isDefault: false,
		children: [
			{
				id: "engTypeId3",
				title: "QA Eng Type",
				description: null,
				parentId: "archivePolicyId4",
				expanded: false,
				type: "engType",
				archiveDatePolicyItems: null,
				isActive: true,
				isDefault: false,
				children: [],
			},
		],
		isParent: true,
	},
	{
		id: "archivePolicyId5",
		title: "Failed - kr Set",
		description: "Failed Kr Set Description",
		parentId: null,
		expanded: true,
		type: "policy",
		archiveDatePolicyItems: [
			{
				ArchiveDatePolicyItemId: 25,
				DaysString: "15 Days",
				IsActive: false,
				ActivatedAtUtc: null,
				ActivatedByUserName: null,
				ActivatedByUserId: null,
				SortOrder: 1,
				DeActivatedAtUtc: "2024-11-12T06:18:19.064604+00:00",
				DeActivatedByUserName: "[BD] Bada Data",
				DeActivatedByUserId: 7,
				CreatedAtUtc: "2024-11-12T06:18:19.064604+00:00",
				CreatedByUserName: "[BD] Bada Data",
				CreatedByUserId: 7,
				ModifiedAtUtc: "2024-11-12T06:18:19.064604+00:00",
				ModifiedByUserName: "[BD] Bada Data",
				ModifiedByUserId: 7,
			},
			{
				ArchiveDatePolicyItemId: 30,
				DaysString: "60 Days",
				IsActive: true,
				ActivatedAtUtc: "2024-11-28T05:44:10.989047+00:00",
				ActivatedByUserName: "[BD] Bada Data",
				ActivatedByUserId: 7,
				SortOrder: 2,
				DeActivatedAtUtc: "2024-11-28T05:43:57.787844+00:00",
				DeActivatedByUserName: "[BD] Bada Data",
				DeActivatedByUserId: 7,
				CreatedAtUtc: "2024-11-12T06:18:19.064604+00:00",
				CreatedByUserName: "[BD] Bada Data",
				CreatedByUserId: 7,
				ModifiedAtUtc: "2024-11-28T05:44:10.989047+00:00",
				ModifiedByUserName: "[BD] Bada Data",
				ModifiedByUserId: 7,
			},
			{
				ArchiveDatePolicyItemId: 26,
				DaysString: "30 Days",
				IsActive: true,
				ActivatedAtUtc: "2024-11-12T06:18:19.064604+00:00",
				ActivatedByUserName: "[BD] Bada Data",
				ActivatedByUserId: 7,
				SortOrder: 3,
				DeActivatedAtUtc: null,
				DeActivatedByUserName: null,
				DeActivatedByUserId: null,
				CreatedAtUtc: "2024-11-12T06:18:19.064604+00:00",
				CreatedByUserName: "[BD] Bada Data",
				CreatedByUserId: 7,
				ModifiedAtUtc: "2024-11-12T06:18:19.064604+00:00",
				ModifiedByUserName: "[BD] Bada Data",
				ModifiedByUserId: 7,
			},
			{
				ArchiveDatePolicyItemId: 27,
				DaysString: "40 Days",
				IsActive: true,
				ActivatedAtUtc: "2024-11-12T06:18:19.064604+00:00",
				ActivatedByUserName: "[BD] Bada Data",
				ActivatedByUserId: 7,
				SortOrder: 4,
				DeActivatedAtUtc: null,
				DeActivatedByUserName: null,
				DeActivatedByUserId: null,
				CreatedAtUtc: "2024-11-12T06:18:19.064604+00:00",
				CreatedByUserName: "[BD] Bada Data",
				CreatedByUserId: 7,
				ModifiedAtUtc: "2024-11-12T06:18:19.064604+00:00",
				ModifiedByUserName: "[BD] Bada Data",
				ModifiedByUserId: 7,
			},
			{
				ArchiveDatePolicyItemId: 28,
				DaysString: "45 Days",
				IsActive: true,
				ActivatedAtUtc: "2024-11-12T06:18:19.064604+00:00",
				ActivatedByUserName: "[BD] Bada Data",
				ActivatedByUserId: 7,
				SortOrder: 6,
				DeActivatedAtUtc: null,
				DeActivatedByUserName: null,
				DeActivatedByUserId: null,
				CreatedAtUtc: "2024-11-12T06:18:19.064604+00:00",
				CreatedByUserName: "[BD] Bada Data",
				CreatedByUserId: 7,
				ModifiedAtUtc: "2024-11-12T06:18:19.064604+00:00",
				ModifiedByUserName: "[BD] Bada Data",
				ModifiedByUserId: 7,
			},
			{
				ArchiveDatePolicyItemId: 29,
				DaysString: "50 Days",
				IsActive: false,
				ActivatedAtUtc: null,
				ActivatedByUserName: null,
				ActivatedByUserId: null,
				SortOrder: 7,
				DeActivatedAtUtc: "2024-11-12T06:18:19.064604+00:00",
				DeActivatedByUserName: "[BD] Bada Data",
				DeActivatedByUserId: 7,
				CreatedAtUtc: "2024-11-12T06:18:19.064604+00:00",
				CreatedByUserName: "[BD] Bada Data",
				CreatedByUserId: 7,
				ModifiedAtUtc: "2024-11-12T06:18:19.064604+00:00",
				ModifiedByUserName: "[BD] Bada Data",
				ModifiedByUserId: 7,
			},
		],
		isActive: true,
		isDefault: false,
		children: [
			{
				id: "engTypeId2",
				title: "Engagement Type Name for Sun",
				description: null,
				parentId: "archivePolicyId5",
				expanded: false,
				type: "engType",
				archiveDatePolicyItems: null,
				isActive: true,
				isDefault: false,
				children: [],
			},
		],
		isParent: true,
	},
]

export type EmailTemplateDataType = typeof EMAIL_TEMPLATE_DATA
