import { types } from "mobx-state-tree"
import FsAdjustmentsViewModel from "./fs-adjustments.view-model"
import FsAdjustmentsModel from "./fs-adjustments.model"

export const FsAdjustmentsStore = () =>
	types
		.compose(FsAdjustmentsModel(), FsAdjustmentsViewModel)
		.named("FsAdjustmentsStore")

export const initialStore = {
	clickPoint: {
		mouseX: null,
		mouseY: null,
	},
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const FSAdjustmentsStoreInstance = FsAdjustmentsStore().create(initialStore)

export default FSAdjustmentsStoreInstance
