import React, { useState } from "react"
import { PageContainer } from "../../../../components/app-frame-elements"
import { ActionStatus } from "@datalobby/types"
import styled from "styled-components"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import FsListOfTransactionMetadataTable from "./sub-components/FsListOfTransactionMetadataTable"
import FsListOfTransactionMetadataCtxMenu from "./sub-components/FsListOfTransactionMetadataCtxMenu"
import { observer } from "mobx-react-lite"

export default observer(function FsListOfTransactionMetadata({
	partialStore,
	permission,
	actionStatus,
}: {
	partialStore: any
	permission: PermissionAsObjectProps
	actionStatus: any
}) {
	return (
		<PageContainer
			hasToolbar={false}
			pageTools={<div />}
			fullWidth
			isLoading={actionStatus === ActionStatus.loading}
		>
			<StyledFsListOfTransactionMetadata>
				<FsListOfTransactionMetadataTable
					partialStore={partialStore}
					permission={permission}
				/>
			</StyledFsListOfTransactionMetadata>
			{partialStore.clickPoint.mouseX && (
				<FsListOfTransactionMetadataCtxMenu
					permission={permission}
					partialStore={partialStore}
				/>
			)}
		</PageContainer>
	)
})

const StyledFsListOfTransactionMetadata = styled.div`
	padding-left: 1rem;
	padding-right: 1rem;
`
