import { DLFileControlModel } from "../../../../../service-modules/file-module/data-models/dl-file-control-model"
//
import {
	GetFirstFolders,
	GetChildrenOfFolder,
	RelayGetChildrenOfFolder,
	//
	AddFolder,
	EditFolder,
	RemoveFolder,
	ReorderChildren,
	SortChildren,
	EditStructureLock,
} from "../../../../../service-modules/file-module/view-model-actions/file-folder"
import {
	AddFile,
	EditFile,
	OpenFileWithoutEditor,
	RemoveFile,
	ReplaceFile,
	UploadFile,
	//
	AddFileFromLib,
	EditFileStatus,
	GetAllFiles,
	GetFileInfo,
	GetFileHistory,
	GetSignOffLockHistory,
	EditSignOffLock,
} from "../../../../../service-modules/file-module/view-model-actions/file"
import {
	DownloadFile,
	DownloadFolder,
	DownloadAll,
	InitiateFolderZipExport,
} from "../../../../../service-modules/file-module/view-model-actions/download"
//
import {
	SingleSignOff,
	MultipleSignOff,
	UnSignOff,
} from "../../../../../service-modules/file-module/view-model-actions/sign-offs"
//
import AssignRoleToFile from "../../../../../service-modules/file-module/view-model-actions/assign/assign-role-to-file"
import SignOffViews from "../../../../../service-modules/file-module/view-model-actions/sign-offs/sign-off.views"

//
//
import FlatListActions from "../../../../../service-modules/file-module/view-model-actions/flat-list-actions"
import FlatListViews from "../../../../../service-modules/file-module/view-model-actions/flat-list-views"
//
import SelectedItemsUiActions from "../../../../../service-modules/file-module/ui-helper-actions/file-selected-items-ui.actions"
import SelectedItemsUiViews from "../../../../../service-modules/file-module/ui-helper-actions/file-selected-items-ui.views"
//
import UiHelperActions from "../../../../../service-modules/file-module/ui-helper-actions/ui-helper.actions"
import UiHelperViews from "../../../../../service-modules/file-module/ui-helper-actions/ui-helper.views"
import SearchFileOrFolder from "../../../../../service-modules/file-module/view-model-actions/file/file-search"
//

const PfFileControl = DLFileControlModel.actions((self) => ({})) // This actions is just placeholder for alignment... :)
	//
	//
	// * Actions
	// default action for flatList
	.actions(GetFirstFolders)
	.actions(GetChildrenOfFolder)
	.actions(RelayGetChildrenOfFolder)
	//
	//
	// folder related
	.actions(AddFolder)
	.actions(EditFolder)
	.actions(RemoveFolder)
	//
	//
	// file related
	.actions(AddFile)
	.actions(EditFile)
	.actions(OpenFileWithoutEditor)
	.actions(RemoveFile)
	.actions(ReplaceFile)
	.actions(EditFileStatus)
	.actions(UploadFile)
	.actions(SearchFileOrFolder)
	.actions(GetAllFiles)
	.actions(GetFileInfo)
	.actions(GetFileHistory)
	.actions(GetSignOffLockHistory)
	.actions(EditSignOffLock)
	//
	//
	// file library
	.actions(AddFileFromLib)
	//
	//
	// assign roles
	.actions(AssignRoleToFile)
	//
	//
	// sign off
	.actions(SingleSignOff)
	.actions(MultipleSignOff)
	.actions(UnSignOff)
	.views(SignOffViews)
	//
	//
	// re-order items by ref number or dnd
	.actions(SortChildren)
	.actions(ReorderChildren)
	.actions(EditStructureLock)
	//
	//
	// download
	.actions(DownloadAll)
	.actions(DownloadFolder)
	.actions(DownloadFile)
	.actions(InitiateFolderZipExport)
	//
	//
	// * Control
	// for flat list
	.actions(FlatListActions)
	.views(FlatListViews)
	// for selection
	.actions(SelectedItemsUiActions)
	.views(SelectedItemsUiViews)
	// for UI
	.actions(UiHelperActions)
	.views(UiHelperViews)

export default PfFileControl
