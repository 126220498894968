import React, { useEffect, useState, useCallback } from "react"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { observer } from "mobx-react-lite"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import DLFileTree from "../../../../components/combined-elements/file-tree/DLFileTree"
import { sortBy } from "@datalobby/common"
import {
	DefaultSignOffPolicy,
	PfSignOffPolicy,
} from "../../../../temporary-data/project-side/signoff-policy"
import {
	SignOffDisplayType,
	FileScreenColumn,
	FileScreenDialog,
	FileUploadFromType,
	FileMenuViewType,
} from "../../../../service-modules/file-module/data-models/dl-file-control-model"
import { OldSignOffType } from "../../../../service-modules/file-module/data-models/sign-off.data-model"
import { CommentListByFileDialogController, FileInfoDialog } from "./dialogs"
import { ConsoleLog } from "../../../../components/basic-elements"
import {
	FileFolderCtxMenu,
	FileCtxMenu,
} from "../proj-files-components/context-menus"
import {
	FileTreeForSelectionDialog,
	FileEditDialog,
	ReplaceFileDialog,
	RoleAssignDialog,
} from "../../../../components/combined-elements/file-tree/file-tree-dialogs"
import MultipleSignOffDialog from "./dialogs/MultipleSignOffDialog"

import { ProjI18n } from "../../../../common-models/enumerations/translation-sheets"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { FileSelectCounter, FileOnlyTableView } from "."
import FileMenuSearchView from "./file-menu-search-view/FileMenuSearchView"
import MoveFileOrFolderDialog from "../../../../components/combined-elements/file-tree/file-tree-dialogs/MoveFileOrFolderDialog"
import SignOffLockDialog from "./dialogs/SignOffLockDialog"
import SignOffLockUnlockHistoryDialog from "./dialogs/SignOffLockUnlockHistoryDialog"
import FileHistoryDialog from "./dialogs/FileHistoryDialog"
import ExportFolderDialog from "./dialogs/ExportFolderDialog"
import DragAndDropConfirmDialog from "../../../../components/combined-elements/file-tree/file-tree-dialogs/DragAndDropConfirmDialog"
import FileMenuQCView from "./file-menu-qc-view/FileMenuQCView"
import { displayedProjRoles } from "../../../../temporary-data/project-side/find-proj-role"
import ExportProjZipFolderZipDialog from "./dialogs/ExportProjZipFolderZipDialog"

// TODO: search feature update is required (it is removed temporary)
export default observer(function ProjFileTreeController({
	partialStore,
}: {
	partialStore: any
}) {
	const store = useRootStore()
	const projStore = useProjStore()
	const libStore = projStore.lib
	const projId = projStore.checkin.projId
	const menuId = partialStore.storeName
	//

	const [i18n, setI18n] = useState<any>({})
	const [moveData, setMoveData] = useState<any>({})

	useEffect(() => {
		const i18nSheet = ProjI18n.files
		const i18nX = store.i18n.combineI18n(
			i18nSheet,
			"ProjFileTreeController"
		)
		setI18n(i18nX)
	}, [])
	const indentWidth = 20

	const setCheckbox = partialStore.pushOrSpliceSelectedItem
	// folder related
	const getLoadingStatus = partialStore.getActionStatus
	// selected item
	const userId = projStore.checkin.userId
	const roleId = projStore.checkin.user.roleId
	const isReadonly = projStore.checkin.user.isReadonly
	const isExternal = projStore.checkin.user.userType === "external"

	// for sign-off area
	const isHovered = partialStore.isHovered
	const getColumnSignOffs = partialStore.getSignOffListByColumnId

	const signOffPolicy = sortBy(
		menuId === DLProjSubMenus.wp ? DefaultSignOffPolicy : PfSignOffPolicy,
		"order"
	)
	//
	// WARNING: UPDATE TOGETHER ORG PROJECT TEMPLATES
	const handleDragAndDrop = (data: any) => {
		ConsoleLog([
			" >>>>>> PROJECT FILES handleDragAndDrop",
			data,
			" >>>>>> ",
		])
		const { node, targetIndex } = data
		if (data.updateAsRootFolder) {
			// reorder as root folder
			// in this case, data is index of the new root folder
			const newRootFolderList = partialStore.sortRootFoldersWithNewItem(
				targetIndex,
				node
			)
			let listForApi1: { id: string; parentId: null }[] = []
			newRootFolderList.map((item: string) => {
				listForApi1.push({ id: item, parentId: null })
			})
			ConsoleLog([
				" <---- reorder as root folder ",
				listForApi1,
				" ----> ",
			])
			partialStore.reorderChildren(null, listForApi1)
		} else if (data.reorderRootFolders) {
			const newOrderedRootFolders = partialStore.getNewOrderedRootFolders(
				targetIndex,
				node
			)
			let listForApi2: { id: string; parentId: null }[] = []
			newOrderedRootFolders?.map((item: string) => {
				listForApi2.push({ id: item, parentId: null })
			})
			ConsoleLog([
				" <---- reorder existing root folders: ",
				listForApi2,
				" ----> ",
			])
			partialStore.reorderChildren(null, listForApi2)
		} else {
			setMoveData(data)
			partialStore.setFileTreeDialogOpen([
				FileScreenDialog.dragAndDropConfirmDialog,
			])
			// partialStore.reorderChildren(data.id, data.children)
		}
	}
	const handleEditFileInfo = useCallback(
		({
			fileId,
			aliasId,
			title,
		}: {
			fileId: string
			aliasId: string
			title: string
		}) => {
			partialStore.editFile({
				projId,
				fileId,
				aliasId,
				title,
			})
		},
		[]
	)
	// permission
	const permission = projStore.checkin.getActionsAsObject(menuId)
	const commentPermission = projStore.checkin.getActionsAsObject(
		DLProjSubMenus.comments
	)

	const handleOpenCommentListOfFile = (objectId: string) => {
		// ConsoleLog("handleOpenCommentListOfFile", objectId)
		partialStore.setFileTreeDialogOpen(FileScreenDialog.commentList, true)
		// projStore.comments.setCurrentTargetObject(menuId, objectId)
		// projStore.comments.setListDialogOpenStatus(true)
		// close on the dialog
	}

	const handleSignOff = (type: OldSignOffType) => {
		partialStore.handleSignOff({
			type,
			userId,
			roleId,
		})
	}

	const userRole = projStore.checkin.user.roleId

	useEffect(() => {}, [signOffPolicy])

	const signOffByShortcut = useCallback((e: any) => {
		const { code } = e

		const selectedItems = partialStore.selectedFiles
		// const objInfo = partialStore.getItemInfo(selectedItems[0])

		const isSignOffLocked = selectedItems.some((itemId: any) =>
			partialStore.flatList.find(
				(obj: any) => obj.id === itemId && obj.signOffLock === true
			)
		)

		if (isSignOffLocked) {
			const type = code === "KeyP" ? "PREPARE" : "REVIEW"
			alert(
				`The file is in "Sign Off Locked" status. This action cannot be performed. Please change the Sign Off Lock Status to perform ${type} on the file.`
			)
			return
		}

		let ableToPrepare = false
		let ableToReview = false
		if (signOffPolicy[0].availableRoles.includes(userRole)) {
			ableToPrepare = true
		}
		if (signOffPolicy[1].availableRoles.includes(userRole)) {
			ableToReview = true
		}
		if (
			partialStore.selectedItems.length !== 0 &&
			partialStore.selectedItemsHasNoFolder
		) {
			const availableSignOffs = partialStore.checkAvailableSignOffs()

			if (code === "KeyP") {
				if (availableSignOffs.prepare && ableToPrepare) {
					handleSignOff(OldSignOffType.prepare)
				} else if (!ableToPrepare) {
					alert("You don't have prepare permisison")
				} else {
					alert("Cannot prepare on the item(s)")
				}
			} else if (code === "KeyR") {
				if (availableSignOffs.review && ableToReview) {
					handleSignOff(OldSignOffType.review)
				} else if (!ableToReview) {
					alert("You don't have review permission")
				} else {
					alert("Cannot review on the item(s)")
				}
			}
		} else if (partialStore.selectedItems.length === 0) {
			alert("Please select files first")
		} else if (!partialStore.selectedItemsHasNoFolder) {
			alert("Cannot sign off on the folders")
		} else {
			alert("Cannot sign off on the current selected items")
		}
	}, [])

	// NOTE: this 1,2,3,4,5 for custom type of sign off in the future
	// deprecated in this step
	// const handleSignOffShortCut = useCallback((e: any) => {
	// 	const { key, pressedKeys } = e

	// 	if (
	// 		partialStore.selectedItems.length !== 0 &&
	// 		partialStore.selectedItemsHasNoFolder
	// 	) {
	// 		const checkAvailableSignOffs = partialStore.checkAvailableSignOffs()
	// 		if (key === "1") {
	// 			if (checkAvailableSignOffs.prepare) {
	// 				handleSignOff(OldSignOffType.prepare)
	// 			} else {
	// 				alert("Cannot prepare on the item(s)")
	// 			}
	// 		} else if (key === "2") {
	// 			if (checkAvailableSignOffs.review) {
	// 				handleSignOff(OldSignOffType.review)
	// 			} else {
	// 				alert("Cannot review on the item(s)")
	// 			}
	// 		} else if (key === "3" && roleId === "id_ep") {
	// 			if (checkAvailableSignOffs.review) {
	// 				handleSignOff(OldSignOffType.review)
	// 			} else {
	// 				alert("Cannot review on the item(s)")
	// 			}
	// 		} else if (key === "4" && roleId === "id_cp") {
	// 			if (checkAvailableSignOffs.review) {
	// 				handleSignOff(OldSignOffType.review)
	// 			} else {
	// 				alert("Cannot review on the item(s)")
	// 			}
	// 		} else if (key === "5" && roleId === "id_qc") {
	// 			if (checkAvailableSignOffs.review) {
	// 				handleSignOff(OldSignOffType.review)
	// 			} else {
	// 				alert("Cannot review on the item(s)")
	// 			}
	// 		}
	// 	} else if (!partialStore.selectedItemsHasNoFolder) {
	// 		alert(
	// 			"Some folders in the selected item list. Please remove the folders from the selected items and try again."
	// 		)
	// 	} else {
	// 	}
	// }, [])

	const handleSetCurrentMenu = (menuId: DLProjSubMenus, newMenu: any) => {
		projStore.menuTabs.setCurrentMainMenu(menuId)
		projStore.menuTabs.addOpenedMenu(newMenu)
	}

	const setFileUploadFrom = (from: FileUploadFromType | undefined) => {
		partialStore.setFileUploadFrom(from)
	}

	const checkDuplicatedAliasId = partialStore.isDuplicatedAliasId
	const checkDuplicatedName = partialStore.isDuplicatedName

	const dntFormat = store.global.getDntFormat
	const treeData = partialStore.flatListAsTree(dntFormat)
	const canDrag = !partialStore.structureLock
	const showCheckbox = partialStore.showCheckbox

	const selectedItems = partialStore.getSelectedItems
	//
	// const searchQuery = partialStore.searchQuery
	const searchQuery = ""
	//
	// for sign off area
	let hoveredColumn = partialStore.hoveredColumn
	//
	const contentsAreaWidth = store.ui.contentsAreaWidth
	if (contentsAreaWidth <= 680) {
		partialStore.setSignOffCellType(SignOffDisplayType.nameOnly)
	}
	const contentsAreaHeight = store.ui.contentsAreaHeight
	let treeHeight =
		selectedItems.length > 0
			? contentsAreaHeight - 36 - 48
			: contentsAreaHeight - 36
	// 48: selected counter in the bottom
	// 36: tree column header

	const signOffAreaWidth =
		partialStore.columnWidth[FileScreenColumn.signOffArea]

	// NOTE: WARNING: below part occurs re-rendering by every pixels. Do not use this kind of condition
	// if (contentsAreaWidth <= 680) {
	// 	partialStore.updateSignOffAreaWidth(200)
	// }
	const historyWidth = partialStore.columnWidth[FileScreenColumn.history]
	let fixedAreaWidth = signOffAreaWidth + 24 + 24 + 160 + historyWidth // + (canDrag ? 48 :24) // 24 means comment icon width
	// for sign off area
	const dateFormat = store.global.dateFormat.value

	const isLocked = projStore.projInfo.projInfo.isLocked

	return (
		<div className="project-file-tree-controller">
			{partialStore.viewType === FileMenuViewType.fileTable ? (
				<FileOnlyTableView partialStore={partialStore} />
			) : partialStore.viewType === FileMenuViewType.search ? (
				<FileMenuSearchView partialStore={partialStore} />
			) : partialStore.viewType === FileMenuViewType.qcView ? (
				<FileMenuQCView partialStore={partialStore} />
			) : (
				<>
					<DLFileTree
						projId={projId}
						// simpleMode --> for project template
						//
						menuId={menuId}
						indentWidth={indentWidth}
						treeHeight={treeHeight}
						treeData={treeData}
						canDrag={canDrag}
						searchQuery={searchQuery}
						// folder & file shared
						isSelected={partialStore.isSelected}
						isCurrentParent={partialStore.isCurrentParent}
						isLastSelectedItem={partialStore.isLastSelectedItem}
						// nextTreeItem={nextTreeItem}
						// prevTreeItem={prevTreeItem}
						// above things are not working properly without partial store
						setNextTreeItem={partialStore.setNextTreeItem}
						setPrevTreeItem={partialStore.setPrevTreeItem}
						showCheckbox={showCheckbox}
						selectedItems={selectedItems}
						setSelectedItem={partialStore.setSelectedItem}
						pushSelectedItem={partialStore.pushSelectedItem}
						setSelectedItemsWithKeyboard={
							partialStore.setSelectedItemsWithKeyboard
						}
						resetSelectedItems={partialStore.resetSelectedItems}
						setCheckbox={setCheckbox}
						permission={permission}
						// shared
						setFileTreeDialogOpen={
							partialStore.setFileTreeDialogOpen
						}
						// folder related
						getLoadingStatus={getLoadingStatus}
						getChildrenOfFolder={partialStore.getChildrenOfFolder}
						toggleExpanded={partialStore.toggleExpanded}
						handleDeleteFolder={partialStore.removeFileFolder}
						setFolderClickPoint={partialStore.setFolderClickPoint}
						//
						setFileClickPoint={partialStore.setFileClickPoint}
						openFileWithoutEditor={
							partialStore.openFileWithoutEditor
						} // TODO: is this reuqired?
						reflectFileCheckout={partialStore.reflectFileCheckout}
						// signOffArea={FileRowSignOffAreaController}
						signOffPolicy={signOffPolicy}
						handleFileStatus={partialStore.editFileStatus}
						//
						handleOpenCommentListOfFile={
							handleOpenCommentListOfFile
						}
						//
						handleDragAndDrop={handleDragAndDrop}
						sortChildren={partialStore.sortChildren}
						//

						//
						handleEditFileInfo={handleEditFileInfo}
						// * for sign-off area
						singleSignOff={partialStore.singleSignOff}
						signOffByShortcut={signOffByShortcut}
						//
						isHovered={isHovered}
						getColumnSignOffs={getColumnSignOffs}
						hoveredColumn={hoveredColumn}
						cellType={partialStore.signOffCellType}
						dateFormat={dateFormat}
						//
						//
						// for column width
						contentsAreaWidth={contentsAreaWidth}
						//
						assignedRolesWidth={
							partialStore.columnWidth[
								FileScreenColumn.assignedRoles
							]
						}
						aliasIdWidth={
							partialStore.columnWidth[FileScreenColumn.aliasId]
						}
						titleWidth={
							partialStore.columnWidth[FileScreenColumn.title]
						}
						formatWidth={
							partialStore.columnWidth[FileScreenColumn.format]
						}
						sizeWidth={
							partialStore.columnWidth[FileScreenColumn.size]
						}
						signOffLockWidth={
							partialStore.columnWidth[
								FileScreenColumn.signOffLock
							]
						}
						//
						fixedAreaWidth={fixedAreaWidth}
						signOffAreaWidth={signOffAreaWidth}
						historyWidth={historyWidth}
						//
						//
						// for column header
						setColumnHighlight={partialStore.setColumnHighlight}
						needCollapseAll={partialStore.needCollapseAll}
						updateSignOffAreaWidth={
							partialStore.updateSignOffAreaWidth
						}
						updateFileInfoAreaWidth={
							partialStore.updateFileInfoAreaWidth
						}
						//
						partialStore={partialStore} // for nextTreeItem & prevTreeItem: these two things are not working well by inherit.. don't know why
						// temp
						userInfo={{ id: userId, isReadonly, isExternal }}
						//
						// NOTE: this handle Duplicate means copy the target
						handleDuplicateFolder={({ folderId }) => {}}
						handleDuplicateFile={({ fileId }) => {}}
						i18n={i18n}
						handleSetCurrentMenu={handleSetCurrentMenu}
						setFileUploadFrom={setFileUploadFrom}
						//
						checkDuplicatedAliasId={checkDuplicatedAliasId}
						checkDuplicatedName={checkDuplicatedName}
						resetLibSelectedItem={() =>
							libStore.setSelectedItemId("")
						}
						userRole={userRole}
						isLocked={isLocked}
					/>
					<FileSelectCounter partialStore={partialStore} />
				</>
			)}

			{/* ----- ctx menus ----- */}
			{partialStore.folderClickPoint.mouseX && (
				<FileFolderCtxMenu
					partialStore={partialStore}
					permission={permission}
				/>
			)}
			{partialStore.fileClickPoint.mouseX && (
				<FileCtxMenu
					partialStore={partialStore}
					permission={permission}
					commentPermission={commentPermission}
				/>
			)}

			{/* ----- Folder related ----- */}
			{/* NOTE: Do not add 'create folder dialog' here because is is also required when the file tree not exist */}

			{/* ----- File related ----- */}
			{partialStore.fileTreeDialogOpenStatus[
				FileScreenDialog.folderTree
			] && (
				<FileTreeForSelectionDialog
					target="folder-only"
					partialStore={partialStore}
					i18n={i18n}
				/>
			)}

			{partialStore.fileTreeDialogOpenStatus[
				FileScreenDialog.fileEdit
			] && (
				<FileEditDialog
					partialStore={partialStore}
					projId={projId}
					i18n={i18n}
				/>
			)}

			{partialStore.fileTreeDialogOpenStatus[
				FileScreenDialog.fileInfo
			] && <FileInfoDialog partialStore={partialStore} i18n={i18n} />}

			{partialStore.fileTreeDialogOpenStatus[
				FileScreenDialog.signOffLockDialog
			] && <SignOffLockDialog partialStore={partialStore} />}

			{partialStore.fileTreeDialogOpenStatus[
				FileScreenDialog.signOffLockUnlockHistoryDialog
			] && <SignOffLockUnlockHistoryDialog partialStore={partialStore} />}

			{partialStore.fileTreeDialogOpenStatus[
				FileScreenDialog.FileHistoryDialog
			] && <FileHistoryDialog partialStore={partialStore} />}

			{partialStore.fileTreeDialogOpenStatus[FileScreenDialog.assign] && (
				<RoleAssignDialog
					partialStore={partialStore}
					roleSet={displayedProjRoles}
					i18n={i18n}
				/>
			)}

			{partialStore.fileTreeDialogOpenStatus[
				FileScreenDialog.replaceFile
			] && (
				<ReplaceFileDialog
					partialStore={partialStore}
					i18n={i18n}
					userRole={userRole}
				/>
			)}

			{partialStore.fileTreeDialogOpenStatus[
				FileScreenDialog.exportFolderDialog
			] && <ExportFolderDialog partialStore={partialStore} />}

			{partialStore.fileTreeDialogOpenStatus[
				FileScreenDialog.exportProjZipFolderZipDialog
			] && <ExportProjZipFolderZipDialog partialStore={partialStore} />}

			{partialStore.fileTreeDialogOpenStatus[
				FileScreenDialog.multipleSignOff
			] && (
				<MultipleSignOffDialog
					partialStore={partialStore}
					i18n={i18n}
				/>
			)}

			{/* ----- Move File or Folder ----- */}
			{partialStore.fileTreeDialogOpenStatus[
				FileScreenDialog.moveFileOrFolder
			] && (
				<MoveFileOrFolderDialog
					partialStore={partialStore}
					i18n={i18n}
				/>
			)}

			{partialStore.fileTreeDialogOpenStatus[
				FileScreenDialog.dragAndDropConfirmDialog
			] && (
				<DragAndDropConfirmDialog
					partialStore={partialStore}
					moveData={moveData}
				/>
			)}

			{/* ----- Comment related ----- */}
			{partialStore.fileTreeDialogOpenStatus[
				FileScreenDialog.commentList
			] && (
				<CommentListByFileDialogController
					partialStore={partialStore}
				/>
			)}
		</div>
	)
})
