import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../../common-models/enumerations/common-enums"
import { OrgSysSettingItemProps } from "../../../../../../../service-modules/sys-setting-module/sys-setting.data-model"
import { SetGlobalDnTType } from "../../../../../../../stores/root-store/global-store/global-store.view-model"

export type EditDntSettingProps = {
	dateFormat: OrgSysSettingItemProps
	timeFormat: OrgSysSettingItemProps
	timeZone: OrgSysSettingItemProps
	// postAction?: any
	setGlobalDnT: (props: SetGlobalDnTType) => void
}
const EditDateAndTimeSetting = (self: any) => ({
	editDntSetting(props: EditDntSettingProps, modifiedByInfo: any) {
		// 0.
		const actionName = "editDntSetting"
		const {
			dateFormat,
			timeFormat,
			timeZone,
			// postAction,
			setGlobalDnT,
		} = props
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		self.updateDateAndTimeSetting(props)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// NOTE: if you want to use fetched data, must update the timeFormat
					// NOTE: recommend to use existing converter
					let newDateFormat = {
						...dateFormat,
						label: "Date Format",
					}

					let newTimeFormat = {
						...timeFormat,
						label: "Time Format",
					}

					let newTimeZone = {
						...timeZone,
						label: "Time Zone",
					}

					setGlobalDnT({
						dateFormat: newDateFormat,
						timeFormat: newTimeFormat,
						timeZone: newTimeZone,
					})

					const modifiedDetails = {
						userName: modifiedByInfo.userName || "",
						userId: modifiedByInfo.aliasId || "",
						modifiedDate: modifiedByInfo.modifiedDate || "",
					}
					self.setModifiedDetails(modifiedDetails)

					// WARNING: MUST UPDATE checkin part together!
					// postAction &&
					// 	postAction({
					// 		dateFormat: newDateFormat,
					// 		timeFormat: newTimeFormat,
					// 		timeZone: newTimeZone,
					// 	}) // NOTE: for org.checkin (DO NOTE REMOVE THIS PART!!)

					// update success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: "Success to update date & time setting",
						open: true,
						autoHide: true,
						autoHideDuration: 1500,
					})
				} else {
					// update fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message,
						// customMessage: "Fail to " + actionName,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default EditDateAndTimeSetting
