import { types } from "mobx-state-tree"
import FSExcelImportViewModel from "./fs-excel-import.view-model"
import FSExcelImportModel from "./fs-excel-import.model"

export const FSExcelImportStore = () =>
	types
		.compose(FSExcelImportModel(), FSExcelImportViewModel)
		.named("FSExcelImportStore")

export const initialStore = {
	clickPoint: {
		mouseX: null,
		mouseY: null,
	},
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const FSExcelImportStoreInstance = FSExcelImportStore().create(initialStore)

export default FSExcelImportStoreInstance
