import { types } from "mobx-state-tree"
import ProjUsersModel from "./proj-setup-users.model"
import ProjUsersViewModel from "./proj-setup-users.view-model"
import ProjUsersUiHelper from "./proj-setup-users.ui-helper"
import { initialClickPoint } from "../../../../common-models/types/dialog.props"
import * as api from "./proj-setup-users.apis"
import * as projUserApis from "../../../../service-modules/user-module/proj-user-shared-apis" // TODO: is this okay to use org-side-shared-apis?
import { ProjSetupUserApiProps } from "./proj-setup-users.apis"

// NOTE: apis below are required.. but for testing...
// TODO: How to set better?
export const ProjSetupUsersStore = ({
	apiActivate,
	apiDeactivate,
	apiAssign,
	apiRead,
	apiReadGroupUsers,
	apiUpdate,
	apiUnassign,
	apiValidatePrevRole,
	apiReadAssignUserEligibility,
	apiConfigureProjDataMgmtAccessType,
}: ProjSetupUserApiProps) =>
	types
		.compose(
			ProjUsersModel({
				apiActivate,
				apiDeactivate,
				apiAssign,
				apiRead,
				apiReadGroupUsers,
				apiUpdate,
				apiUnassign,
				apiValidatePrevRole,
				apiReadAssignUserEligibility,
				apiConfigureProjDataMgmtAccessType,
			}),
			ProjUsersViewModel,
			ProjUsersUiHelper
		)
		.named("ProjSetupUsersStore")

export const initialStore = {
	selectedUser: "",
	selectedUserForAssign: "",
	clickPoint: initialClickPoint,
	prevUserRole: null,
	canAssignUser: false,
	//
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const ProjSetupUsersStoreInstance = ProjSetupUsersStore({
	apiActivate: api.activate,
	apiDeactivate: api.deactivate,
	apiAssign: api.assign,
	apiRead: projUserApis.readProjectUsers,
	apiReadGroupUsers: api.readGroupUsers,
	apiUpdate: api.update,
	apiUnassign: api.unassign,
	apiValidatePrevRole: api.validatePrevRole,
	apiReadAssignUserEligibility: api.readAssignUserEligibility,
	apiConfigureProjDataMgmtAccessType: api.configureProjDataMgmtAccessType,
}).create(initialStore)

export default ProjSetupUsersStoreInstance
