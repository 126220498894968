import { types, applySnapshot } from "mobx-state-tree"
// ---------- common models
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"
import { initialStore } from "./list-of-financial-group.provider"

import {
	ClickPoint,
	ClickPointProps,
} from "../../../../../common-models/types/dialog.props"
import { FinancialGroupModel } from "./data-models/list-of-financial-group.data-model"
import GetListOfFinancialGroup from "./view-model-actions/get-list-of-financial-group"

const ListOfFinancialGroupViewModel = types
	.model({
		financialGroups: types.array(FinancialGroupModel),
		selectedFinancialGroup: "",
		clickPoint: ClickPoint,
		// ---------- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar, // snackbar type 2
	})
	.actions((self) => ({
		setClickPoint(clickPoint: ClickPointProps) {
			self.clickPoint = clickPoint
		},
		setListOfFinancialGroup(list: any) {
			self.financialGroups = list
		},
		setSelectedFinancialGroup(id: string) {
			self.selectedFinancialGroup = id
		},
	}))
	.actions(GetListOfFinancialGroup)
	.views((self) => ({
		viewListOfFinancialGroup() {
			return self.financialGroups
		},
	}))
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default ListOfFinancialGroupViewModel
