import { flow } from "mobx-state-tree"
import { FileSystemApiTypes } from "../../apis/file-system-api-types"
import { CreateProjectZipExportApiProps } from "../../apis/file-system-download-apis"

const CreateProjectZipExport = (
	self: any,
	apiCreateProjectZipExport: FileSystemApiTypes["apiCreateProjectZipExport"]
) => ({
	createProjectZipExport: flow(function* (
		payload: CreateProjectZipExportApiProps
	) {
		const actionName = "createProjectZipExport"

		try {
			const response = yield apiCreateProjectZipExport(payload)
			return response
		} catch (error) {
			self.handleModelError({
				actionName,
				error,
				openSnackbar: true,
			})

			return false
		}
	}),
})

export default CreateProjectZipExport
