import React, { useEffect } from "react"
import { DLButton, DLDialog } from "../../../../components/basic-elements"
import { observer } from "mobx-react-lite"
import { useHistory } from "react-router-dom"
import { IStateTreeNode, getSnapshot } from "mobx-state-tree"
import ProjectLocationBreadCrumb, {
	BreadCrumbsPathModel,
} from "../../../../components/combined-elements/project-dialogs/ProjectLocationBreadCrumb"

export default observer(function ProjLocationDetailsDialog({
	partialStore,
	view,
}: {
	partialStore: any
	view: "org" | "proj"
}) {
	const history = useHistory()
	const cabinetId =
		view === "org"
			? partialStore.selectedCabinetId
			: partialStore.projInfo.cabinetId

	const projectId =
		view === "org"
			? partialStore.selectedProj
			: partialStore.projInfo.projectId
	localStorage.setItem("highlightedProjectId", projectId)

	useEffect(() => {
		partialStore.getCabinetLocation(cabinetId)
	}, [cabinetId])

	const handleClick = () => {
		const pathAsNode =
			partialStore.cabinetLocationDetails as IStateTreeNode<
				typeof BreadCrumbsPathModel
			>
		const pathSnapshot = getSnapshot(pathAsNode)
		localStorage.setItem("pathSnapshot", JSON.stringify(pathSnapshot))
		history.push("/organization/groups", pathSnapshot)
		partialStore.setOpenProjLocationDialog(false)
	}

	return (
		<DLDialog
			eleTestId="add-proj-user-dialog"
			isOpen={partialStore.openProjLocationDialog}
			setIsOpen={partialStore.setOpenProjLocationDialog}
			showCloseBtn={true}
			dialogTitle="Project Location Details"
			dialogContents={
				<div>
					<ProjectLocationBreadCrumb
						path={partialStore.cabinetLocationDetails}
						setIsOpen={partialStore.setOpenProjLocationDialog}
					/>
				</div>
			}
			cancelBtnText="Cancel"
			maxWidth="sm"
			fullWidth={true}
			showSpinner={
				partialStore.getActionStatus("getCabinetLocation") === "LOADING"
			}
			actionBtn={
				<DLButton
					variant="text"
					eleTestId="go-to-cabinet-btn-on-dialog"
					// startIcon={<Icon path={mdiTrashCan} size={0.8} />}
					clickHandler={handleClick}
					color="primary"
					disabled={
						partialStore.getActionStatus("getCabinetLocation") ===
						"LOADING"
					}
				>
					Go To Cabinet
				</DLButton>
			}
		/>
	)
})
