import React, { useEffect } from "react"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import ProjNoPermissionOnMenu from "../../proj-access-control/ProjNoPermissionOnMenu"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { observer } from "mobx-react-lite"
import FsEntityPresentation from "./FsEntityPresentation"

export default observer(function FsEntityPresentationController() {
	const projStore = useProjStore()
	const menuId = DLProjSubMenus.entity_presentation
	const isLocked = projStore.projInfo.projInfo.isLocked
	const isArchived = projStore.projInfo.projInfo.isArchived
	const menuAccess = projStore.checkin.checkAccess(menuId)
	const permission = projStore.checkin.getActionsAsObject(
		menuId,
		isLocked,
		isArchived
	)

	useEffect(() => {
		projStore.fsEntityPresentation.getFsEntityPresentation()
	}, [])

	return (
		<>
			{menuAccess ? (
				<FsEntityPresentation
					partialStore={projStore.fsEntityPresentation}
					permission={permission}
					actionStatus={projStore.fsEntityPresentation.getActionStatus(
						"getFsEntityPresentation"
					)}
				/>
			) : (
				<ProjNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})
