import {
	ActionStatus,
	MessageColorType,
	ProgressStatus,
} from "../../../../common-models/enumerations/common-enums"
import { idToString, IdType } from "../../../../library/converters/id-converter"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 *
 */

const GetFileInfo = (self: any) => ({
	getFileInfo(wpId: string, menu: "wp" | "pf") {
		// 0.
		const actionName = "getFileInfo"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.

		self.readFileInfo({ wpId, menu })
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					const data = response.data
					const fileInfo = {
						id: idToString(data.Id, IdType.file),
						alias: data.Alias,
						attentionStatus:
							data.AttentionStatus === 1
								? ProgressStatus.todo
								: data.AttentionStatus === 2
								? ProgressStatus.inProgress
								: ProgressStatus.done,
						checkedOutBy: {
							id: data.CheckedOutBy.Id.toString(),
							name: data.CheckedOutBy.Name,
							aliasId: data.CheckedOutBy.Alias,
						},
						createdBy: {
							id: data.CreatedBy.Id.toString(),
							name: data.CreatedBy.Name,
							aliasId: data.CreatedBy.Alias,
						},
						createdDate: data.CreatedDate,
						extension: data.Extension,
						srcFormat: data.Format,
						assignedRoles: data.AssignedRoles,
						hasRationale: data.HasRationale,
						isCheckedOut: data.IsCheckedOut,
						isLocked: data.IsLocked,
						lastModifiedBy: {
							id: data.LastModifiedBy.Id.toString(),
							name: data.LastModifiedBy.Name,
							aliasId: data.LastModifiedBy.Alias,
						},
						lastModifiedDate: data.LastModifiedDate,
						srcId: idToString(data.MediaId, IdType.src),
						title: data.Name,
						parentId: idToString(data.ParentId, IdType.folder),
						parentName: data.ParentName,
						qcComments: {
							completed: data.QcComments.Completed,
							pending: data.QcComments.Pending,
						},
						reviewComments: {
							completed: data.ReviewComments.Completed,
							pending: data.ReviewComments.Pending,
						},
						// ProjectId: 77,
						size: data.Size,
					}

					self.setFileInfo(fileInfo)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: false,
						autoHide: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
						autoHide: false,
						message: response.data.Message,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetFileInfo
