import React, { useState } from "react"
import { PageContainer } from "../../../../components/app-frame-elements"
import { ActionStatus } from "@datalobby/types"
import styled from "styled-components"
import { Tab, Tabs } from "@material-ui/core"
import {
	DLButton,
	DLSpinnerCenterAligned,
} from "../../../../components/basic-elements"
import AccountTable from "./sub-components/AccountTable"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import ReportTable from "./sub-components/ReportTable"
import ForeignExchange from "./sub-components/ForeignExchange"
import GroupingTable from "./sub-components/GroupingTable"
import { observer } from "mobx-react-lite"
import { WORKING_TRIAL_BALANCE_TABS } from "./store/data-models/fs-working-trial-balance.data-model"
import FsWorkingTrialBalanceCtxMenus from "./sub-components/FsWorkingTrialBalanceCtxMenus"
import LinkMapNoToAccountDialog from "./sub-components/LinkMapNoToAccountDialog"

export default observer(function FsWorkingTrialBalance({
	partialStore,
	permission,
	actionStatus,
}: {
	partialStore: any
	permission: PermissionAsObjectProps
	actionStatus: ActionStatus
}) {
	const selectedTab = partialStore.selectedTab
	const handleTab = (event: any, value: any) => {
		partialStore.setSelectedTab(value)
		partialStore.setSelectedItem("")
	}

	return (
		<PageContainer
			hasToolbar={false}
			pageTools={<div />}
			fullWidth
			isLoading={actionStatus === ActionStatus.loading}
		>
			<StyledWorkingTrialBalance>
				<div className="FR AC" style={{ gap: 10, marginBottom: 10 }}>
					<DLButton
						eleTestId="submit-project"
						color="primary"
						clickHandler={() => {}}
					>
						New
					</DLButton>
					<DLButton
						eleTestId="submit-project"
						color="primary"
						clickHandler={() => {}}
					>
						Delete
					</DLButton>
					<DLButton
						eleTestId="submit-project"
						color="primary"
						clickHandler={() => {}}
					>
						Properties
					</DLButton>
					<DLButton
						eleTestId="submit-project"
						color="primary"
						clickHandler={() => {}}
					>
						Annoatate
					</DLButton>
					<DLButton
						eleTestId="submit-project"
						color="primary"
						clickHandler={() => {}}
					>
						Settings
					</DLButton>
					<DLButton
						eleTestId="submit-project"
						color="primary"
						clickHandler={() => {}}
					>
						Setup Groups
					</DLButton>
				</div>
				<Tabs
					value={selectedTab}
					onChange={handleTab}
					indicatorColor="primary"
					textColor="primary"
					// centered
					className="type-tabs"
				>
					<Tab
						label={"Account"}
						data-testid="account-tab"
						className={
							"type-tab " +
							(selectedTab === WORKING_TRIAL_BALANCE_TABS.ACCOUNT
								? "selected"
								: "")
						}
					/>
					<Tab
						label={"Report"}
						data-testid="report-tab"
						className={
							"type-tab " +
							(selectedTab === WORKING_TRIAL_BALANCE_TABS.REPORT
								? "selected"
								: "")
						}
					/>
					<Tab
						label={"Cash Flow"}
						data-testid="cash-flow-tab"
						className={
							"type-tab " +
							(selectedTab ===
							WORKING_TRIAL_BALANCE_TABS.CASH_FLOW
								? "selected"
								: "")
						}
					/>
					<Tab
						label={"Grouping"}
						data-testid="grouping-tab"
						className={
							"type-tab " +
							(selectedTab === WORKING_TRIAL_BALANCE_TABS.GROUPING
								? "selected"
								: "")
						}
					/>
					<Tab
						label={"Foreign Exchange"}
						data-testid="foreign-exchange-tab"
						className={
							"type-tab " +
							(selectedTab ===
							WORKING_TRIAL_BALANCE_TABS.FOREIGN_EXCHANGE
								? "selected"
								: "")
						}
					/>
					<Tab
						label={"Performance Measures"}
						data-testid="performance-measures-tab"
						className={
							"type-tab " +
							(selectedTab ===
							WORKING_TRIAL_BALANCE_TABS.PERFORMANCE_MEASURES
								? "selected"
								: "")
						}
					/>
				</Tabs>
				{selectedTab === WORKING_TRIAL_BALANCE_TABS.ACCOUNT && (
					<>
						{partialStore.getActionStatus("linkMapNoToAccount") ===
						"LOADING" ? (
							<DLSpinnerCenterAligned
								absolute
								backgroundOpacity={0.5}
							/>
						) : (
							<AccountTable
								partialStore={partialStore}
								permission={permission}
							/>
						)}
					</>
				)}
				{selectedTab === WORKING_TRIAL_BALANCE_TABS.REPORT && (
					<ReportTable
						partialStore={partialStore}
						permission={permission}
					/>
				)}
				{selectedTab === WORKING_TRIAL_BALANCE_TABS.CASH_FLOW && (
					<AccountTable
						partialStore={partialStore}
						permission={permission}
					/>
				)}
				{selectedTab === WORKING_TRIAL_BALANCE_TABS.GROUPING && (
					<GroupingTable
						partialStore={partialStore}
						permission={permission}
					/>
				)}
				{selectedTab ===
					WORKING_TRIAL_BALANCE_TABS.FOREIGN_EXCHANGE && (
					<ForeignExchange
						partialStore={partialStore}
						permission={permission}
					/>
				)}
			</StyledWorkingTrialBalance>
			{partialStore.clickPoint.mouseX && (
				<FsWorkingTrialBalanceCtxMenus
					permission={permission}
					partialStore={partialStore}
				/>
			)}
			{partialStore.linkMapNoToAccountDialogOpen && (
				<LinkMapNoToAccountDialog partialStore={partialStore} />
			)}
		</PageContainer>
	)
})

const StyledWorkingTrialBalance = styled.div`
	padding-left: 1rem;
	padding-right: 1rem;
	.mt-10 {
		margin-top: 10px;
	}
	.type-tabs {
		min-height: 2rem;
		.type-tab {
			min-width: calc(100% / 6);
			border-top: 1px solid transparent;
			border-right: 1px solid transparent;
			border-left: 1px solid transparent;
			border-bottom: 1px solid ${(props) => props.theme.emphasis};
			min-height: 2rem;
			border-top-right-radius: 3px;
			border-top-left-radius: 3px;
			background-color: ${(props) => props.theme.shade05};
			font-size: 0.75rem;
			overflow: hidden;
			color: ${(props) => props.theme.textMediumEmphasis};
			&.selected {
				color: ${(props) => props.theme.emphasis};
				font-weight: ${(props) => props.theme.shared.fontBold};
				border-top: 1px solid ${(props) => props.theme.emphasis};
				border-right: 1px solid ${(props) => props.theme.emphasis};
				border-left: 1px solid ${(props) => props.theme.emphasis};
				border-bottom: 1px solid transparent;
				background-color: ${(props) => props.theme.background};
			}
		}
		span {
			/* for the border bottom highlighter */
			background-color: transparent;
		}
	}
`
