import { types } from "mobx-state-tree"
import FsListOfTransactionMetadataViewModel from "./fs-list-of-transaction-metadata.view-model"
import FsListOfTransactionMetadataModel from "./fs-list-of-transaction-metadata.model"

export const FsListOfTransactionMetadataStore = () =>
	types
		.compose(
			FsListOfTransactionMetadataModel(),
			FsListOfTransactionMetadataViewModel
		)
		.named("FsListOfTransactionMetadataStore")

export const initialStore = {
	clickPoint: {
		mouseX: null,
		mouseY: null,
	},
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const FsListOfTransactionMetadataStoreInstance =
	FsListOfTransactionMetadataStore().create(initialStore)

export default FsListOfTransactionMetadataStoreInstance
