import React from "react"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../../../stores/org-store/org-store.provider"
import { mdiChevronDown, mdiChevronUp, mdiDotsHorizontal } from "@mdi/js"
import { Icon } from "@mdi/react"
import { DLOrgSubMenus } from "../../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { DLIconButton } from "../../../../../../components/basic-elements"
import styled from "styled-components"
import { convertTestId } from "../../../../../../library/converters/id-converter"

export default observer(function EmailTemplateRowController({
	node,
	handleRightClick,
	canDrag,
	setSelectedTemplateSet,
}: {
	node: any
	handleRightClick: (e: any, id: string) => void
	canDrag: boolean
	setSelectedTemplateSet: React.Dispatch<any>
}) {
	const orgStore = useOrgStore()
	const moduleId = DLOrgSubMenus.setup_arch_policy
	//
	// const [showButtons, setShowButtons] = useState(false)
	// const handleMouseEnter = () => {
	// 	setShowButtons(true)
	// }
	// const handleMouseLeave = () => {
	// 	setShowButtons(false)
	// }
	//
	const handleClick = () => {
		orgStore.projArchDatePolicy.toggleExpand(node.id)
		orgStore.projArchDatePolicy.setSelectedPolicy(node.id)
		setSelectedTemplateSet(node.id)
	}

	let isSelected = orgStore.projArchDatePolicy.isSelected(node.id)
	const permission = orgStore.checkin.getActionsAsObject(moduleId)
	return (
		<div
			// onClick={handleClick}
			// onContextMenu={(e: any) => handleRightClick(e, node.id, "policy")}
			// onMouseEnter={handleMouseEnter}
			// onMouseLeave={handleMouseLeave}
			data-testid="arch-policy-row"
			// style={{ width: rowWidth }}
		>
			{/* <DLTreeNavParentRow
				expanded={node.expanded}
				canDrag={canDrag}
				title={node.title}
				selected={isSelected}
				showButtons={false}
				actions={actions}
				// icon={<Icon path={mdiAlphaPCircle} size={0.8} />}
				permission={permission}
				hasChildren={node.children.length > 0}
			/> */}
			<StyledDLTreeNavParentRow
				className={`FR AC JSB folder-row ${isSelected && "selected"}`}
			>
				<div className="left-side">
					<div
						className="FR AC JSB"
						data-testid={`treeNav-folder-icon-${convertTestId(
							node.title
						)}`}
						onClick={() => {
							orgStore.projArchDatePolicy.setSelectedPolicy(
								node.id
							)
							setSelectedTemplateSet(node.id)
						}}
					>
						<div className="title-text">{node.title}</div>
					</div>
				</div>
				<div className="right-side FR AC">
					{permission.create && (
						<DLIconButton
							eleTestId="arch-date-policy-cxt-menu"
							tooltipText="Actions"
							clickHandler={(e: any) =>
								handleRightClick(e, node.id)
							}
						>
							<Icon path={mdiDotsHorizontal} size={0.7} />
						</DLIconButton>
					)}
					{node.expanded ? (
						<DLIconButton
							eleTestId="collapse"
							tooltipText="Collapse"
							clickHandler={handleClick}
						>
							<Icon
								path={mdiChevronUp}
								size={0.7}
								className="folder-icon expanded"
							/>
						</DLIconButton>
					) : (
						<DLIconButton
							eleTestId="expand"
							tooltipText="Expand"
							clickHandler={handleClick}
						>
							<Icon
								path={mdiChevronDown}
								size={0.7}
								className="folder-icon"
							/>
						</DLIconButton>
					)}
				</div>
			</StyledDLTreeNavParentRow>
		</div>
	)
})

const StyledDLTreeNavParentRow = styled.div`
	width: 100%;
	.left-side {
		.drag-icon {
		}
		.folder-icon {
			cursor: pointer;
			transition: 0.4s;
			:hover {
				color: ${(props) => props.theme.emphasis};
			}
			&.expanded {
			}
		}
		.title-text {
			margin-left: 0.6rem;
		}
	}
	.right-side {
		.buttons-container {
			opacity: 0;
			transition: 0.4s;
		}
	}
	:hover {
		.buttons-container {
			opacity: 1;
		}
	}
	&.selected {
		font-weight: 700;
		color: ${(props) => props.theme.emphasis};
	}
	cursor: pointer;
`
