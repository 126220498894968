import { AxiosResponse } from "axios"
import { DeleteProjectApiProps } from "../../../../../service-modules/project-module/org-project-shared-apis"
import { TOKEN_TYPE } from "../../../../../library/api-requests/shared-for-api-requests"
import { postRequest } from "../../../../../library/api-requests"

export async function read(payload: {
	PageSize: number
	usage?: number
	SearchText?: string
}) {
	const response = await postRequest({
		url: "/GetArchivedManagementProjects",
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export type AmUnarchivedApiProps = {
	apiRead: (payload: {
		PageSize: number
		usage?: number
		SearchText?: string
	}) => Promise<AxiosResponse<any>>
	apiDelete: (payload: DeleteProjectApiProps) => Promise<AxiosResponse<any>>
	apiReadCabinetLocation: (cabinetId: number) => Promise<AxiosResponse<any>>
}
