import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"
import { findProjRoleNumberId } from "../../../../../temporary-data/project-side/find-proj-role"
import { findProjDataMgmtAccessTypeAsNumber } from "../../../../../temporary-data/project-side/find-proj-accessType"
import {
	getProjDataMgmtAccessPermissionIndex,
	ProjDataMgmtAccess,
	UpdateProjUserProps,
} from "../data-models/proj-setup-users.data-models"
import {
	idToNumber,
	IdType,
} from "../../../../../library/converters/id-converter"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - loading, success, fail
 *
 */
const ConfigureProjDataMgmtAccess = (self: any) => ({
	configureProjDataMgmtAccessPermission({
		targetUserId,
		projDataMgmtAccessPermission,
		postSuccess,
	}: {
		targetUserId: string
		projDataMgmtAccessPermission: string
		postSuccess: () => void
	}) {
		// 0.
		const actionName = "configureProjDataMgmtAccessPermission"
		// 1.
		// 2.
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 0,
			color: MessageColorType.green,
			open: true,
		})
		// 3. call API
		const payload: any = {
			targetUserId: idToNumber(targetUserId, IdType.user),
			projectDataManagementAccessPermission:
				getProjDataMgmtAccessPermissionIndex(
					projDataMgmtAccessPermission
				),
		}

		self.manageProjDataMgmtAccessType(payload)
			.then((response: any) => {
				// if (response.status === 200 && response.data.Status === 1) {
				if (response.status === 200) {
					// update stored data
					self.reflectProjDataMgmtAccessType(
						self.selectedUser,
						projDataMgmtAccessPermission
					)
					// close the dialog
					self.setOpenConfigureProjDataMgmtAccess(false)
					postSuccess()
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						message:
							"Data Management Access Permission updated successfully",
						autoHide: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default ConfigureProjDataMgmtAccess
