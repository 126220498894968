import { types } from "mobx-state-tree"
import FsSetupFsGroupsCategoryViewModel from "./fs-setup-fs-groups-category.view-model"
import FsSetupFsGroupsCategoryModel from "./fs-setup-fs-groups-category.model"

export const FsSetupFsGroupsCategoryStore = () =>
	types
		.compose(
			FsSetupFsGroupsCategoryModel(),
			FsSetupFsGroupsCategoryViewModel
		)
		.named("FsSetupFsGroupsCategoryStore")

export const initialStore = {
	clickPoint: {
		mouseX: null,
		mouseY: null,
	},
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const FsSetupFsGroupsCategoryStoreInstance =
	FsSetupFsGroupsCategoryStore().create(initialStore)

export default FsSetupFsGroupsCategoryStoreInstance
