// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
import FileSaver from "file-saver"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - loading, success, fail
 *
 */

const DownloadFileFolder = (self: any) => ({
	downloadFileFolder({
		folderId,
		projId,
	}: {
		folderId: string
		projId?: string
	}) {
		// 0.
		const actionName = "downloadFileFolder"
		// 1.
		// 2.
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 999,
			color: MessageColorType.green,
			open: true,
			// autoHide: true,
		})
		// 3.
		console.log(folderId)
		console.log("folder")
		self.requestDonwloadFileFolder({
			projId,
			folderId,
		})
			.then((response: any) => {
				if (response.status === 200) {
					const contentType = response.headers["content-type"]

					if (contentType?.includes("application/zip")) {
						// ✅ Handle ZIP file download
						const fileName = self.getItemInfo(folderId).title
						const fetchedFile = new Blob([response.data], {
							type: contentType,
						})
						FileSaver.saveAs(fetchedFile, `${fileName}.zip`)

						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200,
							color: MessageColorType.blue,
							open: true,
						})
					} else {
						// ✅ Case: Non-ZIP response (likely an error JSON inside ArrayBuffer)
						console.log(
							"Non-ZIP response received, attempting to parse JSON error..."
						)

						const reader = new FileReader()
						reader.onload = function () {
							try {
								if (typeof reader.result !== "string") {
									throw new Error(
										"Invalid file format. Expected a text response."
									)
								}

								const responseJson = JSON.parse(reader.result)
								console.log(
									"Parsed JSON response:",
									responseJson
								)

								if (responseJson?.Message) {
									self.handleResponse({
										actionName,
										status: ActionStatus.fail,
										code: 999,
										color: MessageColorType.orange,
										open: true,
										message: responseJson.Message, // ✅ Corrected reference
									})
								} else {
									console.log(
										"Unknown JSON structure:",
										responseJson
									)
								}
							} catch (error) {
								console.error("Error parsing JSON:", error)
								self.handleResponse({
									actionName,
									status: ActionStatus.fail,
									code: 999,
									color: MessageColorType.orange,
									open: true,
									message:
										"An error occurred while processing the response.",
								})
							}
						}
						reader.onerror = function () {
							console.error("Error reading Blob:", reader.error)
							self.handleResponse({
								actionName,
								status: ActionStatus.fail,
								code: 999,
								color: MessageColorType.orange,
								open: true,
								autoHide: false,
								message: "Failed to download folder.",
							})
						}

						// ✅ Call readAsText to trigger `onload`
						reader.readAsText(new Blob([response.data]))
					}
				} else {
					// ✅ Case: Non-200 response (failed request)
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
						autoHide: false,
						message: "Failed to download folder.",
					})
				}
			})
			.catch((error: Error) => {
				// ✅ Case: Network error or request failure
				console.error("Axios request failed:", error)
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default DownloadFileFolder
