/**
 * 1. Project Dashboard
 * 2. Informations
 * 3. Note and Comments
 * 4. PBC
 * 5. Task and Time
 * 6. Financial Statements
 * 7. Reconciliation
 * 8. SOX
 * 9. Workpapers
 * 10. Permanent Files
 * 11. Reports
 * 12. Archive
 * 13. Setup
 */

export enum DLProjMenuGroups {
	dashboard = "G_Dashboard",
	info = "G_Information",
	communication = "G_Communications",
	pbc = "G_PBC",
	task_mgmt = "G_TaskAndTime",
	fs = "G_FS",
	reconcil = "G_Reconciliation",
	sox = "G_SOX",
	files = "G_Files",

	reports = "G_Reports",
	archive = "G_Archive",
	setup = "G_ProjSetup",
	fs_data = "G_FSData",
	fs_transaction = "G_FSTransaction",
	fs_adjustments = "G_FSAdjustments",
	fs_excel_import = "G_FSExcelImport",
	proj_data_mgmt = "G_ProjDataMgmt",
}

export enum DLProjSubMenus {
	dashboard_default = "DashboardDefault",
	//
	proj_info = "ProjInfo",
	entity_info = "EntityInfo",
	coa_list = "CoAList",
	//
	board = "Board",
	note = "Note",
	comments = "Comments",
	// files
	wp = "WP",
	pf = "PF",
	// PBC
	pbc = "PBC",
	pbc_team_files = "PBCTeamFiles",
	pbc_client_files = "PBCClientFiles",
	// 6. fs
	fs_setup = "FSSetup",
	fs_fs = "FS",
	fs_cv = "ConsolidatingView",
	fs_ls = "FSLS",
	tb = "TB",
	fs_aje = "AJE", // adjustments
	fs_paje = "PAJE", // passed adjustments
	fs_ee = "EliminatingEntry",
	// -- extra
	closing = "Closing",
	ledgers = "Ledgers",
	fs_papers = "FSPapers",
	// 8. SOX
	planning = "Planning",
	risk = "Risks",
	process = "Process",
	controls = "Controls",
	sox_ls = "SOXLS",
	sox_report = "SOXReport",
	// 11. Reports
	report = "Report",
	checklist = "ProjChecklist",
	// 12. Archive
	arch_diagnosis = "ArchDiagnosis",
	arch_pre_process = "ArchPreProcess",
	signoff_history = "SignOffHistory",
	rationales = "Rationales",
	arch_history = "ArchHistory",
	// 13. setup // TODO: setup part naming seems better to update
	users = "ProjUsers",
	roles = "ProjRoles",
	trash = "ProjTrash",
	notification = "NotiSettings",
	danger = "Danger",
	roll_forward = "RF",
	// 14. fs data
	import_fs_datasets = "ImportFSDatasets",
	assign_fs_accounts = "AssignFSAccounts",
	fs_accounts = "FSAccounts",
	financial_group_presentation = "FinancialGroupPresentation",
	list_of_financial_group = "ListOfFinancialGroup",
	m_create_new_entity = "M_CreateNewEntity",
	entity_presentation = "EntityPresentation",
	m_create_new_fs_group_number = "M_CreateNewFSGroupNumber",
	m_create_new_map_number = "M_CreateNewMapNumber",
	m_setup_groups = "M_SetupGroups",
	m_properties_for_mapping_item = "M_PropertiesForMappingItem",
	map_numbers_presentation = "MapNumbersPresentation",
	list_of_transaction_metadata = "ListOfTransactionMetadata",
	list_of_map_numbers = "ListOfMapNumbers",
	list_of_counterparties = "ListOfCounterparties",
	m_create_new_counterparty = "M_CreateNewCounterparty",
	m_create_new_transaction_metadata = "M_CreateNewTransactionMetadata",
	m_properties_for_transaction_metadata = "M_PropertiesForTransactionMetadata",
	m_properties_for_counterparty_item = "M_PropertiesForCounterpartyItem",
	working_trial_balance = "WorkingTrialBalance",
	adjustments = "Adjustments",
	m_new_adjustment = "M_NewAdjustment",
	m_complete_excel_import = "M_CompletingExcelImport",
	m_advanced_specifications = "M_AdvancedSpecifications",
	m_mapping_header_column = "M_MappingHeaderColumnToFieldDetails",
	m_specific_data_properties = "M_SpecifyDataProperties",
	m_import_file_settings = "M_ImportFileSettings",
	// 15. proj data mgmt
	proj_download_center = "ProjDownloadCenter",
}
