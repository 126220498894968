import React, { useState } from "react"
import { PageContainer } from "../../../../components/app-frame-elements"
import { ActionStatus } from "@datalobby/types"
import styled from "styled-components"
import ReactTableV8 from "../../../../components/basic-elements/tables/DLReactTable"
import { DLButton } from "../../../../components/basic-elements"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import {
	FormControl,
	FormControlLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	TextField,
} from "@material-ui/core"
import { StyledHeader } from "../../../../components/app-frame-elements/header/StyledHeader"
import { Column, Table } from "@tanstack/react-table"
import { InputFieldForTableV8 } from "../../../../components/basic-elements/tables/InputFieldForTable"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { observer } from "mobx-react-lite"
import { DLCheckbox } from "@datalobby/components"
import ImportRoundingSettingsDialog from "./sub-components/ImportRoundingSettingsDialog"

const checkboxOptions = [
	{
		name: "accumulateBalances",
		label: "Accumulate Balances",
		defaultChecked: false,
	},
	{
		name: "clearBalance",
		label: "Clear Balances of Period(s) Being Imported",
		defaultChecked: false,
	},
	{
		name: "roundBalances",
		label: "Round Balances",
		defaultChecked: false,
	},
]

const periodMovementsCheckboxOptions = [
	{
		name: "current-year",
		label: "Current Year",
		defaultChecked: true,
	},
	{
		name: "prior-year-1",
		label: "Period Year 1",
		defaultChecked: false,
	},
	{
		name: "prior-year-2",
		label: "Period Year 2",
		defaultChecked: false,
	},
	{
		name: "prior-year-3",
		label: "Period Year 3",
		defaultChecked: false,
	},
	{
		name: "prior-year-4",
		label: "Period Year 4",
		defaultChecked: false,
	},
]

const balanceSettingCheckboxOptions = [
	{
		name: "checkbox1",
		label: "Balances being imported are year-to-date",
		defaultChecked: true,
	},
	{
		name: "checkbox2",
		label: "Balances include adjustments",
		defaultChecked: false,
	},
	{
		name: "checkbox3",
		label: "Import YTD balance for subsequent periods",
		defaultChecked: false,
	},
	{
		name: "checkbox4",
		label: "Only Balance Sheet Accounts have year-to-date balances",
		defaultChecked: false,
	},
	{
		name: "checkbox5",
		label: "Reverse period closing to Retained Earnings account",
		defaultChecked: false,
	},
]

export default observer(function M_AdvancedSpecifications({
	actionStatus,
	permission,
}: {
	actionStatus: any
	permission: any
}) {
	const store = useRootStore()
	const [defaultAccountType, setDefaultAccountType] =
		useState<any>("income-statement")
	const [importOption, setImportOption] = useState<any>("allAccounts")
	const [symbols, setSymbols] = useState<any>({
		decimalSymbol: "",
		digitGroupSymbol: "",
	})
	const [isOpen, setIsOpen] = useState(false)

	const [balanceType, setBalanceType] = useState("current-year")

	const [balancesCheckbox, setBalancesCheckbox] = useState(
		Object.fromEntries(
			checkboxOptions.map((option) => [
				option.name,
				option.defaultChecked,
			])
		)
	)

	const [balanceSettingCheckbox, setBalanceSettingCheckbox] = useState(
		Object.fromEntries(
			balanceSettingCheckboxOptions.map((option) => [
				option.name,
				option.defaultChecked,
			])
		)
	)

	const [periodMovementsCheckbox, setPeriodMovementsCheckbox] = useState(
		Object.fromEntries(
			periodMovementsCheckboxOptions.map((option) => [
				option.name,
				option.defaultChecked,
			])
		)
	)
	const [regionalSetting, setRegionalSetting] = useState(false)

	const [descriptor, setDescriptor] = useState({
		positive: "",
		negative: "",
	})

	const handleBalanceSettingChange = (event: any) => {
		setBalanceSettingCheckbox({
			...balanceSettingCheckbox,
			[event.target.name]: event.target.checked,
		})
	}

	const handleForm = (name: string, value: any) => {
		setDescriptor((prev: any) => ({
			...prev,
			[name]: value,
		}))
	}

	const handleChange = (event: any) => {
		setBalancesCheckbox({
			...balancesCheckbox,
			[event.target.name]: event.target.checked,
		})
	}

	const handlePeriodMovementsChange = (event: any) => {
		setPeriodMovementsCheckbox({
			...periodMovementsCheckbox,
			[event.target.name]: event.target.checked,
		})
	}

	return (
		<PageContainer
			hasToolbar={false}
			pageTools={<div />}
			fullWidth
			isLoading={actionStatus === ActionStatus.loading}
		>
			<StyledPage>
				<h3>Advanced Specifications</h3>
				<div style={{ margin: "15px 0" }}>
					<h4>Account Options</h4>
					<span>
						If Statement Type field is not assigned. "Income
						Statement" account type is used by default
					</span>
					<div className="FR" style={{ marginTop: 15, gap: 15 }}>
						<div style={{ width: "50%" }}>
							<div>Default Account Type</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<Select
									labelId="period-label"
									id="defaultAccountType"
									value={defaultAccountType}
									variant="outlined"
									displayEmpty
									placeholder="Select Default Account Type"
									onChange={(e) =>
										setDefaultAccountType(e.target.value)
									}
								>
									<MenuItem value={"balance-sheet"}>
										Balance Sheet
									</MenuItem>
									<MenuItem value={"income-statement"}>
										Income Statement
									</MenuItem>
									<MenuItem value={"cash-flow"}>
										Cash Flow
									</MenuItem>
									<MenuItem value={"performance-measures"}>
										Performance Measures
									</MenuItem>
								</Select>
							</FormControl>
						</div>
						<div style={{ width: "50%" }}>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									gap: "8px",
									flex: 1,
								}}
							>
								{checkboxOptions.map((option) => (
									<div
										className="FR AC"
										style={{ width: "100%" }}
										key={option.name}
									>
										<DLCheckbox
											eleClassName={option.name}
											eleTestId="copy-fs"
											eleName={option.name}
											clickHandler={handleChange}
											isChecked={
												balancesCheckbox[option.name]
											}
										/>
										<div>{option.label}</div>
										{option.name === "roundBalances" && (
											<DLButton
												variant="contained"
												eleTestId="setting"
												clickHandler={() =>
													setIsOpen(true)
												}
												color="primary"
												disabled={
													!balancesCheckbox[
														option.name
													]
												}
												size="thin"
												eleStyle={{ marginLeft: 10 }}
											>
												Settings
											</DLButton>
										)}
									</div>
								))}
							</div>
						</div>
					</div>
					<div style={{ marginTop: 15, gap: 15 }}>
						<div>Import</div>
						<div>
							<FormControl component="fieldset">
								<RadioGroup
									value={importOption}
									onChange={(e: any) =>
										setImportOption(e.target.value)
									}
									aria-label="radio-group"
								>
									<FormControlLabel
										value="allAccounts"
										control={<Radio />}
										label="All Accounts"
									/>
									<FormControlLabel
										value="account1"
										control={<Radio />}
										label="Accounts with Non-Zero Total Balance	"
									/>
									<FormControlLabel
										value="account2"
										control={<Radio />}
										label="Accounts with Opening Balance/Period Movement"
									/>
								</RadioGroup>
							</FormControl>
						</div>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								gap: "8px",
								flex: 1,
							}}
						>
							{periodMovementsCheckboxOptions.map((option) => (
								<div
									className="FR AC"
									style={{ width: "100%" }}
									key={option.name}
								>
									<DLCheckbox
										eleClassName={option.name}
										eleTestId="copy-fs"
										eleName={option.name}
										clickHandler={
											handlePeriodMovementsChange
										}
										isChecked={
											periodMovementsCheckbox[option.name]
										}
										eleDisabled={
											importOption !== "account2"
										}
									/>
									<div>{option.label}</div>
								</div>
							))}
						</div>
					</div>
				</div>
				<hr />
				<div style={{ margin: "15px 0" }}>
					<h4>Regional Settings</h4>
					<div
						className="FR AC"
						style={{ width: "100%", marginTop: 20 }}
					>
						<DLCheckbox
							eleClassName="regional-setting"
							eleTestId="regional-setting"
							eleName="regionalSetting"
							clickHandler={(e: any) =>
								setRegionalSetting(e.target.checked)
							}
							isChecked={regionalSetting}
						/>
						<div>
							Use current format in Regional Setting (uncheck to
							overwrite the default symbols)
						</div>
					</div>
					<div className="FR" style={{ marginTop: 15, gap: 15 }}>
						<div style={{ width: "50%" }}>
							<div>Decimal Symbol</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<Select
									labelId="period-label"
									id="decimalSymbol"
									value={symbols.decimalSymbol}
									variant="outlined"
									displayEmpty
									placeholder="Select Decimal Symbol"
									onChange={() => {}}
								></Select>
							</FormControl>
						</div>
						<div style={{ width: "50%" }}>
							<div>Digit Group Symbol</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<Select
									labelId="period-label"
									id="digitGroupSymbol"
									value={symbols.digitGroupSymbol}
									variant="outlined"
									displayEmpty
									placeholder="Select Digit Group Symbol"
									onChange={() => {}}
								></Select>
							</FormControl>
						</div>
					</div>
				</div>
				<hr />
				<div style={{ margin: "15px 0" }}>
					<h4>Balance Setting</h4>

					<div className="FR" style={{ marginTop: 15, gap: 15 }}>
						<div style={{ width: "50%" }}>
							<div>Balance Type</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<Select
									labelId="period-label"
									id="balanceType"
									value={balanceType}
									variant="outlined"
									displayEmpty
									placeholder="Select Balance Type"
									onChange={() => {}}
								>
									<MenuItem value={"current-year"}>
										Current Year Balance
									</MenuItem>
								</Select>
							</FormControl>
						</div>
						<div className="FC" style={{ width: "50%" }}>
							<div className="FR">
								<div>Positive Value Descriptor</div>
								<FormControl
									style={{ marginTop: 5 }}
									fullWidth
									size="small"
								>
									<TextField
										hiddenLabel
										id="positive"
										value={descriptor.positive}
										variant="outlined"
										size="small"
										onChange={(e: any) =>
											handleForm(
												"positive",
												e.target.value
											)
										}
									/>
								</FormControl>
							</div>
							<div className="FR">
								<div>Negative Value Descriptor</div>
								<FormControl
									style={{ marginTop: 5 }}
									fullWidth
									size="small"
								>
									<TextField
										hiddenLabel
										id="negative"
										value={descriptor.negative}
										variant="outlined"
										size="small"
										onChange={(e: any) =>
											handleForm(
												"negative",
												e.target.value
											)
										}
									/>
								</FormControl>
							</div>
						</div>
					</div>

					<div>
						{balanceSettingCheckboxOptions.map((option) => (
							<div
								className="FR AC"
								style={{ width: "100%" }}
								key={option.name}
							>
								<DLCheckbox
									eleClassName={option.name}
									eleTestId="copy-fs"
									eleName={option.name}
									clickHandler={handleBalanceSettingChange}
									isChecked={balancesCheckbox[option.name]}
									eleDisabled={option.name === "checkbox5"}
								/>
								<div>{option.label}</div>
							</div>
						))}
					</div>
				</div>
				<div className="FR JR" style={{ marginTop: 20 }}>
					<DLButton
						variant="contained"
						eleTestId="cancel"
						clickHandler={() => {}}
						color="primary"
					>
						Cancel
					</DLButton>
				</div>
			</StyledPage>

			{isOpen && (
				<ImportRoundingSettingsDialog
					isOpen={isOpen}
					setIsOpen={setIsOpen}
				/>
			)}
		</PageContainer>
	)
})

const StyledPage = styled.div`
	padding-left: 1rem;
	padding-right: 1rem;
	.MuiRadio-colorSecondary.Mui-checked {
		color: ${(props) => props.theme.emphasis};
	}
`
