import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { useOrgStore } from "../../../stores/org-store/org-store.provider"
import OrgNoPermissionOnMenu from "../org-access-control/OrgNoPermissionOnMenu"
import PageContainer from "../../../components/app-frame-elements/sub-components/PageContainer"
import styled from "styled-components"
import { DLOrgSubMenus } from "../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { ProjectStatus } from "../../../common-models/enumerations/project-related-enums"
import { ProjScreenType } from "../../../components/combined-elements/projects-table/projects-table-props"
import SharedProjectTableV8 from "../../../components/combined-elements/projects-table/SharedProjectTableV8"
import { OrgI18n } from "../../../common-models/enumerations/translation-sheets"
import {
	getPageSettings,
	savePageSetting,
} from "../../../library/get-page-settings"
import { IconPlaceholder } from "../../../components/basic-elements/icons/common-icons"
import { DLContextMenu } from "../../../components/basic-elements"
import { idToNumber, IdType } from "../../../library/converters/id-converter"
import ProjLocationDetailsDialog from "../../project-side/project-information/sub-components/ProjLocationDetailsDialog"

export default observer(function ForceArchivedController() {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const orgProjStore = orgStore.projects
	const menuId = DLOrgSubMenus.force_archived
	const projStatus = ProjectStatus.forceArchived
	const actionName = "getOrgProjectList_" + projStatus
	//
	const menuAccess = orgStore.checkin.checkAccess(menuId)
	const permission = orgStore.checkin.getActionsAsObject(menuId)
	const needRefresh = orgProjStore.needRefreshForForceArchived
	const i18nSheet = OrgI18n.projects
	const dntFormat = store.global.getDntFormat

	useEffect(() => {
		// const defaultYear = getDefaultFinancialYear(menuId)
		const pageSetting = getPageSettings(menuId)
		if (pageSetting?.columnFilters) {
			const value = pageSetting?.columnFilters.filter(
				(item: any) => item.id !== "financialYear"
			)
			savePageSetting("columnFilters", value, `pageSetting_${menuId}`)
		}
		if (menuAccess && needRefresh) {
			orgProjStore.getOrgProjectList({
				projStatus,
				year: "All Years",
				PTMAssignedOnly: false,
				menuId,
				ForROAM: false,
				dntFormat,
			})
		}
	}, [menuAccess, needRefresh])

	const loadingStatus = orgProjStore.getActionStatus(actionName)
	const i18n = store.i18n.combineI18n(i18nSheet)
	const clickPointExist = orgProjStore.clickPoint.mouseX !== null

	const projId = orgProjStore.selectedProj
	const projNumId = idToNumber(projId, IdType.project)

	return (
		<>
			{menuAccess ? (
				<PageContainer
					pageTools={<div />}
					hasToolbar={false}
					fullWidth
					fixedHeight
					isLoading={loadingStatus === "LOADING"}
				>
					{/* -------- Table View -------- */}
					<StyledForceArchivedProjects>
						{loadingStatus === "SUCCESS" && (
							<SharedProjectTableV8
								partialStore={orgProjStore}
								i18n={i18n}
								// common part 2
								projScreenType={ProjScreenType.forceArchived}
								menuId={DLOrgSubMenus.force_archived}
								projectStatus={ProjectStatus.forceArchived}
								permission={permission}
							/>
						)}
					</StyledForceArchivedProjects>

					{/* -------- Row Context Menu -------- */}
					{clickPointExist && (
						<DLContextMenu
							eleId="force-archive-ctx-menus"
							clickPoint={{
								mouseX: orgProjStore.clickPoint.mouseX,
								mouseY: orgProjStore.clickPoint.mouseY,
							}}
							handleClose={() =>
								orgProjStore.setClickPoint({
									mouseX: null,
									mouseY: null,
								})
							}
							menuOptions={[
								{
									label: "Open",
									value: "open",
									available: permission.read,
									isLink: true,
									linkTo: `/project/checkin/${projNumId}`,
									icon: <IconPlaceholder />,
								},
								{
									label: "Project Information",
									value: "ctx-proj_info",
									available: permission.read,
									clickEvent: () =>
										orgProjStore.setProjectDetailsDialogOpen(
											true
										),
									icon: <IconPlaceholder />,
								},
								{
									label: "Cabinet Info",
									value: "cabinet_info",
									available: permission.read,
									clickEvent: () =>
										orgProjStore.setOpenProjLocationDialog(
											true
										),
									icon: <IconPlaceholder />,
								},
							]}
						/>
					)}

					{/* -------- Cabinet Info -------- */}
					{orgProjStore.openProjLocationDialog && (
						<ProjLocationDetailsDialog
							partialStore={orgProjStore}
							view={store.global.sidebarViewState}
						/>
					)}
				</PageContainer>
			) : (
				<OrgNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})

const StyledForceArchivedProjects = styled.div`
	padding: 0 1rem;
`
