export const accountsDummyData = [
	{
		id: "acc1",
		entity: "Company A",
		accountNo: 101,
		name: "Petty cash",
		lock: false,
		mapNo: "111",
		type: "Balance sheet",
		sign: "Debit",
		ls: "A",
		taxExportCode: "L01",
		m3Code: "",
		gifi: "",
		taxEntity: "Corporation",
		class: "Assets - Current - other quick",
		openingBalance: "",
		mapNoDesc: "Cash",
		transactions: "",
		adjustments: "",
		final: "",
		py1: "",
		py2: "",
		py3: "",
		py4: "",
		by1: "",
		by2: "",
		by3: "",
		by4: "",
		by5: "",
		annotation: "",
		taxCode: "",
		mapNoFlip: "",
		mapNoFlipDesc: "",
		lsFlip: "",
		group2: "",
		group2Flip: "",
		group3: "",
		group3Flip: "",
		group4: "",
		group4Flip: "",
		group5: "",
		group5Flip: "",
		group6: "",
		group6Flip: "",
		group7: "",
		group7Flip: "",
		group8: "",
		group8Flip: "",
		group9: "",
		group9Flip: "",
		group10: "",
		group10Flip: "",
		forecastYear1: "",
		forecastYear2: "",
		forecastYear3: "",
		forecastYear4: "",
		forecastYear5: "",
		baseline: "",
		cs: "",
		ds: "",
	},
	{
		id: "acc2",
		entity: "Company A",
		accountNo: 102,
		name: "Foreign bank",
		lock: false,
		mapNo: "111.12",
		type: "Balance sheet",
		sign: "Debit",
		ls: "C",
		taxExportCode: "L01",
		m3Code: "",
		gifi: "",
		taxEntity: "Corporation",
		class: "Assets - Current - other quick",
		openingBalance: "",
		mapNoDesc: "Cash on hand - foreign currency",
		transactions: "",
		adjustments: "",
		final: "",
		py1: "",
		py2: "",
		py3: "",
		py4: "",
		by1: "",
		by2: "",
		by3: "",
		by4: "",
		by5: "",
		annotation: "",
		taxCode: "",
		mapNoFlip: "",
		mapNoFlipDesc: "",
		lsFlip: "",
		group2: "",
		group2Flip: "",
		group3: "",
		group3Flip: "",
		group4: "",
		group4Flip: "",
		group5: "",
		group5Flip: "",
		group6: "",
		group6Flip: "",
		group7: "",
		group7Flip: "",
		group8: "",
		group8Flip: "",
		group9: "",
		group9Flip: "",
		group10: "",
		group10Flip: "",
		forecastYear1: "",
		forecastYear2: "",
		forecastYear3: "",
		forecastYear4: "",
		forecastYear5: "",
		baseline: "",
		cs: "",
		ds: "",
	},
	{
		id: "acc3",
		entity: "Company A",
		accountNo: 103,
		name: "Domestic Bank",
		lock: false,
		mapNo: "111.11",
		type: "Balance sheet",
		sign: "Debit",
		ls: "A",
		taxExportCode: "L01",
		m3Code: "",
		gifi: "",
		taxEntity: "Corporation",
		class: "Assets - Current - other quick",
		openingBalance: "",
		mapNoDesc: "Cash on hand - domestic currency",
		transactions: "",
		adjustments: "",
		final: "",
		py1: "",
		py2: "",
		py3: "",
		py4: "",
		by1: "",
		by2: "",
		by3: "",
		by4: "",
		by5: "",
		annotation: "",
		taxCode: "",
		mapNoFlip: "",
		mapNoFlipDesc: "",
		lsFlip: "",
		group2: "",
		group2Flip: "",
		group3: "",
		group3Flip: "",
		group4: "",
		group4Flip: "",
		group5: "",
		group5Flip: "",
		group6: "",
		group6Flip: "",
		group7: "",
		group7Flip: "",
		group8: "",
		group8Flip: "",
		group9: "",
		group9Flip: "",
		group10: "",
		group10Flip: "",
		forecastYear1: "",
		forecastYear2: "",
		forecastYear3: "",
		forecastYear4: "",
		forecastYear5: "",
		baseline: "",
		cs: "",
		ds: "",
	},
	{
		id: "acc4",
		entity: "Company A",
		accountNo: 108,
		name: "Accounts receivable",
		lock: false,
		mapNo: "115",
		type: "Balance sheet",
		sign: "Debit",
		ls: "C",
		taxExportCode: "L02A",
		m3Code: "",
		gifi: "",
		taxEntity: "Corporation",
		class: "Assets - Current - trade receivable",
		openingBalance: "",
		mapNoDesc: "Accounts receivable",
		transactions: "",
		adjustments: "",
		final: "",
		py1: "",
		py2: "",
		py3: "",
		py4: "",
		by1: "",
		by2: "",
		by3: "",
		by4: "",
		by5: "",
		annotation: "",
		taxCode: "",
		mapNoFlip: "",
		mapNoFlipDesc: "",
		lsFlip: "",
		group2: "",
		group2Flip: "",
		group3: "",
		group3Flip: "",
		group4: "",
		group4Flip: "",
		group5: "",
		group5Flip: "",
		group6: "",
		group6Flip: "",
		group7: "",
		group7Flip: "",
		group8: "",
		group8Flip: "",
		group9: "",
		group9Flip: "",
		group10: "",
		group10Flip: "",
		forecastYear1: "",
		forecastYear2: "",
		forecastYear3: "",
		forecastYear4: "",
		forecastYear5: "",
		baseline: "",
		cs: "",
		ds: "",
	},
	{
		id: "acc5",
		entity: "Company A",
		accountNo: 115,
		name: "Short term investments",
		lock: false,
		mapNo: "113",
		type: "Balance sheet",
		sign: "Debit",
		ls: "B",
		taxExportCode: "L03",
		m3Code: "",
		gifi: "",
		taxEntity: "Corporation",
		class: "Assets - Current - other",
		openingBalance: "",
		mapNoDesc: "Short term investments",
		transactions: "",
		adjustments: "",
		final: "",
		py1: "",
		py2: "",
		py3: "",
		py4: "",
		by1: "",
		by2: "",
		by3: "",
		by4: "",
		by5: "",
		annotation: "",
		taxCode: "",
		mapNoFlip: "",
		mapNoFlipDesc: "",
		lsFlip: "",
		group2: "",
		group2Flip: "",
		group3: "",
		group3Flip: "",
		group4: "",
		group4Flip: "",
		group5: "",
		group5Flip: "",
		group6: "",
		group6Flip: "",
		group7: "",
		group7Flip: "",
		group8: "",
		group8Flip: "",
		group9: "",
		group9Flip: "",
		group10: "",
		group10Flip: "",
		forecastYear1: "",
		forecastYear2: "",
		forecastYear3: "",
		forecastYear4: "",
		forecastYear5: "",
		baseline: "",
		cs: "",
		ds: "",
	},
	{
		id: "acc6",
		entity: "Company A",
		accountNo: 116,
		name: "Long term investments",
		lock: false,
		mapNo: "131",
		type: "Balance sheet",
		sign: "Debit",
		ls: "N",
		taxExportCode: "L06",
		m3Code: "",
		gifi: "",
		taxEntity: "Corporation",
		class: "Assets - Other asset",
		openingBalance: "",
		mapNoDesc: "Long term investments",
		transactions: "",
		adjustments: "",
		final: "",
		py1: "",
		py2: "",
		py3: "",
		py4: "",
		by1: "",
		by2: "",
		by3: "",
		by4: "",
		by5: "",
		annotation: "",
		taxCode: "",
		mapNoFlip: "",
		mapNoFlipDesc: "",
		lsFlip: "",
		group2: "",
		group2Flip: "",
		group3: "",
		group3Flip: "",
		group4: "",
		group4Flip: "",
		group5: "",
		group5Flip: "",
		group6: "",
		group6Flip: "",
		group7: "",
		group7Flip: "",
		group8: "",
		group8Flip: "",
		group9: "",
		group9Flip: "",
		group10: "",
		group10Flip: "",
		forecastYear1: "",
		forecastYear2: "",
		forecastYear3: "",
		forecastYear4: "",
		forecastYear5: "",
		baseline: "",
		cs: "",
		ds: "",
	},
	{
		id: "acc7",
		entity: "Company A",
		accountNo: 117,
		name: "Deferred charges",
		lock: false,
		mapNo: "128.1",
		type: "Balance sheet",
		sign: "Debit",
		ls: "L",
		taxExportCode: "L06",
		m3Code: "",
		gifi: "",
		taxEntity: "Corporation",
		class: "Assets - Other asset",
		openingBalance: "",
		mapNoDesc: "Deferred items",
		transactions: "",
		adjustments: "",
		final: "",
		py1: "",
		py2: "",
		py3: "",
		py4: "",
		by1: "",
		by2: "",
		by3: "",
		by4: "",
		by5: "",
		annotation: "",
		taxCode: "",
		mapNoFlip: "",
		mapNoFlipDesc: "",
		lsFlip: "",
		group2: "",
		group2Flip: "",
		group3: "",
		group3Flip: "",
		group4: "",
		group4Flip: "",
		group5: "",
		group5Flip: "",
		group6: "",
		group6Flip: "",
		group7: "",
		group7Flip: "",
		group8: "",
		group8Flip: "",
		group9: "",
		group9Flip: "",
		group10: "",
		group10Flip: "",
		forecastYear1: "",
		forecastYear2: "",
		forecastYear3: "",
		forecastYear4: "",
		forecastYear5: "",
		baseline: "",
		cs: "",
		ds: "",
	},
	{
		id: "acc8",
		entity: "Company A",
		accountNo: 120,
		name: "Inventory",
		lock: false,
		mapNo: "125",
		type: "Balance sheet",
		sign: "Debit",
		ls: "D",
		taxExportCode: "L07",
		m3Code: "",
		gifi: "",
		taxEntity: "Corporation",
		class: "Assets - Current - inventory",
		openingBalance: "",
		mapNoDesc: "Inventory",
		transactions: "",
		adjustments: "",
		final: "",
		py1: "",
		py2: "",
		py3: "",
		py4: "",
		by1: "",
		by2: "",
		by3: "",
		by4: "",
		by5: "",
		annotation: "",
		taxCode: "",
		mapNoFlip: "",
		mapNoFlipDesc: "",
		lsFlip: "",
		group2: "",
		group2Flip: "",
		group3: "",
		group3Flip: "",
		group4: "",
		group4Flip: "",
		group5: "",
		group5Flip: "",
		group6: "",
		group6Flip: "",
		group7: "",
		group7Flip: "",
		group8: "",
		group8Flip: "",
		group9: "",
		group9Flip: "",
		group10: "",
		group10Flip: "",
		forecastYear1: "",
		forecastYear2: "",
		forecastYear3: "",
		forecastYear4: "",
		forecastYear5: "",
		baseline: "",
		cs: "",
		ds: "",
	},
	{
		id: "acc9",
		entity: "Company A",
		accountNo: 131,
		name: "Prepaid Expenses",
		lock: false,
		mapNo: "128.4",
		type: "Balance sheet",
		sign: "Debit",
		ls: "L",
		taxExportCode: "L03",
		m3Code: "",
		gifi: "",
		taxEntity: "Corporation",
		class: "Assets - Current - other",
		openingBalance: "",
		mapNoDesc: "Prepaid",
		transactions: "",
		adjustments: "",
		final: "",
		py1: "",
		py2: "",
		py3: "",
		py4: "",
		by1: "",
		by2: "",
		by3: "",
		by4: "",
		by5: "",
		annotation: "",
		taxCode: "",
		mapNoFlip: "",
		mapNoFlipDesc: "",
		lsFlip: "",
		group2: "",
		group2Flip: "",
		group3: "",
		group3Flip: "",
		group4: "",
		group4Flip: "",
		group5: "",
		group5Flip: "",
		group6: "",
		group6Flip: "",
		group7: "",
		group7Flip: "",
		group8: "",
		group8Flip: "",
		group9: "",
		group9Flip: "",
		group10: "",
		group10Flip: "",
		forecastYear1: "",
		forecastYear2: "",
		forecastYear3: "",
		forecastYear4: "",
		forecastYear5: "",
		baseline: "",
		cs: "",
		ds: "",
	},
	{
		id: "acc10",
		entity: "Company A",
		accountNo: 142,
		name: "Other machinery and equipment - cost",
		lock: false,
		mapNo: "157.8",
		type: "Balance sheet",
		sign: "Debit",
		ls: "U",
		taxExportCode: "L10A",
		m3Code: "",
		gifi: "",
		taxEntity: "Corporation",
		class: "Assets - Capital - amortized cost",
		openingBalance: "",
		mapNoDesc: "Other machinery and equipment - cost",
		transactions: "",
		adjustments: "",
		final: "",
		py1: "",
		py2: "",
		py3: "",
		py4: "",
		by1: "",
		by2: "",
		by3: "",
		by4: "",
		by5: "",
		annotation: "",
		taxCode: "",
		mapNoFlip: "",
		mapNoFlipDesc: "",
		lsFlip: "",
		group2: "",
		group2Flip: "",
		group3: "",
		group3Flip: "",
		group4: "",
		group4Flip: "",
		group5: "",
		group5Flip: "",
		group6: "",
		group6Flip: "",
		group7: "",
		group7Flip: "",
		group8: "",
		group8Flip: "",
		group9: "",
		group9Flip: "",
		group10: "",
		group10Flip: "",
		forecastYear1: "",
		forecastYear2: "",
		forecastYear3: "",
		forecastYear4: "",
		forecastYear5: "",
		baseline: "",
		cs: "",
		ds: "",
	},
	{
		id: "acc11",
		entity: "Company A",
		accountNo: 205,
		name: "Accounts payable",
		lock: false,
		mapNo: "215",
		type: "Balance sheet",
		sign: "Debit",
		ls: "BB",
		taxExportCode: "L16",
		m3Code: "",
		gifi: "",
		taxEntity: "Corporation",
		class: "Liabilities - Current - trade payable",
		openingBalance: "",
		mapNoDesc: "Accounts payable and accrued liabilities",
		transactions: "",
		adjustments: "",
		final: "",
		py1: "",
		py2: "",
		py3: "",
		py4: "",
		by1: "",
		by2: "",
		by3: "",
		by4: "",
		by5: "",
		annotation: "",
		taxCode: "",
		mapNoFlip: "",
		mapNoFlipDesc: "",
		lsFlip: "",
		group2: "",
		group2Flip: "",
		group3: "",
		group3Flip: "",
		group4: "",
		group4Flip: "",
		group5: "",
		group5Flip: "",
		group6: "",
		group6Flip: "",
		group7: "",
		group7Flip: "",
		group8: "",
		group8Flip: "",
		group9: "",
		group9Flip: "",
		group10: "",
		group10Flip: "",
		forecastYear1: "",
		forecastYear2: "",
		forecastYear3: "",
		forecastYear4: "",
		forecastYear5: "",
		baseline: "",
		cs: "",
		ds: "",
	},
	{
		id: "acc12",
		entity: "Company A",
		accountNo: 260,
		name: "Bank Loan (Non Current)",
		lock: false,
		mapNo: "231.2",
		type: "Balance sheet",
		sign: "Debit",
		ls: "NN",
		taxExportCode: "L20",
		m3Code: "",
		gifi: "",
		taxEntity: "Corporation",
		class: "Liabilities - Long term",
		openingBalance: "",
		mapNoDesc: "Bank loan - long term",
		transactions: "",
		adjustments: "",
		final: "",
		py1: "",
		py2: "",
		py3: "",
		py4: "",
		by1: "",
		by2: "",
		by3: "",
		by4: "",
		by5: "",
		annotation: "",
		taxCode: "",
		mapNoFlip: "",
		mapNoFlipDesc: "",
		lsFlip: "",
		group2: "",
		group2Flip: "",
		group3: "",
		group3Flip: "",
		group4: "",
		group4Flip: "",
		group5: "",
		group5Flip: "",
		group6: "",
		group6Flip: "",
		group7: "",
		group7Flip: "",
		group8: "",
		group8Flip: "",
		group9: "",
		group9Flip: "",
		group10: "",
		group10Flip: "",
		forecastYear1: "",
		forecastYear2: "",
		forecastYear3: "",
		forecastYear4: "",
		forecastYear5: "",
		baseline: "",
		cs: "",
		ds: "",
	},
	{
		id: "acc13",
		entity: "Company A",
		accountNo: 297,
		name: "Dividends",
		lock: false,
		mapNo: "286",
		type: "Balance sheet",
		sign: "Debit",
		ls: "TT.4",
		taxExportCode: "L25",
		m3Code: "",
		gifi: "",
		taxEntity: "Corporation",
		class: "Equity - dividend",
		openingBalance: "",
		mapNoDesc: "Dividends",
		transactions: "",
		adjustments: "",
		final: "",
		py1: "",
		py2: "",
		py3: "",
		py4: "",
		by1: "",
		by2: "",
		by3: "",
		by4: "",
		by5: "",
		annotation: "",
		taxCode: "",
		mapNoFlip: "",
		mapNoFlipDesc: "",
		lsFlip: "",
		group2: "",
		group2Flip: "",
		group3: "",
		group3Flip: "",
		group4: "",
		group4Flip: "",
		group5: "",
		group5Flip: "",
		group6: "",
		group6Flip: "",
		group7: "",
		group7Flip: "",
		group8: "",
		group8Flip: "",
		group9: "",
		group9Flip: "",
		group10: "",
		group10Flip: "",
		forecastYear1: "",
		forecastYear2: "",
		forecastYear3: "",
		forecastYear4: "",
		forecastYear5: "",
		baseline: "",
		cs: "",
		ds: "",
	},
]

export const mapNumbersDummyData = [
	{ mapNo: "A01", mapNoDesc: "Property, plant and equipment" },
	{ mapNo: "A02", mapNoDesc: "Intangible assets" },
	{ mapNo: "A03", mapNoDesc: "Fixed assets investments" },
	{ mapNo: "A04", mapNoDesc: "Trade investments" },
	{ mapNo: "A05", mapNoDesc: "Investment property" },
	{ mapNo: "A06", mapNoDesc: "Available-for-sale investments (in purple)" },
	{ mapNo: "A07", mapNoDesc: "Biological assets (in purple)" },
	{
		mapNo: "B01",
		mapNoDesc: "Trade and other receivables after more than one year",
	},
	{ mapNo: "C01", mapNoDesc: "Inventories" },
	{ mapNo: "C02", mapNoDesc: "Trade and other receivables" },
	{ mapNo: "C03", mapNoDesc: "Current asset investments" },
	{ mapNo: "C04", mapNoDesc: "Bank and cash balances" },
	{ mapNo: "C05", mapNoDesc: "Assets held for sale" },
	{ mapNo: "C06", mapNoDesc: "Available-for-sale investments (in purple)" },
	{ mapNo: "C09", mapNoDesc: "Suspense account" },
	{ mapNo: "D", mapNoDesc: "Trade and other payables due within one year" },
	{ mapNo: "D16", mapNoDesc: "Liabilities held for sale (in purple)" },
	{ mapNo: "E", mapNoDesc: "Trade and other payables due after one year" },
	{ mapNo: "F01", mapNoDesc: "Deferred tax" },
	{ mapNo: "F02-F06", mapNoDesc: "Provisions" },
	{ mapNo: "F10", mapNoDesc: "Accruals and deferred income" },
	{ mapNo: "F15", mapNoDesc: "Employee benefits" },
	{ mapNo: "F20", mapNoDesc: "Pensions asset/liability" },
	{
		mapNo: "F30",
		mapNoDesc: "Post-employment medical plan asset/liability (in purple)",
	},
	{ mapNo: "G01", mapNoDesc: "Share capital" },
	{ mapNo: "G02", mapNoDesc: "Other equity (in purple)" },
	{ mapNo: "H", mapNoDesc: "Reserves" },
	{ mapNo: "H09", mapNoDesc: "Cash flow hedging reserve (in purple)" },
	{ mapNo: "H10", mapNoDesc: "Available-for-sale reserve (in purple)" },
	{ mapNo: "H11", mapNoDesc: "Shares to be issued (in purple)" },
	{ mapNo: "H12", mapNoDesc: "Treasury and ESOP shares (in purple)" },
	{ mapNo: "H13", mapNoDesc: "Convertible debt option reserve (in purple)" },
	{ mapNo: "L", mapNoDesc: "Turnover" },
	{ mapNo: "M01-M20", mapNoDesc: "Cost of sales" },
	{ mapNo: "MM1-MM6", mapNoDesc: "Expenses by nature (in purple)" },
	{ mapNo: "P", mapNoDesc: "Marketing, selling and promotion" },
	{ mapNo: "Q", mapNoDesc: "Distribution expenses" },
	{ mapNo: "R", mapNoDesc: "Administration expenses" },
	{ mapNo: "S", mapNoDesc: "Establishment expenses" },
	{ mapNo: "T", mapNoDesc: "Sundry income and charges" },
	{ mapNo: "U", mapNoDesc: "Finance income and expenses" },
	{ mapNo: "V", mapNoDesc: "Investment income" },
	{ mapNo: "W", mapNoDesc: "Taxation" },
	{ mapNo: "X", mapNoDesc: "Non-controlling interests" },
]
