import { types, applySnapshot } from "mobx-state-tree"
import {
	ProjUser,
	GroupUser,
	UpdateProjUserProps,
	getProjDataMgmtAccessPermissionLabelByIndex,
	getProjDataMgmtAccessPermissionIndex,
} from "./data-models/proj-setup-users.data-models"
import Responses from "../../../../common-models/responses"
import ResponseSnackbar from "../../../../common-models/response-snackbar"
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../common-models/common-view-model-actions"
import AssignProjUser from "./view-model-actions/assign-proj-user"
import GetProjUsers from "./view-model-actions/get-proj-users"
import GetGroupUsers from "./view-model-actions/get-group-users"
import EditProjUser from "./view-model-actions/edit-proj-user"
import UnassignProjUser from "./view-model-actions/unassign-proj-user"
import DeactivateProjUser from "./view-model-actions/deactivate-proj-user"
import ActivateProjUser from "./view-model-actions/activate-proj-user"
import ValidatePrevUserRole from "./view-model-actions/validate-prev-user-role"
import {
	findProjDataMgmtAccessTypeAsNumber,
	getProjAccessLabel,
	getProjDataMgmtAccessTypeLabel,
} from "../../../../temporary-data/project-side/find-proj-accessType"
import { initialStore } from "./proj-setup-users.provider"
import { RoleInfoProps2 } from "../../../../service-modules/role-module/roles.data-model"
import { ConsoleLog } from "../../../../components/basic-elements"
import { getTzDate } from "../../../../library/converters/date-utc-converter"
import {
	displayedProjRoles,
	findProjRoleInfo,
} from "../../../../temporary-data/project-side/find-proj-role"
import GetAssignUserEligibility from "./view-model-actions/get-assign-user-eligibility"
import ConfigureProjDataMgmtAccess from "./view-model-actions/configure-proj-data-mgmt-access"

const ProjUsersViewModel = types
	.model({
		projUsers: types.array(ProjUser),
		selectedUser: types.string,
		internalGroupUsers: types.array(GroupUser),
		externalGroupUsers: types.array(GroupUser),
		selectedUserForAssign: types.string,
		prevUserRole: types.union(types.string, types.null),
		canAssignUser: types.boolean,
		// ---------- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		resetList() {
			self.projUsers.length = 0
		},
		setProjUsers(users: any) {
			self.projUsers.length = 0
			self.projUsers = users
		},
		pushItemToList(item: any) {
			self.projUsers.push(item)
		},
		reflectUnassign(userId: string) {
			self.projUsers.splice(
				self.projUsers.findIndex((user) => user.id === userId),
				1
			)
		},
		setPrevUserRole(roleId: string) {
			self.prevUserRole = roleId
		},
		resetPrevUserRole() {
			self.prevUserRole = null
		},
		setSelectedUser(userId: string) {
			ConsoleLog("setSelectedUser " + userId)
			self.selectedUser = userId
		},
		setInternalGroupUsers(users: any) {
			self.internalGroupUsers.length = 0
			self.internalGroupUsers = users
		},
		setExternalGroupUsers(users: any) {
			self.internalGroupUsers.length = 0
			self.externalGroupUsers = users
		},
		setSelectedUserForAssign(userId: string) {
			self.selectedUserForAssign = userId
		},
		reflectUpdate(userId: string, update: UpdateProjUserProps) {
			self.projUsers.map((user: any) => {
				if (user.id === userId) {
					const roleNumId = findProjRoleInfo(update.roleId).oldNumId

					user.roleNumId = roleNumId
					user.roleId = update.roleId
					user.accessTypeId = update.accessTypeId
					user.accessTypeName = getProjAccessLabel(
						update.accessTypeId
					)
					user.expDate = update.expDate
					user.canRollForward = update.canRollForward
				}
			})
		},
		reflectProjDataMgmtAccessType(
			userId: string,
			projDataMgmtAccessType: string
		) {
			const user = self.projUsers.find((user: any) => user.id === userId)
			// if (user) {
			// 	user.projDataMgmtAccessPermissionId =
			// 		findProjDataMgmtAccessTypeAsNumber(projDataMgmtAccessType)!
			// 	user.projDataMgmtAccessPermissionLabel =
			// 		getProjDataMgmtAccessTypeLabel(projDataMgmtAccessType)
			// }
			if (user) {
				user.projDataMgmtAccessPermissionId =
					getProjDataMgmtAccessPermissionIndex(projDataMgmtAccessType)
				user.projDataMgmtAccessPermissionLabel = projDataMgmtAccessType
			}
		},
		setProjUserStatus(userId: string, request: "activate" | "deactivate") {
			self.projUsers.map((user) => {
				if (user.id === userId) {
					user.status = request === "activate" ? "Active" : "Inactive"
				}
			})
			ConsoleLog(["updated proj users", self.projUsers])
		},
		setAssignUserEligibility(eligibility: boolean) {
			self.canAssignUser = eligibility
		},
	}))
	.views((self) => ({
		viewProjectUserIds() {
			return self.projUsers.map((user) => user.aliasId)
		},
		formattedUsers(timeZone: string, dateFormat: string) {
			let userList: any[] = []
			self.projUsers.map((user) => {
				const formattedExpDate = user.expDate
					? getTzDate({ date: user.expDate, timeZone, dateFormat })
					: ""
				userList.push({
					...user,
					expDate: formattedExpDate,
					deactivated: user.status,
				})
			})
			return userList
		},
		userListWithRoleInfo(roles: RoleInfoProps2[]) {
			// NOTE: This list requires role short name and color
			let userList: any[] = [] // this list get proj User props and role Shortname and color
			console.log(roles, "roles1")
			self.projUsers.map((user) => {
				const roleInfo = displayedProjRoles.find(
					(role: any) => role.roleId === user.roleId
				)
				userList.push({
					...user,
					roleShortName: roleInfo?.shortName,
					roleColor: roleInfo?.color,
				})
			})
			return userList
		},
		usersAssignedOnComment(ids: string[]) {
			let userList: {
				id: string
				name: string
				aliasId: string
				roleId: string
			}[] = []
			self.projUsers.map(
				(user) => ids.includes(user.id) && userList.push(user)
			)
			return userList
		},
		viewSelectedUserInfo() {
			const targetUser = self.projUsers.find(
				(user) => user.id === self.selectedUser
			)
			ConsoleLog("viewSelectedUserInfo " + targetUser)
			return targetUser
		},
		viewUserStatus(userId: string) {
			const targetUser = self.projUsers.find((user) => user.id === userId)
			ConsoleLog(["viewUserStatus - targetUser", userId, targetUser])

			return targetUser?.status
		},
		viewUserStatusForTable(userId: string) {
			const targetUser = self.projUsers.find((user) => user.id === userId)
			ConsoleLog("viewUserStatus - targetUser " + targetUser)

			return targetUser?.status ? "deactivated-user" : "active-user"
		},
		viewGroupUsers(userType: "internal" | "external") {
			return userType === "internal"
				? self.internalGroupUsers
				: self.externalGroupUsers
		},
		viewUserInfo(userId: string) {
			const filtered = self.projUsers.filter(
				(user: any) => user.id === userId
			)
			if (filtered.length > 1) {
				ConsoleLog("(!) There are multiple users who have same ID")
				return filtered[0]
			} else if (filtered.length === 1) {
				return filtered[0]
			} else {
				return { name: "unknown" }
			}
		},
		viewSelectedUserForAssign(userType: "internal" | "external") {
			const targetUserGroup =
				userType === "internal"
					? self.internalGroupUsers
					: self.externalGroupUsers
			const targetUser = targetUserGroup.find(
				(user) => user.id === self.selectedUserForAssign
			)
			return targetUser
		},
	}))
	// AGER (Add, Get, Edit, Remove)
	// NOTE: in here, 'assign' be used instead of the 'add'
	.actions(GetProjUsers)
	.actions(AssignProjUser)
	.actions(GetGroupUsers)
	.actions(EditProjUser)
	.actions(UnassignProjUser)
	.actions(DeactivateProjUser)
	.actions(ActivateProjUser)
	.actions(ValidatePrevUserRole)
	.actions(GetAssignUserEligibility)
	.actions(ConfigureProjDataMgmtAccess)
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	// .actions((self) => ({
	// 	refresh() {
	// 		self.initializeStore()
	// 		self.getProjUsers()
	// 	},
	// }))
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default ProjUsersViewModel
