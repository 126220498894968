import React, { useEffect, useState, useCallback } from "react"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import { observer } from "mobx-react-lite"
import {
	DLSingleSelect,
	InputWithLabel,
	DLRadioGroup,
	DLDialog,
	DLDatepicker,
	ConsoleLog,
} from "../../../../components/basic-elements"
import styled from "styled-components"
import {
	ProjDataMgmtAccess,
	ConfigureProjDataMgmtAccessProps,
	getProjDataMgmtAccessPermissionLabelByIndex,
	ProjAccess,
} from "../store/data-models/proj-setup-users.data-models"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import SelectedUserCard from "./SelectedUserCard"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { getProjIdFromUrl } from "../../../../library/api-requests/request-get-others"
import { addTzToDate } from "../../../../library/converters/date-utc-converter"
import { useHistory } from "react-router-dom"

export default observer(function ConfigureProjDataMgmtAccess({
	i18n,
}: {
	i18n: DLI18nProps
}) {
	ConsoleLog(" ___ConfigureProjDataMgmtAccess___ ")
	const store = useRootStore()
	const projStore = useProjStore()
	const history = useHistory()
	const actionName = "configureProjDataMgmtAccess"
	const targetUserId = projStore.projUsers.selectedUser
	const projId = getProjIdFromUrl()
	const actBy = localStorage.getItem("orgUserId") || ""
	const userInfo = projStore.projUsers.viewSelectedUserInfo()

	// Memoized inputs initialization
	const [inputs, setInputs] = useState<ConfigureProjDataMgmtAccessProps>(
		() => ({
			projDataMgmtAccessPermissionLabel:
				userInfo?.projDataMgmtAccessPermissionLabel ||
				ProjDataMgmtAccess.noAccess,
		})
	)

	const isNotPdmaReadOnlyUser = !(
		(inputs?.projDataMgmtAccessPermissionLabel ===
			ProjDataMgmtAccess.createAndDownload ||
			inputs?.projDataMgmtAccessPermissionLabel ===
				ProjDataMgmtAccess.downloadOnly) &&
		userInfo?.accessTypeId === ProjAccess.readonly
	)

	useEffect(() => {
		setInputs({
			projDataMgmtAccessPermissionLabel:
				userInfo?.projDataMgmtAccessPermissionLabel ||
				ProjDataMgmtAccess.noAccess,
		})
	}, [userInfo])

	const handleInputs = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const { name, value } = event.target
			setInputs((prev) => ({
				...prev,
				[name]: value,
			}))
		},
		[]
	)

	const handleConfigureProjDataMgmtAccess = () => {
		const isOwnUser = targetUserId === projStore.checkin.userId
		const permissionLabel = inputs.projDataMgmtAccessPermissionLabel
		const isNoAccess = permissionLabel === ProjDataMgmtAccess.noAccess
		const oldPermissionId = userInfo?.projDataMgmtAccessPermissionId

		// helper function
		const shouldRedirect =
			isOwnUser && (isNoAccess || oldPermissionId === 0)
		const confirmAction = (message: string) => window.confirm(message)

		// Show confirmation dialog if needed
		if (shouldRedirect) {
			const message = isNoAccess
				? "Since you are updating your own Proj Data Mgmt Access Permission to No Access, you will be redirected to the Organization View after the update is successful and will need to revisit the project."
				: "Since you are updating your own Proj Data Mgmt Access Permission from No Access to other, you will be redirected to the Organization View after the update is successful and will need to revisit the project."

			if (!confirmAction(message)) return
		}

		// Post-success callback
		const postSuccess = () => {
			if (isOwnUser) {
				projStore.checkin.updateCurrentUserOnProj(permissionLabel)
				if (shouldRedirect) {
					history.push("/organization/my-page/recent")
					localStorage.removeItem("projTokens")
				}
			}
		}

		// API call
		projStore.projUsers.configureProjDataMgmtAccessPermission({
			targetUserId,
			projDataMgmtAccessPermission: permissionLabel,
			postSuccess,
		})
	}

	return (
		<DLDialog
			eleTestId="configure-proj-data-mgmt-access-dialog"
			isOpen={projStore.projUsers.openConfigureProjDataMgmtAccess}
			setIsOpen={projStore.projUsers.setOpenConfigureProjDataMgmtAccess}
			handleAction={handleConfigureProjDataMgmtAccess}
			showOpenBtn={false}
			showCloseBtn={true}
			dialogTitle={
				<div className="FR AC">
					<div style={{ marginLeft: 8 }}>
						{i18n.twEditUser ||
							"Configure Project Data Mgmt Access"}
					</div>
				</div>
			}
			dialogContents={
				<ConfigureProjDataMgmtAccessForm
					inputs={inputs}
					handleInputs={handleInputs}
					i18n={i18n}
					existingAccessTypeId={
						userInfo?.projDataMgmtAccessPermissionLabel || ""
					}
				/>
			}
			cancelBtnText={i18n.twCancel || "Cancel"}
			actionReady={isNotPdmaReadOnlyUser}
			actionBtn={i18n.twSubmit || "Submit"}
			maxWidth="sm"
			fullWidth={true}
			dialogError={
				projStore.projUsers.getActionStatus(actionName) === "FAIL"
					? projStore.projUsers.getMessage(actionName)
					: ""
			}
			showSpinner={
				projStore.projUsers.getActionStatus(actionName) === "LOADING"
			}
		/>
	)
})

const ConfigureProjDataMgmtAccessForm = observer(
	({
		inputs,
		handleInputs,
		i18n,
		existingAccessTypeId,
	}: {
		inputs: ConfigureProjDataMgmtAccessProps
		handleInputs: (event: React.ChangeEvent<HTMLInputElement>) => void
		i18n: DLI18nProps
		existingAccessTypeId: string
	}) => {
		const projStore = useProjStore()
		const targetUser = projStore.projUsers.viewSelectedUserInfo()
		const isPdmaReadOnlyUser =
			(inputs.projDataMgmtAccessPermissionLabel ===
				ProjDataMgmtAccess.createAndDownload ||
				inputs.projDataMgmtAccessPermissionLabel ===
					ProjDataMgmtAccess.downloadOnly) &&
			targetUser?.accessTypeId === ProjAccess.readonly

		return (
			<StyledConfigureProjDataMgmtAccessForm>
				{targetUser && (
					<div className="input-section FR">
						<InputWithLabel
							label={i18n.twSelectedUser || "Selected User"}
						>
							<SelectedUserCard
								name={targetUser.name}
								aliasId={targetUser.aliasId}
								email={targetUser.email}
								title={targetUser.orgTitle || ""}
							/>
						</InputWithLabel>
					</div>
				)}

				<div className="input-section FR">
					<InputWithLabel label="Project Data Mgmt Access Permission">
						<DLRadioGroup
							selectedValue={
								inputs.projDataMgmtAccessPermissionLabel
							}
							eleOnChange={handleInputs}
							items={[
								{
									value: ProjDataMgmtAccess.createAndDownload,
									label: "Create/Download",
								},
								{
									value: ProjDataMgmtAccess.downloadOnly,
									label: "Download Only",
								},
								{
									value: ProjDataMgmtAccess.viewListOnly,
									label: "View List Only",
								},
								{
									value: ProjDataMgmtAccess.noAccess,
									label: "No Access",
								},
							]}
							groupName="projDataMgmtAccessPermissionLabel"
							itemDirection="column"
							formHelpText={
								isPdmaReadOnlyUser
									? 'For a Read-Only user, the Project Data Management access cannot be set to "Create/Download" or "Download Only." Please select either "View List Only" or "No Access" permissions'
									: ""
							}
						/>
					</InputWithLabel>
				</div>
			</StyledConfigureProjDataMgmtAccessForm>
		)
	}
)

const StyledConfigureProjDataMgmtAccessForm = styled.div`
	padding-left: 0.5rem;
	.input-section {
		margin-bottom: 1.5rem;
		.label {
			min-width: 9rem;
		}
		.input-area {
			min-width: 20rem;
		}
	}
	.help-text {
		width: 100%;
		color: ${(props) => props.theme.warning};
	}
`
