import React, { useState, memo } from "react"
import { makeStyles, Theme } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Box from "@material-ui/core/Box"
import { DLSpinner } from "../../../../components/basic-elements"
import { DLTooltip } from "@datalobby/components"
import Icon from "@mdi/react"
import { mdiInformation } from "@mdi/js"
import {
	getTzDate,
	getYesterdayMidnightMinusOne,
} from "../../../../library/converters/date-utc-converter"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { subDays, setHours, setMinutes, format } from "date-fns"

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		height: "70vh",
		backgroundColor: theme.palette.background.paper,
	},
	buttonGroup: {
		// display: "flex",
		// marginBottom: theme.spacing(2),
		// paddingLeft: theme.spacing(2),
		// gap: theme.spacing(1), // Space between buttons
		display: "flex",
		justifyContent: "space-between", // Push buttons to left & date info to right
		alignItems: "center",
		width: "100%", // Ensure full width
		marginBottom: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2), // Add some spacing on right
		gap: theme.spacing(1),
	},
	button: {
		//textTransform: "none", // Disable uppercase text
	},
	buttonActive: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
	},
	content: {
		flexGrow: 1,
		// padding: theme.spacing(2),
		overflowY: "auto", // Scroll only if the content overflows
	},
	spinner: {
		height: 540,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	dateTimeContainer: {
		// display: "flex",
		// flexDirection: "column", // Stack vertically
		// alignItems: "flex-start", // Align to left
		// gap: "1rem", // Add spacing between elements
		display: "flex",
		flexDirection: "column", // Stack vertically
		alignItems: "flex-start",
		gap: theme.spacing(1), // Space between Today & Base DateTime
		marginLeft: "auto", // Push it to the extreme right
	},
	todayDateContainer: {
		marginLeft: "auto",
		display: "flex",
		alignItems: "center",
		marginRight: 10,
	},
	todayDate: {
		height: 20,
		width: 240,
		backgroundColor: "#94b5f3",
		borderRadius: 2,
	},
}))

export default memo(function MySummaryButtons({
	summaryData,
}: {
	summaryData: Array<{ label: string; content: JSX.Element }>
}) {
	const store = useRootStore()
	const dntFormat = store.global.getDntFormat
	const classes = useStyles()
	const [selectedButton, setSelectedButton] = useState(0)
	const [loading, setLoading] = useState(false)

	const handleButtonClick = (index: number) => {
		setLoading(true)
		setTimeout(() => {
			setSelectedButton(index)
			setLoading(false)
		}, 500) // Simulates loading time
	}

	return (
		<div className={classes.root}>
			<Box className={classes.buttonGroup}>
				{summaryData.map((button, index) => (
					<Button
						key={index}
						variant="contained"
						className={`${classes.button} ${
							selectedButton === index ? classes.buttonActive : ""
						}`}
						onClick={() => handleButtonClick(index)}
					>
						{button.label}
					</Button>
				))}

				{/* Vertically aligned Today & Base DateTime */}
				<Box className={classes.dateTimeContainer}>
					<Box className={classes.todayDateContainer}>
						<span>Today (based on the org. time zone)</span>
						<div className={classes.todayDate}>
							<DLTooltip
								title={"Today's date"}
								elePlacement="top"
								arrow
							>
								<div className="FR JSB">
									<Icon path={mdiInformation} size={0.7} />
									<span>
										{getTzDate({
											date: new Date(),
											timeZone: dntFormat.timeZone,
											dateFormat: dntFormat.dateFormat,
										})}
									</span>
								</div>
							</DLTooltip>
						</div>
					</Box>

					<Box className={classes.todayDateContainer}>
						<span>Base DateTime (based on the org. time zone)</span>
						<div className={classes.todayDate}>
							<DLTooltip
								title={"Base DateTime"}
								elePlacement="top"
								arrow
							>
								<div className="FR JSB">
									<Icon path={mdiInformation} size={0.7} />
									<span>
										{getYesterdayMidnightMinusOne({
											timeZone: dntFormat.timeZone,
											dateFormat: `${dntFormat.dateFormat} ${dntFormat.timeFormat}`,
										})}
									</span>
								</div>
							</DLTooltip>
						</div>
					</Box>
				</Box>
			</Box>
			<Box className={classes.content}>
				{loading ? (
					<div className={classes.spinner}>
						<DLSpinner />
					</div>
				) : (
					summaryData[selectedButton].content
				)}
			</Box>
		</div>
	)
})
