import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"

const getMockData = async () => {
	return new Promise((resolve, reject) => {
		setTimeout(() => {
			resolve({
				status: 200,
				data: {
					Status: 1,
				},
			})
		}, 1000)
	})
}

const LinkMapNoToAccount = (self: any) => ({
	linkMapNoToAccount({
		id,
		mapNo,
		mapNoDesc,
	}: {
		id: string
		mapNo: string
		mapNoDesc: string
	}) {
		// 0. set actionName
		const actionName = "linkMapNoToAccount"
		// 1.
		// 2. set response
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. call API
		getMockData()
			.then((response: any) => {
				if (response) {
					console.log(response, "responseresponse")
					if (response.status === 200 && response.data.Status === 1) {
						self.updateMapNoToAccount(id, mapNo, mapNoDesc)
						self.setLinkMapNoToAccountDialogOpen(false)
						// set response as success
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 999, // temp
							customMessage: "Successfully fetched",
							color: MessageColorType.blue,
							open: true,
						})
					}
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default LinkMapNoToAccount
