import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"

const GetProjDataMgmtZipFileById = (self: any) => ({
	getProjDataMgmtZipFileById(zipFileId: number) {
		const actionName = "getProjDataMgmtZipFileById"
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})

		self.requestProjDataMgmtZipFileById(zipFileId)
			.then((response: any) => {
				// if success
				if (response.status === 200) {
					const downloadUrl = response.data.data.downloadUrl
					// Create an invisible anchor element
					const link = document.createElement("a")
					link.href = downloadUrl
					link.download = ""
					// Append the link to the body and trigger the download
					document.body.appendChild(link)
					link.click()
					// document.body.removeChild(link)
					link?.parentNode?.removeChild(link)

					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage:
							"Success to download the proj data management zip file",
						open: true,
						autoHide: true,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message || response.data.message,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default GetProjDataMgmtZipFileById
