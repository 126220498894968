import { ActionStatus } from "../../../../../common-models/enumerations/common-enums"
import { MenuPermissionProps } from "../../../../../service-modules/role-module/roles.data-model"
import {
	DLProjMenuGroups,
	DLProjSubMenus,
} from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { compareValues } from "../../../../../components/basic-elements/tree-list/getTreeFromFlatData"

const GetProjAccessibleMenus = (self: any) => ({
	getProjAccessibleMenus(
		projStatusName: string,
		customerMenus: any[],
		hasProjectDataMgmtMenuAccess: boolean
	) {
		// 0) set actionName
		const actionName = "getProjAccessibleMenus"
		// 1)
		// 2)
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})

		let accessibleMenus: any = []
		self.assignedRole.menuPermissions.forEach(
			(item: MenuPermissionProps) => {
				if (item.menuId === "G_ProjDataMgmt") {
					if (hasProjectDataMgmtMenuAccess) {
						accessibleMenus.push(item)
					}
				} else if (item.accessible) {
					accessibleMenus.push(item)
				}
			}
		)

		let tree: any = []
		let flat: any[] = []
		let mappedFlatData: any = {}

		accessibleMenus.map((flatItem: MenuPermissionProps) => {
			const matchedItem = customerMenus.find(
				(menu) => menu.id === flatItem.menuId
			)
			if (matchedItem) {
				mappedFlatData[flatItem.menuId] = {
					id: flatItem.menuId,
					title: matchedItem.name,
					url: matchedItem.urlEdge,
					menuGroup: matchedItem.parentId ?? null,
					index: matchedItem.index,
					subMenus: [],
					isAdminMenu: matchedItem.isAdminMenu ? true : false,
				}
			} else {
				// ConsoleLog([
				// 	"*",
				// 	actionName,
				// 	"__ No matched menu info",
				// 	flatItem.menuId,
				// ])
			}
		})

		for (var menuId in mappedFlatData) {
			// NOTE: Do not include dashboard_default at the moment.Updated by @Bada on 2024-12-05 per BO's request. You can remove the condition when needed. Also, update the all-menus.json file
			if (menuId === DLProjSubMenus.dashboard_default) continue
			if (mappedFlatData.hasOwnProperty(menuId)) {
				const item = mappedFlatData[menuId]
				// NOTE: Push arch_diagnosis to the Dashboard group at the moment. Updated by @Bada on 2024-12-05 per BO's request. You can remove the condition when needed. Also, update the all-menus.json file
				if (item.id === DLProjSubMenus.arch_diagnosis) {
					mappedFlatData[DLProjMenuGroups.dashboard]["subMenus"].push(
						item
					)
				}
				if (item.menuGroup === null) {
					// ConsoleLog("GROUP::menuId", item.id)
					tree.push(item)
					flat.push(item)
				} else {
					if (mappedFlatData[item.menuGroup]) {
						// if mappedFlatData[item.menuGroup] not exist, it means, parent menuGroup is not allowed to access
						// if project is ARCHIVED then don't show Roll Forward
						if (
							!(
								item.id === DLProjSubMenus.roll_forward &&
								projStatusName === "ARCHIVED"
							)
						) {
							mappedFlatData[item.menuGroup]["subMenus"].push(
								item
							)
							flat.push(item)
						}
					}
				}
			}
		}

		// arrange by index number
		tree.sort(compareValues("index")) // TODO: How to arrange sub menus?
		tree.map((menuGroup: any) => {
			menuGroup.subMenus.sort(compareValues("index"))
		})
		// ConsoleLog("accessible menu tree", tree)
		self.setAccessibleTreeMenus(tree)

		flat.sort(compareValues("index"))
		// ConsoleLog("flat", flat)
		self.setAccessibleFlatMenus(flat)

		self.handleResponse({
			actionName,
			status: ActionStatus.success,
			code: 200,
			customMessage: "Success to get the accessible project menu list ",
			open: false,
		})
	},
})

export default GetProjAccessibleMenus
