import { types } from "mobx-state-tree"
// model common action
import { CommonModelActions } from "../../../../../common-models/common-model-actions"
// separated actions
import { OrgDashboardApiProps } from "./org-dashboard.apis"
import ReadOrgDashboardList from "./model-actions/read-org-dashboard-list"
import ReadCabinetLocation from "../../../../project-side/project-information/store/model-actions/read-cabinet-location"

const OrgDashboardModel = ({
	apiRead,
	apiReadCabinetLocation,
}: OrgDashboardApiProps) =>
	types
		.model({})
		// CRUD
		.actions((self, read = apiRead) => ReadOrgDashboardList(self, read))
		.actions((self) => ReadCabinetLocation(self, apiReadCabinetLocation))
		// Common model actions
		.actions(CommonModelActions)

export default OrgDashboardModel
