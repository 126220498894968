import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import { DLOrgSubMenus } from "../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import OrgNoPermissionOnMenu from "../../../org-access-control/OrgNoPermissionOnMenu"
import EmailTemplate from "./EmailTemplate"

export default observer(function EmailTemplateController() {
	// default settings
	const store = useRootStore()
	const orgStore = useOrgStore()

	const menuId = DLOrgSubMenus.setup_proj_arch_date_policy
	const actionName = "getProjArchDatePolicies"
	// access & permission
	const menuAccess = orgStore.checkin.checkAccess(menuId)
	const permission = orgStore.checkin.getActionsAsObject(menuId)

	const needRefresh = orgStore.projArchDatePolicy.needRefresh

	useEffect(() => {
		menuAccess &&
			needRefresh &&
			orgStore.projArchDatePolicy.getProjArchDatePolicies()
	}, [menuAccess, needRefresh])

	return (
		<>
			{menuAccess ? (
				<EmailTemplate
					partialStore={orgStore.projArchDatePolicy}
					fetchingStatus={orgStore.projArchDatePolicy.getActionStatus(
						actionName
					)}
					permission={permission}
					contentHeight={store.ui.contentsAreaHeight}
				/>
			) : (
				<OrgNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})
