import { ActionStatus } from "../../../../../../common-models/enumerations/common-enums"
import { convertOrgRole } from "../../../../../../library/converters/convert-role-id"
import {
	idToString,
	IdType,
} from "../../../../../../library/converters/id-converter"

const GetOrgInfo = (self: any) => ({
	getOrgInfo(orgId: string, postAction?: () => void) {
		// 0. set action name
		const actionName = "getOrgInfo"
		// 1.
		// 2. set responses
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. request API
		self.readOrgInfo()
			.then((response: any) => {
				if (
					response.status === 200 &&
					response.data.status.code === 200
				) {
					const fetchedOrgInfoList = response.data.data
					const fetchedOrgInfo = fetchedOrgInfoList.find(
						(element: any) =>
							idToString(element.CustomerID, IdType.org) === orgId
					)
					// ConsoleLog("fetchedOrgInfo.AccessType", fetchedOrgInfo.AccessType)
					// let orgRoleId = ""

					// if (
					// 	fetchedOrgInfo.AccessType === "Super Admin" &&
					// 	fetchedOrgInfo.isArchiveManager
					// ) {
					// 	orgRoleId = "id_aa"
					// }
					// if (
					// 	fetchedOrgInfo.AccessType === "Super Admin" &&
					// 	!fetchedOrgInfo.isArchiveManager
					// ) {
					// 	orgRoleId = "id_sa"
					// }
					// if (fetchedOrgInfo.AccessType === "Group Admin") {
					// 	orgRoleId = "id_ga"
					// }
					// if (fetchedOrgInfo.AccessType === "User Access") {
					// 	orgRoleId = "id_ua"
					// }
					// if (fetchedOrgInfo.AccessType === "External") {
					// 	orgRoleId = "id_ex"
					// }
					const orgRoleId = convertOrgRole(
						fetchedOrgInfo.AccessType,
						fetchedOrgInfo.isArchiveManager
					)

					let newOrgInfo
					newOrgInfo = {
						orgId: idToString(
							fetchedOrgInfo.CustomerID,
							IdType.org
						),
						orgName: fetchedOrgInfo.OrganizationName,
						//
						userId: idToString(fetchedOrgInfo.UserId, IdType.user),
						userAliasId: fetchedOrgInfo.UserName,
						userName: fetchedOrgInfo.Name,
						userTitle: "(pending)", // fetchedOrgInfo has no user title yet / or it seems better to separate the user info API
						email: fetchedOrgInfo.Email,
						isExternalUser: fetchedOrgInfo.isExternal,
						//
						countryCode: fetchedOrgInfo.CountryCode
							? fetchedOrgInfo.CountryCode
							: "US", // for login test to production by localhost (03/02/2020) TODO: remove if it is confirmed
						language: fetchedOrgInfo.Language,
						//
						assignedBy: fetchedOrgInfo.AssignedBy,
						assignedAt: fetchedOrgInfo.AssignedDate,
						//
						accessType: fetchedOrgInfo.AccessType, // TODO: consider to remove and find the new role name by orgRoleId
						isQcUser: fetchedOrgInfo.IsQCUser, // TODO: is this required...?
						isSuperAdmin: fetchedOrgInfo.IsSuperAdmin,
						isArchiveAdmin: fetchedOrgInfo.isArchiveManager,
						isOrgProjectDataMgmtAdmin:
							fetchedOrgInfo.isOrgProjectDataMgmtAdmin,
						hasCustomerViewAccess: fetchedOrgInfo.CustomerView,
						// custom added
						orgRoleId,
					}
					// ConsoleLog("=====/// newOrgInfo ///=====", newOrgInfo)
					localStorage.setItem(
						"orgName",
						fetchedOrgInfo.OrganizationName
					)
					localStorage.setItem("orgRoleId", orgRoleId)
					// for project side bar
					self.setOrgInfo(newOrgInfo)
					postAction && postAction()
					// save success case response
					self.responses.setResponse(actionName, {
						actionName,
						status: ActionStatus.success,
					})
				} else {
					self.responses.setResponse(actionName, {
						actionName,
						status: ActionStatus.fail,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetOrgInfo
