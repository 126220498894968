import {
	ProjAccess,
	ProjDataMgmtAccess,
} from "../../screens/project-side/project-setup-users/store/data-models/proj-setup-users.data-models"

const accessTypes = [
	{
		oldId: 1,
		newId: ProjAccess.archAdmin,
		label: "Project Archive Admin",
	},
	{
		oldId: 2,
		newId: ProjAccess.projAdmin,
		label: "Project Admin",
	},
	{
		oldId: 3,
		newId: ProjAccess.user,
		label: "Project User",
	},
	{
		oldId: 4,
		newId: ProjAccess.readonly,
		label: "Readonly",
	},
	{
		oldId: 5,
		newId: ProjAccess.pbcUpload,
		label: "PBC Upload",
	},
]

const projDataMgmtAccessTypes = [
	{
		oldId: 1,
		newId: ProjDataMgmtAccess.createAndDownload,
		label: "Create/Download",
	},
	{
		oldId: 2,
		newId: ProjDataMgmtAccess.downloadOnly,
		label: "Download Only",
	},
	{
		oldId: 3,
		newId: ProjDataMgmtAccess.viewListOnly,
		label: "View List Only",
	},
	{
		oldId: 4,
		newId: ProjDataMgmtAccess.noAccess,
		label: "No Access",
	},
]

export function findProjAccessType(accessType: number | string) {
	if (typeof accessType === "number") {
		return accessTypes.find((element) => element.oldId === accessType)
			?.newId
	} else if (typeof accessType === "string") {
		return accessTypes.find((element) => element.newId === accessType)
			?.oldId
	}
}

export function findProjAccessTypeId(accessType: number) {
	return accessTypes.find((element) => element.oldId === accessType)?.newId
}

export function findProjAccessTypeAsNumber(accessType: string) {
	return accessTypes.find((element) => element.newId === accessType)?.oldId
}

export function getProjAccessLabel(accessType: number | string) {
	let target

	if (typeof accessType === "number") {
		target = accessTypes.find((element) => element.oldId === accessType)
	} else if (typeof accessType === "string") {
		target = accessTypes.find((element) => element.newId === accessType)
	}

	return target ? target.label : "No matched project access type"
}

export function findProjDataMgmtAccessTypeAsNumber(
	projDataMgmtAccessType: string
) {
	return projDataMgmtAccessTypes.find(
		(element) => element.newId === projDataMgmtAccessType
	)?.oldId
}

export function getProjDataMgmtAccessTypeLabel(
	projDataMgmtAccessType: number | string
) {
	let target

	if (typeof projDataMgmtAccessType === "number") {
		target = projDataMgmtAccessTypes.find(
			(element) => element.oldId === projDataMgmtAccessType
		)
	} else if (typeof projDataMgmtAccessType === "string") {
		target = projDataMgmtAccessTypes.find(
			(element) => element.newId === projDataMgmtAccessType
		)
	}

	return target ? target.label : "No matched project access type"
}
