import React, { useState } from "react"
import CodeMirror from "@uiw/react-codemirror"
import { html } from "@codemirror/lang-html"
import { liquidMixedMode } from "../utils/liquidMixedMode"
import { lineNumbers } from "@codemirror/view"
import { linter, lintGutter } from "@codemirror/lint"
import { Liquid } from "liquidjs"

const EmailTemplateEditor = () => {
	const [code, setCode] = useState(`<!DOCTYPE html>
    <html lang="en">
            {% if payment_terms.type == 'fulfillment' and payment_terms.next_payment.due_at == nil %}
            {% assign due_on_fulfillment_terms = true %}
            {% else %}
            {% assign due_on_fulfillment_terms = false %}
            {% endif %}
            <head>
            <title>{{ email_title }}</title>
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
            <!-- More template content -->
            </head>
            <body>
            <h1>Invoice for Order #1234</h1>
            <p>Dear {{ customer_name }},</p>
            <p>Here is the invoice for your recent order:</p>
            <table>
                    <thead>
                    <tr>
                            <th>Product</th>
                            <th>Quantity</th>
                            <th>Price</th>
                    </tr>
                    </thead>
                    <tbody>
                    {% for item in order_items %}
                    <tr>
                            <td>{{ item.product_name }}</td>
                            <td>{{ item.quantity }}</td>
                            <td>{{ item.price }}</td>
                    </tr>
                    {% endfor %}
                    </tbody>
            </table>
            </body>
    </html>`)

	const onChange = React.useCallback((value: any) => {
		setCode(value)
	}, [])

	const insertTemplate = (template: any) => {
		// Logic to insert template at cursor position
		// This would need to use CodeMirror's API
	}

	const saveTemplate = () => {
		// API call to save the template
		fetch("/api/email-templates/save", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ template: code }),
		})
			.then((response) => response.json())
			.then((data) => {
				// Handle success
			})
			.catch((error) => {
				// Handle error
			})
	}

	const previewTemplate = async () => {
		const engine = new Liquid()

		// Sample data for replacing Liquid variables
		const templateData = {
			payment_terms: {
				type: "fulfillment",
				next_payment: { due_at: null },
			},
			email_title: "Invoice for Order #1234",
		}

		// Open new window
		const previewWindow = window.open("", "_blank")

		if (!previewWindow) {
			alert("Popup blocked! Please allow popups for this site.")
			return
		}

		console.log("New tab opened successfully.")

		try {
			// Process the template
			const processedHtml = await engine.parseAndRender(
				code,
				templateData
			)

			console.log("Processed HTML:", processedHtml)

			// Ensure document writing works
			previewWindow.document.open()
			previewWindow.document.write(processedHtml)
			previewWindow.document.close()

			console.log("Preview rendered successfully.")
		} catch (error) {
			console.error("Error rendering template:", error)
			previewWindow.document.write("<p>Error rendering the preview.</p>")
			previewWindow.document.close()
		}
	}

	return (
		<div className="email-editor-container">
			<div className="editor-toolbar">
				<button
					onClick={() =>
						insertTemplate("{% if condition %}...{% endif %}")
					}
				>
					Insert If Condition
				</button>
				<button onClick={() => insertTemplate("{{ variable }}")}>
					Insert Variable
				</button>
				{/* More toolbar buttons */}
			</div>

			<h2>Edit Order invoice</h2>
			<div className="editor-section">
				<h3>Email subject</h3>
				<input
					type="text"
					className="subject-input"
					defaultValue="Invoice {{name}}"
				/>
			</div>
			<div className="editor-section">
				<h3>Email body (HTML)</h3>
				<CodeMirror
					value={code}
					height="400px"
					extensions={[
						liquidMixedMode(),
						lineNumbers(),
						lintGutter(),
					]}
					onChange={onChange}
					theme="dark"
				/>
			</div>
			<div className="editor-actions">
				<button className="secondary-button">Revert to default</button>
				<button className="primary-button" onClick={previewTemplate}>
					Preview
				</button>
			</div>
		</div>
	)
}

export default EmailTemplateEditor
