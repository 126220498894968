// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
import { convertProjectStatus } from "../../../../library/get-project-status"
import {
	idToString,
	idToNumber,
	IdType,
} from "../../../../library/converters/id-converter"

const RemoveOrgUser = (self: any) => ({
	precheckSuspend(userId: string) {
		const actionName = "precheckSuspend"
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3)
		// 3-1)
		const userNumberId = idToNumber(userId, IdType.user)
		const params = {
			Type: 2,
			UserId: userNumberId,
		}
		// 3-2)
		self.precheckSuspendOrgUser(params)
			.then((response: any) => {
				// if success & at least one project assigned
				if (response.status === 200 && response.data.Status === 2) {
					// const newArray: any[] = []
					// response.data.AssignedProjectsInfo.map((item: any) => {
					// 	const reOrganizedItem = {
					// 		periodType: item.PeriodName,
					// 		type: item.ProjectTypeName,
					// 		clientName: item.ClientName,
					// 		title: item.ProjectName,
					// 	}
					// 	newArray.push(reOrganizedItem)
					// })
					// self.setPrecheckResult(newArray)
					let newList: any[] = []
					response.data.AssignedProjectsInfo.map((project: any) => {
						let epUsers: any = []
						if (
							project.EpUsers !== "" &&
							project.EpUsers !== null
						) {
							project.EpUsers.split(",").map(
								(user: string, i: number) => {
									epUsers.push({
										id: "xxx",
										aliasId: "xxx",
										name: user,
									})
								}
							)
						}
						let qcUsers: any = []
						if (
							project.QCUsers !== "" &&
							project.QCUsers !== null
						) {
							project.QCUsers.split(",").map(
								(user: string, i: number) => {
									qcUsers.push({
										id: "xxx",
										aliasId: "xxx",
										name: user,
									})
								}
							)
						}
						//
						const createdBy = {
							id: idToString(
								project.ClientCreatedBy,
								IdType.user
							),
							aliasId: project.CreatedUser.split(" ")[1].slice(
								1,
								-1
							),
							name: project.CreatedUser.split(" ")[0],
						}
						let archivedBy = null
						if (project.ArchivedBy !== 0 && project.ArchivedBy) {
							archivedBy = {
								id: idToString(project.ArchivedBy, IdType.user),
								aliasId: "xxx",
								name: "xxx",
							}
						}
						let unarchivedBy = null
						if (
							project.UnArchivedBy !== 0 &&
							project.UnArchivedBy
						) {
							unarchivedBy = {
								id: idToString(
									project.UnArchivedBy,
									IdType.user
								),
								aliasId: "xxx",
								name: "xxx",
							}
						}
						let lastAccessBy = null
						if (
							project.LastAccessedBy !== 0 &&
							project.LastAccessedBy !== null
						) {
							lastAccessBy = {
								id: "xxx", // Not getting from backend
								aliasId: "xxx",
								name: project.LastAccessedBy,
							}
						}
						//
						const reOrganizedProj = {
							//
							//
							// * 1. location info: group, cabinet
							groupId: idToString(project.GroupID, IdType.group), // [GroupID], ex) 444
							groupName: project.GroupName || "(pending)", // [GroupName], ex) "Prepare and Review"
							cabinetId: idToString(
								project.CabinetID,
								IdType.cabinet
							),
							cabinetName: "",
							//
							//
							// * 2. client info
							clientId: idToString(
								project.ClientMasterId,
								IdType.client
							), // [ClientMasterId], ex) 104
							clientName: project.ClientName, // [ClientName], ex) "ABCDEFGH"
							clientAliasId: "xxx",
							//
							//
							// * 3. project basic info
							id: idToString(project.ClientID, IdType.project), // [ClientID], ex) 2507
							aliasId: project.LinkName, // project.ProjectBusinessId, // [ProjectBusinessId], ex) "" --> is null possible..?
							title: project.ProjectName, // [ProjectName], ex) "57yth"
							//
							engTypeId: idToString(
								project.EngagementId,
								IdType.engType
							), // [EngagementId], ex) 115
							engTypeName: project.EngagementType || "(pending)", // [EngagementType], ex) "CUULWRRKSC"
							raId: "xxx",
							raName:
								project.RiskAssessmentName === null
									? "(null)"
									: project.RiskAssessmentName,

							//
							// * 4. project status info
							statusId: convertProjectStatus(project.ProjectType)
								.id, // [ProjectType], ex) 1 --> normal, replica, archived, unarchived...
							statusName: convertProjectStatus(
								project.ProjectType
							).name,
							periodId: idToString(
								project.PeriodId,
								IdType.period
							), // [PeriodId], ex) 1
							periodName: project.PeriodName, // [PeriodName], ex) "Full year"
							archiveDatePolicy: "xxx",
							//
							//
							// Additional
							replicaStatus:
								project.ReplicaStatus === "Yes" ? true : false,
							archiveZipStatus:
								project.ArchiveZipStatus === "Yes"
									? true
									: false,
							unarchiveStatus:
								project.UnArchiveStatus === "Yes"
									? true
									: false,
							//
							version: project.Version, // [Version], ex) 0
							projectTypeName: project.ProjectTypeName, // [ProjectTypeName], ex) Replica v1
							isArchived: project.Archive, // [Archive], ex) false
							isLocked: project.IsLocked, // [IsLocked], ex) false
							//
							//
							// * 5. access info
							roleId: "",
							roleName: project.RoleName ? project.RoleName : "-",
							accessible: !project.isGrayout,
							//
							//
							// * 6. schedule info
							financialYear: project.Year, // [Year], ex) 2019
							periodEndDate: project.FinancialYear, // [FinancialYear], ex) "10/23/2019"
							//
							expectedReportDate: "",
							finalReportDate: project.ReportIssuranceDate,
							//
							// archPolicyPeriodId: types.string, // system ID
							// archPolicyPeriodName: types.string,
							// archPolicyPeriodValue: types.integer,
							//
							expectedArchiveDate: "",
							finalArchiveDeadlineDate: "",
							//
							//
							// * 7. history info
							createdBy,
							createdAt: project.CreatedDate,
							//
							lastAccessBy,
							lastAccessAt: project.LastAccessedDate
								? project.LastAccessedDate
								: "",
							//
							archivedBy,
							archivedAt: project.ArchivedDate
								? project.ArchivedDate
								: "", // [ArchivedDate], ex) null
							//
							unarchivedBy,
							unarchivedAt: project.UnarchivedDate
								? project.UnarchivedDate
								: "", // [UnarchivedDate], ex) null
							//
							//
							// * 8. assigned users
							epUsers, // [EpUsers], ex) ""
							qcUsers, // [QCUsers], ex) ""
							//
							//
							// * 9. extras
							//
							//
							// *
							hasExportPermission: project.HasExportAccess,
						}
						// self.pushItemToList(reOrganizedProj)
						newList.push(reOrganizedProj)
					})

					self.setAssignedProjects(newList)
					// update success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage:
							"Success to precheck the organization user",
						open: false,
					})
				}
				// if success & no project assigned
				else if (
					response.status === 200 &&
					response.data.Status === 1
				) {
					self.setAssignedProjects([])
					// update success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage:
							"Success to precheck the organization user",
						open: false,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 200,
						color: MessageColorType.orange,
						customMessage: "Failed",
						open: false,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
	suspendUser(userId: string, deactivateInProjects: boolean) {
		const actionName = "suspendUser"
		// 1)
		// 2)
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3-1)
		const userNumberId = idToNumber(userId, IdType.user)
		const payload = {
			UserId: userNumberId,
			Type: 2, // type 2 means suspend
			// DeletedBy: localStorage.getItem("orgUserId")
			deactivateInProjects,
		}
		// 3-2)
		self.suspendOrgUser(payload)
			.then((response: any) => {
				if (response) {
					// if success
					// update success case response
					if (response.status === 200 && response.data.Status === 1) {
						self.updateUserStatus("suspended", userId)
						self.setSuspendDialogOpen(false)
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200,
							color: MessageColorType.blue,
							customMessage:
								"Success to suspend the organization user",
							open: true,
							autoHide: true,
						})
					} else {
						self.handleResponse({
							actionName,
							status: ActionStatus.fail,
							code: 200,
							color: MessageColorType.orange,
							customMessage: "Failed to suspend user",
							open: true,
						})
					}
					//
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},

	removeUser(userId: string) {
		const actionName = "removeUser"
		// 1)
		// 2)
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3-1)
		const userNumberId = idToNumber(userId, IdType.user)
		const payload = {
			UserId: userNumberId,
			Type: 3, // types 3 means remove
		}
		// 3-2)
		self.deleteOrgUser(payload)
			.then((response: any) => {
				if (response) {
					// if success
					// update success case response
					if (response.status === 200 && response.data.Status === 1) {
						self.spliceUser(userId)
						self.resetSelectedUser()
						self.resetSelectedUserId()
						self.setRemoveDialogOpen(false)
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200,
							color: MessageColorType.blue,
							customMessage:
								"Success to remove the organization user",
							open: true,
							autoHide: true,
						})
					} else {
						self.handleResponse({
							actionName,
							status: ActionStatus.fail,
							code: 200,
							color: MessageColorType.orange,
							customMessage: "Failed",
							open: true,
						})
					}
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default RemoveOrgUser
