import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"
import {
	idToNumber,
	IdType,
} from "../../../../../library/converters/id-converter"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - loading, success, fail
 *
 */

const UnassignProjUser = (self: any) => ({
	unAssignProjUser(
		targetUserId: string,
		actBy: string,
		projId: string,
		postSuccess?: any
	) {
		// 0.
		const actionName = "unAssignProjUser"
		// 1.
		// 2.
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 0,
			color: MessageColorType.green,
			open: true,
		})
		// 3
		let payload = {
			UserId: idToNumber(targetUserId, IdType.user),
			ClientID: idToNumber(projId, IdType.project),
			// FinancialYear: year,
			UnassignedBy: idToNumber(actBy, IdType.user),
		}

		self.requestUnassignProjUser(payload)
			.then((response: any) => {
				// console.log(actionName+ "__response " + response)
				if (response.status === 200 && response.data.Status === 1) {
					// remove user from the stored list
					self.reflectUnassign(self.selectedUser)
					self.setSelectedUser("")
					// If user self unassign we need new projToken
					const orgUserId = localStorage.getItem("orgUserId")
					console.log(orgUserId === targetUserId)
					if (orgUserId === targetUserId) {
						localStorage.removeItem("projTokens")

						// Do not set return here
						// Do not replace the window.location to project/checkout
						// It will checkout users to the organization and take long time
					}
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
					})

					postSuccess && postSuccess()
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
						message: response.data.Message,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default UnassignProjUser
