import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import {
	DLDialog,
	InputWithLabel,
	DLInputField,
	DLSystemMsg,
	DLButton,
	DLTextArea,
} from "../../../../components/basic-elements"
import sharedRegEx from "../../../../library/sharedRegEx"
import {
	DLI18nProps,
	DnTFormatProps,
} from "../../../../common-models/types/common-props"
import { MessageColorType } from "../../../../common-models/enumerations/common-enums"
import { ProjectInfoContainer } from "../../my-page/remind-archiving/ForceArchiveDialog"

export default observer(function ReleaseForceArchiveDialog({
	i18n,
	isOpen,
	setIsOpen,
	partialStore,
	dnt,
}: {
	i18n: DLI18nProps
	isOpen: boolean
	setIsOpen: any
	partialStore: any
	dnt: DnTFormatProps
}) {
	const userEmail = localStorage.getItem("lobbyUserId")
	const initialValues = {
		value: "",
		isValid: true,
	}
	const [email, setEmail] = useState(initialValues)
	const [reason, setReason] = useState("")

	const handleEmailInput = (event: any) => {
		if (!sharedRegEx.email.test(event.target.value)) {
			setEmail({ value: event.target.value, isValid: false })
		} else {
			setEmail({ value: event.target.value, isValid: true })
		}
	}

	const isReady = email.value !== "" && email.isValid && reason !== ""

	const releaseForceArchive = () => {
		partialStore.releaseForceArchive({
			projectId: partialStore.selectedProj,
			reason,
		})
	}

	const projectInfo = partialStore.viewFASelectedProjInfo(
		partialStore.selectedProj,
		dnt
	)

	return (
		<DLDialog
			eleTestId="release-force-archive-project-dialog"
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			showCloseBtn={true}
			dialogTitle={"Release Force-Archived"}
			dialogContents={
				<StyledDialog>
					<div className="alert">
						Are you sure you want to Release the Force-Archived for
						this project?
					</div>
					<ProjectInfoContainer
						projectDetails={projectInfo}
						i18n={i18n}
					/>
					<div className="input-section FR">
						<InputWithLabel
							required
							label={i18n.twEmail || "Email"}
						>
							<DLInputField
								autoFocus
								eleTestId="email_input"
								eleFullWidth
								eleName="email"
								eleValue={email.value}
								eleHandleChange={handleEmailInput}
								eleRequired
								warningMsg={
									!email.isValid
										? `Email not valid`
										: undefined
								}
							/>
							{email.value !== "" &&
								userEmail?.toLowerCase() !==
									email.value.toLowerCase() && (
									<DLSystemMsg
										eleTestId="msg-warning-email-match"
										type={MessageColorType.red}
										msg={
											i18n.twEmailDoesNotMatch ||
											"Email does not match"
										}
									/>
								)}
						</InputWithLabel>
					</div>
					<div className="input-section FR">
						<InputWithLabel required label="Reason">
							<DLTextArea
								eleTestId="reason_input_area"
								eleFullWidth
								eleName="reason"
								eleValue={reason}
								eleHandleChange={(e: any) =>
									setReason(e.target.value)
								}
								eleRequired
								// warningMsg={
								// 	reason === "" ? "Required" : undefined
								// }
								// requestInput={inputs.reason.requestInput}
								eleFieldHeight="5"
							/>
						</InputWithLabel>
					</div>
				</StyledDialog>
			}
			// actionReady={isReady || userEmail === inputs.email.value}
			maxWidth="sm"
			actionBtn={
				<DLButton
					variant="text"
					eleTestId="force-archive-btn-on-dialog"
					// startIcon={<Icon path={mdiTrashCan} size={0.8} />}
					clickHandler={releaseForceArchive}
					color="primary"
					disabled={!(isReady && userEmail === email.value)}
				>
					Release
				</DLButton>
			}
			cancelBtnText={i18n.twCancel || "Cancel"}
		/>
	)
})

const StyledDialog = styled.div`
	.alert {
		padding: 1rem 0;
	}
	.input-section {
		margin-bottom: 1.5rem;
		.label {
			min-width: 3rem;
		}
		.input-area {
			/* width: calc(100% - 9rem); */
			min-width: 20rem;
		}
	}
`
