export const dummyData = [
	{
		fsGroupnumber: "1.1.1.10",
		title: "Group title",
		behaviour: "Normal",
		name: "Assets",
		type: "Balance Sheet ",
		normalSign: "Credit",
		class: "TR",
		calculation: "calculation",
		flipType: "Individual",
		lsGroupNoFlip: "lsGroupNoFlip 1",
		formatting: "Net Income (N)",
	},
	{
		fsGroupnumber: "1.1.1.20",
		title: "Group title2",
		behaviour: "Calculated",
		name: "Other Expense",
		type: "Income Statement",
		normalSign: "Debit",
		class: "QA",
		calculation: "calculation2",
		flipType: "Display if debit",
		lsGroupNoFlip: "lsGroupNoFlip 2",
		formatting: "Section (S)",
	},
]
