import { types } from "mobx-state-tree"
// common models
import { CommonModelActions } from "../../../common-models/common-model-actions"
// separated actions
import {
	ReadOrgProjects,
	// CreateProject,
	ReadUsersByGroup,
	ReadClientsByGroup,
} from "./model-actions"
import { OrgProjectApiProps } from "./orgProjects.apis"
import ReadProjPeriodTypes from "../../../screens/organization-side/org-setup/org-setup-period-types/read-proj-period-types"
import ReadArchPolicyPeriodList from "../../../screens/organization-side/org-setup/org-setup-arch-policy-periods/read-arch-policy-period-list"
import ReadArchPolicyPeriodListByEngTypeId from "../../../screens/organization-side/org-setup/org-setup-arch-policy-periods/read-arch-policy-period-list-by-eng-type-id"
import {
	CreateProject,
	ReadRfProjectsByGroup,
	DeleteProject,
	CreateOtherProject,
} from "../../../service-modules/project-module/model-actions"
import ReadProjUsersAndFileCount from "./model-actions/read-project-users-and-file-count"
import ReadProjectDetails from "../../../service-modules/project-module/model-actions/read-project-details"
import ReadProjInfo from "../../../screens/project-side/project-information/store/model-actions/read-proj-info"
import UpdateProjInfo from "../../../screens/project-side/project-information/store/model-actions/update-proj-info"
import {
	ReadActiveEngTypeList,
	ReadEngTypeList,
} from "../../../screens/organization-side/org-setup/org-setup-eng-types/store/model-actions"
import {
	ReadActiveRiskAssessmentList,
	ReadRiskAssessmentList,
} from "../../../screens/organization-side/org-setup/org-setup-risk-assessment/store/model-actions"
import ReadCabinetLocation from "../../../screens/project-side/project-information/store/model-actions/read-cabinet-location"
import MForceArchive from "./model-actions/m-force-archive"
import ReadPreConditionStatus from "../../../screens/organization-side/archive-management/am-archived-projects/store/model-actions/read-pre-condition-status"
import RequestRegenPreProcessPdfs from "../../../screens/organization-side/archive-management/am-archived-projects/store/model-actions/create-pre-process-pdfs"

const OrgProjectsModel = ({
	apiRead,
	apiCreateProject,
	apiCreateHardCopyOnlyProject,
	apiReadArchPolicyPeriodList,
	apiReadArchPolicyPeriodListByEngTypeId,
	apiReadRfProjects,
	apiReadUsers,
	apiReadClients,
	apiDeleteProject,
	apiReadPeriodNames,
	apiReadProjectUsersAndFileCount,
	apiReadProjectDetails,
	apiReadProjInfo,
	apiUpdateProjInfo,
	apiReadActiveEngType,
	apiReadActiveRiskAssessment,
	apiCheckPreConditionStatus,
	apiReadCabinetLocation,
	apiForceArchive,
}: OrgProjectApiProps) =>
	types
		.model({})
		// get project list
		.actions((self) => ReadOrgProjects(self, apiRead))
		// create project related
		.actions((self, createProject = apiCreateProject) =>
			CreateProject(self, createProject)
		)
		.actions((self, createOtherProject = apiCreateHardCopyOnlyProject) =>
			CreateOtherProject(self, createOtherProject)
		)
		.actions((self) => DeleteProject(self, apiDeleteProject))
		.actions((self) =>
			ReadArchPolicyPeriodList(self, apiReadArchPolicyPeriodList)
		)
		.actions((self) =>
			ReadArchPolicyPeriodListByEngTypeId(
				self,
				apiReadArchPolicyPeriodListByEngTypeId
			)
		)
		.actions((self) => ReadRfProjectsByGroup(self, apiReadRfProjects))
		.actions((self) => ReadUsersByGroup(self, apiReadUsers))
		.actions((self) => ReadClientsByGroup(self, apiReadClients))
		//
		.actions((self) =>
			ReadProjUsersAndFileCount(self, apiReadProjectUsersAndFileCount)
		)
		//
		.actions((self, readPeriodNames = apiReadPeriodNames) =>
			ReadProjPeriodTypes(self, readPeriodNames)
		)
		.actions((self) => ReadProjectDetails(self, apiReadProjectDetails))
		.actions((self) => ReadProjInfo(self, apiReadProjInfo))
		.actions((self) => UpdateProjInfo(self, apiUpdateProjInfo))
		.actions((self) => ReadActiveEngTypeList(self, apiReadActiveEngType))
		.actions((self) =>
			ReadActiveRiskAssessmentList(self, apiReadActiveRiskAssessment)
		)
		.actions((self, preConditionStatus = apiCheckPreConditionStatus) =>
			ReadPreConditionStatus(self, preConditionStatus)
		)
		.actions(RequestRegenPreProcessPdfs)
		.actions((self) => ReadCabinetLocation(self, apiReadCabinetLocation))
		.actions((self) => MForceArchive(self, apiForceArchive))
		// Common model actions
		.actions(CommonModelActions)

export default OrgProjectsModel
