import React, { useEffect } from "react"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import ProjNoPermissionOnMenu from "../../proj-access-control/ProjNoPermissionOnMenu"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { observer } from "mobx-react-lite"
import FsListOfTransactionMetadata from "./FsListOfTransactionMetadata"

export default observer(function FsListOfTransactionMetadataController() {
	const projStore = useProjStore()
	const menuId = DLProjSubMenus.list_of_transaction_metadata
	const isLocked = projStore.projInfo.projInfo.isLocked
	const isArchived = projStore.projInfo.projInfo.isArchived
	const menuAccess = projStore.checkin.checkAccess(menuId)
	const permission = projStore.checkin.getActionsAsObject(
		menuId,
		isLocked,
		isArchived
	)

	useEffect(() => {
		projStore.fsListOfTransactionMetadata.getFsListOfTransactionMetadata()
	}, [])

	return (
		<>
			{menuAccess ? (
				<FsListOfTransactionMetadata
					partialStore={projStore.fsListOfTransactionMetadata}
					permission={permission}
					actionStatus={projStore.fsListOfTransactionMetadata.getActionStatus(
						"getFsListOfTransactionMetadata"
					)}
				/>
			) : (
				<ProjNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})
