import { types } from "mobx-state-tree"
import FsWorkingTrialBalanceViewModel from "./fs-working-trial-balance.view-model"
import FsWorkingTrialBalanceModel from "./fs-working-trial-balance.model"

export const FsWorkingTrialBalanceStore = () =>
	types
		.compose(FsWorkingTrialBalanceModel(), FsWorkingTrialBalanceViewModel)
		.named("FsWorkingTrialBalanceStore")

export const initialStore = {
	clickPoint: {
		mouseX: null,
		mouseY: null,
	},
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const FsWorkingTrialBalanceStoreInstance =
	FsWorkingTrialBalanceStore().create(initialStore)

export default FsWorkingTrialBalanceStoreInstance
