import axios, { AxiosResponse } from "axios"
import { TOKEN_TYPE } from "../../../../../library/api-requests/shared-for-api-requests"
import { getRequest, postRequest } from "../../../../../library/api-requests"

export function readProjDownloadCenterList(params: any) {
	const response = getRequest({
		url: "/GetProjectDataMgmtZipFileListByProjectId",
		params,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export function readProjectDataMgmtZipFileById(
	projectDataMgmtZipFileId: number
) {
	const response = postRequest({
		url: "/RequestProjectDataMgmtZipFileById",
		params: { projectDataMgmtZipFileId },
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export function deleteProjectDataMgmtZipFileById(
	projectDataMgmtZipFileId: number
) {
	const response = postRequest({
		url: "/DeleteProjectDataMgmtZipFileById",
		params: { projectDataMgmtZipFileId },
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export type ProjDownloadCenterListApiProps = {
	apiReadProjDownloadCenterList: (params: any) => Promise<AxiosResponse<any>>
	apiRequestProjDataMgmtZipFileById: (
		zipFileId: number
	) => Promise<AxiosResponse<any>>
	apiDeleteProjDataMgmtZipFileById: (
		zipFileId: number
	) => Promise<AxiosResponse<any>>
}
