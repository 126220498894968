import React, { useEffect, useState } from "react"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import useForm from "../../../../../library/use-form"
import sharedRegEx from "../../../../../library/sharedRegEx"
import {
	DLDialog,
	InputWithLabel,
	DLInputField,
	DLSystemMsg,
	DLTextField,
	DLRadioGroup,
} from "../../../../../components/basic-elements"

import { MessageColorType } from "../../../../../common-models/enumerations/common-enums"
import Icon from "@mdi/react"
import { mdiPencil } from "@mdi/js"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import styled from "styled-components"

const EditFsGroupsCategoryDialog = observer(
	({ partialStore }: { partialStore: any }) => {
		// default setting
		const actionName = "editGroupCategoryItem"
		//
		const inputsSchema = {
			name: { value: "", error: "", requestInput: false },
			number: { value: "", error: "", requestInput: false },
			sortOrder: { value: "", error: "", requestInput: false },
		}

		const groupInfo = partialStore.getGroupById(partialStore.selectedItem)

		const validationSchema = {
			name: {
				isRequired: true,
				validator: {
					regEx: sharedRegEx.noSpecialCharacters,
					error: "Cannot use special characters",
				},
			},
			number: {
				isRequired: true,
				validator: {
					regEx: sharedRegEx.noSpecialCharacters,
					error: "Cannot use special characters",
				},
			},
		}

		const editGroupCategoryItem = () => {
			// const engagementId = orgStore.setupEngTypes.engagementId
			// const userInfo = orgStore.checkin.orgInfo
			// const modifiedByUserName = `[${userInfo.userAliasId}] ${userInfo.userName}`
			// partialStore.editGroupCategoryItem({
			// 	engagementId,
			// 	engTypeName: inputs.name.value,
			// 	sortOrder: parseInt(inputs.sortOrder.value),
			// 	modifiedByUserName,
			// })
		}

		const { inputs, handleOnChange, handleOnSubmit, isReady } = useForm(
			inputsSchema,
			validationSchema,
			editGroupCategoryItem
		)

		useEffect(() => {
			inputs.name.value = groupInfo ? groupInfo.name : ""
			inputs.sortOrder.value = groupInfo ? groupInfo.sortOrder : ""
			inputs.number.value = groupInfo ? groupInfo.number : ""
		}, [groupInfo])

		return (
			<DLDialog
				eleTestId="edit-group-category-dialog"
				isOpen={partialStore.editDialogOpen}
				setIsOpen={partialStore.setEditDialogOpen}
				showCloseBtn={true}
				showOpenBtn={false}
				dialogTitle={
					<div className="FR AC">
						<Icon
							path={mdiPencil}
							size={1}
							color={MessageColorType.default}
							style={{ marginRight: 8 }}
						/>
						Edit Group Category Item
					</div>
				}
				dialogContents={
					<EditFsGroupsCategoryDialogForm
						inputs={inputs}
						handleOnChange={handleOnChange}
						selectedRowDetails={groupInfo}
						partialStore={partialStore}
					/>
				}
				actionReady={
					isReady &&
					(groupInfo?.name !== inputs.name.value ||
						groupInfo?.sortOrder !==
							parseInt(inputs.sortOrder.value))
				}
				handleAction={handleOnSubmit}
				actionBtn={"Update"}
				cancelBtnText={"Cancel"}
				showSpinner={
					partialStore.getActionStatus(actionName) === "LOADING"
				}
				cannotUpdate={
					partialStore.getActionStatus(actionName) === "LOADING"
				}
				useEnterKeyForSubmit
			/>
		)
	}
)

const EditFsGroupsCategoryDialogForm = ({
	inputs,
	handleOnChange,
	selectedRowDetails,
	partialStore,
}: {
	inputs: any
	handleOnChange: any
	selectedRowDetails: any
	partialStore: any
}) => {
	return (
		<StyledForm>
			<div className="input-container">
				<DLInputField
					eleType="number"
					eleTestId="sort-order"
					eleClassName="sort-order"
					eleName="sortOrder"
					eleRequired
					eleValue={inputs.sortOrder.value}
					eleLabel="Sorting Order"
					eleHandleChange={(e: any) => {
						if (e.target.value > 0) {
							handleOnChange(e)
						}
					}}
				/>
				{selectedRowDetails.sortOrder !==
					parseInt(inputs.sortOrder.value) &&
					partialStore.isDuplicatedSortOrder(
						parseInt(inputs.sortOrder.value)
					) && (
						<DLSystemMsg
							type={MessageColorType.red}
							msg="The same Sort Order already exist"
						/>
					)}
			</div>
			<div className="input-container">
				<DLInputField
					eleTestId="number-input"
					eleFullWidth
					eleName="number"
					eleValue={inputs.number.value}
					eleHandleChange={handleOnChange}
					eleRequired
					eleLabel={"Number"}
					warningMsg={inputs.number.error}
					requestInput={inputs.number.requestInput}
				/>
				{selectedRowDetails.number !== inputs.number.value &&
					partialStore.isDuplicatedNumber(inputs.number.value) && (
						<DLSystemMsg
							type={MessageColorType.red}
							msg={"The same number already exist"}
						/>
					)}
			</div>
			<div className="input-container">
				<DLInputField
					eleTestId="name-input"
					eleFullWidth
					eleName="name"
					eleValue={inputs.name.value}
					eleHandleChange={handleOnChange}
					eleRequired
					eleLabel={"Name"}
					warningMsg={inputs.name.error}
					requestInput={inputs.name.requestInput}
				/>
				{selectedRowDetails.name !== inputs.name.value &&
					partialStore.isDuplicatedName(inputs.name.value) && (
						<DLSystemMsg
							type={MessageColorType.red}
							msg={"The same name already exist"}
						/>
					)}
			</div>
			<div className="input-container">
				<div className="label MuiFormLabel-root Mui-disabled">
					Status
				</div>
				<DLRadioGroup
					groupName="groupItemStatus"
					disabled
					itemDirection="row"
					items={[
						{
							value: "Active",
							label: "Active",
						},
						{
							value: "Inactive",
							label: "Inactive",
						},
					]}
					selectedValue={
						selectedRowDetails?.isActive ? "Active" : "Inactive"
					}
					eleClassName="itemStatus"
				/>
			</div>
		</StyledForm>
	)
}

const StyledForm = styled.div`
	.input-container {
		margin-bottom: 1rem;
	}
	.label {
		font-size: 0.7rem;
	}
	.itemStatus {
		margin-left: 8px;
		margin-top: 5px;
	}
`

export default EditFsGroupsCategoryDialog
