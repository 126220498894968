import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import {
	DLButton,
	DLDialog,
	DLRadio,
	DLSpinnerCenterAligned,
} from "../../../../../components/basic-elements"
import ReactTableV8 from "../../../../../components/basic-elements/tables/DLReactTable"
import { Table, Column } from "@tanstack/react-table"
import { InputFieldForTableV8 } from "../../../../../components/basic-elements/tables/InputFieldForTable"

const LinkMapNoToAccountDialog = observer(
	({ partialStore }: { partialStore: any }) => {
		// default setting
		const actionName = "linkMapNoToAccount"

		useEffect(() => {
			partialStore.getMapNumbers()
		}, [])

		return (
			<DLDialog
				eleTestId="link-mapNo-to-account-dialog"
				isOpen={partialStore.linkMapNoToAccountDialogOpen}
				setIsOpen={partialStore.setLinkMapNoToAccountDialogOpen}
				showOpenBtn={false}
				dialogTitle={"Link Map No to the Selected Account"}
				draggable
				dialogContents={
					<>
						{partialStore.getActionStatus("getMapNumbers") ===
						"LOADING" ? (
							<DLSpinnerCenterAligned
								absolute
								backgroundOpacity={0.5}
							/>
						) : (
							<LinkMapNoToAccountDialogContent
								partialStore={partialStore}
							/>
						)}
					</>
				}
				showCloseBtn={false}
				maxWidth="md"
				fullWidth={true}
				showSpinner={
					partialStore.getActionStatus(actionName) === "LOADING"
				}
				cannotUpdate={
					partialStore.getActionStatus(actionName) === "LOADING"
				}
			/>
		)
	}
)

export default LinkMapNoToAccountDialog

const LinkMapNoToAccountDialogContent = ({
	partialStore,
}: {
	partialStore: any
}) => {
	const [selectedMapNo, setSelectedMapNo] = useState("")
	const selectedAccount = partialStore.getAccountById(
		partialStore.selectedItem
	)

	const handleCheckbox = (id: string) => {
		setSelectedMapNo(id)
	}
	const handleSelect = () => {
		const { mapNoDesc } = partialStore
			.viewMapNumbers()
			.find((i: any) => i.mapNo === selectedMapNo)
		console.log({
			id: partialStore.selectedItem,
			mapNo: selectedMapNo,
			mapNoDesc,
		})
		partialStore.linkMapNoToAccount({
			id: partialStore.selectedItem,
			mapNo: selectedMapNo,
			mapNoDesc,
		})
	}

	console.log(partialStore.viewMapNumbers())

	return (
		<StyledContent>
			<div className="FR AC" style={{ gap: 10 }}>
				<DLButton
					eleTestId="select"
					color="primary"
					clickHandler={handleSelect}
					disabled={!selectedMapNo}
				>
					Select
				</DLButton>
				<DLButton
					eleTestId="new-btn"
					color="primary"
					clickHandler={() => {}}
				>
					New
				</DLButton>
				<DLButton
					eleTestId="properties-btn"
					color="primary"
					clickHandler={() => {}}
				>
					Properties
				</DLButton>
				<DLButton
					eleTestId="cancel-btn"
					color="primary"
					clickHandler={() => {}}
				>
					Cancel
				</DLButton>
			</div>
			<div className="FR mt-20">
				<div style={{ flex: 1 }}>
					<b>Account No</b>: {selectedAccount.accountNo}
				</div>
				<div style={{ flex: 1 }}>
					<b>Account Name</b>: {selectedAccount.name}
				</div>
			</div>
			<div className="mt-20">
				<ReactTableV8
					tableColumns={columns(selectedMapNo, handleCheckbox)}
					data={partialStore.viewMapNumbers()}
					hasPagination={false}
					height={500}
					customFilters={filterComponent}
					showNewReorderDialog={false}
					menuId={`MapNoToAccount`}
					showPageSetting={false}
				/>
			</div>
		</StyledContent>
	)
}

const StyledContent = styled.div`
	table {
		border: 1px solid rgba(224, 224, 224, 1);
	}
	.mt-20 {
		margin-top: 20px;
	}
`

const filterComponent = ({
	column,
	table,
}: {
	column: Column<any>
	table: Table<any>
}) => {
	switch (column.id) {
		case "select":
			return

		default:
			return (
				<InputFieldForTableV8
					columnId={column.id}
					onChange={column.setFilterValue}
					value={column.getFilterValue()}
				/>
			)
	}
}

const columns = (
	selectedItem: string,
	handleCheckbox: (id: string) => void
) => {
	return [
		{
			header: "Select",
			// accessorKey: "select",
			size: 30,
			cell: (props: any) => {
				const id = props.row.original.mapNo
				return (
					<div
						style={{
							width: "100%",
							textAlign: "center",
							padding: 8,
						}}
					>
						<DLRadio
							eleTestId={id + "-radio"}
							checked={id === selectedItem}
							onChange={() => handleCheckbox(id)}
							color="primary"
						/>
					</div>
				)
			},
		},
		{
			header: "Map No",
			accessorKey: "mapNo",
		},
		{
			header: "Name",
			accessorKey: "mapNoDesc",
		},
	]
}
