import { flow } from "mobx-state-tree"
import {
	idToNumber,
	IdType,
} from "../../../../../library/converters/id-converter"
import { DLSector } from "../../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../../components/basic-elements"
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { TOKEN_TYPE } from "../../../../../library/api-requests/shared-for-api-requests"
import { getRequest } from "../../../../../library/api-requests"
import { DLOrgSubMenus } from "../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"

const ReadFileStatus = (self: any) => ({
	readFileStatus: flow(function* (
		srcStringId: string,
		sector: DLSector,
		menuId: string
	) {
		// console.log("readFileStatus params:", srcStringId, sector, menuId)
		const actionName = "readFileStatus"
		// 1.
		const userStringId = localStorage.getItem("orgUserId")
		if (srcStringId === "" || srcStringId === undefined) {
			alert(`(${actionName}) Undefined props exist`)
			return
		}
		//
		//
		// 2.
		let srcId: any = undefined
		let userId: any = undefined
		if (srcStringId !== null && userStringId !== null) {
			srcId = idToNumber(srcStringId, IdType.src)
			userId = idToNumber(userStringId, IdType.user)
		}
		//
		//
		// 3.

		if (typeof srcId === "number" && typeof userId === "number") {
			try {
				const endpoint =
					menuId === DLProjSubMenus.wp ||
					menuId === DLOrgSubMenus.proj_templates
						? "/GetFileStatus"
						: menuId === DLProjSubMenus.pf
						? "/GetPfFileStatus"
						: "NoEndpoint"
				if (endpoint === "NoEndpoint") {
					alert(
						"Do not use this API for this menu. Endpoint does not exist"
					)
					return
				}
				const params = {
					MediaID: srcId,
					UserID: userId,
				}
				const response = yield getRequest({
					url: endpoint,
					params,
					tokenType:
						sector === DLSector.org
							? TOKEN_TYPE.orgToken
							: TOKEN_TYPE.projToken,
				})
				ConsoleLog([actionName, "__response ", response])
				return response
			} catch (error) {
				ConsoleLog([actionName, "__error ", error])
				// self.handleModelError({
				// 	actionName,
				// 	error,
				// 	openSnackbar: true,
				// })

				return false
			}
		}
	}),
})

export default ReadFileStatus
