export const dummyEntityData = [
	{
		id: "folderId1",
		parentId: null,
		isParent: true,
		title: "Parent Entity",
		abbreviation: "PE",
		expanded: false,
	},
	{
		id: "folderId4",
		parentId: "folderId1",
		isParent: true,
		title: "Sub entity 6",
		abbreviation: "SE6",
		expanded: false,
	},
	{
		id: "folderId5",
		parentId: "folderId1",
		isParent: true,
		title: "Sub entity 5",
		abbreviation: "SE5",
		expanded: false,
	},
	{
		id: "folderId15",
		parentId: "folderId4",
		isParent: true,
		title: "Sub entity 4",
		abbreviation: "SE4",
		expanded: false,
	},
	{
		id: "folderId2",
		parentId: null,
		isParent: true,
		title: "entity 2",
		abbreviation: "E2",
		expanded: false,
	},
	{
		id: "folderId8",
		parentId: "folderId2",
		isParent: true,
		title: "Sub entity",
		abbreviation: "SE",
		expanded: false,
	},
	{
		id: "folderId9",
		parentId: "folderId2",
		isParent: true,
		title: "Sub entity 2",
		abbreviation: "SE2",
		expanded: false,
	},
	{
		id: "folderId20",
		parentId: "folderId2",
		isParent: true,
		title: "Sub entity 3",
		abbreviation: "SE3",
		expanded: false,
	},
]
