import React, { useEffect, useState } from "react"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import { observer } from "mobx-react-lite"
import DateAndTimeFormat from "./DateAndTimeFormat"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"

import { OrgSysSettingItemProps } from "../../../../../service-modules/sys-setting-module/sys-setting.data-model"
import { OrgI18n } from "../../../../../common-models/enumerations/translation-sheets"
import { ConsoleLog } from "../../../../../components/basic-elements"

export default observer(function DateAndTimeFormatController() {
	// default setting
	const store = useRootStore()
	const orgStore = useOrgStore()
	const actionName = "getDateAndTimeSetting"
	const i18nSheet = OrgI18n.sysSetting
	//
	const [selectedDateFormat, setSelectedDateFormat] =
		useState<OrgSysSettingItemProps>(store.global.dateFormat)
	const [selectedTimeFormat, setSelectedTimeFormat] =
		useState<OrgSysSettingItemProps>(store.global.timeFormat)
	const [selectedTimeZone, setSelectedTimeZone] =
		useState<OrgSysSettingItemProps>(store.global.timeZone)

	const handleDateFormat = (event: any) => {
		// ConsoleLog("event.target.value", event.target.value)
		const selected = orgStore.setupDateAndTimeSetting.findDateFormatItem(
			event.target.value
		)
		if (selected) {
			setSelectedDateFormat((selectedDateFormat: any) => ({
				...selectedDateFormat, // for settingId
				label: selected.label,
				value: selected.value,
				uiValue: selected.uiValue,
			}))
		} else {
			console.error("Matched item does not exist", event.target.value)
		}
	}
	const handleTimeFormat = (event: any) => {
		ConsoleLog(["handleTimeFormat", event.target.value])
		const selected = orgStore.setupDateAndTimeSetting.findTimeFormatItem(
			event.target.value
		)
		if (selected !== undefined && selected !== null) {
			setSelectedTimeFormat((selectedTimeFormat: any) => ({
				...selectedTimeFormat, // for settingId
				label: selected.label,
				value: selected.value,
				uiValue: selected.uiValue,
			}))
		} else {
			console.error("Matched item does not exist", event.target.value)
		}
	}
	const handleTimeZone = (newTz: OrgSysSettingItemProps) => {
		if (newTz !== null) {
			setSelectedTimeZone((selectedTimeZone: OrgSysSettingItemProps) => ({
				...selectedTimeZone, // for setting Id
				label: newTz.label,
				value: newTz.value,
				uiValue: newTz.uiValue,
			}))
		}
	}

	// const updateOrgCheckin = ({
	// 	dateFormat,
	// 	timeFormat,
	// 	timeZone,
	// }: {
	// 	dateFormat: OrgSysSettingItemProps
	// 	timeFormat: OrgSysSettingItemProps
	// 	timeZone: OrgSysSettingItemProps
	// }) => {
	// 	// orgStore.checkin.setDateFormat(dateFormat)
	// 	// orgStore.checkin.setTimeFormat(timeFormat)
	// 	// orgStore.checkin.setTimeZone(timeZone)
	// 	// below setting seems not required because postAction after the updateOrgCheckin do same thing
	// 	// store.global.setGlobalDnT({
	// 	// 	dateFormat,
	// 	// 	timeFormat,
	// 	// 	timeZone
	// 	// })
	// }

	const handleSubmit = () => {
		const userInfo = orgStore.checkin.orgInfo
		const modifiedByInfo = {
			userName: userInfo.userName,
			aliasId: userInfo.userAliasId,
			modifiedDate: new Date().toISOString(),
		}
		orgStore.setupDateAndTimeSetting.editDntSetting(
			{
				dateFormat: selectedDateFormat,
				timeFormat: selectedTimeFormat,
				timeZone: selectedTimeZone,
				// postAction: updateOrgCheckin,
				setGlobalDnT: store.global.setGlobalDnT,
			},
			modifiedByInfo
		)
	}

	const handleCancel = () => {
		setSelectedDateFormat(store.global.dateFormat)
		setSelectedTimeFormat(store.global.timeFormat)
		setSelectedTimeZone(store.global.timeZone)
	}

	useEffect(() => {
		orgStore.setupDateAndTimeSetting.getDntSetting(
			store.global.setGlobalDnT
		)
	}, [])

	const dateFormat = store.global.dateFormat.value
	const i18n = store.i18n.combineI18n(i18nSheet)

	return (
		<DateAndTimeFormat
			partialStore={orgStore.setupDateAndTimeSetting}
			selectedDateFormat={selectedDateFormat}
			selectedTimeFormat={selectedTimeFormat}
			selectedTimeZone={selectedTimeZone}
			actionStatus={orgStore.setupDateAndTimeSetting.getActionStatus(
				actionName
			)}
			handleDateFormat={handleDateFormat}
			handleTimeFormat={handleTimeFormat}
			handleTimeZone={handleTimeZone}
			handleSubmit={handleSubmit}
			handleCancel={handleCancel}
			dateFormat={dateFormat}
			i18n={i18n}
		/>
	)
})
