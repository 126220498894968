import { types } from "mobx-state-tree"
import FsListOfCounterpartiesViewModel from "./fs-list-of-counterparties.view-model"
import FsListOfCounterpartiesModel from "./fs-list-of-counterparties.model"

export const FsListOfCounterpartiesStore = () =>
	types
		.compose(FsListOfCounterpartiesModel(), FsListOfCounterpartiesViewModel)
		.named("FsListOfCounterpartiesStore")

export const initialStore = {
	clickPoint: {
		mouseX: null,
		mouseY: null,
	},
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const FsListOfCounterpartiesStoreInstance =
	FsListOfCounterpartiesStore().create(initialStore)

export default FsListOfCounterpartiesStoreInstance
