import React from "react"
import { PageContainer } from "../../../../components/app-frame-elements"
import { ActionStatus } from "@datalobby/types"
import styled from "styled-components"
import Icon from "@mdi/react"
import {
	mdiFolder,
	mdiFolderPlus,
	mdiMenuDown,
	mdiMinus,
	mdiPencil,
	mdiPlus,
} from "@mdi/js"
import {
	DLContextMenuWithButton,
	DLIconButton,
} from "../../../../components/basic-elements"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import AddFSGroupCategoryDialog from "./sub-components/AddFSGroupCategoryDialog"
import { observer } from "mobx-react-lite"
import StyledTreeForFiles from "../../../../components/combined-elements/file-tree/StyledTreeForFileMenus"
import SortableTree from "react-sortable-tree"

export default observer(function FinancialGroupPresentation({
	partialStore,
	actionStatus,
	permission,
}: {
	partialStore: any
	actionStatus: ActionStatus
	permission: PermissionAsObjectProps
}) {
	const handleClick = (e: any, id: string) => {
		partialStore.setSelectedItem(id)
		partialStore.toggleExpanded(id)
	}

	const selectedItem = partialStore.selectedItem

	return (
		<PageContainer
			hasToolbar={false}
			pageTools={<div />}
			fullWidth
			isLoading={actionStatus === ActionStatus.loading}
		>
			<StyledFinancialGroup>
				<div className="FR" style={{ marginTop: 10 }}>
					<DLContextMenuWithButton
						eleTestId="folders-btn"
						menuOptions={[
							{
								label: `Create FS Group Category`,
								value: "ctx-create-fs-group-category",
								available: permission.delete,
								clickEvent: () => {
									partialStore.setSelectedItem("")
									partialStore.setFolderActionType("add")
									partialStore.setAddFSGroupCategoryDialogOpen(
										true
									)
								},
							},
						]}
						useScroll={false}
						showButton={true}
						showIcon={false}
						btnText="FS Group Categories"
						btnVariant="outlined"
						btnColor="primary"
						btnSize="regular"
						btnClassName="ctx-menu-styles"
						eleIcon={<Icon path={mdiMenuDown} size={1} />}
					/>
				</div>
				<div>
					<div className="FR AC JSB header">
						<div>Number</div>
						<div>Name</div>
						<div>Balance</div>
						<div>PY Balance</div>
					</div>
					<div>
						<StyledTreeForFiles>
							<SortableTree
								treeData={partialStore.flatListAsTree()}
								className={"fs-group-number-component"}
								//
								dndType="dlFileNode"
								shouldCopyOnOutsideDrop
								canDrag={true}
								getNodeKey={({ node }: { node: any }) =>
									node.id
								}
								isVirtualized={false}
								onChange={(treeData) => {}} // sortable tree set this as mandatory
								style={{
									// width: contentsAreaWidth,
									height: 100,
									paddingBottom: "1rem",
									// overflowY: "auto", // Do not use scroll. It makes right side padding even when it has less item
								}}
								rowHeight={32}
								scaffoldBlockPxWidth={100}
								generateNodeProps={(data: any) => {
									console.log(data, "nodeData")
									let forIndent = 0
									for (
										let i = 0;
										i < data.path.length - 1;
										i++
									) {
										forIndent += 20
									}
									return {
										title: data.node.isParent ? (
											<div
												className={`FR AC JSB parent-item ${
													data.node.id ===
														selectedItem &&
													"selected"
												}`}
												onClick={(e: any) =>
													handleClick(e, data.node.id)
												}
											>
												<div
													data-testid={`file-folder-icon-${data.node.id}`}
												>
													{data.node.expanded ? (
														<div
															className="FR AC chevron-and-folder-icon opened"
															style={{
																marginLeft:
																	forIndent,
															}}
														>
															<Icon
																path={mdiMinus}
																size={0.8}
																className="folder-icon expanded"
															/>
														</div>
													) : (
														<div
															className="FR AC chevron-and-folder-icon closed"
															style={{
																marginLeft:
																	forIndent,
															}}
														>
															<Icon
																path={mdiPlus}
																size={0.8}
																className="folder-icon"
															/>
														</div>
													)}
												</div>
												<div className="FR AC">
													<b>{data.node.title}</b>
													{data.node.expanded && (
														<>
															{permission.update && (
																<DLIconButton
																	eleTestId="edit-folder-name-icon-btn"
																	tooltipText={
																		"Rename folder"
																	}
																	clickHandler={(
																		e: any
																	) => {
																		e.stopPropagation()
																		partialStore.setSelectedItem(
																			data
																				.node
																				.id
																		)
																		partialStore.setFolderActionType(
																			"edit"
																		)
																		partialStore.setAddFSGroupCategoryDialogOpen(
																			true
																		)
																	}}
																	eleClassName="open-input-folder-title"
																>
																	<Icon
																		path={
																			mdiPencil
																		}
																		size={
																			0.8
																		}
																	/>
																</DLIconButton>
															)}
															{permission.create && (
																<DLIconButton
																	eleTestId="add-folder-icon-btn"
																	tooltipText={
																		"Add Folder"
																	}
																	clickHandler={(
																		e: any
																	) => {
																		partialStore.setSelectedItem(
																			data
																				.node
																				.id
																		)
																		partialStore.setFolderActionType(
																			"add"
																		)
																		partialStore.setAddFSGroupCategoryDialogOpen(
																			true
																		)
																	}}
																>
																	<Icon
																		path={
																			mdiFolderPlus
																		}
																		size={
																			0.8
																		}
																	/>
																</DLIconButton>
															)}
														</>
													)}
												</div>
												{/* <div></div>
												<div></div> */}
											</div>
										) : (
											<div className="FR JSB AC child-item">
												<div>
													<div
														style={{
															marginLeft:
																forIndent,
														}}
													>
														{data.node.number}
													</div>
												</div>
												<div>{data.node.title}</div>
												<div>{data.node.balance}</div>
												<div>{data.node.pyBalance}</div>
											</div>
										),
									}
								}}
							/>
						</StyledTreeForFiles>
					</div>
				</div>
			</StyledFinancialGroup>
			{partialStore.addFSGroupCategoryDialogOpen && (
				<AddFSGroupCategoryDialog partialStore={partialStore} />
			)}
		</PageContainer>
	)
})

const StyledFinancialGroup = styled.div`
	padding: 0 1rem;
	.header {
		margin-top: 15px;
		border-top: 1px solid;
		border-bottom: 1px solid;
		font-weight: 900;
		font-size: ${(props) => props.theme.shared.textRg};
		height: 36px;
	}
	.header > div {
		flex: 1;
		text-align: start;
	}
	.rst__nodeContent > div:first-child {
		width: 100% !important;
	}
	.rst__rowWrapper,
	.rst__row,
	.rst__rowContents,
	.rst__rowLabel,
	.rst__rowTitle,
	.parent-item,
	.child-item {
		height: 100% !important;
		width: 100% !important;
	}
	.parent-item > div:first-child {
		flex: 1;
		text-align: start;
	}
	.parent-item > div:last-child {
		flex: 3;
		text-align: start;
	}
	.child-item div {
		flex: 1;
		text-align: start;
	}
	.selected {
		background-color: #ff9900;
		color: black;
		font-weight: 700;
	}
`
