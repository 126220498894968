import { types } from "mobx-state-tree"
import {
	ClickPoint,
	ClickPointProps,
} from "../../../../common-models/types/dialog.props"

const AssignRelatedActions = (self: any) => ({
	setOpenAdd(request?: boolean) {
		// initialize selected user (assign target user)
		self.setSelectedUserForAssign("")
		self.openAdd = request ?? !self.openAdd
	},
})

const ProjUsersUiHelper = types
	.model({
		clickPoint: ClickPoint,
		openAdd: false,
		openEdit: false,
		openConfigureProjDataMgmtAccess: false,
	})
	.actions((self) => ({
		setClickPoint(clickPoint: ClickPointProps) {
			self.clickPoint = clickPoint
		},
		setOpenEdit(request?: boolean) {
			self.openEdit = request ?? !self.openEdit
		},
		setOpenConfigureProjDataMgmtAccess(request?: boolean) {
			self.openConfigureProjDataMgmtAccess =
				request ?? !self.openConfigureProjDataMgmtAccess
		},
	}))
	.actions(AssignRelatedActions)
	.views((self) => ({}))

export default ProjUsersUiHelper
