import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { OrgDashboardTableView } from "../.."
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import CommonSnackbarComponent from "../../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import OrgNoPermissionOnMenu from "../../org-access-control/OrgNoPermissionOnMenu"
import { OrgI18n } from "../../../../common-models/enumerations/translation-sheets"
import { PageContainer } from "../../../../components/app-frame-elements"

export default observer(function OrgDashboardTableViewController() {
	// default settings
	const store = useRootStore()
	const orgStore = useOrgStore()
	const menuId = DLOrgSubMenus.dash_table_summary
	const actionName = "getOrgDashboardList"
	const i18nSheet = OrgI18n.projects
	//
	const menuAccess = orgStore.checkin.checkAccess(menuId)

	const needRefresh = orgStore.orgDashboard.needRefresh
	//

	useEffect(() => {
		menuAccess && needRefresh && orgStore.orgDashboard.getOrgDashboardList()
	}, [menuAccess, needRefresh])

	const i18n = store.i18n.combineI18n(i18nSheet)

	return (
		<>
			{menuAccess ? (
				<PageContainer
					fullWidth
					hasToolbar={false}
					pageTools={<div />}
					fixedHeight
				>
					<OrgDashboardTableView
						partialStore={orgStore.orgDashboard}
						contentsHeight={store.ui.contentsAreaHeight}
						actionStatus={orgStore.orgDashboard.getActionStatus(
							actionName
						)}
						dateFormat={store.global.dateFormat.value}
						i18n={i18n}
					/>
					<CommonSnackbarComponent
						i18n={i18n}
						partialStore={orgStore.orgDashboard}
					/>
				</PageContainer>
			) : (
				<OrgNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})
