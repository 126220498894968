import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../../components/basic-elements"
import { getProjDataMgmtAccessPermissionLabelByIndex } from "../../../project-setup-users/store/data-models/proj-setup-users.data-models"

const GetCurrentUserOnProj = (self: any) => ({
	getCurrentUserOnProj() {
		const actionName = "getCurrentUserOnProj"
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})

		self.readCurrentUserOnProj()
			.then((response: any) => {
				if (response.data.status === 200) {
					ConsoleLog([actionName, "__response ", response])
					const { data } = response.data
					const currentUserOnProj = {
						userId: data.userId,
						status: data.status,
						accessTypeId: data.accessTypeId,
						isOrgProjectDataMgmtAdmin:
							data.isOrgProjectDataMgmtAdmin,
						isAssignedToProject: data.isAssignedToProject,
						roleId: data.roleId,
						projDataMgmtAccessPermissionId:
							data.projectDataMgmtAccessPermission,
						projDataMgmtAccessPermissionLabel:
							getProjDataMgmtAccessPermissionLabelByIndex(
								data.projectDataMgmtAccessPermission
							),
						projectAccessRightId: data.projectAccessRightId,
						projectUserTrashStatus: data.projectUserTrashStatus, // TODO: this part will be deprecated. Project & Archive Admin, Project Admin, Project User...
						projectUserStatus: data.projectUserStatus,
						hasProjectDataMgmtMenuAccess:
							data.hasProjectDataMgmtMenuAccess,
					}

					self.setCurrentUserOnProj(currentUserOnProj)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						customMessage:
							"Success to get the user information on the project",
						color: MessageColorType.blue,
						open: false,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						customMessage: "Something wrong",
						color: MessageColorType.orange,
						open: false,
					})
				}
				//
			})
			.catch((error: Error) => {
				ConsoleLog([actionName, "-error: ", error])
				self.handleResponse({
					actionName,
					status: ActionStatus.fail,
					code: 999,
					customMessage:
						"Fail to get the current user information on the project",
					color: MessageColorType.red,
					open: true,
				})
			})
	},
})

export default GetCurrentUserOnProj
