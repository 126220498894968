import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import {
	idToNumber,
	IdType,
	idToString,
} from "../../../../../../library/converters/id-converter"
import { hasEmptyProps } from "@datalobby/common"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 *
 */

const AssignReadonlyUser = (self: any) => ({
	assignReadonlyUser(
		inputs: {
			type: string
			user: string
			expPermission: string
			expiredDate: string
		},
		projectId: string
	) {
		// 0.
		const actionName = "assignUser"
		// 1.
		const checkedProps = hasEmptyProps(inputs)
		if (checkedProps.hasEmptyProps) {
			alert("Empty field exist")
			return
		}
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.call API
		const { user, expPermission, expiredDate } = checkedProps.props

		const clientId = idToNumber(projectId, IdType.project)

		const payload = {
			clientId: clientId,
			UserId: idToNumber(user.toString(), IdType.user),
			IsExportPerm: expPermission, // @Pruehvi: Does this API allow string for export permission...?
			ExpiredDate: expiredDate,
		}
		self.addReadOnlyUser(payload)
			.then((response: any) => {
				// if success
				if (response.status === 200 && response.data.Status === 1) {
					// fetching access mgmt users
					//NOTE : Need to request additional info from backend
					const userId = localStorage.getItem("orgUserId")
					if (userId === user) {
						self.updateAccessible(projectId, true, expPermission)
					}
					const userInfo = self.getUserInfoById(user)
					const fetchedData = response.data.Result
					const reOrganizedUsers = {
						id: idToString(fetchedData.Id, IdType.user), // TODO: @Prudhvi-0817: please use idToString
						// projectName: "",
						title: "",
						// version: "",
						// clientName: "",
						//
						userId: idToString(fetchedData.UserId, IdType.user), // TODO: @Prudhvi-0817: please use idToString
						name: userInfo.name,
						aliasId: userInfo.userAliasId,
						userType:
							inputs.type === "internal"
								? "Internal"
								: "External", // TODO: @Prudhvi-0817: please use 'internal' | 'external instead of the '1' | '2'
						email: userInfo.email,
						//
						expiredDate: fetchedData.ExpiryDate,
						// fiscalYear: "",
						isExportPerm: fetchedData.IsExport === 0 ? false : true,
						// isgrayed: false, // TODO: @Prudhvi-0817: please update as isGrayed and also the related parts
						suspended: false,
						//
						// lastModifiedBy: "",
						// modifiedDate: fetchedData.ModifiedDate,
					}
					self.pushItemToAccessMgmtUserList(reOrganizedUsers)
					// success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200, // temp
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else if (
					response.status === 200 &&
					response.data.Status === 0
				) {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999, // temp
						color: MessageColorType.orange,
						open: true,
						autoHide: true,
						message: response.data.Message,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999, // temp
						color: MessageColorType.orange,
						open: true,
						// autoHide: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
	relayAssignUser(
		projectList: any,
		index: number,
		userId: number,
		expiredDate: string
	) {
		if (projectList.length >= index + 1) {
			const project = projectList[index]
			const projId = project.id
			const projNumId = idToNumber(projId, IdType.project)
			const payload = {
				clientId: projNumId,
				UserId: userId,
				IsExportPerm: "true",
				ExpiredDate: expiredDate, // "2021-06-07T23:59:59.000Z",
			}
			self.addReadOnlyUser(payload)
				.then((response: any) => {
					if (response.status === 200 && response.data.Status === 1) {
						console.log(
							index,
							"_SUCCESS",
							projNumId,
							project.title,
							project.aliasId,
							response.status,
							response.data.Status
						)
						if (projectList.length > index + 1) {
							self.relayAssignUser(
								projectList,
								index + 1,
								userId,
								expiredDate
							)
						}
					} else {
						console.log(
							index,
							"_FAIL-1",
							projNumId,
							project.title,
							project.aliasId,
							response.status,
							response.data.Status
						)
						if (projectList.length > index + 1) {
							self.relayAssignUser(
								projectList,
								index + 1,
								userId,
								expiredDate
							)
						}
					}
					if (projectList.length === index + 1) {
						console.log("THE END", projectList.length, index)
					}
				})
				.catch((err: Error) => {
					console.log(
						index,
						"_FAIL-2",
						projNumId,
						project.title,
						project.aliasId,
						err
					)
					if (projectList.length > index + 1) {
						self.relayAssignUser(
							projectList,
							index + 1,
							userId,
							expiredDate
						)
					}
				})
		}
	},
})

export default AssignReadonlyUser
