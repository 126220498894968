import { types } from "mobx-state-tree"

export enum ProjDownloadCenterZipTypes {
	ProjectZip = "Project Zip",
	FolderZip = "Folder Zip",
}

export function getProjDownloadCenterZipType(zipType: string) {
	switch (zipType) {
		case "ProjectZip":
			return ProjDownloadCenterZipTypes.ProjectZip
		default:
			return ProjDownloadCenterZipTypes.FolderZip
	}
}

export enum ProjDownloadCenterZipProgress {
	Pending = "Pending",
	Completed = "Completed",
	Failed = "Failed",
	Deleted = "Deleted",
	Unknown = "Unknown",
}

export function getProjDownloadCenterZipProgress(progress: string) {
	switch (progress) {
		case "Pending":
			return ProjDownloadCenterZipProgress.Pending
		case "Completed":
			return ProjDownloadCenterZipProgress.Completed
		case "Failed":
			return ProjDownloadCenterZipProgress.Failed
		case "USER DELETED":
		case "Deleted":
			return ProjDownloadCenterZipProgress.Deleted
		default:
			return ProjDownloadCenterZipProgress.Unknown
	}
}

export enum ProjDownloadCenterZipStatus {
	Available = "Available",
	Expired = "Expired",
}

export function getProjDownloadCenterZipStatus(status: string) {
	switch (status) {
		case "Available":
			return ProjDownloadCenterZipStatus.Available
		default:
			return ProjDownloadCenterZipStatus.Expired
	}
}

export const ProjDownloadCenter = types.model({
	id: types.number,
	zipFileName: types.string,
	folderId: types.number,
	folderName: types.string,
	createdAt: types.string,
	createdById: types.number,
	createdByName: types.string,
	zipType: types.enumeration<ProjDownloadCenterZipTypes>(
		Object.values(ProjDownloadCenterZipTypes)
	),
	projectDataType: types.string,
	size: types.number,
	zipProgress: types.enumeration<ProjDownloadCenterZipProgress>(
		Object.values(ProjDownloadCenterZipProgress)
	),
	zipStatus: "",
	lastRequestedBy: types.union(types.number, types.null),
	lastRequestedByName: types.union(types.string, types.null),
	lastRequestedAt: types.union(types.string, types.null),
	expiredAt: types.union(types.string, types.null),
	isDeleted: types.boolean,
	deletedBy: types.union(types.number, types.null),
	deletedAt: types.union(types.string, types.null),
	zipRequestReason: types.string,
	isCompleted: types.boolean,
	isDownloadable: types.boolean,
})

export type ProjDownloadCenterType = typeof ProjDownloadCenter
