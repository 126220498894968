import {
	ActionStatus,
	MessageColorType,
} from "../../../common-models/enumerations/common-enums"
import { DLProjSubMenus } from "../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { idToNumber, IdType } from "../../../library/converters/id-converter"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 *
 */

const CloseOpenedFiles = (self: any) => ({
	closeOpenedFiles(menuId: DLProjSubMenus, details: any, type: number) {
		// 0.
		const actionName = "closeOpenedFiles"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		let payload: any = []
		const userId = localStorage.getItem("orgUserId")
		let userNumberId: any
		if (userId) {
			userNumberId = idToNumber(userId, IdType.user)
		}
		details.map((item: any) => {
			payload.push({
				MediaID: item.id,
				Type: 0,
				UserID: userNumberId,
			})
		})
		self.deleteOpenedFiles(payload, menuId)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					details.map((item: any) => {
						self.removeFromOpenedList(type, item.id)
					})
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
						message: response.data.Message,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default CloseOpenedFiles
