import { types, applySnapshot } from "mobx-state-tree"
// ---------- common models
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"
import { initialStore } from "./fs-working-trial-balance.provider"

import {
	ClickPoint,
	ClickPointProps,
} from "../../../../../common-models/types/dialog.props"
import {
	FSAccounts,
	MapNumbersModel,
	WORKING_TRIAL_BALANCE_TABS,
} from "./data-models/fs-working-trial-balance.data-model"
import GetAccounts from "./view-model-actions/get-accounts"
import GetMapNumbers from "./view-model-actions/get-map-numbers"
import LinkMapNoToAccount from "./view-model-actions/link-map-no-to-account"

const FsWorkingTrialBalanceViewModel = types
	.model({
		accounts: types.array(FSAccounts),
		mapNumbers: types.array(MapNumbersModel),
		clickPoint: ClickPoint,
		selectedItem: "",
		selectedTab: WORKING_TRIAL_BALANCE_TABS.ACCOUNT,
		linkMapNoToAccountDialogOpen: false,
		// ---------- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar, // snackbar type 2
	})
	.actions((self) => ({
		setSelectedItem(id: string) {
			self.selectedItem = id
		},
		setMapNumbers(nos: any) {
			self.mapNumbers = nos
		},
		setAccounts(data: any) {
			self.accounts = data
		},
		setSelectedTab(tab: WORKING_TRIAL_BALANCE_TABS) {
			self.selectedTab = tab
		},
		setClickPoint(clickPoint: ClickPointProps) {
			self.clickPoint = clickPoint
		},
		setLinkMapNoToAccountDialogOpen(request?: boolean) {
			self.linkMapNoToAccountDialogOpen =
				request ?? !self.linkMapNoToAccountDialogOpen
		},
		updateMapNoToAccount(id: string, mapNo: string, mapNoDesc: string) {
			console.log(id, mapNo, mapNoDesc, "mapNoDesc")
			const index = self.accounts.findIndex((i: any) => i.id === id)
			self.accounts[index].mapNo = mapNo
			self.accounts[index].mapNoDesc = mapNoDesc
		},
	}))
	.actions(GetAccounts)
	.actions(GetMapNumbers)
	.actions(LinkMapNoToAccount)
	.views((self) => ({
		viewAccounts() {
			return self.accounts
		},
		viewMapNumbers() {
			return self.mapNumbers
		},
		getAccountById(id: string) {
			return self.accounts.find((item: any) => item.id === id)
		},
	}))
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default FsWorkingTrialBalanceViewModel
