import React from "react"
import { DLContextMenu } from "../../../../../components/basic-elements"
import { observer } from "mobx-react-lite"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"

export default observer(function ListOfFinancialGroupCtxMenu({
	permission,
	partialStore,
}: {
	permission: PermissionAsObjectProps
	partialStore: any
}) {
	const menuOptions = () => [
		{
			label: "Remove",
			value: "remove",
			available: permission.update,
			clickEvent: () => {},
		},
	]

	const handleClose = () => {
		partialStore.setClickPoint({
			mouseX: null,
			mouseY: null,
		})
	}

	return (
		<DLContextMenu
			eleId="list-of-fs-ctx-menus"
			clickPoint={{
				mouseX: partialStore.clickPoint.mouseX,
				mouseY: partialStore.clickPoint.mouseY,
			}}
			handleClose={handleClose}
			menuOptions={menuOptions()}
			// hasDivider={[1]}
		/>
	)
})
