const HandleDialogs = (self: any) => ({
	setAddDialogOpen(request?: boolean) {
		self.addDialogOpen = request || !self.addDialogOpen
	},
	setEditDialogOpen(request?: boolean) {
		self.editDialogOpen = request || !self.editDialogOpen
	},
	setSuspendDialogOpen(request?: boolean) {
		self.suspendDialogOpen = request || !self.suspendDialogOpen
	},
	setRestoreDialogOpen(request?: boolean) {
		self.restoreDialogOpen = request || !self.restoreDialogOpen
	},
	setRemoveDialogOpen(request?: boolean) {
		self.removeDialogOpen = request || !self.removeDialogOpen
	},
	setBulkAddUsersDialogOpen(request?: boolean) {
		self.bulkAddUsersDialogOpen = request || !self.bulkAddUsersDialogOpen
	},
	setAssignedProjectsDialogOpen(request?: boolean) {
		self.assignedProjectsDialogOpen =
			request || !self.assignedProjectsDialogOpen
	},
	setOrgNotificationPermissionDialogOpen(request?: boolean) {
		self.orgNotificationPermissionDialogOpen =
			request || !self.orgNotificationPermissionDialogOpen
	},
})

export default HandleDialogs
