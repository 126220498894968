import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../components/basic-elements"
import { idToString, IdType } from "../../../../library/converters/id-converter"
import {
	convertExtension,
	convertSourceFormat,
} from "../../../../library/converters/source-format-converter"
import { ProjRoles } from "../../../../screens/project-side/project-setup-users/store/data-models/proj-setup-users.data-models"
import { FileScreenDialog } from "../../data-models/dl-file-control-model"
import { initialDLFile } from "../../data-models/initial-file"
import { CreateFileFromTempActionProps } from "../../model-actions/file/create-file-from-temp"
import { convertSignOffs } from "../../proj-files-shared-functions"

const AddFileFromTemp = (self: any) => ({
	addFileFromTemp(props: CreateFileFromTempActionProps) {
		// 0.
		const actionName = "addFileFromLib"
		// 1.
		// 2.
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 111,
			color: MessageColorType.green,
			open: true,
		})
		// 3.

		self.createFileFromTemp(props)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// console.log(actionName+ "__response " + response)
					/**
					 * REFLECT RESPONSE ON THE STORE
					 */
					const data = response.data
					const srcFormat = convertSourceFormat(
						data.MediaType,
						"",
						"add-file-from-temp"
					)
					const createReviewList = (role: string) =>
						props.userProjRoleId === role && data.IsReviewed
							? convertSignOffs([data.Reviewer])
							: []
					const newItem = {
						...initialDLFile,
						id: idToString(data.WPRID, IdType.file),
						parentId: idToString(data.ParentMediaID, IdType.folder), // or props.parentId
						aliasId: props.fileAliasId,
						title: props.fileName,
						extension: convertExtension(data.Extension, srcFormat),
						menuId: self.storeName,
						srcFormat,
						size: data.FileSize,
						path: data.FilePath,
						srcId: idToString(data.MediaID, IdType.src),
						index: 999,
						isPrepared: data.IsPrepared,
						isReviewed: data.IsReviewed,
						prepareList: data.IsPrepared
							? convertSignOffs([data.Preparer])
							: [],
						reviewList: data.IsReviewed
							? convertSignOffs([data.Reviewer])
							: [],
						epReviewList: createReviewList(ProjRoles.ep),
						cpReviewList: createReviewList(ProjRoles.cp),
						qcReviewList: createReviewList(ProjRoles.qc),
					}
					self.pushFlatItem(newItem)
					// close the dialog, open the parent folder
					self.setFileTreeDialogOpen(
						FileScreenDialog.createFile,
						false
					)
					self.toggleExpanded(newItem.parentId, true)
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						message: response.data.Message,
						open: true,
						// autoHide: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message,
						open: true,
						autoHide: false,
					})
				}
			})
			.catch((error: Error) => {
				ConsoleLog([actionName, "__error ", error])
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default AddFileFromTemp
