import React from "react"
import { DLContextMenu } from "../../../../../components/basic-elements"
import { observer } from "mobx-react-lite"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { ActionStatus } from "@datalobby/types"
import { MessageColorType } from "../../../../../common-models/enumerations/common-enums"

export default observer(function FsSetupFsGroupsCategoryTableMenus({
	permission,
	partialStore,
}: {
	permission: PermissionAsObjectProps
	partialStore: any
}) {
	const isActive = partialStore.getGroupById(
		partialStore.selectedItem
	)?.isActive
	const active = partialStore.getActiveList()
	// const inactive = partialStore.getInactiveList()

	const handleActivateDeactivate = () => {
		if (isActive && active.length === 1) {
			partialStore.handleResponse({
				actionName: "editEngType",
				status: ActionStatus.fail,
				code: 200,
				color: MessageColorType.red,
				customMessage: "At least one item must remain active.",
				open: true,
				autoHide: true,
			})
			return
		}
		let proceed = window.confirm(
			`Are you sure you want to ${
				isActive ? "deactivate" : "activate"
			} this item?`
		)
		if (!proceed) return

		// const userInfo = orgStore.checkin.orgInfo
		// const modifiedByUserName = `[${userInfo.userAliasId}] ${userInfo.userName}`

		// partialStore.editEngType({
		// 	engagementId: partialStore.engagementId,
		// 	modifiedByUserName,
		// 	isActive: !isActive,
		// })
	}

	const menuOptions = [
		{
			label: `Edit`,
			value: "ctx-editEngType",
			available: permission.update,
			clickEvent: () => partialStore.setEditDialogOpen(true),
		},
		{
			label: isActive ? "Deactivate" : "Activate",
			value: `ctx-${isActive ? "deactivate" : "activate"}`,
			available: permission.update,
			clickEvent: handleActivateDeactivate,
		},
	]

	const handleClose = () => {
		partialStore.setClickPoint({
			mouseX: null,
			mouseY: null,
		})
	}

	return (
		<DLContextMenu
			eleId="setup-groups=category-ctx-menus"
			clickPoint={{
				mouseX: partialStore.clickPoint.mouseX,
				mouseY: partialStore.clickPoint.mouseY,
			}}
			handleClose={handleClose}
			menuOptions={menuOptions}
			hasDivider={[0]}
		/>
	)
})
