// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"

const MoveArchiveConditions = (self: any) => ({
	moveArchiveConditions(
		policyId: string,
		policyConditions: any,
		modifiedBy: any
	) {
		// 0.
		const actionName = "moveArchiveConditions"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		/**
		 * PARAMETER or PAYLOAD READY
		 */
		const mandatories = policyConditions.filter(
			(policy: any) => policy.isMandatory
		)
		const optionals = policyConditions.filter(
			(policy: any) => !policy.isMandatory
		)
		let conditions: any[] = []
		mandatories.map((item: any) => {
			conditions.push({
				id: idToNumber(item.aliasId, IdType.archiveCondition),
				required: item.isMandatory,
			})
		})
		optionals.map((item: any) => {
			conditions.push({
				id: idToNumber(item.aliasId, IdType.archiveCondition),
				required: item.isMandatory,
			})
		})
		const policyNumberId = idToNumber(policyId, IdType.archivePolicy)
		self.requestMoveArchiveCondition(policyNumberId, conditions)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// console.log(actionName+ "__response " + response)
					/**
					 * REFLECT RESPONSE ON THE STORE
					 */
					self.setPolicyConditions(policyId, policyConditions)
					self.setPolicyLastModifiedByInfo(policyId, modifiedBy)
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: "Success to" + actionName,
						open: true,
						autoHide: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message || response.data.message,
						open: true,
						autoHide: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default MoveArchiveConditions
