import React, { useCallback, useState } from "react"
import {
	DLComboBox,
	DLDatepicker,
	DLInputField,
	DLRadioGroup,
	InputWithLabel,
} from "../../../../../../components/basic-elements"
import { RECURRENCE_OPTIONS } from "../../../report-generation-schedule/utils/report-generation-scheduler-utils"
import { Button } from "@material-ui/core"
import { useRootStore } from "../../../../../../stores/root-store/root-store.provider"
import { OrgI18n } from "../../../../../../common-models/enumerations/translation-sheets"
import styled from "styled-components"

export default function EmailSenderSchedulerForm() {
	const store = useRootStore()
	const i18n = store.i18n.combineI18n(OrgI18n.projects, "CreateProjectDialog")
	const dateFormat = store.global.dateFormat.value
	const { id: defaultRecurId, title: defaultRecurTitle } =
		RECURRENCE_OPTIONS[0]

	const [inputs, setInputs] = useState({
		scheduler: "true",
		recurrence: defaultRecurId,
		executionTimeExpression: "59 23 *** Mon-Fri",
		timeZone: "Time Zone | Korea Standard Time | Asia/Seoul",
		specificStartTime: "",
	})

	const handleInputs = useCallback((event: any) => {
		const { name, value } = event.target
		setInputs((inputs) => ({
			...inputs,
			[name]: value,
		}))
	}, [])

	const handleDateInput = (name: string, date: string) => {
		setInputs((inputs: any) => ({
			...inputs,
			[name]: date,
		}))
	}

	const resetForm = () => {
		setInputs({
			scheduler: "true",
			recurrence: defaultRecurId,
			executionTimeExpression: "59 23 *** Mon-Fri",
			timeZone: "Time Zone | Korea Standard Time | Asia/Seoul",
			specificStartTime: "",
		})
	}

	const saveEmailSenderScheduler = () => {
		window.alert("Email Sender Scheduler Saved")
	}

	return (
		<StyledEmailSenderSchedulerForm>
			<h1>Email Sender Scheduler</h1>

			<div className="input-section">
				<InputWithLabel
					required
					label={i18n.twIsSchedulerState || "Scheduler State:"}
				>
					<DLRadioGroup
						itemDirection="row"
						groupName="scheduler"
						selectedValue={inputs.scheduler}
						items={[
							{ value: "true", label: "ON" },
							{ value: "false", label: "OFF" },
						]}
						eleOnChange={handleInputs}
					/>
				</InputWithLabel>
			</div>

			<div className="input-container">
				<div className="input-section">
					<InputWithLabel
						label={i18n.twRecurrence || "Recurrence"}
						required
						direction="vertical"
					>
						<DLComboBox
							eleTestId="recurrence-input-field"
							withoutLabel
							placeholder={i18n.tsSelectRecurrence}
							onChange={handleInputs}
							getOptionLabel={(option: any) => option.title}
							options={RECURRENCE_OPTIONS}
							defaultValue={{
								id: defaultRecurId,
								title: defaultRecurTitle,
							}}
							eleDisabled={true}
						/>
					</InputWithLabel>
				</div>

				<div className="input-section">
					<InputWithLabel
						label={
							i18n.twExecutionTimeExpression ||
							"Execution Time Expression"
						}
						required
						direction="vertical"
					>
						<DLInputField
							eleTestId="execution-time-expression-input-field"
							eleName="executionTimeExpression"
							eleValue={inputs.executionTimeExpression}
							eleHandleChange={handleInputs}
							eleReadOnly={inputs.scheduler === "false"}
						/>
					</InputWithLabel>
				</div>
			</div>

			<div className="input-container">
				<div className="input-section">
					<InputWithLabel
						label={i18n.twTimeZone || "Time Zone"}
						required
						direction="vertical"
					>
						<DLComboBox
							eleTestId="time-zone-input-field"
							withoutLabel
							placeholder={i18n.tsSelectTimeZone}
							onChange={handleInputs}
							getOptionLabel={(option: any) => option.title}
							options={[
								{ value: "Super Admin", name: "Super Admin" },
								{ value: "Group Admin", name: "Group Admin" },
								{ value: "User Access", name: "User Access" },
							]}
							defaultValue={{
								id: "asia-seoul",
								title: "Time Zone | Korea Standard Time | Asia/Seoul",
							}}
							helperText="Current time in selected time zone is xxxx-xx-xx xx:xx:xx"
							eleDisabled={true}
						/>
					</InputWithLabel>
				</div>
			</div>

			<div className="input-container">
				<div className="input-section">
					<InputWithLabel
						label={i18n.twStartTime || "Specific Start Time"}
						required
						direction="vertical"
					>
						<DLDatepicker
							eleTestId="start-time-input"
							eleName="specificStartTime"
							selected={
								inputs.specificStartTime === ""
									? null
									: inputs.specificStartTime
							}
							onChange={(date: any) =>
								handleDateInput("specificStartTime", date)
							}
							format={dateFormat}
							disabled={inputs.scheduler === "false"}
						/>
					</InputWithLabel>
				</div>
			</div>

			{/* ✅ Buttons Container (Side by Side) */}
			<div className="button-container">
				<Button variant="contained" color="default" onClick={resetForm}>
					Cancel
				</Button>
				<Button
					variant="contained"
					color="primary"
					onClick={saveEmailSenderScheduler}
				>
					Save
				</Button>
			</div>
		</StyledEmailSenderSchedulerForm>
	)
}

const StyledEmailSenderSchedulerForm = styled.div`
	padding: 1.5rem;
	max-width: 600px; /* ✅ Restrict width so buttons align properly */
	// margin: 0 auto; /* ✅ Center the form on the page */

	h1 {
		margin-bottom: 1.5rem;
	}

	.form-content {
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}

	.input-container {
		display: flex;
		gap: 2rem;
		align-items: flex-start;
		flex-wrap: wrap;
	}

	.input-section {
		flex: 1;
		min-width: 250px;
		max-width: 400px;
		width: 100%;
	}

	/* ✅ Buttons below last field, aligned right */
	.button-container {
		display: flex;
		justify-content: flex-end;
		gap: 1rem;
		width: 100%; /* Ensures it aligns with form width */
		margin-top: 1rem;
	}
`
