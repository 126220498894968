import React, { useCallback, useState } from "react"
import {
	PageContainer,
	PageHeader,
} from "../../../../../components/app-frame-elements"
import { observer } from "mobx-react-lite"
import {
	DLIconButton,
	DLButton,
	DLSpinner,
} from "../../../../../components/basic-elements"
import Icon from "@mdi/react"
import { mdiDotsHorizontal } from "@mdi/js"
import { ActionStatus } from "../../../../../common-models/enumerations/common-enums"
import CommonSnackbarComponent from "../../../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import BasicCounter from "../../../../../components/app-frame-elements/sub-components/BasicCounter"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import DLDateRangepicker from "../../../../../components/basic-elements/date-picker/DLDateRangePicker"
import ApprovalDialog from "../../../../../components/combined-elements/dialogs/ApprovalDialog"
import ProjectTrashCtxMenus from "../../../project-trash/sub-components/ProjectTrashCtxMenus"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import { DLCheckbox, DLTooltip } from "@datalobby/components"
import { InputFieldForTableV8 } from "../../../../../components/basic-elements/tables/InputFieldForTable"
import {
	dateRangeFilter,
	multiSelectFilter,
} from "../../../../../library/react-table-utils"
import DLComboBoxWithCheckbox from "../../../../../components/basic-elements/autocomplete-fields/DLComboBoxWithCheckbox"
import { Column, Table } from "@tanstack/react-table"
import ReactTableV8 from "../../../../../components/basic-elements/tables/DLReactTable"
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import styled from "styled-components"
import ProjDownloadCenterCtxMenus from "./ProjDownloadCenterCtxMenus"
import { Box, Tooltip } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import { makeStyles, Theme, withStyles } from "@material-ui/core/styles"
import { mdiInformation } from "@mdi/js"
import { ProjDownloadCenterStoreType } from "../store/proj-download-center.provider"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { getTzDate } from "../../../../../library/converters/date-utc-converter"

export default observer(function ProjDownloadCenterTable({
	partialStore,
	permission,
	contentsHeight,
	actionStatus,
	dateFormat,
	i18n,
}: {
	partialStore: ProjDownloadCenterStoreType
	permission: PermissionAsObjectProps
	contentsHeight: any
	actionStatus: ActionStatus
	dateFormat: string
	i18n: DLI18nProps
}) {
	const store = useRootStore()
	const dntFormat = store.global.getDntFormat
	const selectedItemId = partialStore.selectedItem
	const selectedItemInfo = partialStore.viewSelectedItemInfo(selectedItemId)
	const [zipTypeId, setZipTypeId] = useState(0)

	const classes = useStyles()
	const [selectedButton, setSelectedButton] = useState(0)
	const [loading, setLoading] = useState(false)

	const handleCheckbox = (id: number) => {
		partialStore.setSelectedItem(id)
	}

	const handleContextMenu = (event: any, itemId: number) => {
		event.preventDefault()
		partialStore.setSelectedItem(itemId)
		partialStore.setClickPoint({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
		})
	}

	const handleButtonClick = (index: number) => {
		setLoading(true)
		setTimeout(() => {
			setZipTypeId(index)
			setSelectedButton(index)
			setLoading(false)
		}, 10) // Simulates loading time
	}

	const buttons = [
		{ label: "All", content: <div>All Files</div> },
		{ label: "Project Zip", content: <div>Project Zip</div> },
		{ label: "Folder Zip", content: <div>Folder Zip</div> },
	]

	return (
		<PageContainer
			fixedHeight
			hasToolbar={false}
			isLoading={actionStatus === "LOADING"}
		>
			<div className={classes.root}>
				<Box className={classes.buttonGroup}>
					{buttons.map((button, index) => (
						<Button
							key={index}
							variant="contained"
							className={`${classes.button} ${
								selectedButton === index
									? classes.buttonActive
									: ""
							}`}
							onClick={() => handleButtonClick(index)}
						>
							{button.label}
						</Button>
					))}
				</Box>
				<Box className={classes.content}>
					{loading ? (
						<div className={classes.spinner}>
							<DLSpinner />
						</div>
					) : (
						<>
							{actionStatus === "SUCCESS" && (
								<ReactTableV8
									tableColumns={ProjDownloadCenterColumns(
										handleContextMenu,
										permission,
										i18n,
										handleCheckbox,
										selectedItemId
									)}
									data={partialStore.viewFormattedProjDownloadCenterList(
										dntFormat,
										zipTypeId
									)}
									hasPagination={true}
									showPageSetting={false}
									handleContextMenu={(e: any, rowInfo: any) =>
										handleContextMenu(e, rowInfo.id)
									}
									height={contentsHeight}
									pageHeader={() => (
										<StyledPageHeader>
											<div>
												Total Count: &nbsp;
												{
													partialStore.viewFormattedProjDownloadCenterList(
														dntFormat,
														zipTypeId
													).length
												}
											</div>
											<div>
												<span>
													Today (based on the org.
													time zone)
												</span>
												<div className="today-date">
													<DLTooltip
														title={"Today's date"}
														elePlacement="top"
														arrow
													>
														<div className="FR JSB">
															<Icon
																path={
																	mdiInformation
																}
																size={0.7}
															/>
															<span>
																{getTzDate({
																	date: new Date(),
																	timeZone:
																		dntFormat.timeZone,
																	dateFormat:
																		dntFormat.dateFormat,
																})}
															</span>
														</div>
													</DLTooltip>
												</div>
											</div>
										</StyledPageHeader>
									)}
									i18n={i18n}
									menuId={DLProjSubMenus.proj_download_center}
								/>
							)}
							{/* ----- snackbar ----- */}
							<CommonSnackbarComponent
								i18n={i18n}
								partialStore={partialStore}
							/>
							{/*---------Context Menu---------*/}
							<ProjDownloadCenterCtxMenus i18n={i18n} />
						</>
					)}
				</Box>
			</div>
		</PageContainer>
	)
})

const renderCustomCell = (props: any) => {
	const cellId = props.cell.column.id + "-cell"
	return (
		<div data-testid={cellId}>
			{props.getValue() ? props.getValue() : "-"}
		</div>
	)
}

const ProjDownloadCenterColumns = (
	handleContextMenu: (event: any, itemId: number) => void,
	permission: PermissionAsObjectProps,
	i18n: DLI18nProps,
	handleCheckbox: (id: number) => void,
	selectedId: number
) => {
	const CustomTooltip = withStyles({
		tooltip: {
			fontSize: "0.8rem", // Adjust the font size as needed
			maxWidth: "200px", // Set a maximum width for the tooltip
		},
	})(Tooltip)

	return [
		{
			header: "Select",
			accessorKey: "id",
			size: 10,
			enableColumnFilter: false,
			cell: (props: any) => {
				const id = props.getValue()
				const handleClick = () => {
					handleCheckbox(id)
				}
				return (
					<div className="FR AC JC" style={{ width: "100%" }}>
						<DLCheckbox
							eleTestId={`checkbox-${id}`}
							clickHandler={handleClick}
							color="primary"
							isChecked={selectedId === id}
						/>
					</div>
				)
			},
		},
		{
			header: i18n.twZipType || "Zip File Type",
			accessorKey: "zipType",
			cell: renderCustomCell,
			filterFn: multiSelectFilter,
		},
		{
			header: i18n.twProgress || "Progress",
			accessorKey: "zipProgress",
			cell: renderCustomCell,
		},
		{
			header: i18n.twProjectDataMgmtZipFileName || "Zip File Name",
			accessorKey: "zipFileName",
			cell: renderCustomCell,
		},
		{
			header: i18n.twSize || "Zip File Size",
			accessorKey: "zipFileSize",
			cell: renderCustomCell,
		},
		{
			header: i18n.twCreatedBy || "Created By",
			accessorKey: "createdByName",
			cell: renderCustomCell,
		},
		{
			header: i18n.twCreatedAt || "Created At",
			accessorKey: "createdAt",
			filterFn: dateRangeFilter,
			cell: renderCustomCell,
		},
		{
			header: i18n.twZipFileStatus || "Zip File Status",
			accessorKey: "zipStatus",
			cell: renderCustomCell,
		},
		{
			header: i18n.twZipRequestReason || "Zip Request Reason",
			accessorKey: "zipRequestReason",
			size: 200,
			cell: (props: any) => {
				const reason = props.getValue() || "-" // Default placeholder

				return (
					<CustomTooltip title={reason} arrow placement="top">
						<div
							style={{
								display: "-webkit-box",
								WebkitLineClamp: 2,
								WebkitBoxOrient: "vertical",
								overflow: "hidden",
								textOverflow: "ellipsis",
								maxWidth: "200px",
								cursor: "pointer",
							}}
						>
							{reason}
						</div>
					</CustomTooltip>
				)
			},
		},
		{
			header: i18n.twExpirationDate || "Expiration Date",
			accessorKey: "expiredAt",
			filterFn: dateRangeFilter,
			cell: renderCustomCell,
		},
		{
			header: "Action",
			size: 10,
			enableColumnFilter: false,
			cell: (props: any) => {
				const itemId = props.row.original.id
				return (
					<div className="FR AC JC" style={{ width: "100%" }}>
						<DLIconButton
							aria-label="more"
							clickHandler={(event: any) =>
								handleContextMenu(event, itemId)
							}
							eleTestId="trash-list-context-btn"
						>
							<Icon path={mdiDotsHorizontal} size={0.8} />
						</DLIconButton>
					</div>
				)
			},
		},
	]
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		height: "100vh",
		backgroundColor: theme.palette.background.paper,
	},
	buttonGroup: {
		display: "flex",
		marginBottom: theme.spacing(2),
		// paddingLeft: theme.spacing(2),
		gap: theme.spacing(1), // Space between buttons
	},
	button: {
		//textTransform: "none", // Disable uppercase text
	},
	buttonActive: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
	},
	content: {
		flexGrow: 1,
		// padding: theme.spacing(2),
		overflowY: "auto", // Scroll only if the content overflows
	},
	spinner: {
		height: 540,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
}))

export const StyledPageHeader = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between; /* Distribute content to left and right */
	align-items: center;
	padding-bottom: 10px; /* Optional for spacing */
	.today-date {
		height: 20px;
		width: 240px;
		background-color: #94b5f3;
		border-radius: 2px;
	}
`
