import React from "react"
import { useLocalObservable } from "mobx-react-lite"
import ProjMenuTabsStoreInstance from "./proj-store.menu-tabs"
// import projMenuList from "../../../assets/proj-menu-list"
import ProjectDashboardStoreInstance from "../../screens/project-side/project-dashboard/store/project-dashboard.provider"
// import CoAListStoreInstance from "../coa-list/store/coa-list.store"
// import CoADetailStoreInstance from "../coa-list/store/coa-detail.store"
// import TBStoreInstance from "../financial-statements/tb.store"
// import FSLSStoreInstance from "../financial-statements/fsLs.store"
// import EntrySetStoreInstance from "../financial-statements/entry-set.store"
// import LSStoreInstance from "../financial-statements/ls.store"
import ProjUsersStoreInstance from "../../screens/project-side/project-setup-users/store/proj-setup-users.provider"
import ProjInfoStoreInstance from "../../screens/project-side/project-information/store/proj-info.provider"
import ProjCheckinStoreInstance from "../../screens/project-side/proj-access-control/store/proj-checkin.provider"
// files
import WpStoreInstance from "../../screens/project-side/project-files/workpaper-files/store/wp.provider"
import PfStoreInstance from "../../screens/project-side/project-files/permanent-files/store/pf.provider"
//
import LibInProjStoreInstance from "../../screens/project-side/lib-in-proj/store/lib-in-proj.provider"
//
import CommentsStoreInstance from "../../screens/project-side/communications/proj-comments/store/comments.provider"
import NotesStoreInstance from "../../screens/project-side/communications/proj-notes/store/notes.provider"
import ArchiveDiagnosisStoreInstance from "../../screens/project-side/archive/archive-diagnosis/store/archive-diagnosis.provider"
import ArchiveHistoryStoreInstance from "../../screens/project-side/archive/archive-history/store/archive-history.provider"
import RationalesStoreInstance from "../../screens/project-side/archive/rationales/store/rationales.provider"
import ProjRolesStoreInstance from "../../screens/project-side/project-setup-roles/store/proj-roles.provider"
import ProjectTrashStoreInstance from "../../screens/project-side/project-trash/store/project-trash.provider"
import SignOffHistoryStoreInstance from "../../screens/project-side/archive/sign-off-history/store/sign-off-history.provider"
import NotificationSettingsStoreInstance from "../../screens/project-side/project-setup-notification-settings/store/notification-settings.provider"
import PBCStoreInstance from "../../screens/project-side/prepared-by-client/store/pbc.provider"
import ArchivePreProcessStoreInstance from "../../screens/project-side/archive/archive-pre-process/store/archive-pre-process.provider"
import OpenedFilesStoreInstance from "../../screens/project-side/opened-files/store/opened-files.provider"
import LeadSheetStoreInstance from "../../screens/project-side/financial-statements/lead-sheets/store/lead-sheet.provider"
import TrialBalanceStoreInstance from "../../screens/project-side/financial-statements/trial-balance/store/trial-balance.provider"
import FSSetupStoreInstance from "../../screens/project-side/financial-statements/fs-setup/store/fs-setup.provider"
import FinancialStatementsStoreInstance from "../../screens/project-side/financial-statements/financial-statements/store/financial-statements.provider"
import EliminatingEntryStoreInstance from "../../screens/project-side/financial-statements/eliminating-entry/store/eliminating-entry.provider"
import AdjustmentsStoreInstance from "../../screens/project-side/financial-statements/adjustments/store/adjustments.provider"
import PassedAdjustmentsStoreInstance from "../../screens/project-side/financial-statements/passed-adjustments/store/passed-adjustments.provider"
import BoardStoreInstance from "../../screens/project-side/communications/proj-board/store/board.provider"
import ProjRollForwardStoreInstance from "../../screens/project-side/project-setup-roll-forward/store/proj-roll-forward.provider"
import ChecklistStoreInstance from "../../screens/project-side/reports/checklist/store/checklist.provider"
import AuditProgramStoreInstance from "../../screens/project-side/audit-program/store/audit-program.provider"
import ImportFSDatasetsStoreInstance from "../../screens/project-side/fs-data/import-fs-datasets/store/import-fs-datasets.provider"
import AssignFSAccountsStoreInstance from "../../screens/project-side/fs-data/assign-fs-accounts/store/assign-fs-accounts.provider"
import FSAdjustmentsStoreInstance from "../../screens/project-side/fs-adjustments/fs-adjustments/store/fs-adjustments.provider"
import MapNumbersPresentationStoreInstance from "../../screens/project-side/fs-data/map-numbers/store/map-numbers-presentation.provider"
import ListOfMapNumbersStoreInstance from "../../screens/project-side/fs-data/list-of-map-numbers/store/list-of-map-numbers.provider"
import ListOfFinancialGroupStoreInstance from "../../screens/project-side/fs-data/list-of-financial-group/store/list-of-financial-group.provider"
import FinancialGroupPresentationStoreInstance from "../../screens/project-side/fs-data/financial-group-presentation/store/financial-group-presentation.provider"
import FsEntityPresentationStoreInstance from "../../screens/project-side/fs-data/fs-entity-presentation/store/fs-entity-presentation.provider"
import FsWorkingTrialBalanceStoreInstance from "../../screens/project-side/fs-data/fs-working-trial-balance/store/fs-working-trial-balance.provider"
import FSExcelImportStoreInstance from "../../screens/project-side/fs-excel-import/store/fs-excel-import.provider"
import ProjDownloadCenterStoreInstance from "../../screens/project-side/proj-data-mgmt/proj-download-center/store/proj-download-center.provider"
import FsSetupFsGroupsCategoryController from "../../screens/project-side/fs-data/fs-setup-fs-groups-category/store/fs-setup-fs-groups-category.provider"
import FsListOfCounterpartiesStoreInstance from "../../screens/project-side/fs-transaction/fs-list-of-counterparties/store/fs-list-of-counterparties.provider"
import FsListOfTransactionMetadataStoreInstance from "../../screens/project-side/fs-transaction/fs-list-of-transaction-metadata/store/fs-list-of-transaction-metadata.provider"

const createProjStore = () => {
	const store = {
		menuTabs: ProjMenuTabsStoreInstance,
		checkin: ProjCheckinStoreInstance,
		// ---------- project menus
		// menuList: projMenuList, // TODO: Which place is better for this?
		// findProjMenu(id: string) {
		// 	function searchIndex(element: any) {
		// 		return element.id === id
		// 	}
		// 	const targetIndex = store.menuList.findIndex(searchIndex)
		// 	return store.menuList[targetIndex]
		// },
		// < Checklist for project side main menus >
		//
		// - 1. Project Dashboard
		// - 2. Information
		// - 3. Note & Comments
		// - 4. PBC
		// - 5. Task & Time
		// - 6. Financial Statements
		// - 7. Reconciliation
		// - 8. SOX
		// - 9. Workpapers
		// - 10. Permanent Files
		// - 11. Reports
		// - 12. Archive
		// - 13. Project Setup
		//
		// ----------
		// - 1. Project Dashboard
		projDashboard: ProjectDashboardStoreInstance,
		projInfo: ProjInfoStoreInstance,
		//
		// - 3. Note & Comments
		board: BoardStoreInstance,
		notes: NotesStoreInstance,
		comments: CommentsStoreInstance,
		//
		// - 4. PBC
		pbc: PBCStoreInstance,
		//
		// - 6. Financial Statements
		leadSheet: LeadSheetStoreInstance,
		trialBalance: TrialBalanceStoreInstance,
		fsSetup: FSSetupStoreInstance,
		financialStatements: FinancialStatementsStoreInstance,
		eliminatingEntry: EliminatingEntryStoreInstance,
		adjustments: AdjustmentsStoreInstance,
		passedAdjustments: PassedAdjustmentsStoreInstance,
		//
		openedFiles: OpenedFilesStoreInstance,
		// - 9. Workpapers
		wp: WpStoreInstance,
		pf: PfStoreInstance,
		lib: LibInProjStoreInstance,
		apg: AuditProgramStoreInstance,
		// editor: FileEditorStoreInstance,
		//
		// - 12. Archive
		archive: ArchiveDiagnosisStoreInstance,
		archivePreProcess: ArchivePreProcessStoreInstance,
		archiveHistory: ArchiveHistoryStoreInstance,
		rationales: RationalesStoreInstance,
		signOff: SignOffHistoryStoreInstance,
		//
		// - 13. Project Setup
		projUsers: ProjUsersStoreInstance,
		roles: ProjRolesStoreInstance,
		trash: ProjectTrashStoreInstance,
		notificationSettings: NotificationSettingsStoreInstance,
		projRollForward: ProjRollForwardStoreInstance,
		//
		checklist: ChecklistStoreInstance,
		//
		importFSDatasets: ImportFSDatasetsStoreInstance,
		assignFSAccounts: AssignFSAccountsStoreInstance,
		mapNumbersPresentation: MapNumbersPresentationStoreInstance,
		fsGroupPresentation: FinancialGroupPresentationStoreInstance,
		listOfMapNumbers: ListOfMapNumbersStoreInstance,
		listOfFinancialGroup: ListOfFinancialGroupStoreInstance,
		fsListOfTransactionMetadata: FsListOfTransactionMetadataStoreInstance,
		fsEntityPresentation: FsEntityPresentationStoreInstance,
		fsWorkingTrialBalance: FsWorkingTrialBalanceStoreInstance,
		fsSetupFsGroupsCategory: FsSetupFsGroupsCategoryController,
		fsListOfCounterparties: FsListOfCounterpartiesStoreInstance,
		//
		fsAdjustments: FSAdjustmentsStoreInstance,
		//
		fsExcelImport: FSExcelImportStoreInstance,
		// ----------version 1 temporary store for next items
		// coaList: CoAListStoreInstance,
		// coaDetail: CoADetailStoreInstance,
		// tb: TBStoreInstance,
		// fsLs: FSLSStoreInstance,
		// entrySet: EntrySetStoreInstance,
		// ls: LSStoreInstance
		// ---------- Proj Download Center ----------
		projDownloadCenter: ProjDownloadCenterStoreInstance,
	}
	return store
}

type ProjStoreType = ReturnType<typeof createProjStore>

const ProjStoreContext = React.createContext<ProjStoreType | null>(null)

const ProjStoreProvider = ({ children }: { children: React.ReactNode }) => {
	const projStore = useLocalObservable(createProjStore)

	return (
		<ProjStoreContext.Provider value={projStore}>
			{children}
		</ProjStoreContext.Provider>
	)
}

const useProjStore = () => {
	const projStore = React.useContext(ProjStoreContext)
	if (!projStore) {
		throw Error("Project Store is null")
	}
	return projStore
}

export { ProjStoreContext, ProjStoreProvider, useProjStore }
