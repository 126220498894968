import { types } from "mobx-state-tree"
import { ProjDownloadCenterListApiProps } from "./proj-download-center-list.apis"
import ReadProjDownloadCenterList from "./model-actions/read-proj-download-center-list"
import RequestProjDataMgmtZipFileById from "./model-actions/request-proj-data-mgmt-zip-file-by-id"
import DeleteProjDataMgmtZipFileById from "./model-actions/delete-proj-data-mgmt-zip-file-by-id"

const ProjDownloadCenterListModel = ({
	apiReadProjDownloadCenterList,
	apiRequestProjDataMgmtZipFileById,
	apiDeleteProjDataMgmtZipFileById,
}: ProjDownloadCenterListApiProps) =>
	types
		.model({})
		.actions((self, readList = apiReadProjDownloadCenterList) =>
			ReadProjDownloadCenterList(self, readList)
		)
		.actions(
			(self, requestZipFileById = apiRequestProjDataMgmtZipFileById) =>
				RequestProjDataMgmtZipFileById(self, requestZipFileById)
		)
		.actions((self, deleteZipFileById = apiDeleteProjDataMgmtZipFileById) =>
			DeleteProjDataMgmtZipFileById(self, deleteZipFileById)
		)

export default ProjDownloadCenterListModel
