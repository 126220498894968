import { flow } from "mobx"
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"

const GetPreConditionStatus = (self: any) => ({
	/**
	 *
	 * @param self
	 * @returns
	 *
	 * * i18n records
	 * - success, fail
	 *
	 */
	getPreConditionStatus() {
		// 0.
		const actionName = "getPreConditionStatus"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. call API
		self.readPreConditionStatus()
			.then((response: any) => {
				// if success
				if (
					response.status === 200 &&
					response.data.status.code === 1
				) {
					const fetchedData = response.data.data
					let files: any = []

					fetchedData.archivePreprocessStatus.map((item: any) => {
						files.push({
							fileName: item.Name,
							isPreProcessed: item.Status,
							downloadUrl: item.DownloadUrl,
							viewUrl: item.ViewUrl,
						})
					})
					const reOrganizedData = {
						projectSize: fetchedData.ProjectSize,
						isPreProcessed: fetchedData.StartPreprocessStatus,
						preProcessFiles: files,
					}
					self.setPreConditionData(reOrganizedData)
					// if success
					self.setNeedRefresh(false)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					// set fail response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
	relayGetPreConditionStatus: flow(function* (
		projectList: any,
		index: number,
		getProjectToken: any,
		userId: number
	) {
		if (index === 0) {
			console.log("project list length: ", projectList.length)
		}
		if (projectList.length >= index + 1) {
			const project = projectList[index]
			const projId = project.id
			const projNumId = idToNumber(projId, IdType.project)
			const tokenAvailable = yield getProjectToken(projNumId, userId)
			console.log(index, "~~~~~tokenAvailable", tokenAvailable)
			if (tokenAvailable) {
				// when success to get the token
				// console.log("request precondition status of", projNumId)
				self.readPreConditionStatus().then((response: any) => {
					if (response.data.status.code === 1) {
						console.log(
							"success to get the pdf status of",
							projNumId
						)
						const fetchedData = response.data.data
						const status = fetchedData.StartPreprocessStatus
						self.pushPdfStatusItem({
							id: projNumId,
							title: project.title,
							aliasId: project.aliasId,
							pdfStatus: status,
						})
						if (projectList.length > index + 1) {
							self.relayGetPreConditionStatus(
								projectList,
								index + 1,
								getProjectToken,
								userId
							)
						}
						if (projectList.length === index + 1) {
							console.log("THE END", self.pdfStatusList)
						}
					} else {
						console.log(
							"failed to get the pdf status of ",
							projNumId
						)
						if (projectList.length > index + 1) {
							self.relayGetPreConditionStatus(
								projectList,
								index + 1,
								getProjectToken,
								userId
							)
						}
						if (projectList.length === index + 1) {
							console.log("THE END", self.pdfStatusList)
						}
					}
				})
			} else {
				console.log("token is not available")
				if (projectList.length > index + 1) {
					self.relayGetPreConditionStatus(
						projectList,
						index + 1,
						getProjectToken,
						userId
					)
				}
				if (projectList.length === index + 1) {
					console.log("THE END", self.pdfStatusList)
				}
			}
		}
	}),
})

export default GetPreConditionStatus
