import React, { useEffect } from "react"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import ProjNoPermissionOnMenu from "../../proj-access-control/ProjNoPermissionOnMenu"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { observer } from "mobx-react-lite"
import FinancialGroupPresentation from "./FinancialGroupPresentation"

export default observer(function FinancialGroupPresentationController() {
	const projStore = useProjStore()
	const menuId = DLProjSubMenus.financial_group_presentation
	const isLocked = projStore.projInfo.projInfo.isLocked
	const isArchived = projStore.projInfo.projInfo.isArchived
	const menuAccess = projStore.checkin.checkAccess(menuId)
	const permission = projStore.checkin.getActionsAsObject(
		menuId,
		isLocked,
		isArchived
	)

	useEffect(() => {
		projStore.fsGroupPresentation.getFinancialGroupPresentation()
	}, [])

	return (
		<>
			{menuAccess ? (
				<FinancialGroupPresentation
					partialStore={projStore.fsGroupPresentation}
					permission={permission}
					actionStatus={projStore.fsGroupPresentation.getActionStatus(
						"getFinancialGroupPresentation"
					)}
				/>
			) : (
				<ProjNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})
