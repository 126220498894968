import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import sharedRegEx from "../../../../../library/sharedRegEx"
import useForm from "../../../../../library/use-form"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import {
	DLDialog,
	DLInputField,
	InputWithLabel,
} from "../../../../../components/basic-elements"
// import { NoSpecialChar2Msg } from "../../../basic-elements/system-msg/DLSystemMsg"
import { NoSpecialChar2Msg } from "../../../../../components/basic-elements/system-msg/DLSystemMsg"
import styled from "styled-components"

export default observer(function AddFSGroupCategoryDialog({
	partialStore,
}: {
	partialStore: any
}) {
	const actionName = "addFSGroupCategory"
	const inputsSchema = {
		name: { value: "", error: "", requestInput: false },
	}

	const isEditMode = partialStore.folderActionType === "edit"

	const [prevFolderName, setPrevFolderName] = useState("")

	useEffect(() => {
		if (isEditMode) {
			const prevName = partialStore.getFSGroupDetailsById(
				partialStore.selectedItem
			)?.title
			setPrevFolderName(prevName)
			inputsSchema.name.value = prevName
		}
	}, [])

	const validationSchema = {
		name: {
			isRequired: true,
			validator: {
				regEx: sharedRegEx.noSpecialChar2,
				error: <NoSpecialChar2Msg />,
			},
			maxLength: 50,
		},
	}

	const open = partialStore.addFSGroupCategoryDialogOpen

	const handleAdd = (name: string) => {
		if (isEditMode) {
			partialStore.updateFlatList(
				partialStore.selectedItem,
				inputs.name.value
			)
		} else {
			const data = {
				id: `folder${new Date().getTime()}`,
				parentId: partialStore.selectedItem || null,
				isParent: true,
				title: name,
				expanded: false,
				balance: null,
				pyBalance: null,
				number: null,
			}
			partialStore.pushItemToFlatList(data)
		}
		partialStore.setAddFSGroupCategoryDialogOpen(false)
	}

	const { inputs, handleOnChange, handleOnSubmit, isReady } = useForm(
		inputsSchema,
		validationSchema,
		() => handleAdd(inputs.name.value),
		open
	)

	let isDuplicated = false
	if (!isEditMode) {
		isDuplicated = partialStore.isDuplicatedFolderName(inputs.name.value)
	} else {
		isDuplicated =
			prevFolderName !== inputs.name.value &&
			partialStore.isDuplicatedFolderName(inputs.name.value)
	}

	const actionStatus = partialStore.getActionStatus(actionName)

	const isLoading = actionStatus === "LOADING"
	return (
		<DLDialog
			eleTestId={"add-fs-group-category-dialog"}
			isOpen={open}
			setIsOpen={partialStore.setAddFSGroupCategoryDialogOpen}
			handleAction={handleOnSubmit}
			showOpenBtn={false}
			showCloseBtn={true}
			dialogTitle={
				isEditMode ? "Edit FS Group Category" : "Add FS Group Category"
			}
			dialogContents={
				<FileFolderDialogForm
					inputs={inputs}
					handleOnChange={handleOnChange}
					isDuplicated={isDuplicated}
				/>
			}
			cancelBtnText={"Cancel"}
			actionReady={
				isReady && !isDuplicated && prevFolderName !== inputs.name.value
			}
			actionBtn={isEditMode ? "Edit" : "Add"}
			maxWidth="sm"
			fullWidth={true}
			showSpinner={isLoading}
			cannotUpdate={isLoading}
			useEnterKeyForSubmit
		/>
	)
})

const FileFolderDialogForm = observer(
	({
		inputs,
		handleOnChange,
		isDuplicated,
	}: {
		inputs: any
		handleOnChange: (props: any) => void
		isDuplicated: boolean
	}) => {
		return (
			<StyledFileFolderForm>
				<div className="input-section FR">
					<InputWithLabel required label={"FS Group Catergory Name"}>
						<DLInputField
							eleTestId="name-input"
							autoFocus
							eleName="name"
							eleValue={inputs.name.value}
							eleHandleChange={handleOnChange}
							eleRequired
							warningMsg={
								isDuplicated
									? "The same name already exist"
									: inputs.name.error
							}
							warningType="orange" // @Noah: this warning type also has impact on the testID. It seems not good. Have to update
							requestInput={inputs.name.requestInput}
							eleFullWidth
						/>
					</InputWithLabel>
				</div>
			</StyledFileFolderForm>
		)
	}
)

const StyledFileFolderForm = styled.div`
	.input-section {
		.label {
			min-width: 9rem;
			height: 4rem;
			/* height: include warning message */
		}
		.input-area {
			min-width: 20rem;
		}
	}
`
