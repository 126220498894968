// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import {
	idToString,
	IdType,
} from "../../../../../../library/converters/id-converter"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 *
 */

const EditReadOnlyUser = (self: any) => ({
	editReadOnlyUser(payload: any, dateWithNoTz: string) {
		// 0.
		const actionName = "updateReadOnlyUser"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.call API
		self.updateReadOnlyUser(payload)
			.then((response: any) => {
				// if success
				if (response.data.Status === 1) {
					const toDate = new Date(dateWithNoTz).toDateString()
					// success case response
					const projectId = idToString(
						payload[0].clientId,
						IdType.project
					)
					const Id = idToString(payload[0].Id, IdType.user)
					const user = self.accessMgmtUserList.find(
						(item: any) => item.id === Id
					)
					const userId = localStorage.getItem("orgUserId")
					if (userId === user.userId) {
						// if (
						// 	new Date(toDate).getTime() <
						// 	new Date(new Date().toDateString()).getTime()
						// ) {
						const toDateObj = new Date(toDate)
						const today = new Date()
						today.setHours(0, 0, 0, 0) // Reset time to midnight
						if (toDateObj.getTime() < today.getTime()) {
							self.updateAccessible(projectId, false, "false")
						} else {
							self.updateAccessible(
								projectId,
								true,
								payload[0].IsExportPerm ? "true" : "false"
							)
						}
					}

					self.updateAccessMgmtUserList(payload[0], dateWithNoTz)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200, // temp
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999, // temp
						color: MessageColorType.orange,
						open: true,
						// autoHide: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default EditReadOnlyUser
