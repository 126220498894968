import React, { useState } from "react"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import { observer } from "mobx-react-lite"
//
import {
	ConsoleLog,
	DLButton,
	DLContextMenuWithButton,
	DLIconButton,
	DLRadio,
} from "../../../../../components/basic-elements"
//
import Icon from "@mdi/react"
import DLDateRangepicker from "../../../../../components/basic-elements/date-picker/DLDateRangePicker"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import ReactTableV8 from "../../../../../components/basic-elements/tables/DLReactTable"
import { Table, Column } from "@tanstack/react-table"
import { InputFieldForTableV8 } from "../../../../../components/basic-elements/tables/InputFieldForTable"
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { mdiDotsHorizontal, mdiMenuDown, mdiPlus } from "@mdi/js"
import { WORKING_TRIAL_BALANCE_TABS } from "../store/data-models/fs-working-trial-balance.data-model"
import { StyledHeader } from "../../../../organization-side/org-setup/org-setup-users/sub-components/OrgSetupUserListTable"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"

export default observer(function AccountTable({
	partialStore,
	permission,
}: {
	partialStore: any
	permission: PermissionAsObjectProps
}) {
	ConsoleLog(" __________// AccountTable //__________ ")

	const store = useRootStore()
	const projStore = useProjStore()

	const handleContextMenu = (event: any, id: number) => {
		event.preventDefault()
		partialStore.setClickPoint({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
		})
		partialStore.setSelectedItem(id)
	}

	const dateFormat = store.global.dateFormat.value

	const selectedItem = partialStore.selectedItem

	console.log(selectedItem, "selectedItem")

	const handleCheckbox = (id: string) => {
		partialStore.setSelectedItem(id)
	}

	const pageHeader = (instance: Table<any>) => {
		return (
			<StyledHeader>
				<div>
					{permission.create && (
						<DLButton
							variant="contained"
							size="regular"
							eleClassName="top-row-btns"
							eleTestId="add-btn"
							startIcon={<Icon path={mdiPlus} size={1} />}
							clickHandler={() => {}}
							color="primary"
						>
							Add
						</DLButton>
					)}
				</div>
				<div className="FR mt-10">
					<DLContextMenuWithButton
						eleTestId="folders-btn"
						menuOptions={[
							{
								label: `Link Map No to Account`,
								value: "ctx-link-mapNo-to-account",
								available: permission.update,
								disabled: !selectedItem,
								clickEvent: () =>
									partialStore.setLinkMapNoToAccountDialogOpen(
										true
									),
							},
						]}
						useScroll={false}
						showButton={true}
						showIcon={false}
						btnText="Item Actions"
						btnVariant="outlined"
						btnColor="primary"
						btnSize="regular"
						btnClassName="ctx-menu-styles"
						eleIcon={<Icon path={mdiMenuDown} size={1} />}
					/>
				</div>
			</StyledHeader>
		)
	}

	console.log(
		JSON.parse(JSON.stringify(partialStore.viewAccounts())),
		"viewAccounts"
	)

	return (
		<ReactTableV8
			tableColumns={columns(
				selectedItem,
				handleCheckbox,
				handleContextMenu
			)}
			data={projStore.fsWorkingTrialBalance.viewAccounts()}
			hasPagination={true}
			customFilters={(props: any) =>
				filterComponent({ ...props, dateFormat })
			}
			handleContextMenu={(e: any, row: any) =>
				handleContextMenu(e, row.id)
			}
			pageHeader={pageHeader}
			height={store.ui.contentsAreaHeight - 120}
			permission={permission}
			showNewReorderDialog={true}
			menuId={`${DLProjSubMenus.working_trial_balance}-Account`}
		/>
	)
})

const filterComponent = ({
	column,
	table,
	dateFormat,
}: {
	column: Column<any>
	table: Table<any>
	dateFormat: string
}) => {
	switch (column.id) {
		case "createdAt":
			const filterValues: any = column.getFilterValue()
			return (
				<DLDateRangepicker
					selectedStartDate={filterValues?.startDate}
					selectedEndDate={filterValues?.endDate}
					eleStartName="startDate"
					eleEndName="endDate"
					onChange={(value: any, name: string) => {
						column.setFilterValue({
							...filterValues,
							[name]: value,
						})
					}}
					format={dateFormat}
				/>
			)

		case "actions":
		case "id":
			return

		default:
			return (
				<InputFieldForTableV8
					columnId={column.id}
					onChange={column.setFilterValue}
					value={column.getFilterValue()}
				/>
			)
	}
}

const columns = (
	selectedItem: string,
	handleCheckbox: (id: string) => void,
	handleContextMenu: any
) => {
	return [
		{
			header: "Select",
			accessorKey: "id",
			size: 30,
			cell: (props: any) => {
				const id = props.row.original.id
				return (
					<div style={{ width: "100%", textAlign: "center" }}>
						<DLRadio
							eleTestId={id + "-radio"}
							checked={id === selectedItem}
							onChange={() => handleCheckbox(id)}
							color="primary"
						/>
					</div>
				)
			},
		},
		{
			header: "Actions",
			accessorKey: "actions",
			cell: (props: any) => {
				const id = props.row.original.id
				return (
					<div className="FR AC JC" style={{ width: "100%" }}>
						<DLIconButton
							aria-label="more"
							clickHandler={(event: any) =>
								handleContextMenu(event, id)
							}
							eleTestId="act-btn-on-row"
						>
							<Icon path={mdiDotsHorizontal} size={0.8} />
						</DLIconButton>
					</div>
				)
			},
		},
		{
			header: "Entity",
			accessorKey: "entity",
		},
		{
			header: "Account No",
			accessorKey: "accountNo",
		},
		{
			header: "Name",
			accessorKey: "name",
		},
		{
			header: "Lock",
			accessorKey: "lock",
		},
		{
			header: "Map No",
			accessorKey: "mapNo",
		},
		{
			header: "Type",
			accessorKey: "type",
		},
		{
			header: "Sign",
			accessorKey: "sign",
		},
		{
			header: "L/S",
			accessorKey: "ls",
		},
		{
			header: "Tax Export Code",
			accessorKey: "taxExportCode",
		},
		{
			header: "M3 Code (USA only)",
			accessorKey: "m3Code",
		},
		{
			header: "GIFI (Canada only)",
			accessorKey: "gifi",
		},
		{
			header: "Tax Entity",
			accessorKey: "taxEntity",
		},
		{
			header: "Class",
			accessorKey: "class",
		},
		{
			header: "Opening Balance",
			accessorKey: "openingBalance",
		},
		{
			header: "Transactions",
			accessorKey: "transactions",
		},
		{
			header: "Adjustments",
			accessorKey: "adjustments",
		},
		{
			header: "Final",
			accessorKey: "final",
		},
		{
			header: "PY1",
			accessorKey: "py1",
		},
		{
			header: "PY2",
			accessorKey: "py2",
		},
		{
			header: "PY3",
			accessorKey: "py3",
		},
		{
			header: "PY4",
			accessorKey: "py4",
		},
		{
			header: "BY1",
			accessorKey: "by1",
		},
		{
			header: "BY2",
			accessorKey: "by2",
		},
		{
			header: "BY3",
			accessorKey: "by3",
		},
		{
			header: "BY4",
			accessorKey: "by4",
		},
		{
			header: "BY5",
			accessorKey: "by5",
		},
		{
			header: "Annotation",
			accessorKey: "annotation",
		},
		{
			header: "Tax Code",
			accessorKey: "taxCode",
		},
		{
			header: "Map No Description",
			accessorKey: "mapNoDesc",
		},
		{
			header: "Map No (Flip)",
			accessorKey: "mapNoFlip",
		},
		{
			header: "Map No (Flip) Description",
			accessorKey: "mapNoFlipDesc",
		},
		{
			header: "L/S (Flip)",
			accessorKey: "lsFlip",
		},
		{
			header: "Group 2",
			accessorKey: "group2",
		},
		{
			header: "Group 2 (Flip)",
			accessorKey: "group2Flip",
		},
		{
			header: "Group 3",
			accessorKey: "group3",
		},
		{
			header: "Group 3 (Flip)",
			accessorKey: "group3Flip",
		},
		{
			header: "Group 4",
			accessorKey: "group4",
		},
		{
			header: "Group 4 (Flip)",
			accessorKey: "group4Flip",
		},
		{
			header: "Group 5",
			accessorKey: "group5",
		},
		{
			header: "Group 5 (Flip)",
			accessorKey: "group5Flip",
		},
		{
			header: "Group 6",
			accessorKey: "group6",
		},
		{
			header: "Group 6 (Flip)",
			accessorKey: "group6Flip",
		},
		{
			header: "Group 7",
			accessorKey: "group7",
		},
		{
			header: "Group 7 (Flip)",
			accessorKey: "group7Flip",
		},
		{
			header: "Group 8",
			accessorKey: "group8",
		},
		{
			header: "Group 8 (Flip)",
			accessorKey: "group8Flip",
		},
		{
			header: "Group 9",
			accessorKey: "group9",
		},
		{
			header: "Group 9 (Flip)",
			accessorKey: "group9Flip",
		},
		{
			header: "Group 10",
			accessorKey: "group10",
		},
		{
			header: "Group 10 (Flip)",
			accessorKey: "group10Flip",
		},
		{
			header: "Forecast Year 1",
			accessorKey: "forecastYear1",
		},
		{
			header: "Forecast Year 2",
			accessorKey: "forecastYear2",
		},
		{
			header: "Forecast Year 3",
			accessorKey: "forecastYear3",
		},
		{
			header: "Forecast Year 4",
			accessorKey: "forecastYear4",
		},
		{
			header: "Forecast Year 5",
			accessorKey: "forecastYear5",
		},
		{
			header: "Baseline",
			accessorKey: "baseline",
		},
		{
			header: "CS (Credit Statement of Cash Flow)",
			accessorKey: "cs",
		},
		{
			header: "DS (Debit Statement of Cash Flow)",
			accessorKey: "ds",
		},
	]
}
