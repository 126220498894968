import { flow } from "mobx-state-tree"

const ManageProjDataMgmtAccessType = (self: any, update: any) => ({
	manageProjDataMgmtAccessType: flow(function* (params) {
		const actionName = "manageProjDataMgmtAccess"
		try {
			const response = yield update(params)

			return response
		} catch (error) {
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default ManageProjDataMgmtAccessType
