import { types } from "mobx-state-tree"
import {
	ClickPoint,
	ClickPointProps,
} from "../../../../../common-models/types/dialog.props"

const ProjDownloadCenterUiHelper = types
	.model({
		clickPoint: ClickPoint,
		selectedItem: types.number,
	})
	.actions((self) => ({
		setClickPoint(clickPoint: ClickPointProps) {
			self.clickPoint = clickPoint
		},
		setSelectedItem(selectedItem: number) {
			self.selectedItem = selectedItem
		},
	}))

export default ProjDownloadCenterUiHelper
