import { id } from "date-fns/locale"

export const HandleSelectedUser = (self: any) => ({
	setSelectedUserId(userId: string) {
		self.selectedUser = { id: userId }
	},
	setSelectedUser(user: any) {
		self.selectedUser = user
	},
	setSelectedUsers(users: any) {
		self.selectedUsers = users
	},
	getUserInfoById(userId: string) {
		return self.orgUserList.find((user: any) => user.id === userId)
	},
	// reset
	resetSelectedUser() {
		self.selectedUser = {
			id: "",
		}
	},
	resetSelectedUserId() {
		self.selectedUser = {
			id: "",
		}
	},
})

export const ViewSelectedUser = (self: any) => ({
	viewSelectedUserInfo() {
		const selectedUserId = self.selectedUser.id
		const selectedUserInfo = self.orgUserList.find(
			(user: any) => user.id === selectedUserId
		)

		return selectedUserInfo
	},
	viewSelectedUserId() {
		return self.selectedUser.id
	},
})
