import { types } from "mobx-state-tree"

export const FinancialGroupModel = types.model({
	fsGroupnumber: types.string,
	title: types.string,
	behaviour: types.string,
	name: types.string,
	type: types.string,
	normalSign: types.string,
	class: types.string,
	calculation: types.string,
	flipType: types.string,
	lsGroupNoFlip: types.string,
	formatting: types.string,
})
