import { types, applySnapshot } from "mobx-state-tree"
// ---------- common models
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"
// ---------- view model actions
import {
	GetUnarchivedProjects,
	RemoveAMUnarchivedProject,
} from "./view-model-actions"
import { initialStore } from "./am-unarchived-projects.provider"
import ProjectTableFilterViews from "../../../../../service-modules/project-module/view-model-views/table-filter-views"
import { DLProjectModel } from "../../../../../service-modules/project-module/data-model/project-module-data-models"
import { ProjectStatus } from "../../../../../common-models/enumerations/project-related-enums"
import {
	formattedDateWithoutTzLabel,
	formattedDateWithTz2,
	getTzDate,
} from "../../../../../library/converters/date-utc-converter"
import { DLOrgSubMenus } from "../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { DnTFormatProps } from "../../../../../common-models/types/common-props"
import { ProjScreenType } from "../../../../../components/combined-elements/projects-table/projects-table-props"
import GetCabinetLocation from "../../../../project-side/project-information/store/view-model-actions/get-cabinet-location"
import { CabinetDetails } from "../../../../project-side/project-information/store/data-models/proj-info.data-model"

const AmUnarchivedProjectsViewModel = types
	.model({
		storeName: types.enumeration<DLOrgSubMenus>(
			Object.values(DLOrgSubMenus)
		),
		//
		amUnarchivedProjectList: types.array(DLProjectModel),
		cabinetLocationDetails: types.array(CabinetDetails),
		//
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		pushItemToList(item: any) {
			self.amUnarchivedProjectList.push(item)
		},
		resetStoredList() {
			self.amUnarchivedProjectList.length = 0
		},
		deleteUnarchivedProject(clientId: string) {
			self.amUnarchivedProjectList.splice(
				self.amUnarchivedProjectList.findIndex(
					(item: any) => item.id === clientId
				),
				1
			)
		},
		setCabinetLocationDetails(details: any) {
			self.cabinetLocationDetails.length = 0
			self.cabinetLocationDetails = details
		},
	}))
	.views((self) => ({
		viewSelectedProjInfo(selectedProj: string, projStatus: ProjectStatus) {
			const selectedProjInfo = self.amUnarchivedProjectList.find(
				(project: any) => project.id === selectedProj
			)

			return selectedProjInfo
		},
		getProjLengthOfYear(year: string) {
			if (year === "All Years") {
				return self.amUnarchivedProjectList.length
			}
			return self.amUnarchivedProjectList.filter(
				(project: any) => project.financialYear === parseInt(year)
			).length
		},
		formattedProjList(
			dntFormat: DnTFormatProps,
			projScreenType: ProjScreenType
		) {
			const { dateFormat, timeFormat, timeZone } = dntFormat
			// Copy default list from the store
			const formatUpdatedList = [...self.amUnarchivedProjectList]

			function formatDate(date: string | null) {
				if (!date) return "-"
				return getTzDate({
					date,
					timeZone,
					dateFormat,
				})
			}

			function formatTime(date: string | null) {
				if (date) {
					return formattedDateWithTz2({
						date,
						timeZone,
						timeFormat,
						dateFormat,
					})
				}
				return ""
			}

			function formatDateWithTime(date: string | null) {
				if (date) {
					return formattedDateWithoutTzLabel({
						date,
						dntFormat,
					})
				}
				return ""
			}

			let formattedList: any[] = []
			formatUpdatedList.map((project: any) => {
				const formattedPeriodEndDate = formatDate(project.periodEndDate)
				const formattedUnarchivedDate = formatDateWithTime(
					project.unarchivedAt
				)
				const formattedFinalReportDate = formatDate(
					project.finalReportDate
				)
				const formattedExpectedReportDate = formatDate(
					project.expectedReportDate
				)
				const formattedExpectedArchiveDate = formatDate(
					project.expectedArchiveDate
				)
				const formattedFinalArchiveDeadlineDate = formatDate(
					project.finalArchiveDeadlineDate
				)
				const formattedLastAccess = formatTime(project.lastAccessAt)
				const formattedCreatedAt = formatDateWithTime(project.createdAt)

				// Replace date part
				const formattedProject = {
					...project,
					periodEndDate: formattedPeriodEndDate,
					finalReportDate: formattedFinalReportDate,
					expectedReportDate: formattedExpectedReportDate,
					finalArchiveDeadlineDate: formattedFinalArchiveDeadlineDate,
					expectedArchiveDate: formattedExpectedArchiveDate,
					lastAccessDate: formattedLastAccess
						? formattedLastAccess.date
						: "",
					lastAccessTime: formattedLastAccess
						? formattedLastAccess.time
						: "",
					unarchivedAt: formattedUnarchivedDate,
					createdAt: formattedCreatedAt,
				}
				formattedList.push(formattedProject)
			})
			return formattedList
		},
	}))
	.actions(GetUnarchivedProjects)
	.actions(RemoveAMUnarchivedProject)
	.actions(GetCabinetLocation)
	// Filter related views
	.views(ProjectTableFilterViews)
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default AmUnarchivedProjectsViewModel
