import { types } from "mobx-state-tree"
import ProjCheckinModel from "./proj-checkin.model"
import ProjCheckinViewModel from "./proj-checkin.view-model"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import { ProjCheckinApiProps } from "./proj-checkin.apis"
import * as api from "./proj-checkin.apis"
import { initialRoleSet2 } from "../../../../service-modules/role-module/roles.data-model"
import { initialCurrentUserOnProj } from "./data-model/current-user-on-proj.data-model"

export const ProjCheckinStore = ({
	apiRequestToken,
	apiReadProjInfo,
	apiReadProjSettings,
	apiReadCurrentUserOnProj,
}: ProjCheckinApiProps) =>
	types
		.compose(
			ProjCheckinModel({
				apiRequestToken,
				apiReadProjInfo,
				apiReadProjSettings,
				apiReadCurrentUserOnProj,
			}),
			ProjCheckinViewModel
		)
		.named("ProjCheckinStore")

export const initialStore = {
	/**
	 * //// 1. token
	 * 2. project default info
	 * 3. time & locale
	 * 4. role set & permissions
	 * 5. avialable menus
	 * 6. checkin
	 */

	user: {},
	currentUserOnProj: initialCurrentUserOnProj,
	client: {},
	roleSet: initialRoleSet2,
	// 4. role set & permissions
	assignedRole: {
		roleId: "",
		usePeriod: {},
		globalPermissions: [],
		menuPermissions: [],
		//
		name: "",
		shortName: "",
		color: "",
	},
	// 5. available menus
	accessibleTreeMenus: [],
	// 6. checkin
	projCheckin: ActionStatus.standby,
	//
	//
	//
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const ProjCheckinStoreInstance = ProjCheckinStore({
	apiRequestToken: api.requestToken,
	apiReadProjInfo: api.readProjInfo,
	apiReadProjSettings: api.readProjSettings,
	apiReadCurrentUserOnProj: api.readCurrentUserOnProj,
}).create(initialStore)

export default ProjCheckinStoreInstance
