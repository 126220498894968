import React, { useEffect, useState } from "react"
import { PageContainer } from "../../../../components/app-frame-elements"
import { ActionStatus } from "@datalobby/types"
import styled from "styled-components"
import { Tab, Tabs } from "@material-ui/core"
import { Table, Column } from "@tanstack/react-table"
import ReactTableV8 from "../../../../components/basic-elements/tables/DLReactTable"

import { DLButton } from "../../../../components/basic-elements"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { InputFieldForTableV8 } from "../../../../components/basic-elements/tables/InputFieldForTable"
import FsSetupFsGroupsCategoryTable from "./sub-components/FsSetupFsGroupsCategoryTable"
import { observer } from "mobx-react-lite"
import FsSetupFsGroupsCategoryTableMenus from "./sub-components/FsSetupFsGroupsCategoryTableMenus"
import AddFsGroupsCategoryDialog from "./sub-components/AddFsGroupsCategoryDialog"
import EditFsGroupsCategoryDialog from "./sub-components/EditFsGroupsCategoryDialog"

const tabs = [
	{ label: "L/S", value: "ls" },
	{ label: "Group 2", value: "group2" },
	{ label: "Group 3", value: "group3" },
	{ label: "Group 4", value: "group4" },
	{ label: "Group 5", value: "group5" },
	{ label: "Group 6", value: "group6" },
	{ label: "Group 7", value: "group7" },
	{ label: "Group 8", value: "group7" },
	{ label: "Group 9", value: "group9" },
	{ label: "Group 10", value: "group10" },
]
export default observer(function FsSetupFsGroupsCategory({
	partialStore,
	actionStatus,
	permission,
}: {
	partialStore: any
	actionStatus: any
	permission: any
}) {
	const store = useRootStore()
	const [status, setStatus] = useState(0)

	const handleStatus = (event: any, value: any) => {
		setStatus(value)
	}

	useEffect(() => {
		partialStore.getSetupFSGroupsCategory()
	}, [status])

	return (
		<PageContainer
			hasToolbar={false}
			pageTools={<div />}
			fullWidth
			isLoading={actionStatus === ActionStatus.loading}
		>
			<StyledNewMapNumber>
				<Tabs
					value={status}
					onChange={handleStatus}
					indicatorColor="primary"
					textColor="primary"
					// centered
					className="setup-groups-tabs"
				>
					{tabs.map((item: any, index: number) => {
						return (
							<Tab
								label={item.label}
								data-testid={`${item.value}-tab`}
								className={
									"type-tab " +
									(status === index ? "selected" : "")
								}
							/>
						)
					})}
				</Tabs>

				<FsSetupFsGroupsCategoryTable
					partialStore={partialStore}
					permission={permission}
				/>
				{partialStore.clickPoint.mouseX && (
					<FsSetupFsGroupsCategoryTableMenus
						permission={permission}
						partialStore={partialStore}
					/>
				)}

				{partialStore.addDialogOpen && (
					<AddFsGroupsCategoryDialog partialStore={partialStore} />
				)}
				{partialStore.editDialogOpen && (
					<EditFsGroupsCategoryDialog partialStore={partialStore} />
				)}
			</StyledNewMapNumber>
		</PageContainer>
	)
})

const StyledNewMapNumber = styled.div`
	padding-left: 1rem;
	padding-right: 1rem;
	.setup-groups-tabs {
		min-height: 2rem;
		.type-tab {
			min-width: calc(100% / 10);
			border-top: 1px solid transparent;
			border-right: 1px solid transparent;
			border-left: 1px solid transparent;
			border-bottom: 1px solid ${(props) => props.theme.emphasis};
			min-height: 2rem;
			border-top-right-radius: 3px;
			border-top-left-radius: 3px;
			background-color: ${(props) => props.theme.shade05};
			font-size: 0.75rem;
			overflow: hidden;
			color: ${(props) => props.theme.textMediumEmphasis};
			&.selected {
				color: ${(props) => props.theme.emphasis};
				font-weight: ${(props) => props.theme.shared.fontBold};
				border-top: 1px solid ${(props) => props.theme.emphasis};
				border-right: 1px solid ${(props) => props.theme.emphasis};
				border-left: 1px solid ${(props) => props.theme.emphasis};
				border-bottom: 1px solid transparent;
				background-color: ${(props) => props.theme.background};
			}
		}
		span {
			/* for the border bottom highlighter */
			background-color: transparent;
		}
	}
`
