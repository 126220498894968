import { flow } from "mobx-state-tree"
import { ProjDownloadCenterListApiProps } from "../proj-download-center-list.apis"

const ReadProjDownloadCenterList = (
	self: any,
	apiRead: ProjDownloadCenterListApiProps["apiReadProjDownloadCenterList"]
) => ({
	readProjDownloadCenterList: flow(function* (params: any) {
		const actionName = "readProjDownloadCenterList"
		try {
			const response = yield apiRead(params)
			return response
		} catch (error) {
			self.handleModelError({ actionName, error, openSnackbar: true })
			return false
		}
	}),
})

export default ReadProjDownloadCenterList
