import { types } from "mobx-state-tree"

export const FSAccounts = types.model({
	id: types.string,
	entity: types.string,
	accountNo: types.number,
	name: types.string,
	lock: types.boolean,
	mapNo: types.string,
	type: types.string,
	sign: types.string,
	ls: types.string,
	taxExportCode: types.string,
	m3Code: types.string,
	gifi: types.string,
	taxEntity: types.string,
	class: types.string,
	openingBalance: types.string,
	transactions: types.string,
	adjustments: types.string,
	final: types.string,
	py1: types.string,
	py2: types.string,
	py3: types.string,
	py4: types.string,
	by1: types.string,
	by2: types.string,
	by3: types.string,
	by4: types.string,
	by5: types.string,
	annotation: types.string,
	taxCode: types.string,
	mapNoDesc: types.string,
	mapNoFlip: types.string,
	mapNoFlipDesc: types.string,
	lsFlip: types.string,
	group2: types.string,
	group2Flip: types.string,
	group3: types.string,
	group3Flip: types.string,
	group4: types.string,
	group4Flip: types.string,
	group5: types.string,
	group5Flip: types.string,
	group6: types.string,
	group6Flip: types.string,
	group7: types.string,
	group7Flip: types.string,
	group8: types.string,
	group8Flip: types.string,
	group9: types.string,
	group9Flip: types.string,
	group10: types.string,
	group10Flip: types.string,
	forecastYear1: types.string,
	forecastYear2: types.string,
	forecastYear3: types.string,
	forecastYear4: types.string,
	forecastYear5: types.string,
	baseline: types.string,
	cs: types.string,
	ds: types.string,
})

export enum WORKING_TRIAL_BALANCE_TABS {
	ACCOUNT = 0,
	REPORT = 1,
	CASH_FLOW = 2,
	GROUPING = 3,
	FOREIGN_EXCHANGE = 4,
	PERFORMANCE_MEASURES = 5,
}

export const MapNumbersModel = types.model({
	mapNo: types.string,
	mapNoDesc: types.string,
})
