import React, { useState } from "react"
import { PageContainer } from "../../../../components/app-frame-elements"
import { ActionStatus } from "@datalobby/types"
import styled from "styled-components"
import { DLButton, DLDialog } from "../../../../components/basic-elements"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { Button, TextField } from "@material-ui/core"

import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { observer } from "mobx-react-lite"

export default observer(function M_CompletingExcelImport({
	actionStatus,
	permission,
}: {
	actionStatus: any
	permission: any
}) {
	const store = useRootStore()

	const [filePath, setFilePath] = useState("")
	const [isOpen, setIsOpen] = useState(false)

	const handleSelectFilesFromLocal = (e: any) => {
		const localFile = e.target.files[0]
		setFilePath(localFile.name)
	}

	return (
		<PageContainer
			hasToolbar={false}
			pageTools={<div />}
			fullWidth
			isLoading={actionStatus === ActionStatus.loading}
		>
			<StyledPage>
				<h3>Completing Excel Import Wizard</h3>

				<div className="FC" style={{ marginTop: 15 }}>
					<div className="FC" style={{ gap: 10 }}>
						<div>
							You have successfully completed the Excel Import
							Wizard.
						</div>
						<div>
							Press Import to import the contents of the Excel
							file.
						</div>
					</div>
					<div style={{ marginTop: 15 }}>
						<h4>Save Layout Settings</h4>
						<div style={{ marginTop: 10 }}>
							This option allows you to save the layout and
							settings file type, column selection, and
							assignments, etc) you have just specified for the
							input file in order to reuse the same settings for
							another input file which uses the same layout
						</div>
						<div
							className="FR AC"
							style={{ gap: 10, marginTop: 10 }}
						>
							Existing File:
							<TextField
								variant="outlined"
								size="small"
								value={filePath}
								onChange={(e) => setFilePath(e.target.value)}
								style={{ marginLeft: 8 }}
							/>
							<Button
								variant="contained"
								color="primary"
								component="label"
							>
								Browse
								<input
									type="file"
									hidden
									onChange={handleSelectFilesFromLocal}
								/>
							</Button>
						</div>
					</div>

					<div className="FR" style={{ marginTop: 20, gap: 10 }}>
						<DLButton
							variant="contained"
							eleTestId="cancel"
							clickHandler={() => setIsOpen(true)}
							color="primary"
							disabled={!filePath}
						>
							Import
						</DLButton>
						<DLButton
							variant="contained"
							eleTestId="cancel"
							clickHandler={() => {}}
							color="primary"
						>
							Cancel
						</DLButton>
					</div>
				</div>
				{isOpen && (
					<ConfirmDialog
						filePath={filePath}
						isOpen={isOpen}
						setIsOpen={setIsOpen}
					/>
				)}
			</StyledPage>
		</PageContainer>
	)
})

const StyledPage = styled.div`
	padding-left: 1rem;
	padding-right: 1rem;
`

function ConfirmDialog({
	filePath,
	isOpen,
	setIsOpen,
}: {
	filePath: string
	isOpen: boolean
	setIsOpen: any
}) {
	const handleAction = () => {}

	return (
		<DLDialog
			eleTestId={`confirm-dialog`}
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			handleAction={handleAction}
			showCloseBtn={true}
			dialogTitle={`Confirm`}
			dialogContents={
				<div className="FC" style={{ gap: 10 }}>
					<div>{filePath}</div>
					<div>This file already exist</div>
					<div>Replace existing file?</div>
				</div>
			}
			cancelBtnText={"Cancel"}
			actionBtn={
				<div className="FR JR" style={{ width: "100%", gap: 10 }}>
					<DLButton
						eleTestId="yes-btn"
						color="primary"
						clickHandler={() => {}}
					>
						Yes
					</DLButton>
					<DLButton
						eleTestId="no-btn"
						color="primary"
						clickHandler={() => setIsOpen(false)}
					>
						No
					</DLButton>
				</div>
			}
			maxWidth="sm"
			fullWidth={true}
			actionReady={true}
		/>
	)
}
