import { types } from "mobx-state-tree"

export const MapNumbersModel = types.model({
	mapNumber: types.string,
	title: types.string,
	behaviour: types.string,
	name: types.string,
	type: types.string,
	normalSign: types.string,
	class: types.string,
	calculation: types.string,
	flipType: types.string,
	mapNoFlip: types.string,
	units: types.string,
	gifiCanadaOnly: types.string,
	ls: types.string,
	lsFlip: types.string,
	group2to10: types.string,
	group2to10Flip: types.string,
	showCurrencySymbol: types.string,
	startNewPage: types.string,
	columnPosition: types.string,
	underline: types.string,
	printFeatures: types.string,
	accumultorTotal: types.string,
	cashFlowCredits: types.string,
	cashFlowDebits: types.string,
	taxEntity: types.string,
	m3TaxEntity: types.string,
	taxExportCode: types.string,
	m3Code: types.string,
})
