import React, { useState } from "react"
import { PageContainer } from "../../../../components/app-frame-elements"
import { ActionStatus } from "@datalobby/types"
import styled from "styled-components"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import FsListOfCounterpartiesTable from "./sub-components/FsListOfCounterpartiesTable"
import FsListOfCounterpartiesCtxMenu from "./sub-components/FsListOfCounterpartiesCtxMenu"
import { observer } from "mobx-react-lite"

export default observer(function FsListOfCounterparties({
	partialStore,
	permission,
	actionStatus,
}: {
	partialStore: any
	permission: PermissionAsObjectProps
	actionStatus: any
}) {
	return (
		<PageContainer
			hasToolbar={false}
			pageTools={<div />}
			fullWidth
			isLoading={actionStatus === ActionStatus.loading}
		>
			<StyledFsListOfCounterparties>
				<FsListOfCounterpartiesTable
					partialStore={partialStore}
					permission={permission}
				/>
			</StyledFsListOfCounterparties>
			{partialStore.clickPoint.mouseX && (
				<FsListOfCounterpartiesCtxMenu
					permission={permission}
					partialStore={partialStore}
				/>
			)}
		</PageContainer>
	)
})

const StyledFsListOfCounterparties = styled.div`
	padding-left: 1rem;
	padding-right: 1rem;
`
