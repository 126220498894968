import { types } from "mobx-state-tree"
import FinancialGroupPresentationViewModel from "./financial-group-presentation.view-model"
import FinancialGroupPresentationModel from "./financial-group-presentation.model"

export const FinancialGroupPresentationStore = () =>
	types
		.compose(
			FinancialGroupPresentationModel(),
			FinancialGroupPresentationViewModel
		)
		.named("FinancialGroupPresentationStore")

export const initialStore = {
	clickPoint: {
		mouseX: null,
		mouseY: null,
	},
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const FinancialGroupPresentationStoreInstance =
	FinancialGroupPresentationStore().create(initialStore)

export default FinancialGroupPresentationStoreInstance
