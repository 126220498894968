import React, { useMemo } from "react"
import { observer } from "mobx-react-lite"
import {
	DeleteIcon,
	DownloadIcon,
} from "../../../../components/basic-elements/icons/common-icons"
import { DLContextMenu } from "../../../../components/basic-elements"
import { DLI18nProps } from "../../../../common-models/types/common-props"

export default observer(function MySummaryCtxMenus({
	partialStore,
	i18n,
}: {
	partialStore: any
	i18n: DLI18nProps
}) {
	const { mouseX, mouseY } = partialStore.clickPoint
	const selectedProjId = partialStore.selectedProj
	const selectedProjInfo = partialStore.viewSelectedProjInfo(selectedProjId)

	// Memoized menu options to prevent unnecessary re-computation
	const options = useMemo(
		() => [
			{
				label: i18n.twDownload || "Download",
				value: "download",
				available: true,
				disabled: false,
				clickEvent: () => alert("Download is coming soon!"),
				icon: <DownloadIcon />,
			},
			{
				label: i18n.twDelete || "Delete",
				value: "delete",
				available: true,
				disabled: false,
				clickEvent: () => alert("Delete is coming soon!"),
				icon: <DeleteIcon />,
			},
		],
		[
			selectedProjId,
			selectedProjInfo?.isDeleted,
			selectedProjInfo?.isDownloadable,
		]
	)

	const handleClose = () =>
		partialStore.setClickPoint({ mouseX: null, mouseY: null })

	return (
		<DLContextMenu
			eleId="proj-download-center-ctx-menus"
			clickPoint={{ mouseX, mouseY }}
			handleClose={handleClose}
			menuOptions={options}
		/>
	)
})
