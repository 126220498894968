import { types } from "mobx-state-tree"

export const OrgListItem = types.model({
	// TODO: API configuration is required
	// about organization
	orgId: "",
	orgName: "",
	// about the user
	userId: "",
	userAliasId: "",
	userName: "",
	userTitle: "",
	email: "",
	isExternalUser: false,
	// locale
	countryCode: "", // sometimes, countryCode is undefined. Take care on the viewModel. Do not allow it on the data-model itself
	language: "",
	// assign info
	assignedBy: "", // ex. "Anusha [Anu]"
	assignedAt: "", // ex."11/22/2018"
	// access type
	accessType: "", // ex. "Super Admin"
	isQcUser: false,
	isSuperAdmin: false,
	isArchiveAdmin: false,
	isOrgProjectDataMgmtAdmin: false,
	hasCustomerViewAccess: false,
	// custom added
	orgRoleId: "",
})
