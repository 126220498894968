import { types } from "mobx-state-tree"

import OrgUsersModel from "./org-setup-users.model"
import OrgSetupUsersUiHelper from "./org-setup-users.ui-helper"
import OrgUsersViewModel from "./org-setup-users.view-model"

import * as api from "./org-setup-users.apis"
import { OrgSetupUserApiProps } from "./org-setup-users.apis"
import { ActionStatus } from "../../../common-models/enumerations/common-enums"

export const OrgSetupUsersStore = ({
	apiRead,
	apiCreate,
	apiUpdate,
	apiRestore,
	apiDelete,
}: OrgSetupUserApiProps) =>
	types
		.compose(
			OrgUsersModel({
				apiRead,
				apiCreate,
				apiUpdate,
				apiRestore,
				apiDelete,
			}),
			OrgUsersViewModel,
			OrgSetupUsersUiHelper
		)
		.named("OrgSetupUsersStore")

export const initialStore = {
	totalUser: 0,
	latestPageIndex: 0,
	bulkUserFileReadStatus: ActionStatus.standby,
	// ui-helper
	selectedUser: { id: "" },
	selectedUsers: [],
	clickPoint: {
		mouseX: null,
		mouseY: null,
	},
	// ui-helper > dialogs
	addDialogOpen: false,
	editDialogOpen: false,
	suspendDialogOpen: false,
	restoreDialogOpen: false,
	removeDialogOpen: false,
	orgNotificationPermissionDialogOpen: false,
	// common parts
	// viewModel related parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const OrgSetupUsersStoreInstance = OrgSetupUsersStore({
	apiRead: api.read,
	apiCreate: api.create,
	apiUpdate: api.update,
	apiRestore: api.restore,
	apiDelete: api.deleteUser,
}).create(initialStore)

export default OrgSetupUsersStoreInstance
