import React, { useMemo } from "react"
import { observer } from "mobx-react-lite"
import {
	DeleteIcon,
	DownloadIcon,
} from "../../../../../components/basic-elements/icons/common-icons"
import { DLContextMenu } from "../../../../../components/basic-elements"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"

export default observer(function ProjDownloadCenterCtxMenus({
	i18n,
}: {
	i18n: DLI18nProps
}) {
	const projStore = useProjStore()
	const { projDownloadCenter, checkin } = projStore
	const { mouseX, mouseY } = projDownloadCenter.clickPoint
	const { projDataMgmtAccessPermissionId } = checkin.currentUserOnProj
	const selectedItem = projDownloadCenter.selectedItem
	const selectedItemInfo =
		projDownloadCenter.viewSelectedItemInfo(selectedItem)

	// Memoized menu options to prevent unnecessary re-computation
	const options = useMemo(
		() => [
			{
				label: i18n.twDownload || "Download",
				value: "download",
				available: projDataMgmtAccessPermissionId >= 2,
				disabled:
					projDataMgmtAccessPermissionId < 2 ||
					!selectedItemInfo?.isDownloadable,
				clickEvent: () =>
					projDownloadCenter.getProjDataMgmtZipFileById(selectedItem),
				icon: <DownloadIcon />,
			},
			{
				label: i18n.twDelete || "Delete",
				value: "delete",
				available: projDataMgmtAccessPermissionId === 3,
				disabled:
					projDataMgmtAccessPermissionId !== 3 ||
					selectedItemInfo?.isDeleted,
				clickEvent: () =>
					projDownloadCenter.removeProjDataMgmtZipFileById(
						selectedItem
					),
				icon: <DeleteIcon />,
			},
		],
		[
			projDataMgmtAccessPermissionId,
			selectedItem,
			selectedItemInfo?.isDeleted,
			selectedItemInfo?.isDownloadable,
		]
	)

	const handleClose = () =>
		projDownloadCenter.setClickPoint({ mouseX: null, mouseY: null })

	return (
		<DLContextMenu
			eleId="proj-download-center-ctx-menus"
			clickPoint={{ mouseX, mouseY }}
			handleClose={handleClose}
			menuOptions={options}
		/>
	)
})
