import { flow } from "mobx-state-tree"
import { ProjDownloadCenterListApiProps } from "../proj-download-center-list.apis"

const RequestProjDataMgmtZipFileById = (
	self: any,
	apiRead: ProjDownloadCenterListApiProps["apiRequestProjDataMgmtZipFileById"]
) => ({
	requestProjDataMgmtZipFileById: flow(function* (zipFileId: number) {
		const actionName = "requestProjDataMgmtZipFileById"
		try {
			const response = yield apiRead(zipFileId)
			return response
		} catch (error) {
			self.handleModelError({ actionName, error, openSnackbar: true })
			return false
		}
	}),
})

export default RequestProjDataMgmtZipFileById
