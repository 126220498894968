import React from "react"
import styled from "styled-components"

const StyledDLTab = styled.div`
	min-height: var(--standard-unit);
	font-weight: var(--font-bold);
	display: flex;
	flex-direction: row;
	align-items: center;
	transition: 0.4s;
	overflow: hidden;

	svg {
		width: 1rem;
		overflow: hidden;
		transition: 0.4s;
		fill: ${(props) => props.theme.textHighEmphasis};
	}

	.tab-label {
		width: 100%;
		overflow: hidden;
		white-space: nowrap;
		position: relative;

		.text-label {
			display: inline-block;
			padding-left: 6px;
			max-width: 170px;
			// overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}
	&.active:hover .scrolling-text {
		display: inline-block;
		animation: marquee 5s linear infinite;
		animation-delay: 4s;
		white-space: nowrap;
		padding-right: 10px;
	}

	@keyframes marquee {
		0% {
			transform: translateX(100%);
		}
		100% {
			transform: translateX(-100%);
		}
	}

	.tab-control {
		svg {
			margin-right: 0;
			margin-left: 8px;
		}
	}
`

export default StyledDLTab
