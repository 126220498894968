export enum ProjI18n {
	menus = "ProjMenus",
	//
	archPreProcess = "ProjArchPreProcessing",
	projInfo = "ProjInfo",
	notes = "ProjNotes",
	comments = "ProjComments",
	files = "ProjFiles",
	pbc = "ProjPBC",
	archDiagnosis = "ProjArchDiagnosis",
	signOffHistory = "ProjSignOffHistory",
	openedFiles = "ProjOpenedFiles",
	archHistory = "ProjArchHistory",
	users = "ProjUsers",
	noti = "ProjNotification",
	trash = "ProjTrash",
	rationale = "ProjRationale",
	danger = "ProjDanger",
	projDownloadCenter = "ProjDownloadCenter",
}
