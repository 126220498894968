import React from "react"
import { observer } from "mobx-react-lite"
import {
	DLComboBox,
	DLDatepickerWithoutInput,
	DLInputField,
	InputWithLabel,
} from "../../../../../components/basic-elements"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import { OrgI18n } from "../../../../../common-models/enumerations/translation-sheets"
import { Button } from "@material-ui/core"
import styled from "styled-components"
import EmailSenderSchedulerForm from "./sub-components/EmailSenderSchedulerForm"

export default observer(function EmailSenderScheduleController() {
	const store = useRootStore()
	const i18n = store.i18n.combineI18n(OrgI18n.projects, "CreateProjectDialog")
	const dateFormat = store.global.dateFormat.value

	return <EmailSenderSchedulerForm />
})
