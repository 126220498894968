import React, { useMemo } from "react"
import styled from "styled-components"
import SharedProjectTableV8 from "../../../../components/combined-elements/projects-table/SharedProjectTableV8"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import {
	DLContextMenu,
	DLIconButton,
	DLSpinner,
} from "../../../../components/basic-elements"
import { ProjScreenType } from "../../../../components/combined-elements/projects-table/projects-table-props"
import { ProjectStatus } from "../../../../common-models/enumerations/project-related-enums"
import ProjectDetailsDialog from "../../../../components/combined-elements/project-dialogs/ProjectDetailsDialog"
import CommonSnackbarComponent from "../../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import {
	MySummaryTableTitles,
	MySummaryDueInDays,
	DueDaysPropForMySummary,
} from "../utils/my-summary-utils"
import { observer } from "mobx-react-lite"
import MySummaryButtons from "./MySummaryButtons"
import MySummaryFileTable from "./MySummaryFileTable"
import { OrgStoreType } from "../../../../stores/org-store/org-store.provider"

export default observer(function MySummaryTable({
	orgStore,
	i18n,
	menuId,
	permission,
	clickPointExist,
	clickPoint,
	menuOptions,
	loadingStatus,
}: {
	orgStore: OrgStoreType
	i18n: any
	menuId: DLOrgSubMenus
	permission: any
	clickPointExist: boolean
	clickPoint: any
	menuOptions: any
	loadingStatus: any
}) {
	const renderTable = (
		title: string,
		dueInDays: string | undefined,
		dueDaysProp: string | undefined,
		height = 540
	) => (
		<StyledMySummaryProjects>
			{loadingStatus === "SUCCESS" && (
				<SharedProjectTableV8
					partialStore={orgStore.projects}
					i18n={i18n}
					projScreenType={ProjScreenType.normal}
					menuId={menuId}
					projectStatus={ProjectStatus.normal}
					permission={permission}
					showPageHeader={false}
					tableTitle={title}
					height={height}
					dueInDays={dueInDays}
					dueDaysPropForMySummary={dueDaysProp}
				/>
			)}

			{clickPointExist && (
				<DLContextMenu
					eleId="remind-archive-ctx-menus"
					clickPoint={clickPoint}
					handleClose={() =>
						orgStore.projects.setClickPoint({
							mouseX: null,
							mouseY: null,
						})
					}
					menuOptions={menuOptions}
				/>
			)}

			<CommonSnackbarComponent
				i18n={i18n}
				partialStore={orgStore.projects}
			/>

			{orgStore.projects.projectDetailsDialogOpen && (
				<ProjectDetailsDialog partialStore={orgStore.projects} />
			)}
		</StyledMySummaryProjects>
	)

	const renderLoading = () => (
		<div
			style={{
				height: 540,
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<DLSpinner />
		</div>
	)

	const renderMySummaryFile = () => (
		<MySummaryFileTable
			partialStore={orgStore.projects}
			actionStatus="SUCCESS"
			permission={permission}
			i18n={i18n}
			contentsHeight={540}
		/>
	)

	const summaryData = useMemo(
		() => [
			{
				label: "Due in 5 Days",
				content:
					loadingStatus === "LOADING" ? (
						renderLoading()
					) : (
						<>
							{/* Final Archive Deadline Date Table */}
							{renderTable(
								MySummaryTableTitles.finalArchiveDateWithIn5Days,
								MySummaryDueInDays.finalArchiveDeadlineDate_5,
								DueDaysPropForMySummary.dueDaysForFinalArchiveDeadlineDate
							)}

							{/* Expected Archive Deadline Date Table */}
							{renderTable(
								MySummaryTableTitles.expectedArchiveDateWithIn5Days,
								MySummaryDueInDays.expectedArchiveDeadlineDate_5,
								DueDaysPropForMySummary.dueDaysForExpectedArchiveDeadlineDate
							)}
						</>
					),
			},
			{
				label: "Due in 20 Days",
				content:
					loadingStatus === "LOADING" ? (
						renderLoading()
					) : (
						<>
							{/* Final Archive Deadline Date Table */}
							{renderTable(
								MySummaryTableTitles.finalArchiveDateWithIn20Days,
								MySummaryDueInDays.finalArchiveDeadlineDate_20,
								DueDaysPropForMySummary.dueDaysForFinalArchiveDeadlineDate
							)}

							{/* Expected Archive Deadline Date Table */}
							{renderTable(
								MySummaryTableTitles.expectedArchiveDateWithIn20Days,
								MySummaryDueInDays.expectedArchiveDeadlineDate_20,
								DueDaysPropForMySummary.dueDaysForExpectedArchiveDeadlineDate
							)}
						</>
					),
			},
			{
				label: "Overdue",
				content:
					loadingStatus === "LOADING" ? (
						renderLoading()
					) : (
						<>
							{/* Final Archive Deadline Date Table */}
							{renderTable(
								MySummaryTableTitles.finalArchiveDateOverdue,
								MySummaryDueInDays.finalArchiveDeadlineDateOverdue,
								DueDaysPropForMySummary.overdueFinalArchiveDeadlineDate
							)}

							{/* Expected Archive Deadline Date Table */}
							{renderTable(
								MySummaryTableTitles.expectedArchiveDateOverdue,
								MySummaryDueInDays.expectedArchiveDeadlineDateOverdue,
								DueDaysPropForMySummary.overdueExpectedArchiveDeadlineDate
							)}
						</>
					),
			},
			{
				label: "My Summary File",
				content:
					loadingStatus === "LOADING"
						? renderLoading()
						: renderMySummaryFile(),
			},
		],
		[
			loadingStatus,
			orgStore,
			i18n,
			permission,
			menuId,
			clickPoint,
			menuOptions,
		]
	)

	return <MySummaryButtons summaryData={summaryData} />
})

const StyledMySummaryProjects = styled.div`
	padding: 0 1rem;
`
