import React from "react"
import { observer } from "mobx-react-lite"

// dialogs
// components
import { PageContainer } from "../../../../../components/app-frame-elements"
import { ConsoleLog } from "../../../../../components/basic-elements"
// dependencies

import OrgSetupUserListTable from "../../../org-setup/org-setup-users/sub-components/OrgSetupUserListTable"
import UserNotificationSettingCtxMenus from "./UserNotificationSettingCtxMenus"
import { ActionStatus } from "../../../../../common-models/enumerations/common-enums"

import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import styled from "styled-components"
import { DLI18nProps } from "../../../../../common-models/types/common-props"

import CommonSnackbarComponent from "../../../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import ConfigureOrgNotificationPermissionDialog from "../dialogs/ConfigureOrgNotificationPermissionDialog"

export default observer(function UserNotificationSetting({
	partialStore,
	actionStatus,
	clickPointExist,
	permission,
	i18n,
}: {
	partialStore: any
	actionStatus: ActionStatus
	clickPointExist: boolean
	permission: PermissionAsObjectProps
	i18n: DLI18nProps
}) {
	ConsoleLog(" __________ UserNotificationSetting __________ ")

	return (
		<PageContainer
			hasToolbar={false}
			pageTools={<div />}
			fullWidth
			fixedHeight
			isLoading={actionStatus === "LOADING"}
		>
			{/* ----- Contents ----- */}
			{actionStatus === "SUCCESS" && (
				<StyledSetupClients>
					<OrgSetupUserListTable
						i18n={i18n}
						permission={permission}
						isUserNotificationSetting={true}
					/>
				</StyledSetupClients>
			)}

			{/* ---------- dialogs  ---------- */}
			{partialStore.orgNotificationPermissionDialogOpen && (
				<ConfigureOrgNotificationPermissionDialog i18n={i18n} />
			)}
			{/* ---------- context menus ---------- */}
			<UserNotificationSettingCtxMenus permission={permission} />

			{/* ---------- snackbar  ---------- */}
			<CommonSnackbarComponent i18n={i18n} partialStore={partialStore} />
		</PageContainer>
	)
})

const StyledSetupClients = styled.div`
	padding-left: 1rem;
	padding-right: 1rem;
	.partition {
		margin: 0 0.5rem;
	}
`
