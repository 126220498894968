import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { DLDialog, ConsoleLog } from "../../../../../components/basic-elements"
import styled from "styled-components"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import Icon from "@mdi/react"
import { mdiArrowRightBold } from "@mdi/js"
import BreadCrumbsAndOptions, {
	BreadCrumbsOptionProps,
} from "../../../../../components/combined-elements/postpone-to-classify/BreadCrumbsAndOptions"
import { DLObjectFormat } from "../../../../../common-models/enumerations/common-enums"
import { CreateProjectPathProps } from "../../../../../components/combined-elements/create-project/create-project.data-props"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import { DLOrgSubMenus } from "../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { ProjectInfoContainer } from "../../../archive-management/common-dialogs/AmProjectDeleteDialog"

export default observer(function MoveGroupItemDialog({
	i18n,
	targetItemId,
	currentMenu,
}: {
	i18n: DLI18nProps
	targetItemId: string
	currentMenu: DLOrgSubMenus
}) {
	const orgStore = useOrgStore()
	//
	const open = orgStore.groups.openMoveDialog
	const handleOpen = orgStore.groups.setOpenMoveDialog

	const setSelectionPath = orgStore.groups.setPathForSelection

	const getLocationDetail = (id: string) => {
		if (id.includes("group")) {
			orgStore.groups.getOrgGroupCabinetList(id)
		} else if (id.includes("cabinet")) {
			orgStore.groups.getOrgCabinetDetail(id)
		} else {
			// TODO: Disable the item in the cabinet
		}
	}
	const handleMoveGroupItem = (
		target: any,
		destination: BreadCrumbsOptionProps
	) => {
		ConsoleLog(["target and destination", target, destination])
		if (target.type === DLObjectFormat.cabinet) {
			orgStore.groups.moveCabinet(target.id, destination.id)
		} else if (target.type === DLObjectFormat.project) {
			orgStore.groups.moveProject({
				projId: target.id,
				moveTo: destination.id,
				additionalAction: () => {
					if (currentMenu === DLOrgSubMenus.normal_projs) {
						orgStore.projects.customRefresh(
							DLOrgSubMenus.normal_projs,
							true
						)
						orgStore.groups.initializeStore()
					}
				},
			})
		}
	}

	const pathForSelection = orgStore.groups.selectionPathWithNameAndFormat
	const lastItem = pathForSelection[pathForSelection.length - 1]
	const targetInfo = orgStore.groups.getTargetDetail(targetItemId)
	const getNameById = orgStore.groups.getNameById
	let options: BreadCrumbsOptionProps[] = []
	if (lastItem) {
		options = orgStore.groups.getChildrenInTheFlatListAsOptions(lastItem.id)
	} else {
		options = orgStore.groups.groupOptions
	}

	useEffect(() => {
		console.log(
			orgStore.groups.getGroupFlatListLength,
			orgStore.groups.groupOptions,
			"getGroupFlatListLength"
		)
		if (lastPath === undefined && options.length < 2) {
			const userId = orgStore.checkin.orgUserId
			orgStore.groups.getOrgGroupList(userId, () => {}, true)
		}
	}, [lastItem])

	let actionReady = lastItem?.objFormat !== DLObjectFormat.project
	let cannotMoveReason = ""
	const lastPath = pathForSelection[pathForSelection.length - 1]

	// If no selection exists, disable the move button immediately
	if (pathForSelection.length === 0) {
		actionReady = false
		cannotMoveReason = "Please select Target Location"
	} else {
		const idPath = pathForSelection.map((item) => item.id)

		if (targetInfo) {
			const isTargetProject = targetInfo.type === DLObjectFormat.project
			const isMovingToSelf = lastPath.id === targetInfo.id
			const isMovingToChild = idPath.includes(targetInfo.id)
			const isMovingToSameLocation = lastPath.id === targetInfo.parentId
			const isUnderProject = lastPath.objFormat === DLObjectFormat.project
			const isInvalidProjectMove =
				isTargetProject && lastPath.objFormat === DLObjectFormat.group

			if (
				isMovingToSelf ||
				isMovingToChild ||
				isMovingToSameLocation ||
				isUnderProject ||
				isInvalidProjectMove
			) {
				actionReady = false
			}

			if (isMovingToSelf) {
				cannotMoveReason = "Cannot move to oneself"
			} else if (isMovingToChild) {
				cannotMoveReason = "Cannot move to children of the item"
			} else if (isMovingToSameLocation) {
				cannotMoveReason = "Cannot move to same location"
			} else if (isUnderProject) {
				cannotMoveReason = "Cannot move to under the project"
			} else if (isInvalidProjectMove) {
				cannotMoveReason =
					"Cannot move to under the group directly. Please select a cabinet in the group"
			}
		}
	}

	const projectInfo =
		targetInfo?.type === DLObjectFormat.project
			? orgStore.groups.getSelectedProjInfo(targetInfo.id)
			: {}

	console.log(targetInfo, "targetInfo")

	return (
		<DLDialog
			eleTestId="select-destination-to-move-dialog"
			isOpen={open}
			setIsOpen={handleOpen}
			handleAction={() => handleMoveGroupItem(targetInfo, lastItem)}
			showOpenBtn={false}
			showCloseBtn={true}
			dialogTitle={i18n.twMoveItem || "Move item"}
			dialogContents={
				<>
					{orgStore.groups.getActionStatus("getOrgCabinetDetail") !==
						"LOADING" && (
						<DialogContents
							path={pathForSelection}
							setPath={setSelectionPath}
							getLocationDetail={getLocationDetail}
							// defaultOptions={defaultOptions}
							options={options}
							targetItem={targetInfo}
							getNameById={getNameById}
							actionReady={actionReady}
							cannotMoveReason={cannotMoveReason}
							lastPath={lastPath}
							i18n={i18n}
							projectInfo={projectInfo}
							orgStore={orgStore}
						/>
					)}
				</>
			}
			cancelBtnText={i18n.twCancel || "Cancel"}
			actionBtn={i18n.twMove || "Move"}
			actionReady={actionReady}
			maxWidth="sm"
			fullWidth={true}
			showSpinner={
				orgStore.groups.getActionStatus("getOrgCabinetDetail") ===
				"LOADING"
			}
		/>
	)
})

const DialogContents = observer(
	({
		path,
		setPath,
		getLocationDetail,
		// defaultOptions,
		options,
		targetItem,
		getNameById,
		actionReady,
		cannotMoveReason,
		lastPath,
		i18n,
		projectInfo,
		orgStore,
	}: {
		path: CreateProjectPathProps
		setPath: (path: any) => void
		getLocationDetail: (id: string) => void
		// defaultOptions: BreadCrumbsOptionProps[]
		options: BreadCrumbsOptionProps[]
		targetItem: any
		getNameById: (id: string) => string | undefined
		actionReady: boolean
		cannotMoveReason: string
		lastPath: any
		i18n: DLI18nProps
		projectInfo: any
		orgStore?: any
	}) => {
		return (
			<>
				<BreadCrumbsAndOptions
					path={path}
					setPath={setPath}
					options={options}
					getLocationDetail={getLocationDetail}
					showLocationDetail
				/>
				<StyledTargetAndDestination>
					<div className="target-and-destination FR AC JSB">
						<div className="target-and-from">
							<div className="heading3">
								{i18n.twCurrentLocation || "Current Location"}
							</div>

							<div className="from">
								from:{" "}
								{getNameById(targetItem.parentId) ||
									(orgStore &&
										orgStore.projects.normalProjList.find(
											(item: any) =>
												item.id === targetItem.id
										)?.cabinetName)}
							</div>
							<div className="target">{targetItem.title}</div>
						</div>
						<div className="arrow">
							<Icon path={mdiArrowRightBold} size={1} />
						</div>

						<div
							className={`destination ${
								!actionReady && "cannot-move"
							}`}
						>
							<div className="heading3">Next Location</div>
							{path.length > 0 ? (
								<span>
									{" "}
									To:{" "}
									{lastPath.id !== targetItem.parentId &&
										lastPath.name}
								</span>
							) : (
								<span>Please select destination</span>
							)}
						</div>
					</div>
					{!actionReady && (
						<div className="warning-message">
							! {cannotMoveReason}
						</div>
					)}

					{projectInfo?.title && (
						<ProjectInfoContainer
							projectDetails={projectInfo}
							i18n={i18n}
						/>
					)}
				</StyledTargetAndDestination>
			</>
		)
	}
)

const StyledTargetAndDestination = styled.div`
	.target-and-destination {
		/* .target-and-from {}
		.arrow {}
		.destination {}*/

		.target-and-from,
		.destination {
			height: 120px;
			width: 45%;
			.heading3 {
				font-weight: 600;
			}
		}
		.target-and-from {
			padding: 1rem;
			border-radius: 4px;
			background-color: ${(props) => props.theme.shade10};
			.target {
				margin-top: 0.6rem;
				padding: 0.6rem;
				border-radius: 3px;
				border: 1px solid gray;
			}
		}
		.arrow {
		}
		.destination {
			padding: 1rem;
			border-radius: 4px;
			background-color: ${(props) => props.theme.shade10};
			&.cannot-move {
				color: ${(props) => props.theme.warning};
				background-color: ${(props) => props.theme.background};
				border: 1px dashed;
			}
		}
	}
	.warning-message {
		color: ${(props) => props.theme.warning};
	}
`
