import React, { useState } from "react"
import { PageContainer } from "../../../../components/app-frame-elements"
import ReactTableV8 from "../../../../components/basic-elements/tables/DLReactTable"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import { withStyles } from "@material-ui/core/styles"
import { Tooltip } from "@material-ui/core"
import { DLCheckbox, DLTooltip } from "@datalobby/components"
import Icon from "@mdi/react"
import {
	dateRangeFilter,
	multiSelectFilter,
} from "../../../../library/react-table-utils"
import { mdiDotsHorizontal } from "@mdi/js"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { mdiInformation } from "@mdi/js"
import { getTzDate } from "../../../../library/converters/date-utc-converter"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import CommonSnackbarComponent from "../../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import { DLIconButton } from "../../../../components/basic-elements"
import styled from "styled-components"
import MySummaryCtxMenus from "./MySummaryCtxMenus"

function MySummaryFileTable({
	partialStore,
	actionStatus,
	permission,
	i18n,
	contentsHeight,
}: {
	partialStore: any
	actionStatus: string
	permission: any
	i18n: DLI18nProps
	contentsHeight: any
}) {
	const store = useRootStore()
	const dntFormat = store.global.getDntFormat
	const selectedItemId = partialStore.selectedProj
	const selectedItemInfo = partialStore.viewSelectedProjInfo(selectedItemId)
	const [zipTypeId, setZipTypeId] = useState(0)

	const handleCheckbox = (id: string) => {
		partialStore.setSelectedProj(id)
	}

	const handleContextMenu = (event: any, itemId: string) => {
		event.preventDefault()
		partialStore.setSelectedProj(itemId)
		partialStore.setClickPoint({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
		})
	}

	return (
		<PageContainer
			fixedHeight
			hasToolbar={false}
			isLoading={actionStatus === "LOADING"}
		>
			<>
				{actionStatus === "SUCCESS" && (
					<ReactTableV8
						tableColumns={MySummaryFileColumns(
							handleContextMenu,
							permission,
							i18n,
							handleCheckbox,
							selectedItemId
						)}
						data={getData(dntFormat, zipTypeId)}
						hasPagination={true}
						showPageSetting={false}
						handleContextMenu={(e: any, rowInfo: any) =>
							handleContextMenu(e, rowInfo.id)
						}
						height={contentsHeight}
						pageHeader={() => (
							<StyledPageHeader>
								<div>
									Total Count: &nbsp;
									{getData(dntFormat, zipTypeId).length}
								</div>
							</StyledPageHeader>
						)}
						i18n={i18n}
						menuId={DLProjSubMenus.proj_download_center}
					/>
				)}
				{/* ----- snackbar ----- */}
				<CommonSnackbarComponent
					i18n={i18n}
					partialStore={partialStore}
				/>
				{/*---------Context Menu---------*/}
				<MySummaryCtxMenus partialStore={partialStore} i18n={i18n} />
			</>
		</PageContainer>
	)
}

export default MySummaryFileTable

const renderCustomCell = (props: any) => {
	const cellId = props.cell.column.id + "-cell"
	return (
		<div data-testid={cellId}>
			{props.getValue() ? props.getValue() : "-"}
		</div>
	)
}

const MySummaryFileColumns = (
	handleContextMenu: (event: any, itemId: string) => void,
	permission: PermissionAsObjectProps,
	i18n: DLI18nProps,
	handleCheckbox: (id: string) => void,
	selectedId: string
) => {
	const CustomTooltip = withStyles({
		tooltip: {
			fontSize: "0.8rem", // Adjust the font size as needed
			maxWidth: "200px", // Set a maximum width for the tooltip
		},
	})(Tooltip)

	return [
		{
			header: "Select",
			accessorKey: "id",
			size: 10,
			enableColumnFilter: false,
			cell: (props: any) => {
				const id = props.getValue()
				const handleClick = () => {
					handleCheckbox(id)
				}
				return (
					<div className="FR AC JC" style={{ width: "100%" }}>
						<DLCheckbox
							eleTestId={`checkbox-${id}`}
							clickHandler={handleClick}
							color="primary"
							isChecked={selectedId === id}
						/>
					</div>
				)
			},
		},
		{
			header: i18n.twProgress || "Progress",
			accessorKey: "progress",
			cell: renderCustomCell,
		},
		{
			header: i18n.twFileName || "File Name",
			accessorKey: "fileName",
			cell: renderCustomCell,
		},
		{
			header: i18n.twFileSize || "File Size",
			accessorKey: "fileSize",
			cell: renderCustomCell,
		},
		{
			header: i18n.twCreatedAt || "Created At",
			accessorKey: "createdAt",
			filterFn: dateRangeFilter,
			cell: renderCustomCell,
		},
		{
			header: i18n.twFileStatus || "File Status",
			accessorKey: "status",
			cell: renderCustomCell,
		},
		{
			header: i18n.twExpirationDate || "Expiration Date",
			accessorKey: "expiredAt",
			filterFn: dateRangeFilter,
			cell: renderCustomCell,
		},
		{
			header: i18n.twOrgName || "Org Name",
			accessorKey: "orgName",
			cell: renderCustomCell,
		},
		{
			header: i18n.twBaseDateTime || "Base DateTime",
			accessorKey: "baseDateTime",
			filterFn: dateRangeFilter,
			cell: renderCustomCell,
		},
		{
			header: i18n.twMessageHeader || "Message Header",
			accessorKey: "messageHeader",
			size: 200,
			cell: (props: any) => {
				const reason = props.getValue() || "-" // Default placeholder

				return (
					<CustomTooltip title={reason} arrow placement="top">
						<div
							style={{
								display: "-webkit-box",
								WebkitLineClamp: 2,
								WebkitBoxOrient: "vertical",
								overflow: "hidden",
								textOverflow: "ellipsis",
								maxWidth: "200px",
								cursor: "pointer",
							}}
						>
							{reason}
						</div>
					</CustomTooltip>
				)
			},
		},
		{
			header: i18n.twMessageD5Final || "Message D5 Final (x)",
			accessorKey: "messageD5Final",
			cell: renderCustomCell,
		},
		{
			header: i18n.twMessageD5Expected || "Message D5 Expected (x)",
			accessorKey: "messageD5Expected",
			cell: renderCustomCell,
		},
		{
			header: i18n.twMessageD20Final || "Message D20 Final (x)",
			accessorKey: "messageD20Final",
			cell: renderCustomCell,
		},
		{
			header: i18n.twMessageD20Expected || "Message D20 Expected (x)",
			accessorKey: "messageD20Expected",
			cell: renderCustomCell,
		},
		{
			header: i18n.twMessageOverdueFinal || "Message Overdue Final (x)",
			accessorKey: "messageOverdueFinal",
			cell: renderCustomCell,
		},
		{
			header:
				i18n.twMessageOverdueExpected || "Message Overdue Expected (x)",
			accessorKey: "messageOverdueExpected",
			cell: renderCustomCell,
		},
		{
			header: "Action",
			size: 10,
			enableColumnFilter: false,
			cell: (props: any) => {
				const itemId = props.row.original.id
				return (
					<div className="FR AC JC" style={{ width: "100%" }}>
						<DLIconButton
							aria-label="more"
							clickHandler={(event: any) =>
								handleContextMenu(event, itemId)
							}
							eleTestId="trash-list-context-btn"
						>
							<Icon path={mdiDotsHorizontal} size={0.8} />
						</DLIconButton>
					</div>
				)
			},
		},
	]
}

export const StyledPageHeader = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between; /* Distribute content to left and right */
	align-items: center;
	padding-bottom: 10px; /* Optional for spacing */
`

const data = [
	{
		id: "1",
		progress: "Completed",
		fileName: "file1",
		fileSize: "1 MB",
		createdAt: "2025-03-17 03:41 PM",
		status: "Available",
		expiredAt: "2025-04-14 08:49 PM",
		orgName: "Org Name 1",
		baseDateTime: "2025-03-17 11:59 PM",
		messageHeader: "Message Header 1",
		messageD5Final: "5",
		messageD5Expected: "7",
		messageD20Final: "10",
		messageD20Expected: "10",
		messageOverdueFinal: "0",
		messageOverdueExpected: "0",
	},
	{
		id: "2",
		progress: "Deleted",
		fileName: "file2",
		fileSize: "2 MB",
		createdAt: "2025-03-14 08:11 PM",
		status: "Expired",
		expiredAt: "2025-03-15 08:11 PM",
		orgName: "Org Name 2",
		baseDateTime: "2025-03-14 11:59 PM",
		messageHeader: "Message Header 2",
		messageD5Final: "7",
		messageD5Expected: "7",
		messageD20Final: "10",
		messageD20Expected: "12",
		messageOverdueFinal: "5",
		messageOverdueExpected: "8",
	},
	{
		id: "3",
		progress: "Pending",
		fileName: "file3",
		fileSize: "3 MB",
		createdAt: "2025-03-13 11:08 PM",
		status: "Available",
		expiredAt: "2025-04-13 09:31 PM",
		orgName: "Org Name 3",
		baseDateTime: "2025-03-13 11:59 PM",
		messageHeader: "Message Header 3",
		messageD5Final: "7",
		messageD5Expected: "3",
		messageD20Final: "10",
		messageD20Expected: "10",
		messageOverdueFinal: "11",
		messageOverdueExpected: "15",
	},
]

function getData(dntFormat: any, zipTypeId: any) {
	return data
}
