import { types } from "mobx-state-tree"
import FsEntityPresentationViewModel from "./fs-entity-presentation.view-model"
import FsEntityPresentationModel from "./fs-entity-presentation.model"

export const FsEntityPresentationStore = () =>
	types
		.compose(FsEntityPresentationModel(), FsEntityPresentationViewModel)
		.named("FsEntityPresentationStore")

export const initialStore = {
	clickPoint: {
		mouseX: null,
		mouseY: null,
	},
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const FsEntityPresentationStoreInstance =
	FsEntityPresentationStore().create(initialStore)

export default FsEntityPresentationStoreInstance
