import React from "react"
import { OrgDefaultModel } from "./org-store.model"
import { OrgStoreViewModel } from "./org-store.view-model"
import OrgStoreUiHelper from "./org-store.ui-helper"
import { useLocalObservable } from "mobx-react-lite"
// import { OrgUsersStoreInstance } from "../organization-setup/org-users/store/orgUsers.view-model"

// sub store instances
import OrgCheckinStoreInstance from "../../screens/organization-side/org-access-control/org-checkin/store/org-checkin.provider"
import NotificationsStoreInstance from "../../screens/organization-side/notifications/store/notifications.provider"
import OrgProjTemplatesStoreInstance from "../../screens/organization-side/org-library/org-proj-templates/store/org-proj-templates.provider"
import OrgFileLibStoreInstance from "../../screens/organization-side/org-library/org-file-library/store/org-file-lib.provider"

import AmArchivedProjectsStoreInstance from "../../screens/organization-side/archive-management/am-archived-projects/store/am-archived-projects.provider"
import AmUnarchivedProjectsStoreInstance from "../../screens/organization-side/archive-management/am-unarchived-projects/store/am-unarchived-projects.provider"
import OrgGroupsStoreInstance from "../../screens/organization-side/groups/store/org-groups.provider"
import OrgProjectsStoreInstance from "../org-sub-stores/org-projects-store/orgProjects.provider"
import OrgTrashStoreInstance from "../../screens/organization-side/org-trash/store/org-trash.provider"
// sub store in the org settings
import OrgSetupUsersStoreInstance from "../org-sub-stores/org-users-store/org-setup-users.provider"
import OrgSetupGroupsStoreInstance from "../org-sub-stores/org-groups-store/org-setup-groups.provider"
import OrgSetupClientsStoreInstance from "../org-sub-stores/org-clients-store/org-clients.provider"
import OrgSetupChecklistStoreInstance from "../../screens/organization-side/org-setup/org-setup-checklist/store/org-setup-checklist.provider"
import OrgSetupEngTypesStoreInstance from "../../screens/organization-side/org-setup/org-setup-eng-types/store/org-eng-types.provider"
import OrgSetupRiskAssessmentStore from "../../screens/organization-side/org-setup/org-setup-risk-assessment/store/org-risk-assessment.provider"
import DateAndTimeSettingStoreInstance from "../../screens/organization-side/org-setup/org-setup-system-settings/date-and-time-format-setting/store/date-and-time-setting.provider"
import EmailNotificationStoreInstance from "../../screens/organization-side/org-setup/org-setup-system-settings/email-notification/store/email-notification.provider"
import OrgRolesStoreInstance from "../../screens/organization-side/org-setup/org-setup-org-roles/store/org-roles.provider"
import OrgArchivePoliciesStoreInstance from "../../screens/organization-side/org-setup/org-archive-policies/store/org-archive-policies.provider"
//
import RecentProjInstance from "../../screens/organization-side/my-page/recent-projects/store/recent-proj-list.provider"
import { types } from "mobx-state-tree"
import { ActionStatus } from "../../common-models/enumerations/common-enums"
import OrgSearchStoreInstance from "../../screens/organization-side/org-search/store/org-search.provider"
import OrgDashboardStoreInstance from "../../screens/organization-side/org-dashboard/table-view/store/org-dashboard.provider"
import ReportsManagementStoreInstance from "../../screens/organization-side/reports-management(Deprecated)/online-projects/store/reports-management.provider"
import OtherProjectsStoreInstance from "../../screens/organization-side/reports-management(Deprecated)/other-projects/store/other-projects.provider"
import ChecklistsStoreInstance from "../../screens/organization-side/reports-management(Deprecated)/checklists/store/checklists.provider"
import OrgProjArchiveDatePolicyStoreInstance from "../../screens/organization-side/org-setup/org-proj-arch-date-policy/store/org-proj-arch-date-policy.provider"
import ERPDataStoreInstance from "../../screens/organization-side/erp-data-sync/ERPData/store/erp-data.provider"
import AULMappedERPStoreInstance from "../../screens/organization-side/erp-data-sync/MappingAULToERP/store/map-aul-to-erp.provider"
import OrgSetupProjectAdminSettingsStoreInstance from "../../screens/organization-side/org-setup/org-setup-project-admin-settings/store/org-setup-project-admin-settings.provider"
import CheckEPMatchStoreInstance from "../../screens/organization-side/erp-data-sync/CheckEPMatch/store/check-ep-match.provider"
import CheckFinalReportDateMatchStoreInstance from "../../screens/organization-side/erp-data-sync/CheckFinalReportDateMatch/store/check-final-report-date-match.provider"

const OrgDefaultStore = types
	.compose(OrgDefaultModel, OrgStoreViewModel, OrgStoreUiHelper)
	.named("OrgDefaultStore")

export const initialStore = {
	// ui-helper
	currentOrgMainMenu: {
		id: "",
		title: "",
	},
	// common parts
	actionStatus: ActionStatus.standby,
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const OrgDefaultStoreInstance = OrgDefaultStore.create(initialStore)

const createOrgStore = () => {
	const store = {
		checkin: OrgCheckinStoreInstance,
		default: OrgDefaultStoreInstance,
		//
		recent: RecentProjInstance,
		//
		orgProjTemplates: OrgProjTemplatesStoreInstance,
		orgFileLib: OrgFileLibStoreInstance,
		//
		noti: NotificationsStoreInstance,
		//
		orgDashboard: OrgDashboardStoreInstance,
		//
		archiveMgmt: AmArchivedProjectsStoreInstance,
		unarchiveMgmt: AmUnarchivedProjectsStoreInstance,
		//
		groups: OrgGroupsStoreInstance,
		projects: OrgProjectsStoreInstance,
		trash: OrgTrashStoreInstance,
		//
		reportsMgmt: ReportsManagementStoreInstance,
		otherProjects: OtherProjectsStoreInstance,
		checklists: ChecklistsStoreInstance,
		//
		setupUsers: OrgSetupUsersStoreInstance,
		setupGroups: OrgSetupGroupsStoreInstance,
		setupClients: OrgSetupClientsStoreInstance,
		setupChecklist: OrgSetupChecklistStoreInstance,
		setupEngTypes: OrgSetupEngTypesStoreInstance,
		setupRiskAssessment: OrgSetupRiskAssessmentStore,
		setupDateAndTimeSetting: DateAndTimeSettingStoreInstance,
		emailNotification: EmailNotificationStoreInstance,
		orgRoles: OrgRolesStoreInstance,
		archPolicy: OrgArchivePoliciesStoreInstance,
		projArchDatePolicy: OrgProjArchiveDatePolicyStoreInstance,
		orgSetupProjAdminSettings: OrgSetupProjectAdminSettingsStoreInstance,
		//
		search: OrgSearchStoreInstance,
		//
		erpData: ERPDataStoreInstance,
		mappingAULToERP: AULMappedERPStoreInstance,
		checkEPMatch: CheckEPMatchStoreInstance,
		checkFinalReportDateMatch: CheckFinalReportDateMatchStoreInstance,
	}
	return store
}

export type OrgStoreType = ReturnType<typeof createOrgStore>

const OrgStoreContext = React.createContext<OrgStoreType | null>(null)

const OrgStoreProvider = ({ children }: { children: React.ReactNode }) => {
	const orgStore = useLocalObservable(createOrgStore)

	return (
		<OrgStoreContext.Provider value={orgStore}>
			{children}
		</OrgStoreContext.Provider>
	)
}

const useOrgStore = () => {
	const orgStore = React.useContext(OrgStoreContext)
	if (!orgStore) {
		throw Error("Organization Store is null")
	}
	return orgStore
}

export { OrgStoreContext, OrgStoreProvider, useOrgStore }
