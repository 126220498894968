import { types } from "mobx-state-tree"
import WpModel from "./wp.model"
import WpViewModel from "./wp.view-model"
import WpUiHelper from "./wp.ui-helper"
// ---------- common models
import { initialClickPoint } from "../../../../../common-models/types/dialog.props"
//
import * as fileApi from "../../../../../service-modules/file-module/apis/file-system-file-apis"
import * as folderApi from "../../../../../service-modules/file-module/apis/file-system-folder-apis"
import * as downloadApi from "../../../../../service-modules/file-module/apis/file-system-download-apis"
import { assignRole } from "../../../../../service-modules/file-module/apis/file-system-assign-apis"
//
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import {
	FileMenuViewType,
	SignOffDisplayType,
} from "../../../../../service-modules/file-module/data-models/dl-file-control-model"
//
import WpFileControl from "./wp.file-control"
import { ProjectFilesApiProps } from "../../proj-files-components/project-files-api-types"
import {
	DLCommentType,
	DialogActionType,
} from "../../../../../common-models/enumerations/common-enums"
import { OldSignOffType } from "../../../../../service-modules/file-module/data-models/sign-off.data-model"
import { readTrialBalance } from "../../../financial-statements/financial-statements/store/financial-statements.api"
import { read } from "../../../financial-statements/fs-setup/store/fs-setup.apis"
import * as api from "./wp.apis"
import { initialFileInfo } from "../../../../../service-modules/file-module/data-models/initial-file"

export const WpStore = (props: ProjectFilesApiProps) =>
	types
		.compose(
			WpModel(props),
			WpViewModel,
			WpUiHelper,
			// NOTE:
			WpFileControl
		)
		.named("WpStore")

export const initialStore = {
	storeName: DLProjSubMenus.wp,
	// file-control
	fileTreeDialogOpenStatus: {},
	showColumns: {},
	showSignOffColumns: {},
	columnWidth: {},
	fileInfo: initialFileInfo,
	//
	folderClickPoint: initialClickPoint,
	fileClickPoint: initialClickPoint,
	viewType: FileMenuViewType.tree,
	searchType: "file",
	//
	multipleSignOffType: { type: OldSignOffType.prepare },
	commentDetailDialogMode: DialogActionType.read,
	commentType: DLCommentType.normal,
	//

	//
	signOffCellType: SignOffDisplayType.nameAndDate,
	signOffDisplayRange: "LATEST",
	signOffSortType: "ASCENDING",
	// viewModel related parts
	finalAnalysis: {
		purpose: "",
		scope: "",
		conclusion: "",
	},
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const WpStoreInstance = WpStore({
	// default get
	apiReadFirstFolder: folderApi.readFirstFolder,
	apiReadChildrenOfFolder: folderApi.readChildrenOfFolder,
	apiUpdateStructureLock: folderApi.updateStructureLock,
	//
	apiCreateFile: fileApi.createFile,
	apiUpdateFile: fileApi.updateFile,
	apiDeleteFile: fileApi.deleteFile,
	apiReplaceFile: fileApi.replaceFile,
	apiReadAllFiles: fileApi.readAllFiles,
	//
	apiSearchFileOrFolder: fileApi.searchFileOrFolder,
	//
	apiCreateFolder: folderApi.createFolder,
	apiUpdateFolder: folderApi.updateFolder,
	apiDeleteFolder: folderApi.deleteFolder,
	apiReorderChildren: folderApi.reorderChildren,
	// download
	apiDownloadFile: downloadApi.downloadFile,
	apiDownloadFolder: downloadApi.downloadFolder,
	apiDownloadAll: downloadApi.downloadAll,
	apiCreateProjectZipExport: downloadApi.requestExportProjectZip,
	apiCreateFolderZipExport: downloadApi.createFolderZipExport,
	//
	apiAssignRole: assignRole,
	//
	apiCreateFileFromLib: fileApi.createFileFromLib,
	apiCreateFileFromTemp: fileApi.createFileFromTemp,
	apiReadTrialBalance: readTrialBalance,
	apiReadFS: read,
	apiReadFA: api.readFA,
	apiSaveFA: api.saveFA,
	apiReadFileInfo: fileApi.readFileInfo,
	apiReadFileHistory: fileApi.readFileHistory,
	apiReadSignOffLockHistory: fileApi.readSignOffLockHistory,
	apiUpdateSignOffLock: fileApi.updateLock,
	apiReadFileLocation: fileApi.readFileLocation,
}).create(initialStore)

export default WpStoreInstance
