import { types, applySnapshot } from "mobx-state-tree"
// ---------- common models
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"
import { initialStore } from "./list-of-map-numbers.provider"

import {
	ClickPoint,
	ClickPointProps,
} from "../../../../../common-models/types/dialog.props"
import { MapNumbersModel } from "./data-models/list-of-map-numbers.data-model"
import GetListOfMapNumbers from "./view-model-actions/get-list-of-map-numbers"

const ListOfMapNumbersViewModel = types
	.model({
		mapNumbers: types.array(MapNumbersModel),
		selectedMapNumber: "",
		clickPoint: ClickPoint,
		// ---------- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar, // snackbar type 2
	})
	.actions((self) => ({
		setClickPoint(clickPoint: ClickPointProps) {
			self.clickPoint = clickPoint
		},
		setListOfMapNumbers(list: any) {
			self.mapNumbers = list
		},
		setSelectedMapNumber(id: string) {
			self.selectedMapNumber = id
		},
	}))
	.actions(GetListOfMapNumbers)
	.views((self) => ({
		viewListOfMapNumbers() {
			return self.mapNumbers
		},
	}))
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default ListOfMapNumbersViewModel
