export const dummyFinancialGroupData = [
	{
		id: "folderId1",
		parentId: null,
		isParent: true,
		number: null,
		title: "Group 1",
		expanded: false,
		balance: null,
		pyBalance: null,
	},
	{
		id: "folderId4",
		parentId: "folderId1",
		isParent: true,
		number: null,
		title: "Sub group 6",
		expanded: false,
		balance: null,
		pyBalance: null,
	},
	{
		id: "folderId5",
		parentId: "folderId1",
		isParent: true,
		number: null,
		title: "Sub group 5",
		expanded: false,
		balance: null,
		pyBalance: null,
	},
	{
		id: "folderId15",
		parentId: "folderId4",
		isParent: true,
		number: null,
		title: "Sub group 4",
		expanded: false,
		balance: null,
		pyBalance: null,
	},
	{
		id: "folderId2",
		parentId: null,
		isParent: true,
		number: null,
		title: "Group 2",
		expanded: false,
		balance: "0.00",
		pyBalance: null,
	},
	{
		id: "folderId8",
		parentId: "folderId2",
		isParent: true,
		number: null,
		title: "Sub group",
		expanded: false,
		balance: "0.00",
		pyBalance: null,
	},
	{
		id: "folderId9",
		parentId: "folderId2",
		isParent: true,
		number: null,
		title: "Sub group 2",
		expanded: false,
		balance: "0.00",
		pyBalance: null,
	},
	{
		id: "folderId20",
		parentId: "folderId2",
		isParent: true,
		number: null,
		title: "Sub group 3",
		expanded: false,
		balance: "0.00",
		pyBalance: null,
	},
]
