import React, { useState } from "react"
import { PageContainer } from "../../../../components/app-frame-elements"
import { ActionStatus } from "@datalobby/types"
import styled from "styled-components"
import {
	Button,
	FormControl,
	FormControlLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	TextField,
	Typography,
} from "@material-ui/core"

import { DLButton, DLRadioGroup } from "../../../../components/basic-elements"
import { DLCheckbox } from "@datalobby/components"

const checkboxOptions = [
	{
		name: "includePriorBalances",
		label: "Include Prior Balances",
		defaultChecked: true,
	},
	{
		name: "includeBudgetBalances",
		label: "Include Budget Balances",
		defaultChecked: true,
	},
	{
		name: "includeForecastBalances",
		label: "Include Forecast Balances",
		defaultChecked: true,
	},
	{
		name: "includeCustomBalances",
		label: "Include Custom Balances",
		defaultChecked: true,
	},
	{
		name: "applyForeignExchange",
		label: "Apply Foreign Exchange",
		defaultChecked: false,
	},
	{
		name: "excludeZeroBalanceAccounts",
		label: "Exclude Zero Balance Accounts",
		defaultChecked: true,
	},
	{
		name: "enableAccountSynchronization",
		label: "Enable Account Synchronization",
		defaultChecked: true,
	},
	{
		name: "unmapNewExternalAccounts",
		label: "Unmap New External Accounts",
		defaultChecked: false,
	},
]

const initialValues = {
	currentYear: 100,
	budgetBalances: 100,
	forecastBalances: 100,
	priorYear1: 100,
	priorYear2: 100,
	priorYear3: 100,
	priorYear4: 100,
}

const MCreateNewEntity = ({ actionStatus }: { actionStatus: any }) => {
	const [newEntity, setNewEntity] = useState({
		name: "",
		abbreviation: "",
		externalSource: "none",
	})

	const [externalSourceCheckbox, setExternalSourceCheckbox] = useState(
		Object.fromEntries(
			checkboxOptions.map((option) => [
				option.name,
				option.defaultChecked,
			])
		)
	)

	const [filePath, setFilePath] = useState("")

	const handleForm = (name: string, value: any) => {
		setNewEntity((prev: any) => ({
			...prev,
			[name]: value,
		}))
	}

	const [values, setValues] = useState<any>(initialValues)

	const handleValues = (event: any) => {
		setValues({ ...values, [event.target.name]: event.target.value })
	}

	const handleChange = (event: any) => {
		setExternalSourceCheckbox({
			...externalSourceCheckbox,
			[event.target.name]: event.target.checked,
		})
	}

	return (
		<PageContainer
			hasToolbar={false}
			pageTools={<div />}
			fullWidth
			isLoading={actionStatus === ActionStatus.loading}
		>
			<StyledNewEntity>
				<h3>New Entity</h3>
				<div style={{ margin: "15px 0" }}>
					<h4>Entity Identification</h4>
					<div
						className="FR"
						style={{ marginTop: 15, gap: 15, padding: "20px" }}
					>
						<div style={{ width: "50%" }}>
							<div>Name</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<TextField
									hiddenLabel
									id="name"
									value={newEntity.name}
									variant="outlined"
									size="small"
									onChange={(e: any) =>
										handleForm("name", e.target.value)
									}
								/>
							</FormControl>
						</div>
						<div style={{ width: "50%" }}>
							<div>Abbreviation</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<TextField
									hiddenLabel
									id="abbreviation"
									value={newEntity.abbreviation}
									variant="outlined"
									size="small"
									onChange={(e: any) =>
										handleForm(
											"abbreviation",
											e.target.value
										)
									}
								/>
							</FormControl>
						</div>
					</div>
				</div>
				<hr />
				<div style={{ margin: "15px 0" }}>
					<h4>External source for entity detail</h4>
					<div style={{ padding: "20px" }}>
						<FormControl component="fieldset">
							<RadioGroup
								value={newEntity.externalSource}
								onChange={(e: any) =>
									handleForm("externalSource", e.target.value)
								}
								aria-label="radio-group"
							>
								<FormControlLabel
									value="none"
									control={<Radio />}
									label="None"
								/>
								<FormControlLabel
									value="file"
									control={<Radio />}
									label={
										<>
											Existing File:
											<TextField
												variant="outlined"
												size="small"
												value={filePath}
												onChange={(e) =>
													setFilePath(e.target.value)
												}
												style={{ marginLeft: 8 }}
											/>
											<Button
												variant="contained"
												component="label"
												style={{ marginLeft: 8 }}
											>
												Browse...
												<input type="file" hidden />
											</Button>
										</>
									}
								/>
							</RadioGroup>
						</FormControl>
					</div>
					<div
						style={{
							display: "flex",
							padding: "2rem",
						}}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								gap: "8px",
								flex: 1,
							}}
						>
							{checkboxOptions.slice(0, 4).map((option) => (
								<div
									className="FR"
									style={{ width: "100%" }}
									key={option.name}
								>
									<DLCheckbox
										eleClassName={option.name}
										eleTestId="copy-fs"
										eleName={option.name}
										clickHandler={handleChange}
										isChecked={
											externalSourceCheckbox[option.name]
										}
									/>
									<div>{option.label}</div>
								</div>
							))}
						</div>

						<div
							style={{
								display: "flex",
								flexDirection: "column",
								gap: "8px",
								flex: 1,
							}}
						>
							{checkboxOptions.slice(4).map((option) => (
								<div
									className="FR"
									style={{ width: "100%" }}
									key={option.name}
								>
									<DLCheckbox
										eleClassName={option.name}
										eleTestId="copy-fs"
										eleName={option.name}
										clickHandler={handleChange}
										isChecked={
											externalSourceCheckbox[option.name]
										}
									/>
									<div>{option.label}</div>
								</div>
							))}
						</div>
					</div>
				</div>
				<hr />
				<div style={{ margin: "15px 0" }}>
					<h4>Fractional contributions to parent entity</h4>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							margin: "auto",
							padding: "20px",
						}}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								gap: "10px",
								width: "48%",
							}}
						>
							{[
								"Current year",
								"Budget balances",
								"Forecast balances",
							].map((label, index) => (
								<div
									key={index}
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									<Typography style={{ width: "50%" }}>
										{label}:
									</Typography>
									<TextField
										variant="outlined"
										size="small"
										name={Object.keys(initialValues)[index]}
										value={
											values[
												Object.keys(initialValues)[
													index
												]
											]
										}
										onChange={handleValues}
										style={{
											width: "60px",
											marginLeft: "8px",
										}}
									/>
									<Typography style={{ marginLeft: "5px" }}>
										%
									</Typography>
								</div>
							))}
						</div>

						<div
							style={{
								display: "flex",
								flexDirection: "column",
								gap: "10px",
								width: "48%",
							}}
						>
							{[
								"Prior year 1",
								"Prior year 2",
								"Prior year 3",
								"Prior year 4",
							].map((label, index) => (
								<div
									key={index}
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									<Typography style={{ width: "50%" }}>
										{label}:
									</Typography>
									<TextField
										variant="outlined"
										size="small"
										name={
											Object.keys(initialValues)[
												index + 3
											]
										}
										value={
											values[
												Object.keys(initialValues)[
													index + 3
												]
											]
										}
										onChange={handleValues}
										style={{
											width: "60px",
											marginLeft: "8px",
										}}
									/>
									<Typography style={{ marginLeft: "5px" }}>
										%
									</Typography>
								</div>
							))}
						</div>
					</div>
				</div>
				<div className="FR AC JR" style={{ marginTop: 15 }}>
					<DLButton
						variant="contained"
						eleTestId="save-btn"
						size="regular"
						color="primary"
						clickHandler={() => {}}
					>
						Save
					</DLButton>
				</div>
			</StyledNewEntity>
		</PageContainer>
	)
}

export default MCreateNewEntity

const StyledNewEntity = styled.div`
	padding-left: 1rem;
	padding-right: 1rem;
	.MuiRadio-colorSecondary.Mui-checked {
		color: ${(props) => props.theme.emphasis};
	}
`
