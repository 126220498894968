import { types, applySnapshot } from "mobx-state-tree"
// ---------- common models
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"
import { initialStore } from "./fs-list-of-transaction-metadata.provider"

import {
	ClickPoint,
	ClickPointProps,
} from "../../../../../common-models/types/dialog.props"
import { MapNumbersModel } from "./data-models/list-of-transaction-metadata.data-model"
import GetFsListOfTransactionMetadata from "./view-model-actions/get-list-of-transaction-metadata"

const FsListOfTransactionMetadataViewModel = types
	.model({
		transactionMetadata: types.array(MapNumbersModel),
		selectedItem: "",
		clickPoint: ClickPoint,
		// ---------- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar, // snackbar type 2
	})
	.actions((self) => ({
		setClickPoint(clickPoint: ClickPointProps) {
			self.clickPoint = clickPoint
		},
		setTransactionMetadata(list: any) {
			self.transactionMetadata = list
		},
		setSelectedItem(id: string) {
			self.selectedItem = id
		},
	}))
	.actions(GetFsListOfTransactionMetadata)
	.views((self) => ({
		viewFsListOfTransactionMetadata() {
			return self.transactionMetadata
		},
	}))
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default FsListOfTransactionMetadataViewModel
