import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"

const ForceArchive = (self: any) => ({
	forceArchive({ projectId, reason }: { projectId: string; reason: string }) {
		// 0.
		const actionName = "forceArchive"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})

		const clientId = idToNumber(projectId, IdType.project)
		// 3.
		self.mForceArchive({ clientId, reason })
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					self.removeRemindArcProject(projectId)
					self.resetSelectedProj()
					self.setForceArchiveDialog(false)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: response.data.Message,
						open: true,
						autoHide: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message,
						open: true,
						autoHide: false,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default ForceArchive
