import React from "react"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../../../stores/org-store/org-store.provider"
import DLTreeNavChildRow from "../../../../../../components/combined-elements/tree-nav/DLTreeNavChildRow"
import { Icon } from "@mdi/react"
import { mdiDrag, mdiMinus } from "@mdi/js"
import { DLOrgSubMenus } from "../../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import styled from "styled-components"
import { DLIconButton } from "../../../../../../components/basic-elements"

export default observer(function EngTypeRowController({
	node,
	// handleRightClick,
	canDrag,
}: {
	node: any
	// handleRightClick: (e: any, id: string, on: "policy" | "engType") => void
	canDrag: boolean
}) {
	const orgStore = useOrgStore()
	const title = node.title

	const defaultSetId: any = orgStore.projArchDatePolicy.flatList.find(
		(i: any) => i.type === "policy" && i.isDefault
	)?.id

	const handleMoveToDefault = () => {
		const proceed = window.confirm(
			"Are you sure you want to move this Engagement Type to Default archive date policy set?"
		)
		if (!proceed) {
			return
		}
		orgStore.projArchDatePolicy.moveEngType(node.id, defaultSetId)
	}

	return (
		<div data-testid="eng-type-row" style={{ fontSize: "0.8rem" }}>
			<StyledChildRow
				className={`FR AC JSB file-row ${!node.isActive && "inactive"}`}
			>
				<div className="left-side">
					{canDrag && (
						<Icon path={mdiDrag} size={0.8} className="drag-icon" />
					)}

					<div className="title-text">
						{node.isActive ? title : `[Inactive] ${title}`}
					</div>
				</div>
				{defaultSetId !== node.parentId && (
					<div className="right-side">
						<DLIconButton
							eleTestId="move-to-default"
							clickHandler={handleMoveToDefault}
						>
							<Icon path={mdiMinus} size={0.7} />
						</DLIconButton>
					</div>
				)}
			</StyledChildRow>
		</div>
	)
})

const StyledChildRow = styled.div`
	width: 100%;
	.left-side {
		.drag-icon {
		}
		.title-text {
			margin-left: 0.6rem;
		}
	}
	.right-side {
		.buttons-container {
			opacity: 0;
			transition: 0.4s;
		}
	}
	:hover {
		.buttons-container {
			opacity: 1;
		}
	}
	&.inactive {
		opacity: 0.5;
	}
`
