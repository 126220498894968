import React, { useState } from "react"
import { DLContextMenu } from "../../../../../components/basic-elements"
import { observer } from "mobx-react-lite"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import { Menu, MenuItem } from "@material-ui/core"
import Icon from "@mdi/react"
import { mdiCheck, mdiChevronRight } from "@mdi/js"
import { IconPlaceholder } from "../../../../../components/basic-elements/icons/common-icons"

const menuData = [
	{
		label: "Account Details",
		subItems: [
			"Account Number",
			"Split-up Account",
			"Account Description",
			"Extended Account Description",
			"Entity Abbreviation",
			"Entity Description",
			"Statement Type",
			"Normal Sign",
			"Account Division",
			"Sub-Account Number",
		],
	},
	{
		label: "Balances",
		subItems: [
			"Current Year Balance",
			"Prior Year 1 Balance",
			"Prior Year 2 Balance",
			"Prior Year 3 Balance",
			"Prior Year 4 Balance",
			"Current Year Budget",
			"Prior Year 1 Budget",
			"Prior Year 2 Budget",
			"Prior Year 3 Budget",
			"Prior Year 4 Budget",
		],
	},
	{
		label: "Custom Balances",
		subItems: ["Current Year", "Year 1", "Year 2", "Year 3", "Year 4"],
	},
	{
		label: "Grouping",
		subItems: [
			"Mapping Number",
			"L/S",
			"Group 2",
			"Group 3",
			"Group 4",
			"Group 5",
			"Group 6",
			"Group 7",
			"Group 8",
			"Group 9",
			"Group 10",
		],
	},
	{
		label: "Other",
		subItems: [
			"Tax Identifier",
			"Tax Identifier Description",
			"Unit 1",
			"Unit Description",
			"Unit 2",
			"GIFI",
		],
	},
	{
		label: "Foreign Exchange Rates",
		subItems: [
			"Current Year Balance Rate",
			"Prior Year 1 Balance Rate",
			"Prior Year 2 Balance Rate",
			"Prior Year 3 Balance Rate",
			"Prior Year 4 Balance Rate",
			"Current Year Budget Rate",
			"Prior Year 1 Budget Rate",
			"Prior Year 2 Budget Rate",
			"Prior Year 3 Budget Rate",
			"Prior Year 4 Budget Rate",
		],
	},
	{
		label: "Custom Balance Rates",
		subItems: ["Current Year", "Year 1", "Year 2", "Year 3", "Year 4"],
	},
]

const toCamelCase = (str: any) =>
	str
		.replace(/[^a-zA-Z0-9 ]/g, "")
		.replace(/(?:^|\s)(\w)/g, (_: any, c: any) =>
			c ? c.toUpperCase() : ""
		)
		.replace(/\s+/g, "")

export default observer(function MappingHeaderColumnCtxMenu({
	permission,
	partialStore,
}: {
	permission: PermissionAsObjectProps
	partialStore: any
}) {
	const { mouseX, mouseY } = partialStore.clickPoint
	const open = mouseY !== null && mouseX !== null
	const [subMenuAnchor, setSubMenuAnchor] = useState<null | HTMLElement>(null)
	const [currentMenu, setCurrentMenu] = useState<null | string>(null)

	const handleClose = () => {
		partialStore.setClickPoint({
			mouseX: null,
			mouseY: null,
		})
		// setSubMenuAnchor(null)
	}

	const handleSubMenuOpen = (
		event: React.MouseEvent<HTMLElement>,
		menu: string
	) => {
		event.stopPropagation()
		setSubMenuAnchor(event.currentTarget)
		setCurrentMenu(menu)
	}

	const handleSubMenuClose = (event: any) => {
		event.stopPropagation()
		setSubMenuAnchor(null)
	}

	const handleSetMappedColumn = (mappedColumn: string) => {
		partialStore.setColumnMapping(partialStore.selectedcolumn, mappedColumn)
	}

	console.log(subMenuAnchor, "subMenuAnchorsubMenuAnchor")

	const mappedColumn = partialStore.getSelectedMappedColumn()

	return (
		<Menu
			anchorReference="anchorPosition"
			anchorPosition={
				mouseY !== null && mouseX !== null
					? { top: mouseY, left: mouseX }
					: undefined
			}
			keepMounted
			open={open}
			onClose={handleClose}
		>
			{menuData.map((item) => {
				const value = toCamelCase(item.label)
				return (
					<MenuItem
						key={value}
						value={value}
						onClick={(e: any) => handleSubMenuOpen(e, value)}
					>
						{item.label}
						<Icon
							path={mdiChevronRight}
							size={1}
							style={{ marginLeft: "auto" }}
						/>
						{currentMenu === value && (
							<Menu
								anchorEl={subMenuAnchor}
								open={Boolean(subMenuAnchor)}
								onClose={handleSubMenuClose}
								anchorOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
								transformOrigin={{
									vertical: "top",
									horizontal: "left",
								}}
								// onBlur={handleSubMenuClose}
							>
								{item.subItems.map((subItem) => (
									<MenuItem
										key={toCamelCase(subItem)}
										value={toCamelCase(subItem)}
										onClick={() =>
											handleSetMappedColumn(
												toCamelCase(subItem)
											)
										}
									>
										{mappedColumn ===
										toCamelCase(subItem) ? (
											<Icon path={mdiCheck} size={1} />
										) : (
											<IconPlaceholder />
										)}
										{subItem}
									</MenuItem>
								))}
							</Menu>
						)}
					</MenuItem>
				)
			})}
		</Menu>
	)
})
