import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import { hasEmptyProps } from "@datalobby/common"
import {
	IdType,
	idToNumber,
} from "../../../../../../library/converters/id-converter"

const EditArchivePolicy = (self: any) => ({
	editArchivePolicy(title: string, description: string, policyId: string) {
		// 0.
		const actionName = "editArchivePolicy"
		// 1.
		const checkedProps = hasEmptyProps({ title, policyId })
		if (checkedProps.hasEmptyProps) {
			alert("Empty field exist")
			return
		}
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		/**
		 * PARAMETER or PAYLOAD READY
		 */
		const policyNumberId = idToNumber(policyId, IdType.archivePolicy)
		const payload = {
			Description: description,
			Id: policyNumberId,
			Title: checkedProps.props.title,
		}
		self.updateArchivePolicy(payload)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// console.log(actionName+ "__response " + response)
					/**
					 * REFLECT RESPONSE ON THE STORE
					 */
					// set success case response
					self.editPolicyInfo(title, description, policyId)
					self.setOpenEditDialog(false)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						// customMessage: "Success to" + actionName,
						open: true,
						autoHide: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message || response.data.message,
						open: true,
						autoHide: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default EditArchivePolicy
