import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
import { FileScreenDialog } from "../../data-models/dl-file-control-model"

export type AssignRoleProps = {
	fileId: string
	roleId: string
	type: "assign" | "unassign"
	multiple?: boolean
}

export const assignRoleActionPrefix = "assignRoleToFile_"
const AssignRoleToFile = (self: any) => ({
	assignRole(props: AssignRoleProps) {
		const { fileId, roleId, type, multiple } = props
		// 0.
		const actionName = assignRoleActionPrefix + roleId
		// 1.
		// 2.
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 0,
			color: MessageColorType.green,
			customMessage: "update is in progress...",
			open: true,
		})
		// 3.
		self.requestAssignRole(props)
			.then((response: any) => {
				// console.log(actionName+ "__response " + response)
				if (response.status === 200 && response.data.Status === 1) {
					/**
					 * REFLECT to screen
					 */
					self.reflectAssign(fileId, roleId, type)
					if (!multiple) {
						self.setFileTreeDialogOpen(
							FileScreenDialog.assign,
							false
						)
					}

					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: "Success to update the assigned role",
						open: false,
					})
				} else if (response === false) {
					//
				} else if (
					response.status === 200 &&
					response.data.Status === 0
				) {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						customMessage: response.data.Message,
						open: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						// message: response.data.Message,
						customMessage:
							"Something wrong..." + response.data.Message,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
	assignMultipleRoleToFile({
		fileId,
		prevRoles,
		roles,
	}: {
		fileId: string
		prevRoles: string[]
		roles: { id: string; checked: boolean }[]
	}) {
		// 0.
		const actionName = "assignMultipleRoleToFile"
		// 1.
		// 2.
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 0,
			color: MessageColorType.green,
			customMessage: "Update the assigned roles on the file...",
			open: false,
		})
		// 3.
		// console.log(actionName, fileId, prevRoles, roles)
		let assignList: string[] = []
		let unassignList: string[] = []
		roles.map((item) => {
			const prevRoleCheck = prevRoles.findIndex(
				(role) => role === item.id
			)
			/**
			 * * cases
			 * 1. this role is in the previous list & in the new list --> No Update
			 * 2. this role is in the previous list & NOT in the new list --> unassign
			 * 3. this role is NOT in the prev list & in the new list --> assign
			 * 4. this role is NOT in the prev list & NOT in the new list --> No Update
			 */
			if (prevRoleCheck !== -1 && item.checked === false) {
				unassignList.push(item.id)
			} else if (prevRoleCheck === -1 && item.checked) {
				assignList.push(item.id)
			}
		})

		assignList.map((item) => {
			self.assignRole({
				fileId,
				roleId: item,
				type: "assign",
				multiple: true,
			})
		})

		unassignList.map((item) => {
			self.assignRole({
				fileId,
				roleId: item,
				type: "unassign",
				multiple: true,
			})
		})

		self.handleResponse({
			actionName,
			status: ActionStatus.success,
			code: 200,
			color: MessageColorType.green,
			customMessage: "Update the assigned roles on the file...",
			open: false,
		})
	},
})

export default AssignRoleToFile
