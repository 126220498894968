import React, { useEffect } from "react"
import ProjDownloadCenterTable from "./sub-components/ProjDownloadCenterTable"
import { ActionStatus } from "@datalobby/types"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { ProjI18n } from "../../../../common-models/enumerations/translation-sheets"
import { getSnapshot } from "mobx-state-tree"
import { observer } from "mobx-react-lite"
import ProjNoPermissionOnMenu from "../../proj-access-control/ProjNoPermissionOnMenu"

export default observer(function ProjDownloadCenterController() {
	const store = useRootStore()
	const projStore = useProjStore()
	const pdcStore = projStore.projDownloadCenter
	const menuId = DLProjSubMenus.proj_download_center
	const i18nSheet = ProjI18n.projDownloadCenter
	//
	const permission = projStore.checkin.getActionsAsObject(menuId)
	const i18n = store.i18n.combineI18n(i18nSheet)
	const needRefresh = pdcStore.needRefresh
	const menuAccess = projStore.checkin.checkAccess(menuId)

	useEffect(() => {
		menuAccess && needRefresh && pdcStore.getProjDownloadCenterList()
	}, [menuAccess, needRefresh])

	return (
		<>
			{menuAccess ? (
				<ProjDownloadCenterTable
					partialStore={pdcStore}
					permission={permission}
					contentsHeight={store.ui.contentsAreaHeight - 100}
					actionStatus={pdcStore.getActionStatus(
						"getProjDownloadCenterList"
					)}
					dateFormat={store.global.dateFormat.value}
					i18n={i18n}
				/>
			) : (
				<ProjNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})
