export const MySummaryTableTitles = {
	finalArchiveDateWithIn5Days: "Based on Final Archive Deadline Date",
	expectedArchiveDateWithIn5Days: "Based on Expected Archive Deadline Date",
	finalArchiveDateWithIn20Days: "Based on Final Archive Deadline Date",
	expectedArchiveDateWithIn20Days: "Based on Expected Archive Deadline Date",
	finalArchiveDateOverdue: "Based on Final Archive Deadline Date",
	expectedArchiveDateOverdue: "Based on Expected Archive Deadline Date",
}

export const MySummaryDueInDays = {
	finalArchiveDeadlineDate_5: "finalArchiveDeadlineDate_5",
	expectedArchiveDeadlineDate_5: "expectedArchiveDeadlineDate_5",
	expectedReportDate_5: "expectedReportDate_5",
	finalArchiveDeadlineDate_20: "finalArchiveDeadlineDate_20",
	expectedArchiveDeadlineDate_20: "expectedArchiveDeadlineDate_20",
	expectedReportDate_20: "expectedReportDate_20",
	finalArchiveDeadlineDateOverdue: "finalArchiveDeadlineDateOverdue",
	expectedArchiveDeadlineDateOverdue: "expectedArchiveDeadlineDateOverdue",
}

export const DueDaysPropForMySummary = {
	dueDaysForFinalArchiveDeadlineDate: "dueDaysForFinalArchiveDeadlineDate",
	dueDaysForExpectedArchiveDeadlineDate:
		"dueDaysForExpectedArchiveDeadlineDate",
	dueDaysForExpectedReportDate: "dueDaysForExpectedReportDate",
	overdueFinalArchiveDeadlineDate: "dueDaysForFinalArchiveDeadlineDate",
	overdueExpectedArchiveDeadlineDate: "dueDaysForExpectedArchiveDeadlineDate",
}
