import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
import { hasEmptyProps } from "@datalobby/common"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 */
const EditGroupName = (self: any) => ({
	editGroupName(props: { groupName: string; groupId: string }) {
		// 0.
		const actionName = "editGroupName"
		// 1.
		const checkedProps = hasEmptyProps(props)
		if (checkedProps.hasEmptyProps) {
			alert("Empty field exist")
			return
		}
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. API Call
		const { groupName, groupId } = checkedProps.props
		const groupNumberId = idToNumber(groupId.toString(), IdType.group)

		const payload = {
			CabinetName: groupName,
			CabinetID: groupNumberId,
		}
		self.updateGroupName(payload)
			.then((response: any) => {
				// if success
				// ConsoleLog(response)
				if (response.data.Status === 1) {
					self.updateGroupNameStore(props.groupName, props.groupId)
					self.setEditGroupDialogOpen(false)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 111,
						color: MessageColorType.orange,
						open: true,
						customMessage: response.data.Message,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default EditGroupName
