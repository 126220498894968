import React from "react"
import { DLButton } from "../../../../../components/basic-elements"
import { observer } from "mobx-react-lite"
import Icon from "@mdi/react"
import { mdiClose, mdiShieldLockOutline } from "@mdi/js"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import { ProjectStatus } from "../../../../../common-models/enumerations/project-related-enums"
import { ActionStatus } from "@datalobby/types"
import { MessageColorType } from "../../../../../common-models/enumerations/common-enums"

export default observer(function ArchPreProcessMainActionBtns({
	partialStore,
	i18n,
	projectStatus,
	isArchChecklistCompleted,
}: {
	partialStore: any
	i18n: DLI18nProps
	projectStatus: ProjectStatus
	isArchChecklistCompleted: boolean
}) {
	return (
		<div className="archive-action-area FR AC">
			<DLButton
				eleTestId="start-pre-process-btn"
				startIcon={<Icon path={mdiShieldLockOutline} size={0.8} />}
				clickHandler={() => {
					if (!isArchChecklistCompleted) {
						partialStore.handleResponse({
							actionName: "startPreProcessing",
							status: ActionStatus.fail,
							code: 200,
							color: MessageColorType.red,
							open: true,
							autoHide: true,
							message:
								"Please save 1.Archive Checklist in Archive Diagnosis before Starting Pre-process.",
						})
						return
					}
					partialStore.setStartPreProcessDialogOpen(true)
				}}
				disabled={partialStore.preConditionData.isPreProcessed}
			>
				{i18n.twStart}
			</DLButton>
			{projectStatus !== ProjectStatus.replica && (
				<DLButton
					eleTestId="cancel-pre-process-btn"
					startIcon={<Icon path={mdiClose} size={0.8} />}
					clickHandler={() =>
						partialStore.setCancelPreProcessDialogOpen(true)
					}
					disabled={!partialStore.preConditionData.isPreProcessed}
				>
					{i18n.twCancel}
				</DLButton>
			)}
			{projectStatus !== ProjectStatus.archived && (
				<DLButton
					eleTestId="confirm-archive-btn"
					startIcon={<Icon path={mdiShieldLockOutline} size={0.8} />}
					clickHandler={() =>
						partialStore.setArchiveProjectDialogOpen(true)
					}
					disabled={!partialStore.preConditionData.isPreProcessed}
				>
					{i18n.twConfirm}
				</DLButton>
			)}
		</div>
	)
})
