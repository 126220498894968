import React from "react"
// for store
import { observer } from "mobx-react-lite"
// for input area
import InputWithLabel from "../../../../../components/basic-elements/input-with-label/InputWithLabel"
import sharedRegEx from "../../../../../library/sharedRegEx"
// validate form
import useForm from "../../../../../library/use-form"
// for UI
import styled from "styled-components"
import {
	DLButton,
	DLDialog,
	DLInputField,
	DLRadioGroup,
} from "../../../../../components/basic-elements"
import Icon from "@mdi/react"
import { mdiPlus } from "@mdi/js"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"

const AddFsGroupsCategoryDialog = observer(
	({ partialStore }: { partialStore: any }) => {
		// default setting
		const actionName = "addGroupCategory"
		//
		const inputsSchema = {
			name: { value: "", error: "", requestInput: false },
			number: { value: "", error: "", requestInput: false },
			sortOrder: { value: "", error: "", requestInput: false },
		}

		const validationSchema = {
			name: {
				isRequired: true,
				validator: {
					regEx: sharedRegEx.noSpecialCharacters,
					error: "Cannot use special characters",
				},
			},
			number: {
				isRequired: true,
				validator: {
					regEx: sharedRegEx.noSpecialCharacters,
					error: "Cannot use special characters",
				},
			},
			sortOrder: {
				isRequired: true,
				validator: {
					regEx: sharedRegEx.numbersOnly,
					error: "Numbers only",
				},
			},
		}

		const addGroupCategory = () => {
			const name = inputs.name.value
			const sortOrder = parseInt(inputs.sortOrder.value, 10)
			if (sortOrder === 0) {
				partialStore.handleResponse({
					actionName: "clickInfoButton",
					status: ActionStatus.fail,
					code: 200,
					color: MessageColorType.red,
					message:
						"Sorting Order should be greater than 0. Please enter a different Sorting Order.",
					open: true,
					autoHide: true,
				})
				return
			}
			// partialStore.addGroupCategory({
			// 	EngagementTypeItemName: engTypeName,
			// 	SortOrder: sortOrder,
			// 	IsActive: true,
			// })
		}

		const { inputs, handleOnChange, handleOnSubmit, isReady } = useForm(
			inputsSchema,
			validationSchema,
			addGroupCategory,
			partialStore.addDialogOpen // resetPoint of inputs and actionReady
		)

		return (
			<DLDialog
				eleTestId="add-group-category-dialog"
				isOpen={partialStore.addDialogOpen}
				setIsOpen={partialStore.setAddDialogOpen}
				handleAction={handleOnSubmit}
				showOpenBtn={false}
				showCloseBtn={true}
				dialogTitle={
					<div className="FR AC">
						<Icon
							path={mdiPlus}
							size={1}
							// color={MessageColorType.blue}
							style={{ marginRight: 8 }}
						/>
						Add Group Category
					</div>
				}
				dialogContents={
					<AddFsGroupsCategoryForm
						inputs={inputs}
						handleOnChange={handleOnChange}
						actionName={actionName}
						partialStore={partialStore}
					/>
				}
				cancelBtnText="Cancel"
				actionReady={
					isReady && !partialStore.isDuplicatedName(inputs.name.value)
				}
				actionBtn="Submit"
				maxWidth="sm"
				fullWidth={true}
				dialogError={
					partialStore.getActionStatus(actionName) !== "SUCCESS"
						? partialStore.responses.getResponse(actionName)
								?.message
						: undefined
				}
				showSpinner={
					partialStore.getActionStatus(actionName) === "LOADING"
				}
				cannotUpdate={
					partialStore.getActionStatus(actionName) === "LOADING"
				}
				useEnterKeyForSubmit // This one should be deprecated if the engType get more field in it
			/>
		)
	}
)

const AddFsGroupsCategoryForm = observer(
	({
		inputs,
		handleOnChange,
		actionName,
		partialStore,
	}: {
		inputs: any
		handleOnChange: any
		actionName: string
		partialStore: any
	}) => {
		return (
			<StyledAddDialog>
				<div className="input-section FR">
					<InputWithLabel required label={"Sorting Order"}>
						<DLInputField
							eleType="number"
							autoFocus
							eleTestId="sorting-order-input"
							eleFullWidth
							eleName="sortOrder"
							eleValue={inputs.sortOrder.value}
							eleHandleChange={handleOnChange}
							eleRequired
							warningMsg={
								inputs.sortOrder.error ||
								(partialStore.isDuplicatedSortOrder(
									parseInt(inputs.sortOrder.value, 10)
								) &&
									"The same Sort Order already exist")
							}
							warningType={
								partialStore.getActionStatus(actionName) !==
									"SUCCESS" &&
								partialStore.isDuplicatedSortOrder(
									parseInt(inputs.sortOrder.value, 10)
								)
									? "red"
									: undefined
							}
							requestInput={inputs.sortOrder.requestInput}
						/>
					</InputWithLabel>
				</div>
				<div className="input-section FR">
					<InputWithLabel required label={"Number"}>
						<DLInputField
							autoFocus
							eleTestId="number-input"
							eleFullWidth
							eleName="number"
							eleValue={inputs.number.value}
							eleHandleChange={handleOnChange}
							eleRequired
							warningMsg={
								inputs.number.error ||
								(partialStore.isDuplicatedNumber(
									inputs.number.value
								) &&
									"The same number already exist")
							}
							warningType={
								partialStore.getActionStatus(actionName) !==
									"SUCCESS" &&
								partialStore.isDuplicatedNumber(
									inputs.number.value
								)
									? "red"
									: undefined
							}
							requestInput={inputs.number.requestInput}
						/>
					</InputWithLabel>
				</div>
				<div className="input-section FR">
					<InputWithLabel required label={"Name"}>
						<DLInputField
							autoFocus
							eleTestId="name-input"
							eleFullWidth
							eleName="name"
							eleValue={inputs.name.value}
							eleHandleChange={handleOnChange}
							eleRequired
							warningMsg={
								inputs.name.error ||
								(partialStore.isDuplicatedName(
									inputs.name.value
								) &&
									"The same name already exist")
							}
							warningType={
								partialStore.getActionStatus(actionName) !==
									"SUCCESS" &&
								partialStore.isDuplicatedName(inputs.name.value)
									? "red"
									: undefined
							}
							requestInput={inputs.name.requestInput}
						/>
					</InputWithLabel>
				</div>
				<div className="input-section FR">
					<InputWithLabel required label={"Status"}>
						<DLRadioGroup
							groupName="itemStatus"
							disabled
							itemDirection="row"
							items={[
								{
									value: "Active",
									label: "Active",
								},
								{
									value: "Inactive",
									label: "Inactive",
								},
							]}
							selectedValue={"Active"}
							eleClassName="itemStatus"
						/>
					</InputWithLabel>
				</div>
			</StyledAddDialog>
		)
	}
)

const StyledAddDialog = styled.div`
	padding-left: 0.5rem;
	.input-section {
		margin-bottom: 1.5rem;
		.label {
			min-width: 9rem;
		}
		.input-area {
			/* width: calc(100% - 9rem); */
			min-width: 20rem;
		}
	}
`

export default AddFsGroupsCategoryDialog
