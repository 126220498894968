import { types } from "mobx-state-tree"
import ListOfFinancialGroupViewModel from "./list-of-financial-group.view-model"
import ListOfFinancialGroupModel from "./list-of-financial-group.model"

export const ListOfFinancialGroupStore = () =>
	types
		.compose(ListOfFinancialGroupModel(), ListOfFinancialGroupViewModel)
		.named("ListOfFinancialGroupStore")

export const initialStore = {
	clickPoint: {
		mouseX: null,
		mouseY: null,
	},
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const ListOfFinancialGroupStoreInstance =
	ListOfFinancialGroupStore().create(initialStore)

export default ListOfFinancialGroupStoreInstance
