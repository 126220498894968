import React, { useEffect } from "react"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import {
	DLDialog,
	InputWithLabel,
	DLInputField,
	DLSystemMsg,
	DLDialogHeader,
	ConsoleLog,
} from "../../../../../components/basic-elements"
import { I18nDialogFormProps } from "../../../../../common-models/types/dialog.props"
import sharedRegEx from "../../../../../library/sharedRegEx"
import useForm from "../../../../../library/use-form"
import { MessageColorType } from "../../../../../common-models/enumerations/common-enums"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import styled from "styled-components"
import {
	DLIcon,
	DLIconName,
	CommonIconSize,
} from "../../../../../components/basic-elements/icons/common-icons"

export default observer(function EditGroupDialog({
	i18n,
}: {
	i18n: DLI18nProps
}) {
	ConsoleLog(" -=-=-=-=- EditGroupDialog -=-=-=-=- ")
	const orgStore = useOrgStore()
	const actionName = "editGroupName"
	// default inputs
	const inputsSchema = {
		groupName: { value: "", error: "", requestInput: false },
	}

	const validationSchema = {
		groupName: {
			isRequired: true,
			validator: {
				regEx: sharedRegEx.noSpecialCharacters,
				error: "Invalid format",
			},
		},
	}

	const editGroupName = () => {
		const groupName = inputs.groupName.value
		const groupId = orgStore.setupGroups.selectedGroup
		orgStore.setupGroups.editGroupName({
			groupName,
			groupId,
		})
	}

	const {
		inputs,
		handleOnChange,
		handleOnSubmit,
		isReady,
		// initializeInputs
	} = useForm(inputsSchema, validationSchema, editGroupName)

	const groupInfo = orgStore.setupGroups.getGroupInfoById(
		orgStore.setupGroups.selectedGroup
	)
	const groupName = groupInfo?.groupName

	useEffect(() => {
		inputs.groupName.value = groupInfo?.groupName
	}, [groupInfo])

	const { twCancel, twUpdate } = i18n
	return (
		<DLDialog
			eleTestId="edit-org-group-dialog"
			isOpen={orgStore.setupGroups.editGroupDialogOpen}
			setIsOpen={orgStore.setupGroups.setEditGroupDialogOpen}
			showOpenBtn={false}
			showCloseBtn
			dialogTitle={
				<DLDialogHeader
					dialogName={i18n.twEditTitle}
					targetName={groupName ? groupName : "(Unknown group)"}
					icon={
						<DLIcon
							name={DLIconName.edit}
							size={CommonIconSize.dialogHeaderIcon}
						/>
					}
				/>
			}
			dialogContents={
				<EditGroupDialogForm
					inputs={inputs}
					handleOnChange={handleOnChange}
					i18n={i18n}
				/>
			}
			actionReady={isReady}
			handleAction={handleOnSubmit}
			actionBtn={twUpdate}
			cancelBtnText={twCancel}
			showSpinner={
				orgStore.setupGroups.getActionStatus(actionName) === "LOADING"
			}
			cannotUpdate={
				orgStore.setupGroups.getActionStatus(actionName) === "LOADING"
			}
			useEnterKeyForSubmit
		/>
	)
})

const EditGroupDialogForm = observer(
	({ inputs, handleOnChange, i18n }: I18nDialogFormProps) => {
		const orgStore = useOrgStore()

		return (
			<StyledEditGroupDialog>
				<div className="input-section FR">
					<InputWithLabel required label={i18n.twName}>
						<DLInputField
							autoFocus
							eleTestId="edit_group_name_input"
							eleFullWidth
							eleName="groupName"
							eleValue={inputs.groupName.value}
							eleHandleChange={handleOnChange}
							eleRequired
							warningMsg={inputs.groupName.error}
							requestInput={inputs.groupName.requestInput}
						/>
						{orgStore.setupGroups.isDuplicatedName(
							inputs.groupName.value
						) &&
							orgStore.setupGroups
								.getGroupInfoById(
									orgStore.setupGroups.selectedGroup
								)
								?.groupName.toLowerCase() !==
								inputs.groupName.value.toLowerCase() && (
								<DLSystemMsg
									type={MessageColorType.red}
									msg={i18n.twNameValidation}
								/>
							)}
					</InputWithLabel>
				</div>
			</StyledEditGroupDialog>
		)
	}
)
const StyledEditGroupDialog = styled.div`
	padding-left: 0.5rem;
	.input-section {
		margin-bottom: 1.5rem;
		.label {
			min-width: 9rem;
		}
		.input-area {
			/* width: calc(100% - 9rem); */
			min-width: 20rem;
		}
	}
`
