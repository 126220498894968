import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../components/basic-elements"

export type EditFileFolderProps = {
	folderId: string
	folderName: string
	postAction?: any
}

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - loading, success, fail
 *
 */

const EditFileFolder = (self: any) => ({
	editFileFolder({ folderId, folderName, postAction }: EditFileFolderProps) {
		// 0.
		const actionName = "editFileFolder"
		// const menuId = self.storeName
		// Validate folder name
		if (!folderName) {
			alert("Cannot use empty title. Please try again.")
			return
		}
		// Show loading status
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 0,
			color: MessageColorType.green,
			open: true,
		})
		// 3. Attempt to update folder
		self.updateFileFolder({ folderId, folderName })
			.then((response: any) => {
				// console.log(actionName+ "__response " + response)
				if (response.status === 200 && response.data.Status === 1) {
					// Reflect the updated folder name in UI
					self.reflectFolderName(folderId, folderName)
					postAction && postAction()
					// Show success message
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
					})
				} else if (response === false) {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 400,
						color: MessageColorType.red,
						open: true,
						message:
							"Failed to update the folder. Please try again.",
					})
				} else {
					// Handle unexpected failure response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
						message: response.data.Message,
					})
				}
			})
			.catch((error: Error) => {
				ConsoleLog([actionName, "__error ", error])
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default EditFileFolder
