import React, { useState, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { useOrgStore } from "../../../stores/org-store/org-store.provider"
import { PageContainer } from "../../../components/app-frame-elements"
import ProjBasicCtxMenus from "../../../components/combined-elements/projects-table/ProjBasicCtxMenus"
import { DLOrgSubMenus } from "../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { OrgI18n } from "../../../common-models/enumerations/translation-sheets"
import { ProjScreenType } from "../../../components/combined-elements/projects-table/projects-table-props"
import { ProjectStatus } from "../../../common-models/enumerations/project-related-enums"
import { StyledSharedProjectsTable } from "../../../components/combined-elements/projects-table/StyledSharedProjectsTable"
import OrgNoPermissionOnMenu from "../org-access-control/OrgNoPermissionOnMenu"
import {
	getDefaultFinancialYear,
	getPageSettings,
	savePageSetting,
} from "../../../library/get-page-settings"
import { ConsoleLog } from "../../../components/basic-elements"

import { handleExport } from "../../../components/basic-elements/export/ExportXLSX"

import SharedProjectTableV8 from "../../../components/combined-elements/projects-table/SharedProjectTableV8"
import PreProcessDialog from "../archive-management/am-archived-projects/dialogs/PreProcessDialog"
import ProjLocationDetailsDialog from "../../project-side/project-information/sub-components/ProjLocationDetailsDialog"
import DeleteProjectDialog from "../../../components/combined-elements/delete-project-dialog/DeleteProjectDialog"

export default observer(function ReplicaProjectsController() {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const menuId = DLOrgSubMenus.replicas
	const projStatus = ProjectStatus.replica
	const actionName = "getOrgProjectList_" + projStatus
	const i18nSheet = OrgI18n.projects
	//
	const menuAccess = orgStore.checkin.checkAccess(menuId)
	const permission = orgStore.checkin.getActionsAsObject(menuId)

	const needRefresh = orgStore.projects.needRefreshForReplica
	//
	const [filteredData, setFilteredData] = useState<any>([])
	//
	const defaultYear = getDefaultFinancialYear(menuId)
	const dntFormat = store.global.getDntFormat
	useEffect(() => {
		if (menuAccess && needRefresh) {
			const currentYear = new Date().getFullYear()
			const pageSetting = getPageSettings(menuId)
			if (pageSetting?.columnFilters) {
				const value = pageSetting?.columnFilters.filter(
					(item: any) => item.id !== "financialYear"
				)
				savePageSetting("columnFilters", value, `pageSetting_${menuId}`)
			}
			orgStore.projects.getOrgProjectList({
				projStatus: ProjectStatus.replica,
				year: currentYear.toString(),
				PTMAssignedOnly: false,
				menuId,
				ForROAM: false,
				dntFormat,
			})
		}
	}, [menuAccess, needRefresh])

	const handleCtxMenuClose = () => {
		orgStore.projects.setClickPoint({
			mouseX: null,
			mouseY: null,
		})
	}

	const getUsersAndFilesCount = () => {
		let projectIdList: string[] = []
		filteredData.map((data: any) => {
			projectIdList.push(data.id)
		})

		ConsoleLog(["getUsersAndFilesCount", filteredData[0], projectIdList])

		orgStore.projects.getUsersAndFilesCountOfProjects(filteredData, 0)
	}
	const downloadResult = () => {
		const data = orgStore.projects.usersAndFilesCountOfProjects
		ConsoleLog(["user and file counting result", data])
		const fileName =
			localStorage.getItem("orgName") +
			" - Replicas (" +
			defaultYear +
			")"
		// handleExport2(data, fileName)
		handleExport(data, fileName)
	}

	const partialStore = orgStore.projects
	const loadingStatus = orgStore.projects.getActionStatus(actionName)

	const clickPoint = {
		mouseX: orgStore.projects.clickPoint.mouseX,
		mouseY: orgStore.projects.clickPoint.mouseY,
	}
	const i18n = store.i18n.combineI18n(i18nSheet)
	const userRole = orgStore.checkin.assignedRole.shortName
	const clickPointExist = partialStore.clickPoint.mouseX !== null

	return (
		<>
			{menuAccess ? (
				<PageContainer
					pageTools={<div />}
					hasToolbar={false}
					fullWidth
					fixedHeight
					isLoading={loadingStatus === "LOADING"}
				>
					<StyledSharedProjectsTable>
						<SharedProjectTableV8
							// NOTE: Need to compare with dashboard table for consistency
							// common part 1
							partialStore={partialStore}
							i18n={i18n}
							// common part 2
							projScreenType={ProjScreenType.replica}
							menuId={DLOrgSubMenus.replicas}
							projectStatus={ProjectStatus.replica}
							permission={permission}
						/>
					</StyledSharedProjectsTable>
					{clickPointExist && (
						<ProjBasicCtxMenus
							projStatus={ProjectStatus.replica}
							partialStore={partialStore}
							permission={permission}
							clickPoint={clickPoint}
							handleClose={handleCtxMenuClose}
							userRole={userRole}
						/>
					)}
					{partialStore.openDeleteProjDialog && (
						// <DeleteProjectDialog
						// 	projStatus={ProjectStatus.replica}
						// 	partialStore={partialStore}
						// 	i18n={i18n}
						// 	projectInfo={partialStore.viewSelectedProjInfo(
						// 		partialStore.selectedProj,
						// 		ProjectStatus.replica
						// 	)}
						// />
						<DeleteProjectDialog
							i18n={i18n}
							isOpen={partialStore.openDeleteProjDialog}
							setIsOpen={partialStore.setOpenDeleteProjDialog}
							deleteAction={() =>
								orgStore.projects.removeProject({
									projectId: partialStore.selectedProj,
									cabinetId: partialStore.selectedCabinetId,
									handleResponse: partialStore.handleResponse,
									postWorkForSuccess: () => {
										partialStore.popItemFromProjectList(
											partialStore.selectedProj,
											projStatus
										)
										partialStore.setOpenDeleteProjDialog(
											false
										)
										partialStore.resetSelectedItems()
										partialStore.resetSelectedProj()
									},
								})
							}
							projectInfo={partialStore.viewSelectedProjInfo(
								partialStore.selectedProj,
								ProjectStatus.replica
							)}
						/>
					)}
					{orgStore.projects.preProcessDialog && (
						<PreProcessDialog partialStore={orgStore.projects} />
					)}
					{orgStore.projects.openProjLocationDialog && (
						<ProjLocationDetailsDialog
							partialStore={orgStore.projects}
							view={store.global.sidebarViewState}
						/>
					)}
				</PageContainer>
			) : (
				<OrgNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})
