import React, { useState } from "react"
import { List, ListItem, ListItemText } from "@material-ui/core"
import {
	mdiChevronRight,
	mdiChevronLeft,
	mdiArrowUp,
	mdiArrowDown,
} from "@mdi/js"
import styled from "styled-components"
import Icon from "@mdi/react"
import { DLButton, DLIconButton } from "../buttons"

const ReorderTableColumns = ({
	columns,
	setColumnVisibility,
	setColumnOrder,
	handleCancel,
}: {
	columns: any
	setColumnVisibility: any
	setColumnOrder: any
	handleCancel: any
}) => {
	const [activeColumns, setActiveColumns] = useState<any>(
		columns.filter((item: any) => item.getIsVisible())
	)
	const [hiddenColumns, setHiddenColumns] = useState<any>(
		columns.filter((item: any) => !item.getIsVisible())
	)
	const [selectedActive, setSelectedActive] = useState<any>([])
	const [selectedHidden, setSelectedHidden] = useState<any>([])

	const moveToHidden = () => {
		setHiddenColumns([...hiddenColumns, ...selectedActive])
		setActiveColumns(
			activeColumns.filter((col: any) => !selectedActive.includes(col))
		)
		setSelectedActive([])
	}

	const moveToActive = () => {
		setActiveColumns([...activeColumns, ...selectedHidden])
		setHiddenColumns(
			hiddenColumns.filter((col: any) => !selectedHidden.includes(col))
		)
		setSelectedHidden([])
	}

	const handleSelect = (column: any, list: any, setList: any) => {
		setList((prev: any) =>
			prev.includes(column)
				? prev.filter((c: any) => c !== column)
				: [...prev, column]
		)
	}

	const moveItem = (direction: any) => {
		setActiveColumns((prev: any) => {
			const newOrder = [...prev]
			selectedActive.sort(
				(a: any, b: any) =>
					prev.findIndex((c: any) => c === a) -
					prev.findIndex((c: any) => c === b)
			)
			if (direction === -1) {
				selectedActive.forEach((col: any) => {
					const index = newOrder.findIndex((c) => c === col)
					if (index > 0) {
						;[newOrder[index], newOrder[index - 1]] = [
							newOrder[index - 1],
							newOrder[index],
						]
					}
				})
			} else {
				selectedActive.reverse().forEach((col: any) => {
					const index = newOrder.findIndex((c) => c === col)
					if (index < newOrder.length - 1) {
						;[newOrder[index], newOrder[index + 1]] = [
							newOrder[index + 1],
							newOrder[index],
						]
					}
				})
			}
			return newOrder
		})
	}

	const handleApply = () => {
		const arr1Ids = new Set(columns.map((item: any) => item.id))

		const visibleColumns: any = {}
		activeColumns.forEach((item: any) => {
			visibleColumns[item.id] = arr1Ids.has(item.id)
		})
		columns.forEach((item: any) => {
			if (!(item.id in visibleColumns)) {
				visibleColumns[item.id] = false
			}
		})
		setColumnVisibility(visibleColumns)
		setColumnOrder(Object.keys(visibleColumns))
	}

	return (
		<div>
			<Container>
				<ButtonContainer>
					<DLIconButton
						size="medium"
						eleTestId="sort-up"
						clickHandler={() => moveItem(-1)}
						disabled={selectedActive.length === 0}
					>
						<Icon path={mdiArrowUp} size={1} />
					</DLIconButton>
					<DLIconButton
						size="medium"
						eleTestId="sort-down"
						clickHandler={() => moveItem(1)}
						disabled={selectedActive.length === 0}
					>
						<Icon path={mdiArrowDown} size={1} />
					</DLIconButton>
				</ButtonContainer>
				<ColumnContainer>
					<Title>Active Columns</Title>
					<List
						style={{
							height: 400,
							overflow: "hidden",
							overflowY: "scroll",
						}}
					>
						{activeColumns.map((col: any) => (
							<ListItem
								button
								selected={selectedActive.includes(col)}
								onClick={() =>
									handleSelect(
										col,
										selectedActive,
										setSelectedActive
									)
								}
								key={col.accessorKey}
								style={{
									backgroundColor: selectedActive.includes(
										col
									)
										? "#d3d3d3"
										: "inherit",
								}}
							>
								<ListItemText
									primary={
										col.columnDef.header?.props?.title ||
										typeof col.columnDef.header === "string"
											? col.columnDef.header
											: React.Children.map(
													col.columnDef.header.props
														.children,
													(child) =>
														typeof child ===
														"string"
															? child
															: " "
											  ).join("")
									}
								/>
							</ListItem>
						))}
					</List>
				</ColumnContainer>
				<ButtonContainer>
					<DLIconButton
						color="primary"
						clickHandler={moveToHidden}
						eleTestId="move-to-hidden"
						disabled={selectedActive.length === 0}
						size="medium"
					>
						<Icon
							path={mdiChevronRight}
							size={1}
							className="sorting-icon"
						/>
					</DLIconButton>
					<DLIconButton
						color="primary"
						clickHandler={moveToActive}
						eleTestId="move-to-active"
						disabled={selectedHidden.length === 0}
						size="medium"
					>
						<Icon
							path={mdiChevronLeft}
							size={1}
							className="sorting-icon"
						/>
					</DLIconButton>
				</ButtonContainer>
				<ColumnContainer>
					<Title>Hidden Columns</Title>
					<List
						style={{
							height: 400,
							overflow: "hidden",
							overflowY: "scroll",
						}}
					>
						{hiddenColumns.map((col: any) => (
							<ListItem
								button
								selected={selectedHidden.includes(col)}
								onClick={() =>
									handleSelect(
										col,
										selectedHidden,
										setSelectedHidden
									)
								}
								key={col.accessorKey}
								style={{
									backgroundColor: selectedHidden.includes(
										col
									)
										? "#d3d3d3"
										: "inherit",
								}}
							>
								<ListItemText
									primary={
										col.columnDef.header?.props?.title ||
										typeof col.columnDef.header === "string"
											? col.columnDef.header
											: React.Children.map(
													col.columnDef.header.props
														.children,
													(child) =>
														typeof child ===
														"string"
															? child
															: " "
											  ).join("")
									}
								/>
							</ListItem>
						))}
					</List>
				</ColumnContainer>
			</Container>
			<div className="FR JSB AC">
				<DLButton
					eleTestId="cancel-changes"
					eleClassName="cancel-btn"
					clickHandler={handleCancel}
					variant="text"
				>
					Cancel
				</DLButton>
				<DLButton
					eleTestId="apply-changes"
					eleClassName="apply-btn"
					clickHandler={handleApply}
					color="primary"
				>
					Apply
				</DLButton>
			</div>
		</div>
	)
}

export default ReorderTableColumns

const Container = styled.div`
	display: flex;
	justify-content: center;
	gap: 20px;
	padding: 20px;
`

const ColumnContainer = styled.div`
	padding: 16px;
	width: 300px;
	border: 1px solid #ccc;
	border-radius: 8px;
	background-color: #f9f9f9;
`

const Title = styled.div`
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 10px;
`

const ButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 10px;
	.center {
		height: 32px;
	}
`
