import { types } from "mobx-state-tree"
import ListOfMapNumbersViewModel from "./list-of-map-numbers.view-model"
import ListOfMapNumbersModel from "./list-of-map-numbers.model"

export const ListOfMapNumbersStore = () =>
	types
		.compose(ListOfMapNumbersModel(), ListOfMapNumbersViewModel)
		.named("ListOfMapNumbersStore")

export const initialStore = {
	clickPoint: {
		mouseX: null,
		mouseY: null,
	},
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const ListOfMapNumbersStoreInstance =
	ListOfMapNumbersStore().create(initialStore)

export default ListOfMapNumbersStoreInstance
