import React, { useState } from "react"
import { PageContainer } from "../../../../components/app-frame-elements"
import { ActionStatus } from "@datalobby/types"
import styled from "styled-components"
import ReactTableV8 from "../../../../components/basic-elements/tables/DLReactTable"
import { DLButton } from "../../../../components/basic-elements"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { FormControl, MenuItem, Select, TextField } from "@material-ui/core"
import { StyledHeader } from "../../../../components/app-frame-elements/header/StyledHeader"
import { Column, Table } from "@tanstack/react-table"
import { InputFieldForTableV8 } from "../../../../components/basic-elements/tables/InputFieldForTable"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { dummyData } from "./dummyData"

const M_SpecifyDataProperties = ({
	actionStatus,
	permission,
}: {
	actionStatus: any
	permission: any
}) => {
	const store = useRootStore()

	const [properties, setProperties] = useState({
		fieldSeperator: "tab",
		textQualifier: "quote",
		excludeFirstRows: 0,
		excludeLastRows: 0,
	})

	const handleForm = (name: string, value: any) => {
		setProperties((prev: any) => ({
			...prev,
			[name]: value,
		}))
	}

	const trimmedArr = dummyData.slice(
		properties.excludeFirstRows,
		dummyData.length - properties.excludeLastRows
	)

	const pageHeader = (instance: Table<any>) => {
		const totalERPData = instance.getPreFilteredRowModel().flatRows.length
		const filteredClients = instance.getFilteredRowModel().flatRows.length

		return (
			<StyledHeader style={{ width: "100%" }}>
				<div className="FR" style={{ marginTop: 15, gap: 15 }}>
					<div className="FR AC" style={{ width: "50%", gap: 10 }}>
						<div>Field Seperator</div>
						<FormControl
							style={{ marginTop: 5, width: 200 }}
							fullWidth
							size="small"
						>
							<Select
								labelId="period-label"
								id="fieldSeperator"
								value={properties.fieldSeperator}
								variant="outlined"
								displayEmpty
								disabled
								placeholder="Select fieldSeperator"
								onChange={(e) =>
									handleForm("fieldSeperator", e.target.value)
								}
							>
								<MenuItem value={"tab"}>Normal</MenuItem>
							</Select>
						</FormControl>
					</div>
					<div className="FR AC" style={{ width: "50%", gap: 10 }}>
						<div>Text Qualifier</div>
						<FormControl
							style={{ marginTop: 5, width: 200 }}
							fullWidth
							size="small"
						>
							<Select
								labelId="period-label"
								id="textQualifier"
								value={properties.textQualifier}
								variant="outlined"
								displayEmpty
								placeholder="Select"
								onChange={(e) =>
									handleForm("textQualifier", e.target.value)
								}
							>
								<MenuItem value={"quote"}>""</MenuItem>
							</Select>
						</FormControl>
					</div>
				</div>
				<div className="FR" style={{ marginTop: 15, gap: 15 }}>
					<div className="FR AC" style={{ width: "50%", gap: 10 }}>
						<div>Exclude first</div>
						<FormControl
							style={{ marginTop: 5, width: 200 }}
							fullWidth
							size="small"
						>
							<TextField
								hiddenLabel
								id="excludeFirstRows"
								type="number"
								value={properties.excludeFirstRows}
								variant="outlined"
								size="small"
								onChange={(e: any) =>
									e.target.value >= 0 &&
									handleForm(
										"excludeFirstRows",
										e.target.value
									)
								}
							/>
						</FormControl>
						<div>Rows</div>
					</div>
					<div className="FR AC" style={{ width: "50%", gap: 10 }}>
						<div>Exclude Last</div>
						<FormControl
							style={{ marginTop: 5, width: 200 }}
							fullWidth
							size="small"
						>
							<TextField
								hiddenLabel
								type="number"
								id="excludeLastRows"
								value={properties.excludeLastRows}
								variant="outlined"
								size="small"
								onChange={(e: any) =>
									e.target.value >= 0 &&
									handleForm(
										"excludeLastRows",
										e.target.value
									)
								}
							/>
						</FormControl>
						<div>Rows</div>
					</div>
				</div>
			</StyledHeader>
		)
	}

	return (
		<PageContainer
			hasToolbar={false}
			pageTools={<div />}
			fullWidth
			isLoading={actionStatus === ActionStatus.loading}
		>
			<StyledPage>
				<h3>Specify Data Properties</h3>

				<ReactTableV8
					tableColumns={columns()}
					data={trimmedArr}
					hasPagination={true}
					customFilters={(props: any) =>
						filterComponent({ ...props })
					}
					height={store.ui.contentsAreaHeight - 60}
					permission={permission}
					pageHeader={pageHeader}
					menuId={DLProjSubMenus.m_setup_groups}
					showNewReorderDialog={true}
				/>

				<div className="FR JR" style={{ marginTop: 20 }}>
					<DLButton
						variant="contained"
						eleTestId="cancel"
						clickHandler={() => {}}
						color="primary"
					>
						Cancel
					</DLButton>
				</div>
			</StyledPage>
		</PageContainer>
	)
}

export default M_SpecifyDataProperties

const filterComponent = ({
	column,
	table,
}: {
	column: Column<any>
	table: Table<any>
}) => {
	switch (column.id) {
		case "actions":
			return

		default:
			return (
				<InputFieldForTableV8
					columnId={column.id}
					onChange={column.setFilterValue}
					value={column.getFilterValue()}
				/>
			)
	}
}

const columns = () => {
	return [
		{
			header: "Account Number",
			accessorKey: "accountNumber",
		},
		{
			header: "Description",
			accessorKey: "description",
		},
		{
			header: "Type",
			accessorKey: "type",
		},
		{
			header: "LS",
			accessorKey: "ls",
		},
		{
			header: "Ratio Class",
			accessorKey: "ratioClass",
		},
		{
			header: "Cur. P1 Balance",
			accessorKey: "curP1Balance",
		},
		{
			header: "PY1 P1 Balance",
			accessorKey: "py1P1Balance",
		},
		{
			header: "PY2 P1 Balance",
			accessorKey: "py2P1Balance",
		},
	]
}

const StyledPage = styled.div`
	padding-left: 1rem;
	padding-right: 1rem;
	.form-row {
		div:first-child {
			flex: 1;
		}
		div:last-child {
			flex: 3;
		}
	}
`
