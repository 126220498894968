import React from "react"
import { DLContextMenu } from "../../../../../components/basic-elements"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import { ConsoleLog } from "../../../../../components/basic-elements"

export default observer(function UserNotificationSettingCtxMenus({
	permission,
}: {
	permission: PermissionAsObjectProps
}) {
	ConsoleLog(" -=-=-=-=-$ UserNotificationSettingCtxMenus $-=-=-=-=- ")

	const orgStore = useOrgStore()

	const handleClose = () => {
		orgStore.setupUsers.setClickPoint({
			mouseX: null,
			mouseY: null,
		})
	}

	const userInfo = orgStore.setupUsers.viewSelectedUserInfo()

	console.log(permission, "permission1")

	console.log(
		permission.update && userInfo?.status !== "suspended",
		"permission2"
	)

	const menuOptions = [
		{
			label: "Configure Notification Permission",
			value: "ctx-config-notification-perm",
			available: permission.update,
			clickEvent: () => {
				orgStore.setupUsers.setOrgNotificationPermissionDialogOpen(true)
			},
		},
		// {
		// 	label: "User Details",
		// 	value: "ctx-detail",
		// 	available: permission.read,
		// 	isLink: true,
		// 	linkTo: "/organization/organization-setup/organization-user-list/detail",
		// 	clickEvent: () => {},
		// },
	]

	return (
		<DLContextMenu
			eleId="config-notification-perm-ctx-menus"
			clickPoint={{
				mouseX: orgStore.setupUsers.clickPoint.mouseX,
				mouseY: orgStore.setupUsers.clickPoint.mouseY,
			}}
			handleClose={handleClose}
			menuOptions={menuOptions}
			hasDivider={[0, 3]}
		/>
	)
})
