import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"

const RemoveProjDataMgmtZipFileById = (self: any) => ({
	removeProjDataMgmtZipFileById(zipFileId: number) {
		const actionName = "removeProjDataMgmtZipFileById"
		const isConfirmed = window.confirm(
			"Are you sure you want to delete this record?"
		)
		if (!isConfirmed) {
			return
		}
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})

		self.deleteProjDataMgmtZipFileById(zipFileId)
			.then((response: any) => {
				// if success
				if (response.status === 200) {
					// self.spliceItemFromList(zipFileId)
					self.updateDeletedItemStatus(zipFileId)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage:
							"Success to delete the proj download center record",
						open: true,
						autoHide: true,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message || response.data.message,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default RemoveProjDataMgmtZipFileById
