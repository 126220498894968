import React from "react"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { DLContextMenu } from "../../../../../components/basic-elements"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import { DLObjectFormat } from "../../../../../common-models/enumerations/common-enums"
import { OrgI18n } from "../../../../../common-models/enumerations/translation-sheets"
import {
	DLIcon,
	DLIconName,
	CommonIconSize,
	MoveIcon,
	FolderAddIcon,
	PlusIcon,
} from "../../../../../components/basic-elements/icons/common-icons"
import { CreateProjectMethod } from "../../../../../components/combined-elements/create-project/create-project.data-props"

export default observer(function OrgGroupCabinetCtxMenu({
	permission,
}: {
	permission: PermissionAsObjectProps
}) {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const sheetId = OrgI18n.groups

	const handleClose = () => {
		orgStore.groups.setClickPoint({
			mouseX: null,
			mouseY: null,
		})
	}
	/**
	 * (action name ) --> actionName on the permission
	 * NOTE: some permission are shared with multiple actions.
	 * (ex. create project and create cabinet are share the permission of 'create' )
	 * - create project --> create
	 * - add cabinet --> createCabinet
	 * - edit cabinet name --> update
	 * - move cabinet --> move
	 * - delete cabinet --> delete
	 */
	const handleAddCabinet = () => {
		const cabinetId = orgStore.groups.rightClickTarget
		orgStore.groups.pushItemToCurrentPath(cabinetId)
		orgStore.groups.setOpenCreateCabinetDialog(true)
		orgStore.groups.getOrgCabinetDetail(cabinetId)
	}

	const handleEditCabinet = () =>
		orgStore.groups.setOpenEditCabinetDialog(true)

	const handleRemoveCabinet = () => {
		const cabinetId = orgStore.groups.rightClickTarget
		const cabinetName = orgStore.groups.getNameById(cabinetId)
		let proceed = window.confirm(
			`Sub cabinet and files also be deleted. Do you really want to delete <${cabinetName}> cabinet?`
		)
		if (!proceed) return
		orgStore.groups.removeCabinetInGroup(cabinetId)
	}
	const handleMoveCabinet = () => orgStore.groups.setOpenMoveDialog(true)

	const count = orgStore.groups.selectedItems.length

	const menuOptions = (i18n: { [x: string]: string }) => {
		return [
			{
				label: i18n.twAddProject || "Add Project",
				value: "ctx-add_project",
				available: permission.create && count === 1,
				icon: <PlusIcon />,
				clickEvent: () => {
					orgStore.groups.setCreateProjectMethod(
						CreateProjectMethod.byBlank
					)
					orgStore.groups.setOpenCreateProjDialog(true)
				},
			},
			{
				label: i18n.twAddCabinet || "Add Cabinet",
				value: "ctx-add_sub_cabinet",
				available: permission.create && count === 1,
				icon: <FolderAddIcon />,
				clickEvent: handleAddCabinet,
			},
			{
				label:
					`${i18n.twEdit} ${i18n.twCabinetName}` ||
					"Edit Cabinet Name",
				value: "ctx-edit_cabinet",
				available: permission.update && count === 1,
				icon: (
					<DLIcon
						name={DLIconName.edit}
						size={CommonIconSize.ctxMenuIcon}
					/>
				),
				clickEvent: handleEditCabinet,
			},
			{
				label: i18n.twMoveCabinet || "Move Cabinet",
				value: "ctx-move_cabinet",
				available: permission.move && count === 1,
				icon: <MoveIcon />,
				clickEvent: handleMoveCabinet,
			},
			{
				label: i18n.twDelete || "Delete",
				value: "ctx-delete_cabinet",
				available: permission.delete,
				icon: (
					<DLIcon
						name={DLIconName.delete}
						size={CommonIconSize.ctxMenuIcon}
					/>
				),
				clickEvent: handleRemoveCabinet,
			},
		]
	}
	//

	const i18n = store.i18n.combineI18n(sheetId)
	let selectedItemType = orgStore.groups.rightClickTargetInfo?.type
	let cabinetCtxMenuOpen = selectedItemType === DLObjectFormat.cabinet
	return (
		<DLContextMenu
			eleId="cabinet-in-group-ctx-menus"
			clickPoint={{
				mouseX: cabinetCtxMenuOpen
					? orgStore.groups.clickPoint.mouseX
					: null,
				mouseY: cabinetCtxMenuOpen
					? orgStore.groups.clickPoint.mouseY
					: null,
			}}
			handleClose={handleClose}
			menuOptions={menuOptions(i18n)}
			hasDivider={[0, 3]}
		/>
	)
})
