import { types } from "mobx-state-tree"
// model common action
import { CommonModelActions } from "../../../../../common-models/common-model-actions"
// separated actions

import {
	ReadAmArchivedProjectList,
	ReadReadOnlyUsers,
	ReadFilteredUsers,
	DeleteReadOnlyUser,
	AddReadOnlyUser,
	UpdateReadOnlyUser,
	DeleteAmArchivedProject,
	UpdateArchivedProjectUnarchive,
	ReadExportArchivedZipFile,
	ReadVerifyDigitalSignatureHashValue,
	MReleaseForceArchive,
} from "./model-actions"
import { AmArchivedApiProps } from "./am-archived-projects.apis"

import RequestRegenPreProcessPdfs from "./model-actions/create-pre-process-pdfs"
import RequestGenerateForceArchivedPreProcessPdfs from "./model-actions/create-force-archived-pre-process-pdfs"
import DeleteForceArchivedPreProcessPdfs from "./model-actions/delete-force-archived-pre-process-pdfs"
import RequestGenerateForceArchivedZip from "./model-actions/create-force-archived-zip"
import RequestRepairReplica from "./model-actions/request-repair-replica"
import ReadFilters from "../../am-archived-projects-2/store/model-actions/read-filters"
import ReadAmArchivedProjectList2 from "../../am-archived-projects-2/store/model-actions/read-am-archived-projects2"
import { ReadArchiveDiagnosis } from "../../../../project-side/archive/archive-diagnosis/store/model-actions"
import { read as readArchiveDiagnosisApi } from "../../../../project-side/archive/archive-diagnosis/store/archive-diagnosis.apis"
import ReadPreConditionStatus from "./model-actions/read-pre-condition-status"
import ReadForceArchivedPreConditionStatus from "./model-actions/read-force-archived-pre-condition-status"
import ReadCabinetLocation from "../../../../project-side/project-information/store/model-actions/read-cabinet-location"

const AmArchivedProjectsModel = ({
	apiRead,
	apiUnarchiveProject,
	apiDelete,
	// readonly assign related
	apiAddAccessMgmtUser,
	apiReadAccessMgmtUser,
	apiReadFilteredUsers,
	apiDeleteReadOnlyUser,
	apiUpdateReadOnlyUser,
	apiRepairReplica,
	apiCheckPreConditionStatus,
	apiForceArchivedCheckPreConditionStatus,
	apiReadFilters,
	apiReleaseForceArchive,
	apiReadCabinetLocation,
}: // apiStartPreProcess,
AmArchivedApiProps) =>
	types
		.model({})
		// CRUD actions
		.actions((self) => ReadAmArchivedProjectList(self, apiRead))
		.actions((self) => ReadAmArchivedProjectList2(self, apiRead))
		.actions((self) => ReadReadOnlyUsers(self, apiReadAccessMgmtUser))
		.actions((self) => ReadFilteredUsers(self, apiReadFilteredUsers))
		.actions((self) => AddReadOnlyUser(self, apiAddAccessMgmtUser))
		.actions((self) => UpdateReadOnlyUser(self, apiUpdateReadOnlyUser))
		.actions((self) => DeleteReadOnlyUser(self, apiDeleteReadOnlyUser))
		.actions((self) => DeleteAmArchivedProject(self, apiDelete))
		.actions((self) =>
			UpdateArchivedProjectUnarchive(self, apiUnarchiveProject)
		)
		.actions(ReadExportArchivedZipFile)
		.actions(ReadVerifyDigitalSignatureHashValue)

		.actions((self) => RequestRepairReplica(self, apiRepairReplica))
		.actions((self) => MReleaseForceArchive(self, apiReleaseForceArchive))
		//
		.actions(RequestRegenPreProcessPdfs)
		.actions(RequestGenerateForceArchivedPreProcessPdfs)
		.actions(DeleteForceArchivedPreProcessPdfs)
		.actions(RequestGenerateForceArchivedZip)
		.actions((self) => ReadArchiveDiagnosis(self, readArchiveDiagnosisApi))
		//
		.actions((self, preConditionStatus = apiCheckPreConditionStatus) =>
			ReadPreConditionStatus(self, preConditionStatus)
		)
		.actions(
			(
				self,
				forceArchivedPreConditionStatus = apiForceArchivedCheckPreConditionStatus
			) =>
				ReadForceArchivedPreConditionStatus(
					self,
					forceArchivedPreConditionStatus
				)
		)
		//
		.actions((self, readFilterData = apiReadFilters) =>
			ReadFilters(self, readFilterData)
		)
		.actions((self) => ReadCabinetLocation(self, apiReadCabinetLocation))
		// .actions((self, startPreProcess = apiStartPreProcess) =>
		// 	CreatePreProcessing(self, startPreProcess)
		// )
		// common model actions
		.actions(CommonModelActions)

export default AmArchivedProjectsModel
