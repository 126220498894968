import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { PageContainer } from "../../../../../components/app-frame-elements"
import CommonSnackbarComponent from "../../../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import { ActionStatus } from "../../../../../common-models/enumerations/common-enums"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import EmailTemplateSetNavController from "./sub-components/EmailTemplateSetNavController"
import EmailTemplateItems from "./sub-components/EmailTemplateItems"
import CreateEmailTemplateSetDialog from "./sub-components/dialogs/CreateEmailTemplateSetDialog"
import EditEmailTemplateSetDialog from "./sub-components/dialogs/EditEmailTemplateSetDialog"

export default observer(function EmailTemplate({
	partialStore,
	fetchingStatus,
	permission,
	contentHeight,
}: {
	partialStore: any
	fetchingStatus: ActionStatus
	permission: PermissionAsObjectProps
	contentHeight: any
}) {
	const [selectedTemplate, setSelectedTemplateSet] = useState<any>(null)
	return (
		<PageContainer
			hasToolbar={false}
			fullWidth
			fixedHeight
			isLoading={fetchingStatus === "LOADING"}
		>
			{fetchingStatus === "SUCCESS" && (
				<div
					className="FR proj-arch-date-policy"
					style={{ height: contentHeight }}
				>
					<EmailTemplateSetNavController
						permission={permission}
						setSelectedTemplateSet={setSelectedTemplateSet}
					/>
					<EmailTemplateItems
						partialStore={partialStore}
						selectedTemplate={selectedTemplate}
					/>
				</div>
			)}
			<CommonSnackbarComponent i18n={{}} partialStore={partialStore} />
			{partialStore.openCreateDialog && <CreateEmailTemplateSetDialog />}
			{partialStore.openEditDialog && <EditEmailTemplateSetDialog />}
		</PageContainer>
	)
})
