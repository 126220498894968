import React, { useState } from "react"
import { PageContainer } from "../../../../components/app-frame-elements"
import { ActionStatus } from "@datalobby/types"
import styled from "styled-components"
import Icon from "@mdi/react"
import { mdiEye, mdiFlag, mdiPencil, mdiPlus } from "@mdi/js"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import { DLButton } from "../../../../components/basic-elements"

const FsAdjustments = ({
	partialStore,
	actionStatus,
	permission,
}: {
	partialStore: any
	actionStatus: ActionStatus
	permission: PermissionAsObjectProps
}) => {
	const adjustments = partialStore.viewAdjustments()

	return (
		<PageContainer
			hasToolbar={false}
			pageTools={<div />}
			fullWidth
			isLoading={actionStatus === ActionStatus.loading}
		>
			<StyledAdjustments>
				<div className="FR" style={{ gap: 10 }}>
					<DLButton
						variant="contained"
						eleTestId="account-line-btn"
						size="regular"
						color="primary"
						clickHandler={() => {}}
					>
						All types
					</DLButton>
					<DLButton
						variant="contained"
						eleTestId="add-adjustment-btn"
						size="regular"
						color="primary"
						clickHandler={() => {}}
						startIcon={
							<Icon path={mdiPlus} color={"blue"} size={1} />
						}
					>
						Adjustment
					</DLButton>
					<DLButton
						variant="contained"
						eleTestId="group-line-btn"
						size="regular"
						color="primary"
						clickHandler={() => {}}
						startIcon={
							<Icon path={mdiEye} color={"blue"} size={1} />
						}
					>
						Hide Annotations
					</DLButton>
				</div>
				<div style={{ marginTop: 15 }}>
					{adjustments.map((entry: any) => (
						<Entry key={entry.id}>
							<Header>
								<Title>
									{entry.number} - {entry.period}
								</Title>
								<Icons>
									<IconButton>
										<Icon path={mdiPencil} size={1} />
									</IconButton>
									<IconButton>
										<Icon path={mdiFlag} size={1} />
									</IconButton>
								</Icons>
							</Header>
							<Description>{entry.description}</Description>
							<Type>Type: {entry.type}</Type>
							<AccountList>
								{entry.accounts.map(
									(account: any, idx: number) => (
										<AccountRow key={idx}>
											<div>{account.account}</div>
											<div>{account.value}</div>
										</AccountRow>
									)
								)}
							</AccountList>
						</Entry>
					))}
				</div>
			</StyledAdjustments>
		</PageContainer>
	)
}

export default FsAdjustments

const StyledAdjustments = styled.div`
	padding-left: 1rem;
	padding-right: 1rem;
`

const Container = styled.div`
	padding: 20px;
	font-family: Arial, sans-serif;
	background-color: #f9f9f9;
	width: 600px;
	margin: auto;
	border: 1px solid #ddd;
	border-radius: 8px;
`

const Entry = styled.div`
	background: #fff;
	border: 1px solid #ccc;
	border-radius: 8px;
	margin-bottom: 20px;
	padding: 15px;
`

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
`

const Title = styled.div`
	font-size: 16px;
	font-weight: bold;
`

const Icons = styled.div`
	display: flex;
	gap: 10px;
`

const IconButton = styled.div`
	cursor: pointer;
	color: #555;
	&:hover {
		color: #000;
	}
`

const Description = styled.div`
	margin-bottom: 10px;
	font-size: 14px;
	color: #333;
`

const Type = styled.div`
	margin-bottom: 10px;
	font-size: 12px;
	font-style: italic;
	color: #666;
`

const AccountList = styled.div`
	font-size: 14px;
	color: #444;
`

const AccountRow = styled.div`
	display: flex;
	justify-content: space-between;
`
