import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import {
	idToString,
	IdType,
} from "../../../../../../library/converters/id-converter"

export function reOrganizeItem(item: any) {
	const reOrganizedItem = {
		id: item.Id.toString(), // [Id], ex) 13
		aliasId: idToString(
			item.ArchivePreConditionId,
			IdType.archiveCondition
		), // [ArchivePreConditionId], ex) 12
		title: item.ArchivePreCondition, // [ArchivePreCondition], ex) "Opened Files"
		description: item.Description, // [Description], ex) "All Workpaper & Permanent File closed"
		helpText: item.HelpText, // [HelpText], ex) "Every opened Workpaper or Permanent File in the project should be closed to complete archival/retention process"
		//
		isMandatory: item.IsMandatory === 1 ? true : false, // [IsMandatory], ex) 1
		//								order: item.Order, // [Order], ex) 1
		createdBy: idToString(item.CreatedBy, IdType.user), // [CreatedBy], ex) 1
		createdAt: item.CreatedDate, // [CreatedDate], ex) "2018-11-26T21:34:34.44527-05:00"
		modifiedBy:
			item.ModifiedBy === 0
				? null
				: idToString(item.ModifiedBy, IdType.user), // [ModifiedBy], ex) 0
		modifiedAt: item.ModifiedDate, // [ModifiedDate]
	}
	return reOrganizedItem
}

const GetArchPolicies = (self: any) => ({
	getArchivePolicies() {
		// 0.
		const actionName = "getArchivePolicies"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		self.readArchivePolicies()
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					const fetchedPolicies = response.data.ArchiveConditions
					let reOrganizedPolicies: any[] = []

					fetchedPolicies.map((policy: any) => {
						const fetchedEngTypes = policy.EngagementsTypesList
						const fetchedConditions = policy.PreConditionsList

						let reOrganizedPolicy: any = {}
						let reOrganizedEngTypes: any[] = []
						let reOrganizedConditions: {
							mandatoryList: any[]
							optionalList: any[]
						} = {
							mandatoryList: [],
							optionalList: [],
						}
						let reOrganizedFlatConditions: any[] = []
						const policyId = idToString(
							policy.Id,
							IdType.archivePolicy
						)

						// 1. engagement type list
						fetchedEngTypes.map((engType: any) => {
							const engTypeId = idToString(
								engType.EngagementTypeId,
								IdType.engType
							)
							const reOrganizedEngType = {
								id: engTypeId, // [Id], ex) 2
								title: engType.EngagementType, // [EngagementType], ex) "A"
								//
								createdBy: idToString(
									engType.CreatedBy,
									IdType.user
								), // [CreatedBy], ex) 1
								createdAt: engType.CreatedDate, // [CreatedDate], ex) "2018-11-26T21:34:34.44527-05:00"
								modifiedBy: idToString(
									engType.ModifiedBy,
									IdType.user
								), // [ModifiedBy], ex) 0
								modifiedAt: engType.ModifiedDate, // [ModifiedDate]
							}
							const flatEngType = {
								id: engTypeId,
								title: engType.EngagementType,
								description: null,
								parentId: policyId,
								expanded: false,
								type: "engType",
								isDefault: false,
								// lastModifiedBy: null,
								// lastModifiedAt: null,
							}
							self.pushItemToFlatList(flatEngType)
							reOrganizedEngTypes.push(reOrganizedEngType)
						})

						// 2. mandatory conditions
						fetchedConditions.MandatoryList.map((item: any) => {
							reOrganizedConditions.mandatoryList.push(
								reOrganizeItem(item)
							)
							reOrganizedFlatConditions.push(reOrganizeItem(item))
						})
						// 3. optional conditions
						fetchedConditions.NonMandatoryList.map((item: any) => {
							reOrganizedConditions.optionalList.push(
								reOrganizeItem(item)
							)
							reOrganizedFlatConditions.push(reOrganizeItem(item))
						})

						// 4. combine
						reOrganizedPolicy = {
							id: policyId, // [Id], ex) 2
							title: policy.Title, // [Title], ex) "Default Archive Policy"
							description: policy.Description, // [Description], ex) "Default Archive Policy"
							orgId: idToString(policy.CustomerId, IdType.org), // [CustomerId], ex) 3 --> Why this props is required..? (micro service?)
							//
							engTypes: reOrganizedEngTypes, // re-organized array
							conditions: reOrganizedConditions, // reorganized array
							//
							createdBy: idToString(
								policy.CreatedBy,
								IdType.user
							), // [CreatedBy], ex) 1
							createdAt: policy.CreatedDate, // [CreatedDate], ex) "2018-11-26T21:34:34.44527-05:00"
							modifiedBy: idToString(
								policy.ModifiedBy,
								IdType.user
							), // [ModifiedBy], ex) 0
							modifiedAt: policy.ModifiedDate, // [ModifiedDate], ex) null
							isActive: policy.IsActive === 1 ? true : false, // [IsActive], ex) 1
							isDefault: policy.IsDefault === 1, // [IsDefault], ex) 1
							isResetAllowed:
								policy.IsResetAllowed === 1 ? true : false, // [IsResetAllowed], ex) 0
							lastModifiedBy:
								policy.LastModifiedBy === "-" ||
								policy.LastModifiedBy === null
									? null
									: idToString(
											policy.LastModifiedBy,
											IdType.user
									  ), // [LastModifiedBy], ex) "-"
							lastModifiedAt: policy.LastModifiedDate, // [LastModifiedDate], ex) null
						}
						const flatPolicy = {
							id: policyId,
							parentId: null,
							title: policy.Title,
							description: policy.Description,
							expanded: false,
							type: "policy",
							isDefault: policy.IsDefault === 1,
							lastModifiedBy: policy.LastModifiedBy,
							lastModifiedAt: policy.LastModifiedDate,
						}
						const policyDetail = {
							id: policyId,
							conditions: reOrganizedFlatConditions,
						}
						self.pushItemToFlatList(flatPolicy)
						self.setPolicyDetail(policyDetail)
						reOrganizedPolicies.push(reOrganizedPolicy)
					})
					// NOTE: 'setList' is replace whole list. If you want add item, need to use 'pushItemToList'
					// self.setList(reOrganizedPolicies)
					self.setSelectedPolicy(reOrganizedPolicies[0].id)
					self.setNeedRefresh(false)
					// set success case
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: "Successfully fetched archive policies",
						open: true,
						autoHide: true,
					})
				} else {
					// set fail case
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message,
						open: true,
						autoHide: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetArchPolicies
