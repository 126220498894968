import React from "react"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import {
	DLIcon,
	DLIconName,
	CommonIconSize,
	FolderAddIcon,
	FileAddIcon,
	MoveIcon,
} from "../../../../../components/basic-elements/icons/common-icons"
import { ProjI18n } from "../../../../../common-models/enumerations/translation-sheets"

import { FileScreenDialog } from "../../../../../service-modules/file-module/data-models/dl-file-control-model"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import { ConsoleLog } from "../../../../../components/basic-elements"
import { getProjIdFromUrl } from "../../../../../library/api-requests/request-get-others"
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"

const FileFolderContextMenuOptions = (
	partialStore: any,
	permission: PermissionAsObjectProps
) => {
	const store = useRootStore()
	const projStore = useProjStore()
	const projId = getProjIdFromUrl()

	const setFileTreeDialogOpen = partialStore.setFileTreeDialogOpen //setCreateFolderDialogOpen

	const downloadFileFolder = partialStore.downloadFileFolder
	const selectedItems = partialStore.selectedItems
	const multipleItems = selectedItems.length > 1
	const selectedItem = partialStore.selectedItems[0]?.id
	const selectedItemDetails = partialStore.getItemById(selectedItem)
	const selectedParentId = selectedItemDetails.parentId
	const structureLock = partialStore.structureLock
	const menuId = partialStore.storeName
	const isLocked = projStore.projInfo.projInfo.isLocked
	const projectStatusId = parseInt(projStore.projInfo.projInfo.statusId)

	let folderId = ""
	if (selectedItems.length < 1) {
		//
	} else if (selectedItems.length === 1) {
		folderId = selectedItems[0].id // TODO: Add menuType...
	} else if (selectedItems > 1) {
		// temporary solution
		// below lines doesn't work for tree area. Apply this on the DLFileTree right click
		// const lastItem = selectedItems[selectedItems.length - 1].id
		// partialStore.setSelectedItem(lastItem)
	}

	const handleFolderDelete = () => {
		// remove the files of deleted folder on the stored data
		const postAction = () => {
			const list = partialStore.flatList.filter(
				(item: any) => item.parentId != folderId
			)
			partialStore.setUpdatedFlatList(list)
		}
		partialStore.removeFileFolder({ folderId, postAction })
	}

	const handleOpenFolderDownloadDialog = () => {
		if (!multipleItems) {
			if (projId !== null) {
				// if (menuId === DLProjSubMenus.wp) {
				partialStore.setDownloadType("Folder")
				setFileTreeDialogOpen(FileScreenDialog.exportFolderDialog, true)
				// } else {
				// 	downloadFileFolder({ folderId, projId })
				// }
			} else {
				alert("Project ID is null")
			}
		}
	}

	const handleOpenFolderZipExportDialog = () => {
		if (
			projStore.checkin.currentUserOnProj
				.projDataMgmtAccessPermissionId !== 3
		) {
			window.alert("You do not have permission to export folder zip")
		}
		if (!multipleItems) {
			if (projId !== null) {
				partialStore.setDownloadType("Folder")
				setFileTreeDialogOpen(
					FileScreenDialog.exportProjZipFolderZipDialog,
					true
				)
			} else {
				alert("Project ID is null")
			}
		}
	}

	// ----- i18n -----
	const i18n = store.i18n.combineI18n(
		ProjI18n.files,
		"file-folder-ctx-menu-options"
	)

	return [
		{
			label: i18n.twAddFolder || "Add Folder",
			value: "ctx-add-folder",
			available: permission.create && !isLocked,
			disable: multipleItems,
			clickEvent: () =>
				setFileTreeDialogOpen(FileScreenDialog.createFolder, true),
			icon: <FolderAddIcon />,
		},
		{
			label: i18n.twAddFile || "Add File",
			value: "ctx-add-file",
			available: permission.create && !isLocked,
			disable: multipleItems,
			clickEvent: () => {
				ConsoleLog(
					"@@@@ open creates file dialog - by file-folder ctx menu options"
				)
				setFileTreeDialogOpen(FileScreenDialog.createFile, true)
			},
			icon: <FileAddIcon />,
		},
		{
			label: i18n.twMoveFile || "Move Folder",
			value: "ctx-move-folder",
			available: permission.update && !isLocked,
			disabled: !selectedParentId || structureLock,
			clickEvent: () =>
				setFileTreeDialogOpen(FileScreenDialog.moveFileOrFolder, true),
			tooltip: !selectedParentId
				? "Cannot move the root folder"
				: undefined,
			icon: <MoveIcon />,
		},
		{
			label: i18n.twEdit || "Edit",
			value: "ctx-edit-folder",
			available: permission.update && !isLocked,
			disable: multipleItems,
			clickEvent: () =>
				setFileTreeDialogOpen(FileScreenDialog.editFolder, true),
			icon: (
				<DLIcon
					name={DLIconName.edit}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
		},
		{
			label: i18n.twDownload || "Download",
			value: "ctx-download-folder",
			available: permission.export,
			disabled: multipleItems, // NOTE: for backend performance / Need to be updated
			clickEvent: handleOpenFolderDownloadDialog,
			icon: (
				<DLIcon
					name={DLIconName.download}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
		},
		{
			label: i18n.twDelete || "Delete",
			value: "ctx-delete-folder",
			available: permission.delete && !isLocked,
			clickEvent: handleFolderDelete,
			icon: (
				<DLIcon
					name={DLIconName.delete}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
		},
		{
			label: i18n.twFolderZipExport || "Folder Zip Export",
			value: "ctx-folder-zip-export",
			available:
				projectStatusId === 1 ||
				projectStatusId === 2 ||
				projectStatusId === 4,
			disabled:
				multipleItems ||
				projStore.checkin.currentUserOnProj
					.projDataMgmtAccessPermissionId !== 3,
			clickEvent: handleOpenFolderZipExportDialog,
			icon: (
				<DLIcon
					name={DLIconName.download}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
		},
	]
}

export default FileFolderContextMenuOptions
