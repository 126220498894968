import React from "react"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import ProjNoPermissionOnMenu from "../../proj-access-control/ProjNoPermissionOnMenu"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { observer } from "mobx-react-lite"
import { ActionStatus } from "@datalobby/types"
import M_PropertiesForMappingItem from "./M_PropertiesForMappingItem"

export default observer(function M_PropertiesForMappingItemController() {
	const projStore = useProjStore()
	const menuId = DLProjSubMenus.m_properties_for_mapping_item

	const menuAccess = projStore.checkin.checkAccess(menuId)

	return (
		<>
			{menuAccess ? (
				<M_PropertiesForMappingItem
					actionStatus={ActionStatus.standby}
				/>
			) : (
				<ProjNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})
