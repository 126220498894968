import React, { useEffect, useState } from "react"
import { Redirect, useLocation, useParams } from "react-router-dom"
import { observer } from "mobx-react-lite"
import { useProjStore } from "../../../stores/proj-store/proj-store.provider"
import { ConsoleLog, DLSpinner } from "../../../components/basic-elements"
import {
	ActionStatus,
	DLSector,
} from "../../../common-models/enumerations/common-enums"
import { DLProjSubMenus } from "../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import keyboardJS from "keyboardjs"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { idToString, IdType } from "../../../library/converters/id-converter"
import { findProjToken } from "../../../library/token-utils"
import { convertProjRoleForPermission } from "../../../library/converters/convert-role-id"
import { initializeProjStores } from "../../../stores/initialize-stores"
import StyledCheckin from "../../../components/app-frame-elements/checkin-progress-circles.style"

/**
 * NOTE: Approach cases
 * 1. from the organization: Select a project on the organization sector
 * 2. from the projRoute: prerequisites exist but checkin status is not success
 */

/**
 * Project checkin flow
 * ( NOTE: if the projCheckinStatus be fail, user will be redirected to the organization )
 * * <Step 1> Does project token exist?
 * --- if false: get project token
 * ------ if fail: set project checkin status as fail (on the viewModelAction)
 * ------ if success: --> step 2
 * --- if true: --> step 2
 * * <Step 2> get project information ( = Is Project Token valid?)
 * --- if fail: set project checkin status as fail (on the viewModelAction)
 * --- if success: --> step 3
 * * <Step 3> get project settings (DnT and etc...)
 * --- if fail: set project checkin status as fail (on the viewModelAction)
 * --- if success: --> step 4
 * * <Step 4> get user's assigned role
 * --- if fail: set project checkin status as fail (on the viewModelAction)
 * --- if success: --> step 5
 * * <Step 5> get accessible menus
 * --- if fail: set project checkin status as fail (on the viewModelAction)
 * --- if success: --> step 6
 * * <Step 6> set projCheckinStatus as success
 */

const actionNames = {
	step1: "getProjToken",
	step2: "getProjInfo",
	step3: "getRoleSet",
	step4: "getProjSettings",
	// step5: "getProjAssignedRole",
	step5: "getPermissionSet",
	step6: "getProjAccessibleMenus",
}

export default observer(function ProjCheckin() {
	const store = useRootStore()
	const projStore = useProjStore()
	const checkinStore = projStore.checkin

	let { id } = useParams<{ id: string }>()
	console.log(" ___ProjCheckin - projNumId:", id)

	// ----- required items
	const orgUserId = localStorage.getItem("orgUserId")
	const orgId = localStorage.getItem("orgId")
	const orgToken = localStorage.getItem("orgToken")

	const projNumId = parseInt(id)
	const projId = idToString(projNumId, IdType.project)
	const orgRoleId = localStorage.getItem("orgRoleId") // to allow some actions on setup > user screen for AA, SA
	// ---- Prerequisite (This prerequisite do not check the projToken existence)

	// ----- set redirecting destination
	let from: any
	let goTo = `/project/${id}/information/project-information` // default page of project sector
	let location: { state: { from: any } } = useLocation()

	if (location?.state) {
		from = location.state.from
	}
	if (from && !from.pathname.includes("lobby-checkin")) {
		goTo = from.pathname
	}

	// WARNING: Because cannot initialize the projStore in Organization,
	// must use step 1 (token status check) as useState in this component to initialize the checkin store
	const [tokenStatus, setTokenStatus] = useState<undefined | ActionStatus>(
		undefined
	)

	let step1_status = checkinStore.getActionStatus(actionNames.step1)
	let step2_status = checkinStore.getActionStatus(actionNames.step2)
	let step3_status = checkinStore.getActionStatus(actionNames.step3)
	let step4_status = checkinStore.getActionStatus(actionNames.step4)
	let step5_status = checkinStore.getActionStatus(actionNames.step5)
	let step6_status = checkinStore.getActionStatus(actionNames.step6)

	ConsoleLog("ProjCheckin step_status", [
		step1_status,
		step2_status,
		step3_status,
		step4_status,
		step5_status,
		step6_status,
	])

	// ? ----- STEP 1: ProjId & ProjToken
	useEffect(() => {
		ConsoleLog("ProjCheckin", ["from", from?.pathname, "goTo", goTo])

		keyboardJS.reset()
		keyboardJS.pause()

		const prerequisite =
			orgUserId !== null &&
			orgId !== null &&
			orgToken !== null &&
			projId !== null &&
			orgRoleId !== null
		//

		console.log("ProjCheckin prerequisite 1", prerequisite)

		// WARNING: initialize checkin store to reset the status
		ConsoleLog("initialize proj checkin store")
		checkinStore.initializeStore()
		// checkinStore.setProjCheckin(ActionStatus.standby)
		console.log("ProjCheckin prerequisite 2", prerequisite)
		console.log("checkinStore.projCheckin", checkinStore.projCheckin)

		projStore.checkin.initializeStore()
		initializeProjStores(projStore, true)

		// ------------
		/**
		 * If prerequisite is false, set projCheckinStatus as fail
		 * - This Prerequisite do not check the projToken existence
		 * - ProjRoute checks the similar prerequisite
		 * - (ProjRoute checks the projToken existence together)
		 */
		if (prerequisite === false) {
			console.error("(ProjCheckin) One of the prerequisites is null")
			checkinStore.setProjCheckin(ActionStatus.fail)
		} else {
			/**
			 * if ProjToken does not exist, request projToken
			 * if ProjToken exist, set the 'getProjToken' status to success
			 * (token validation will be done in step2)
			 */
			checkinStore.setProjCheckin(ActionStatus.loading)
			const whenSuccess = () => {
				setTokenStatus(ActionStatus.success)

				localStorage.setItem("latestProjId", projId)
				checkinStore.setProjId(projId)
				// checkinStore.setProjNumId(projNumId)

				checkinStore.responses.setResponse(actionNames.step1, {
					actionName: actionNames.step1,
					status: ActionStatus.success,
				})
			}

			const prevToken = findProjToken(projId)
			if (prevToken) {
				// when there is same project ID in the tokens already
				console.log(projId, "'s token already exist ")
				if (projId !== null) {
					// Actually, this condition is true always by prerquisite checking
					// when getProjToken again, set proj ID on the store on there,
					// and if the projToken already exist, set proj ID on the store here
					// checkinStore.setProjId(projId)
					// checkinStore.setProjNumId(projNumId)
				}

				whenSuccess()
				// localStorage.setItem("latestProjToken", prevToken)
			} else {
				// when the projTokens doesn't have the projId also
				// 'prerequisite === true' means projId exist and orgUserId also
				// but typescript cannot catch it... That's why the line below has 'projId &&'
				ConsoleLog("PROJECT CHECKIN STEP 1: get projToken")
				projId &&
					orgUserId &&
					checkinStore.getProjToken({
						projId,
						userId: orgUserId,
						whenSuccess,
						whenFail: () =>
							checkinStore.setProjCheckin(ActionStatus.fail),
					})
			}
		}
	}, [projId])

	//
	//
	// ? ----- STEP 2
	useEffect(() => {
		if (tokenStatus === ActionStatus.success) {
			// if (step1_status === "SUCCESS") {
			ConsoleLog("PROJECT CHECKIN STEP 2: get project info")
			// restrict the additional call for project Information
			const additionalAction = (projInfo: any) => {
				projStore.projInfo.setProjInfo(projInfo)
				const actionNameForInfo = "getProjInfo"
				projStore.projInfo.responses.setResponse(actionNameForInfo, {
					actionName: actionNameForInfo,
					status: ActionStatus.success,
				})
				projStore.projInfo.setNeedRefresh(false)
			}

			checkinStore.getProjInfo(projNumId, additionalAction)
			checkinStore.getCurrentUserOnProj()
			//
			orgId !== null && checkinStore.setOrgId(orgId)
			orgUserId !== null && checkinStore.setUserId(orgUserId)
			orgRoleId !== null &&
				checkinStore.setUser({
					// the first setUser is in the getProjInfo()
					...checkinStore.user,
					orgRoleId,
				})
		}
	}, [tokenStatus])

	//
	//
	// ? ----- STEP 3
	useEffect(() => {
		if (tokenStatus === ActionStatus.success) {
			if (step2_status === "SUCCESS") {
				ConsoleLog("PROJECT CHECKIN STEP 3: Get Role Set")
				checkinStore.getRoleSet(DLSector.proj)
			}
		}
	}, [step2_status === "SUCCESS"])

	//
	//
	// ? ----- STEP 4
	useEffect(() => {
		if (tokenStatus === ActionStatus.success) {
			if (step3_status === "SUCCESS") {
				ConsoleLog("PROJECT CHECKIN STEP 4: project DnT setting")
				checkinStore.getProjSettings(store.global.setGlobalDnT)
			}
		}
	}, [step3_status === "SUCCESS"])

	//
	//

	// ? ----- STEP 5
	useEffect(() => {
		if (tokenStatus === ActionStatus.success) {
			if (step4_status === "SUCCESS") {
				ConsoleLog("PROJECT CHECKIN STEP 5: role and permission")
				// const roleId = checkinStore.user.roleId
				// const isReadonly = checkinStore.user.isReadonly
				// const tempRight = checkinStore.user.prevRight
				const oldNumId = checkinStore.user.oldNumId
				const oldARId = checkinStore.user.oldAccessRightId
				const permissionRole = convertProjRoleForPermission(
					oldNumId,
					oldARId
				)

				console.log(oldNumId, oldARId, "oldNumId")

				if (permissionRole) {
					checkinStore.getPermissionSet(DLSector.proj, permissionRole)
				} else {
					console.log(
						"Cannot find the matched role for",
						oldNumId,
						oldARId
					)
				}

				//(tempRight, roleId, isReadonly)
			}
		}
	}, [step4_status === "SUCCESS"])

	//
	//
	// ? ----- STEP 6
	useEffect(() => {
		if (tokenStatus === ActionStatus.success) {
			if (step5_status === "SUCCESS") {
				ConsoleLog("PROJECT CHECKIN STEP 6: accessible menus")
				const customerMenus = store.checkin.customerMenus
				const projStatusName = projStore.projInfo.projInfo.statusName
				const { hasProjectDataMgmtMenuAccess } =
					checkinStore.currentUserOnProj

				checkinStore.getProjAccessibleMenus(
					projStatusName,
					customerMenus,
					hasProjectDataMgmtMenuAccess
				)
			}
		}
		// TODO: replace the related action to "getProjSetting"
	}, [step5_status === "SUCCESS"])

	//
	//
	// ? ----- STEP 7
	useEffect(() => {
		if (tokenStatus === ActionStatus.success) {
			if (step6_status === "SUCCESS") {
				ConsoleLog("PROJECT CHECKIN STEP 7: set checkin success")
				// ConsoleLog(
				// 	"permission:",
				// 	checkinStore.assignedRole,
				// 	"/menus---",
				// 	checkinStore.accessibleTreeMenus
				// )
				// ConsoleLog("Good! Let's turn on the final part")
				checkinStore.setProjCheckin(ActionStatus.success)
				const nextMenu = store.checkin.findMenuByUrl(goTo)
				if (nextMenu !== undefined) {
					if (
						nextMenu.id !== DLProjSubMenus.proj_info &&
						nextMenu.parentId !== null
					) {
						projStore.menuTabs.setCurrentMainMenu(nextMenu.id)
						projStore.menuTabs.addOpenedMenu({
							id: nextMenu.id,
							title: nextMenu.name,
							url: nextMenu.urlEdge,
						})
					}
				}
			}
		}
	}, [step6_status === "SUCCESS"])

	//
	//
	//
	useEffect(() => {
		const data = localStorage.getItem("themeSetting")
		const themeSetting = data ? JSON.parse(data) : []

		if (
			themeSetting.some(
				(item: any) => item.userId === orgUserId && item.orgId === orgId
			)
		) {
			const theme = themeSetting.find(
				(item: any) => item.userId === orgUserId && item.orgId === orgId
			).theme
			ConsoleLog([theme, orgId, "themetheme"])
			store.ui.setTheme(theme)
		} else {
			store.ui.setTheme("blue1") // if not in local storage then by default it is light
		}
	}, [])

	return (
		<StyledCheckin>
			{checkinStore.projCheckin === "STANDBY" && (
				<div
					className="FR AC JC"
					style={{ width: "100%", height: "100%" }}
				>
					<DLSpinner />
				</div>
			)}
			{checkinStore.projCheckin === "LOADING" && (
				<div
					className="progress-circles"
					data-testid="proj-chekcin-progress-circles"
				>
					<div className="text-info">
						<div>Project Checkin is in progress</div>
					</div>
					<div className="steps">
						<div className={`progress-step ${step1_status}`} />
						<div className={`progress-step ${step2_status}`} />
						<div className={`progress-step ${step3_status}`} />
						<div className={`progress-step ${step4_status}`} />
						<div className={`progress-step ${step5_status}`} />
						<div className={`progress-step ${step6_status}`} />
					</div>
				</div>
			)}
			{checkinStore.projCheckin === "SUCCESS" &&
				tokenStatus === ActionStatus.success && <Redirect to={goTo} />}
			{checkinStore.projCheckin === "FAIL" && (
				<Redirect to="/organization/my-page" />
			)}
		</StyledCheckin>
	)
})
