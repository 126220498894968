import React, { useEffect, useState, useCallback } from "react"
import { observer } from "mobx-react-lite"
import {
	DLSingleSelect,
	InputWithLabel,
	DLRadioGroup,
	DLDialog,
	DLDatepicker,
	ConsoleLog,
} from "../../../../../components/basic-elements"
import styled from "styled-components"
import { ActionStatus } from "../../../../../common-models/enumerations/common-enums"
import SelectedUserCard from "../../../../project-side/project-setup-users/dialogs/SelectedUserCard"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import { getProjIdFromUrl } from "../../../../../library/api-requests/request-get-others"
import { addTzToDate } from "../../../../../library/converters/date-utc-converter"
import { useHistory } from "react-router-dom"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"

interface ConfigureOrgNotificationPermissionProps {
	orgNotificationPermissionLabel: string
}

enum OrgNotificationPermissions {
	receiveEmail = "Receive Email",
	doNotReceiveEmail = "Do not Receive Email",
}

export default observer(function ConfigureOrgNotificationPermissionDialog({
	i18n,
}: {
	i18n: DLI18nProps
}) {
	ConsoleLog(" ___ConfigureOrgNotificationPermissionDialog___ ")
	const store = useRootStore()
	const orgStore = useOrgStore()
	const { setupUsers } = orgStore
	const actionName = "configureProjDataMgmtAccess"
	const targetUserId = setupUsers.selectedUser
	const userInfo = setupUsers.viewSelectedUserInfo()
	const projId = getProjIdFromUrl()
	const actBy = localStorage.getItem("orgUserId") || ""

	// Memoized inputs initialization
	const [inputs, setInputs] =
		useState<ConfigureOrgNotificationPermissionProps>(() => ({
			orgNotificationPermissionLabel:
				userInfo?.orgNotificationPermissionLabel ||
				OrgNotificationPermissions.doNotReceiveEmail,
		}))

	useEffect(() => {
		setInputs({
			orgNotificationPermissionLabel:
				userInfo?.orgNotificationPermissionLabel ||
				OrgNotificationPermissions.doNotReceiveEmail,
		})
	}, [userInfo])

	const handleInputs = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const { name, value } = event.target
			setInputs((prev) => ({
				...prev,
				[name]: value,
			}))
		},
		[]
	)

	const handleConfigureOrgNotificationPermission = () => {
		window.alert("Coming soon!")
		setupUsers.setOrgNotificationPermissionDialogOpen(false)
		return
	}

	return (
		<DLDialog
			eleTestId="configure-org-notification-permission-dialog"
			isOpen={setupUsers.orgNotificationPermissionDialogOpen}
			setIsOpen={setupUsers.setOrgNotificationPermissionDialogOpen}
			handleAction={handleConfigureOrgNotificationPermission}
			showOpenBtn={false}
			showCloseBtn={true}
			dialogTitle={
				<div className="FR AC">
					<div style={{ marginLeft: 8 }}>
						{i18n.twEditUser ||
							"Configure Org Notification Permission"}
					</div>
				</div>
			}
			dialogContents={
				<ConfigureOrgNotificationForm
					inputs={inputs}
					handleInputs={handleInputs}
					i18n={i18n}
					existingAccessTypeId={
						userInfo?.orgNotificationPermissionLabel || ""
					}
				/>
			}
			cancelBtnText={i18n.twCancel || "Cancel"}
			actionReady={true}
			actionBtn={i18n.twSubmit || "Submit"}
			maxWidth="sm"
			fullWidth={true}
			dialogError={
				setupUsers.getActionStatus(actionName) === "FAIL"
					? setupUsers.getMessage(actionName)
					: ""
			}
			showSpinner={setupUsers.getActionStatus(actionName) === "LOADING"}
		/>
	)
})

const ConfigureOrgNotificationForm = observer(
	({
		inputs,
		handleInputs,
		i18n,
		existingAccessTypeId,
	}: {
		inputs: ConfigureOrgNotificationPermissionProps
		handleInputs: (event: React.ChangeEvent<HTMLInputElement>) => void
		i18n: DLI18nProps
		existingAccessTypeId: string
	}) => {
		const orgStore = useOrgStore()
		const { setupUsers } = orgStore
		const targetUser = setupUsers.viewSelectedUserInfo()

		return (
			<StyledConfigureOrgNotificationForm>
				{targetUser && (
					<div className="input-section FR">
						<InputWithLabel
							label={i18n.twSelectedUser || "Selected User"}
						>
							<SelectedUserCard
								name={targetUser.name}
								aliasId={targetUser.aliasId}
								email={targetUser.email}
								title={targetUser.orgTitle || ""}
							/>
						</InputWithLabel>
					</div>
				)}

				<div className="input-section FR">
					<InputWithLabel label="Org Notification Permission">
						<DLRadioGroup
							selectedValue={
								inputs.orgNotificationPermissionLabel
							}
							eleOnChange={handleInputs}
							items={[
								{
									value: OrgNotificationPermissions.receiveEmail,
									label: "Receive Email",
								},
								{
									value: OrgNotificationPermissions.doNotReceiveEmail,
									label: "Do not Receive Email",
								},
							]}
							groupName="orgNotificationPermissionLabel"
							itemDirection="column"
						/>
					</InputWithLabel>
				</div>
			</StyledConfigureOrgNotificationForm>
		)
	}
)

const StyledConfigureOrgNotificationForm = styled.div`
	padding-left: 0.5rem;
	.input-section {
		margin-bottom: 1.5rem;
		.label {
			min-width: 9rem;
		}
		.input-area {
			min-width: 20rem;
		}
	}
	.help-text {
		width: 100%;
		color: ${(props) => props.theme.warning};
	}
`
