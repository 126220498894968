import React from "react"
import {
	DLDialog,
	DLPasswordField,
	DLSystemMsg,
} from "../../../components/basic-elements"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { observer } from "mobx-react-lite"
import sharedRegEx from "../../../library/sharedRegEx"
import styled from "styled-components"
import Icon from "@mdi/react"
import useForm from "../../../library/use-form"
import Validator from "./sub-components/Validator"
import {
	ActionStatus,
	MessageColorType,
} from "../../../common-models/enumerations/common-enums"

export default observer(function ChangePw({
	partialStore,
	open,
	setOpen,
	handleChangePw,
}: {
	partialStore: any
	open: boolean
	setOpen: any
	handleChangePw: any
}) {
	const store = useRootStore()
	const actionName = "editPassword"

	// default inputs
	const inputsScheme = {
		curPw: { value: "", error: "", requestInput: false },
		newPw: { value: "", error: "", requestInput: false },
		confirmPw: { value: "", error: "", requestInput: false },
	}

	const validationScheme = {
		curPw: {
			isRequired: true,
			// validator: {
			// 	regEx: sharedRegEx.dlPassword,
			// 	error: "Password format is invalid",
			// },
		},
		newPw: {
			isRequired: true,
			validator: {
				regEx: sharedRegEx.dlPassword,
				error: "Password format is invalid",
			},
		},
		confirmPw: {
			isRequired: true,
			validator: {
				regEx: sharedRegEx.dlPassword,
				error: "Password format is invalid",
			},
		},
	}

	const { inputs, handleOnChange, handleOnSubmit, isReady } = useForm(
		inputsScheme,
		validationScheme,
		handleChangePw
	)

	const i18n = store.i18n.globalI18n("ChangePW")
	const actionStatus = partialStore.getActionStatus(actionName)
	const msg = partialStore.getMessage(actionName)
	return (
		<DLDialog
			eleTestId="change-pw-dialog"
			isOpen={open}
			setIsOpen={setOpen}
			showOpenBtn={false}
			dialogTitle={i18n.twChangePw || "Change Password"}
			dialogContents={
				<ChangePwDialogForm
					inputs={inputs}
					handleOnChange={handleOnChange}
					actionStatus={actionStatus}
					msg={msg}
				/>
			}
			handleAction={handleOnSubmit}
			actionReady={isReady}
			actionBtn={i18n.twSave || "Save"}
			showCloseBtn
			cancelBtnText={i18n.twCancel || "Cancel"}
			showSpinner={actionStatus === ActionStatus.loading}
			useEnterKeyForSubmit
		/>
	)
})

const ChangePwDialogForm = observer(
	({
		inputs,
		handleOnChange,
		actionStatus,
		msg,
	}: {
		inputs: any
		handleOnChange: any
		actionStatus: ActionStatus
		msg: string
	}) => {
		return (
			<StyledChangePwDialogForm className="FC">
				<div className="input-section">
					<DLPasswordField
						eleValue={inputs.curPw.value}
						eleName="curPw"
						eleTestId="current-password-input"
						elePlaceholder="Please input your current password"
						eleLabel="Current Password"
						eleOnChange={handleOnChange}
						eleFullWidth
						eleWarningMsg={inputs.curPw.error}
						eleWarningType="orange"
					/>
				</div>
				<div className="input-section">
					<DLPasswordField
						eleValue={inputs.newPw.value}
						eleName="newPw"
						eleTestId="new-password-input"
						elePlaceholder="Please input your new password"
						eleLabel="New Password"
						eleOnChange={handleOnChange}
						eleFullWidth
						eleWarningMsg={inputs.newPw.error}
						eleWarningType="orange"
					/>
					<Validator value={inputs.newPw.value} />
				</div>
				<div className="input-section">
					<DLPasswordField
						eleValue={inputs.confirmPw.value}
						eleName="confirmPw"
						eleTestId="new-password-confirm"
						elePlaceholder="Please input your new password to confirm"
						eleLabel="Confirm Password"
						eleOnChange={handleOnChange}
						eleFullWidth
						eleWarningMsg={
							inputs.confirmPw.error
								? inputs.confirmPw.error
								: inputs.newPw.value === inputs.confirmPw.value
								? undefined
								: "Passwords are not matched"
						}
						eleWarningType="orange"
					/>
					<Validator value={inputs.confirmPw.value} />
				</div>
				{/* {actionStatus === ActionStatus.loading && msg} */}

				{/* {actionStatus === ActionStatus.fail && (
					<DLSystemMsg msg={msg} type={MessageColorType.red} />
				)} */}
			</StyledChangePwDialogForm>
		)
	}
)

const StyledChangePwDialogForm = styled.div`
	.input-section {
		margin-bottom: ${(props) => props.theme.shared.spacing3};
	}
	.input-validation-ui {
		margin-top: ${(props) => props.theme.shared.spacingSS};
		.match-case {
			display: flex;
			align-items: center;
			margin-right: ${(props) => props.theme.shared.spacing1};
			svg {
				margin-right: ${(props) => props.theme.shared.spacingSS};
			}
			&.regex-matched {
				color: ${(props) => props.theme.shared.spacingSS};
				svg {
					fill: blue;
				}
			}
		}
	}
`
