import { types } from "mobx-state-tree"
import {
	deleteProjectDataMgmtZipFileById,
	ProjDownloadCenterListApiProps,
} from "./proj-download-center-list.apis"
import { initialClickPoint } from "../../../../../common-models/types/dialog.props"
import {
	readProjDownloadCenterList,
	readProjectDataMgmtZipFileById,
} from "./proj-download-center-list.apis"
import ProjDownloadCenterUiHelper from "./proj-download-center.ui-helper"
import ProjDownloadCenterModel from "./proj-download-center.model"
import ProjDownloadCenterViewModel from "./proj-download-center.view-model"

export const ProjDownloadCenterStore = ({
	apiReadProjDownloadCenterList,
	apiRequestProjDataMgmtZipFileById,
	apiDeleteProjDataMgmtZipFileById,
}: ProjDownloadCenterListApiProps) =>
	types
		.compose(
			ProjDownloadCenterModel({
				apiReadProjDownloadCenterList,
				apiRequestProjDataMgmtZipFileById,
				apiDeleteProjDataMgmtZipFileById,
			}),
			ProjDownloadCenterUiHelper,
			ProjDownloadCenterViewModel
		)
		.named("ProjDownloadCenterStore")

export const initialStore = {
	selectedItem: 0,
	clickPoint: initialClickPoint,
	//
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const ProjDownloadCenterStoreInstance = ProjDownloadCenterStore({
	apiReadProjDownloadCenterList: readProjDownloadCenterList,
	apiRequestProjDataMgmtZipFileById: readProjectDataMgmtZipFileById,
	apiDeleteProjDataMgmtZipFileById: deleteProjectDataMgmtZipFileById,
}).create(initialStore)

export default ProjDownloadCenterStoreInstance

export type ProjDownloadCenterStoreType = typeof ProjDownloadCenterStoreInstance
