import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 */
const RestoreOrgTrashItem = (self: any) => ({
	restoreOrgTrashItem(itemId: string) {
		// 0.
		const actionName = "restoreOrgTrash"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.request API call
		const payload = {
			TrashID: parseInt(itemId),
		}
		self.updateOrgTrashItem(payload)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					self.deleteItem(itemId)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200, // temp
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
					self.spliceSelectedItem(itemId)
				} else {
					const type = self.getTrashItemTypeById(itemId)
					const msgCode =
						type === "Template" || type === "TemplateParentFolder"
							? `${ActionStatus.fail}_${actionName}_${type}`
							: `${ActionStatus.fail}_${actionName}`
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999, // temp
						color: MessageColorType.orange,
						open: true,
						autoHide: false,
						// msgCode
						message: response.data.Message,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
	restoreMultipleItems(items: string[]) {
		// 0.
		const actionName = "restoreMultipleItems"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		items.map((item) => {
			self.restoreOrgTrashItem(item)
		})
	},
})

export default RestoreOrgTrashItem
