import { Instance, types } from "mobx-state-tree"

export const CurrentUserOnProj = types.model({
	userId: types.number,
	status: types.number,
	accessTypeId: types.number,
	isOrgProjectDataMgmtAdmin: types.boolean,
	isAssignedToProject: types.boolean,
	roleId: types.number,
	projDataMgmtAccessPermissionId: types.number,
	projDataMgmtAccessPermissionLabel: types.string,
	projectAccessRightId: types.number,
	projectUserTrashStatus: types.number,
	projectUserStatus: types.number,
	hasProjectDataMgmtMenuAccess: types.boolean,
})

export type CurrentUserOnProjType = Instance<typeof CurrentUserOnProj>

export const initialCurrentUserOnProj: CurrentUserOnProjType = {
	userId: 0,
	status: 1,
	accessTypeId: 0,
	isOrgProjectDataMgmtAdmin: false,
	isAssignedToProject: false,
	roleId: 0,
	projDataMgmtAccessPermissionId: 0,
	projDataMgmtAccessPermissionLabel: "",
	projectAccessRightId: 0,
	projectUserTrashStatus: 0,
	projectUserStatus: 0,
	hasProjectDataMgmtMenuAccess: false,
}
