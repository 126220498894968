import React, { useState } from "react"
import { DLDialog } from "../../../../../components/basic-elements"
import { FormControl, MenuItem, Select } from "@material-ui/core"

function ImportRoundingSettingsDialog({
	isOpen,
	setIsOpen,
}: {
	isOpen: boolean
	setIsOpen: any
}) {
	const [settings, setSettings] = useState({
		roundTo: "ones",
		balanceSheet: "",
		incomeStatement: "",
	})

	const handleChange = (name: string, value: any) => {
		setSettings((prev: any) => ({
			...prev,
			[name]: value,
		}))
	}

	const handleAction = () => {}

	return (
		<DLDialog
			eleTestId={`import-rounding-settings-dialog`}
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			handleAction={handleAction}
			showCloseBtn={true}
			dialogTitle={`Import Rounding Settings`}
			dialogContents={
				<div className="FC" style={{ gap: 10 }}>
					<div className="FR AC" style={{ gap: 20 }}>
						<div style={{ flex: 1 }}>Round to</div>
						<FormControl
							style={{ marginTop: 5, flex: 3 }}
							fullWidth
							size="small"
						>
							<Select
								labelId="period-label"
								id="roundTo"
								value={settings.roundTo}
								variant="outlined"
								displayEmpty
								placeholder="Select Round To"
								onChange={(e: any) =>
									handleChange("roundTo", e.target.value)
								}
							>
								<MenuItem value={"ones"}>Ones</MenuItem>
								<MenuItem value={"thousands"}>
									Thousands
								</MenuItem>
								<MenuItem value={"millions"}>Millions</MenuItem>
								<MenuItem value={"billions"}>Billions</MenuItem>
								<MenuItem value={"ten-thousands"}>
									Ten Thousands
								</MenuItem>
								<MenuItem value={"hundred-millions"}>
									Hundred Millions
								</MenuItem>
							</Select>
						</FormControl>
					</div>
					<div className="FR AC" style={{ gap: 20 }}>
						<div style={{ flex: 1 }}>Balance Sheet</div>
						<FormControl
							style={{ marginTop: 5, flex: 3 }}
							fullWidth
							size="small"
						>
							<Select
								labelId="period-label"
								id="balanceSheet"
								value={settings.balanceSheet}
								variant="outlined"
								displayEmpty
								placeholder="Select Balance Sheet"
								onChange={(e: any) =>
									handleChange("balanceSheet", e.target.value)
								}
							>
								<MenuItem value={""}></MenuItem>
							</Select>
						</FormControl>
					</div>
					<div className="FR AC" style={{ gap: 20 }}>
						<div style={{ flex: 1 }}>Income Statement</div>
						<FormControl
							style={{ marginTop: 5, flex: 3 }}
							fullWidth
							size="small"
						>
							<Select
								labelId="period-label"
								id="incomeStatement"
								value={settings.incomeStatement}
								variant="outlined"
								displayEmpty
								placeholder="Select Income Statement"
								onChange={(e: any) =>
									handleChange(
										"incomeStatement",
										e.target.value
									)
								}
							>
								<MenuItem value={""}></MenuItem>
							</Select>
						</FormControl>
					</div>
				</div>
			}
			cancelBtnText={"Cancel"}
			actionBtn={"Ok"}
			maxWidth="sm"
			fullWidth={true}
			actionReady={true}
		/>
	)
}

export default ImportRoundingSettingsDialog
