// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 *
 */

const Unarchive = (self: any) => ({
	unarchive(projectId: string, reason: string, filesData: any) {
		// 0.
		const actionName = "unarchiveProject"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.call API
		const projNumberId = idToNumber(projectId, IdType.project)
		const userId = localStorage.getItem("orgUserId")
		let userNumberId
		if (userId) {
			userNumberId = idToNumber(userId, IdType.user)
		}
		const formData = new FormData()
		const ArchiveDataObj = {
			ClientID: projNumberId,
			IsArchivedProcess: false, // For Normal and Unarchived
			UnArchivedBy: userNumberId,
			Step: 1,
			ReplicaClientID: 0, // ? ---- Why 0 ?
			reason: reason,
		}
		filesData.map((file: any) => {
			formData.append("files", file.file)
		})
		formData.append("param", JSON.stringify(ArchiveDataObj))
		self.archivedProjectUnarchive(formData)
			.then((response: any) => {
				// if success
				if (
					response.status === 200 &&
					response.data.status.code === 1
				) {
					const fetchedData = response.data.data
					const item = {
						unarchivedDateTime: fetchedData.UnarchivedDate,
						engagementPartner: fetchedData.EPUser,
						unarchivedBy: fetchedData.UnarchivedByUser,
					}
					self.setUnarchivedDetails(item)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
						// customMessage: fetchedData.Message,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999, // temp
						color: MessageColorType.orange,
						open: true,
						customMessage: response.data.data.Message,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
	// archiveProjectUnarchiveStep2(
	// 	clientId: string,
	// 	replicaClientId: number,
	// 	reason: string,
	// 	filesData: any
	// ) {
	// 	// 0.
	// 	const actionName = "unarchiveProjectStep2"
	// 	// 1.
	// 	// 2.
	// 	self.responses.setResponse(actionName, {
	// 		actionName,
	// 		status: ActionStatus.loading,
	// 	})
	// 	// 3.call API
	// 	let userId = localStorage.getItem("orgUserId")
	// 	const formData = new FormData()
	// 	const ArchiveDataObj = {
	// 		ClientID: parseInt(clientId),
	// 		UnArchivedBy: userId,
	// 		Step: 2,
	// 		ReplicaClientID: replicaClientId,
	// 	}
	// 	formData.append("ArchiveDataObj", JSON.stringify(ArchiveDataObj))
	// 	self.archivedProjectUnarchive(formData)
	// 		.then((response: any) => {
	// 			if (response) {
	// 				// if success
	// 				ConsoleLog(response)
	// 				if (response.data.Status === 2) {
	// 					self.archiveProjectUnarchiveStep3(
	// 						clientId,
	// 						response.data.ReplicaClientID,
	// 						reason,
	// 						filesData
	// 					)
	// 					self.handleResponse({
	// 						actionName,
	// 						status: ActionStatus.success,
	// 						code: 200,
	// 						color: MessageColorType.blue,
	// 						customMessage: "Success to create a file",
	// 						open: false,
	// 					})
	// 				}
	// 				if (response.data.Status === 4) {
	// 					self.setUnarchiveProgressDialogOpen(false)
	// 					self.handleResponse({
	// 						actionName,
	// 						status: ActionStatus.fail,
	// 						code: 999, // temp
	// 						customMessage: response.data.Message,
	// 						color: MessageColorType.orange,
	// 						open: true,
	// 					})
	// 				}
	// 			}
	// 		})
	// 		.catch((error: Error) => {
	// 			self.handleViewModelError({
	// 				error,
	// 				actionName,
	// 				open: true,
	// 			})
	// 		})
	// },
	// archiveProjectUnarchiveStep3(
	// 	clientId: string,
	// 	replicaClientId: number,
	// 	reason: string,
	// 	filesData: any
	// ) {
	// 	// 0.
	// 	const actionName = "unarchiveProjectStep3"
	// 	// 1.
	// 	// 2.
	// 	self.responses.setResponse(actionName, {
	// 		actionName,
	// 		status: ActionStatus.loading,
	// 	})
	// 	// 3.call API
	// 	let userId = localStorage.getItem("orgUserId")
	// 	const formData = new FormData()
	// 	const ArchiveDataObj = {
	// 		ClientID: parseInt(clientId),
	// 		UnArchivedBy: userId,
	// 		Step: 3,
	// 		ReplicaClientID: replicaClientId,
	// 	}
	// 	formData.append("ArchiveDataObj", JSON.stringify(ArchiveDataObj))

	// 	self.archivedProjectUnarchive(formData)
	// 		.then((response: any) => {
	// 			if (response) {
	// 				// if success
	// 				if (response.data.Status === 2) {
	// 					self.archiveProjectUnarchiveStep4(
	// 						clientId,
	// 						response.data.ReplicaClientID,
	// 						reason,
	// 						filesData
	// 					)
	// 					self.handleResponse({
	// 						actionName,
	// 						status: ActionStatus.success,
	// 						code: 200,
	// 						color: MessageColorType.blue,
	// 						customMessage: "Success to create a file",
	// 						open: true,
	// 					})
	// 				}
	// 				if (response.data.Status === 4) {
	// 					self.setUnarchiveProgressDialogOpen(false)
	// 					self.handleResponse({
	// 						actionName,
	// 						status: ActionStatus.fail,
	// 						code: 999, // temp
	// 						customMessage: response.data.Message,
	// 						color: MessageColorType.orange,
	// 						open: true,
	// 					})
	// 				}
	// 			}
	// 		})
	// 		.catch((error: Error) => {
	// 			self.handleViewModelError({
	// 				error,
	// 				actionName,
	// 				open: true,
	// 			})
	// 		})
	// },
	// archiveProjectUnarchiveStep4(
	// 	clientId: string,
	// 	replicaClientId: number,
	// 	reason: string,
	// 	filesData: any
	// ) {
	// 	// 0.
	// 	const actionName = "unarchiveProjectStep4"
	// 	// 1.
	// 	// 2.
	// 	self.responses.setResponse(actionName, {
	// 		actionName,
	// 		status: ActionStatus.loading,
	// 	})
	// 	// 3.call API
	// 	let userId = localStorage.getItem("orgUserId")
	// 	const formData = new FormData()
	// 	const ArchiveDataObj = {
	// 		ClientID: parseInt(clientId),
	// 		UnArchivedBy: userId,
	// 		Step: 4,
	// 		ReplicaClientID: replicaClientId,
	// 	}
	// 	formData.append("ArchiveDataObj", JSON.stringify(ArchiveDataObj))

	// 	self.archivedProjectUnarchive(formData)
	// 		.then((response: any) => {
	// 			if (response) {
	// 				// if success
	// 				if (response.data.Status === 2) {
	// 					self.archiveProjectUnarchiveStep5(
	// 						clientId,
	// 						response.data.ReplicaClientID,
	// 						reason,
	// 						filesData
	// 					)
	// 					self.handleResponse({
	// 						actionName,
	// 						status: ActionStatus.success,
	// 						code: 200,
	// 						color: MessageColorType.blue,
	// 						customMessage: "Success to create a file",
	// 						open: true,
	// 					})
	// 				}
	// 				if (response.data.Status === 4) {
	// 					self.handleResponse({
	// 						actionName,
	// 						status: ActionStatus.fail,
	// 						code: 999, // temp
	// 						customMessage: response.data.Message,
	// 						color: MessageColorType.orange,
	// 						open: true,
	// 					})
	// 				}
	// 			}
	// 		})
	// 		.catch((error: Error) => {
	// 			self.handleViewModelError({
	// 				error,
	// 				actionName,
	// 				open: true,
	// 			})
	// 		})
	// },
	// archiveProjectUnarchiveStep5(
	// 	clientId: string,
	// 	replicaClientId: number,
	// 	reason: string,
	// 	filesData: any
	// ) {
	// 	// 0.
	// 	const actionName = "unarchiveProjectStep5"
	// 	// 1.
	// 	// 2.
	// 	self.responses.setResponse(actionName, {
	// 		actionName,
	// 		status: ActionStatus.loading,
	// 	})
	// 	// 3.call API
	// 	let userId = localStorage.getItem("orgUserId")
	// 	const formData = new FormData()
	// 	const ArchiveDataObj = {
	// 		ClientID: parseInt(clientId),
	// 		UnArchivedBy: userId,
	// 		Step: 5,
	// 		ReplicaClientID: replicaClientId,
	// 	}
	// 	formData.append("ArchiveDataObj", JSON.stringify(ArchiveDataObj))

	// 	self.archivedProjectUnarchive(formData)
	// 		.then((response: any) => {
	// 			if (response) {
	// 				// if success
	// 				if (response.data.Status === 2) {
	// 					self.archiveProjectUnarchiveStep6(
	// 						clientId,
	// 						response.data.ReplicaClientID,
	// 						reason,
	// 						filesData
	// 					)
	// 					self.handleResponse({
	// 						actionName,
	// 						status: ActionStatus.success,
	// 						code: 200,
	// 						color: MessageColorType.blue,
	// 						customMessage: "Success to create a file",
	// 						open: true,
	// 					})
	// 				}
	// 				if (response.data.Status === 4) {
	// 					self.handleResponse({
	// 						actionName,
	// 						status: ActionStatus.fail,
	// 						code: 999, // temp
	// 						customMessage: response.data.Message,
	// 						color: MessageColorType.orange,
	// 						open: true,
	// 					})
	// 				}
	// 			}
	// 		})
	// 		.catch((error: Error) => {
	// 			self.handleViewModelError({
	// 				error,
	// 				actionName,
	// 				open: true,
	// 			})
	// 		})
	// },
	// archiveProjectUnarchiveStep6(
	// 	clientId: string,
	// 	replicaClientId: number,
	// 	reason: string,
	// 	filesData: any
	// ) {
	// 	// 0.
	// 	const actionName = "unarchiveProjectStep6"
	// 	// 1.
	// 	// 2.
	// 	self.responses.setResponse(actionName, {
	// 		actionName,
	// 		status: ActionStatus.loading,
	// 	})
	// 	// 3.call API
	// 	let userId = localStorage.getItem("orgUserId")
	// 	const formData = new FormData()
	// 	const ArchiveDataObj = {
	// 		ClientID: parseInt(clientId),
	// 		UnArchivedBy: userId,
	// 		Step: 6,
	// 		ReplicaClientID: replicaClientId,
	// 	}
	// 	formData.append("ArchiveDataObj", JSON.stringify(ArchiveDataObj))

	// 	self.archivedProjectUnarchive(formData)
	// 		.then((response: any) => {
	// 			if (response) {
	// 				// if success
	// 				if (response.data.Status === 2) {
	// 					self.archiveProjectUnarchiveStep7(
	// 						clientId,
	// 						response.data.ReplicaClientID,
	// 						reason,
	// 						filesData
	// 					)
	// 					self.handleResponse({
	// 						actionName,
	// 						status: ActionStatus.success,
	// 						code: 200,
	// 						color: MessageColorType.blue,
	// 						customMessage: "Success to create a file",
	// 						open: true,
	// 					})
	// 				}
	// 				if (response.data.Status === 4) {
	// 					self.handleResponse({
	// 						actionName,
	// 						status: ActionStatus.fail,
	// 						code: 999, // temp
	// 						customMessage: response.data.Message,
	// 						color: MessageColorType.orange,
	// 						open: true,
	// 					})
	// 				}
	// 			}
	// 		})
	// 		.catch((error: Error) => {
	// 			self.handleViewModelError({
	// 				error,
	// 				actionName,
	// 				open: true,
	// 			})
	// 		})
	// },
	// archiveProjectUnarchiveStep7(
	// 	clientId: string,
	// 	replicaClientId: number,
	// 	reason: string,
	// 	filesData: any
	// ) {
	// 	// 0.
	// 	const actionName = "unarchiveProjectStep7"
	// 	// 1.
	// 	// 2.
	// 	self.responses.setResponse(actionName, {
	// 		actionName,
	// 		status: ActionStatus.loading,
	// 	})
	// 	// 3.call API
	// 	let userId = localStorage.getItem("orgUserId")
	// 	const formData = new FormData()
	// 	const ArchiveDataObj = {
	// 		ClientID: parseInt(clientId),
	// 		UnArchivedBy: userId,
	// 		Step: 3,
	// 		ReplicaClientID: replicaClientId,
	// 		reason: reason,
	// 	}
	// 	ConsoleLog(filesData)
	// 	filesData.map((file: any) => {
	// 		formData.append("files", file.file)
	// 	})
	// 	formData.append("ArchiveDataObj", JSON.stringify(ArchiveDataObj))
	// 	self.archivedProjectUnarchive(formData)
	// 		.then((response: any) => {
	// 			if (response) {
	// 				// if success
	// 				ConsoleLog(response.data)
	// 				if (response.data.Status === 1) {
	// 					const item = {
	// 						unarchivedDateTime:
	// 							response.data.UnarchivedDateTime,
	// 						engagementPartner: response.data.EPUser,
	// 						unarchivedBy: response.data.UnarchivedByUser,
	// 					}
	// 					self.pushItemToUnarchivedDetails(item)
	// 					self.setShowUnarchivedDetails(true)
	// 					self.handleResponse({
	// 						actionName,
	// 						status: ActionStatus.success,
	// 						code: 200,
	// 						color: MessageColorType.blue,
	// 						customMessage: "Success to unarchive project",
	// 						open: false,
	// 					})
	// 				}
	// 				if (response.data.Status === 4) {
	// 					self.handleResponse({
	// 						actionName,
	// 						status: ActionStatus.fail,
	// 						code: 999, // temp
	// 						customMessage: response.data.Message,
	// 						color: MessageColorType.orange,
	// 						open: true,
	// 					})
	// 				}
	// 			}
	// 		})
	// 		.catch((error: Error) => {
	// 			self.handleViewModelError({
	// 				error,
	// 				actionName,
	// 				open: true,
	// 			})
	// 		})
	// },
})

export default Unarchive
