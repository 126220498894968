import React, { useState } from "react"
import styled from "styled-components"
import { observer } from "mobx-react-lite"
import ReactTableV8 from "../../../../../../components/basic-elements/tables/DLReactTable"
import {
	DLButton,
	DLContextMenu,
	DLRadio,
} from "../../../../../../components/basic-elements"
import Icon from "@mdi/react"
import { mdiDotsHorizontal } from "@mdi/js"
import { useRootStore } from "../../../../../../stores/root-store/root-store.provider"
import EditSortingOrderDialog from "./dialogs/EditSortingOrderDialog"
import { useOrgStore } from "../../../../../../stores/org-store/org-store.provider"
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import {
	EMAIL_TEMPLATE_DATA,
	EmailTemplateDataType,
} from "../utils/email-templae-utils"
import EmailTemplateEditor from "./EmailTemplateEditor"

export default observer(function EmailTemplateItems({
	partialStore,
	selectedTemplate,
}: {
	partialStore: any
	selectedTemplate: any
}) {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const selectedPolicy = partialStore.viewSelectedPolicy()
	const [selectedRow, setSelectedRow] = useState<any>(null)
	const [selectedRowStatus, setSelectedRowStatus] = useState("active")
	const [clickPoint, setClickPoint] = useState({
		mouseX: null,
		mouseY: null,
	})

	const handleRowClick = (id: number, status: string) => {
		setSelectedRow(id)
		setSelectedRowStatus(status)
	}

	const handleClickPoint = (e: any, id: number, status: string) => {
		e.preventDefault()
		setClickPoint({
			mouseX: e.clientX,
			mouseY: e.clientY,
		})
		handleRowClick(id, status)
	}

	const dntFormat = store.global.getDntFormat
	const active = partialStore.viewSelectedActiveDatePolicy(dntFormat)
	const inactive = partialStore.viewSelectedInActiveDatePolicy(dntFormat)

	console.log(partialStore.selectedPolicy, active, inactive, "selectedPolicy")

	const handleActivateDeactivate = () => {
		// Determine action type based on current status
		const isActivating = selectedRowStatus !== "active"
		const action = isActivating ? "activate" : "deactivate"
		// Prevent modifying the default policy set item
		if (partialStore.selectedPolicyInfo?.isDefault) {
			partialStore.handleResponse({
				actionName: "actOrDeactivatePolicyItem",
				status: ActionStatus.fail,
				code: 200,
				color: MessageColorType.red,
				message: `Cannot ${action} the default policy set item.`,
				open: true,
				autoHide: true,
			})
			return
		}
		if (!isActivating && active.length === 1) {
			partialStore.handleResponse({
				actionName: "deactivatePolicyItem",
				status: ActionStatus.fail,
				code: 200,
				color: MessageColorType.red,
				message: `At least one policy set item must remain active.`,
				open: true,
				autoHide: true,
			})
			return
		}
		const proceed = window.confirm(
			`Are you sure you want to ${action} this item?`
		)
		if (!proceed) return
		// Retrieve the selected row details
		const sourceList = isActivating ? inactive : active
		const selectedRowDetails = sourceList.find(
			(item: any) => item.ArchiveDatePolicyItemId === selectedRow
		)
		if (!selectedRowDetails) {
			partialStore.handleResponse({
				actionName: "actOrDeactivatePolicyItem",
				status: ActionStatus.fail,
				code: 200,
				color: MessageColorType.red,
				message: `Selected item details could not be found.`,
				open: true,
				autoHide: true,
			})
			return
		}
		// Extract required properties & perform the action
		const { SortOrder, IsActive } = selectedRowDetails
		const userInfo = orgStore.checkin.orgInfo
		const modifiedByUserName = `[${userInfo.userAliasId}] ${userInfo.userName}`

		partialStore.editArchivePolicyDatePolicyItem(
			partialStore.selectedPolicy,
			selectedRow,
			SortOrder,
			!IsActive,
			modifiedByUserName
		)
	}

	const tempName = EMAIL_TEMPLATE_DATA.find(
		(item: any) => item.id === selectedTemplate
	)

	return (
		<>
			<h1>Email Template Info for {tempName?.title}</h1>
			<EmailTemplateEditor />
		</>
	)
})

const ArchPolicyItemCtxMenu = observer(
	({
		clickPoint,
		handleClose,
		handleSorting,
		handleActivateDeactivate,
		status,
	}: {
		clickPoint: any
		handleClose: any
		handleSorting: any
		handleActivateDeactivate: any
		status: string
	}) => {
		const menuOptions = [
			{
				label: "Edit Sorting Order",
				value: "ctx-sort-item",
				clickEvent: handleSorting,
			},
			{
				label: status === "active" ? "Deactivate" : "Activate",
				value: "ctx-archive-arch-policy",
				clickEvent: handleActivateDeactivate,
			},
		]
		return (
			<DLContextMenu
				eleId="arch-date-policy-item-ctx-menu"
				clickPoint={clickPoint}
				handleClose={handleClose}
				menuOptions={menuOptions}
			/>
		)
	}
)

const StyledEmailTemplateItems = styled.div`
	margin-left: 8px;
	.inactive {
		margin-top: 2rem;
	}
	.title {
		color: #3473f3;
		font-weight: bold;
	}
	.desc {
		font-size: 0.8rem;
	}
	.active {
		margin-top: 2rem;
	}
	.header {
		margin-bottom: 8px;
	}
	table {
		border: 1px solid rgba(224, 224, 224, 1);
	}
	table > thead > tr > th {
		padding: 0;
		.header {
			min-height: 2rem;
			padding-left: 8px;
			justify-content: center;
		}
	}
	table > tbody > tr > td {
		padding: 8px;
	}
`

const renderCustomCell = (props: any) => {
	const cellId = props.cell.column.id + "-cell"
	return <div data-testid={cellId}>{props.getValue() || "-"}</div>
}

const columns = (
	type: string,
	selectedRow: any,
	handleClickPoint: any,
	handleRowClick: any
) => [
	{
		header: "Select",
		accessorKey: "ArchiveDatePolicyItemId",
		size: 30,
		cell: (props: any) => {
			const id = props.row.original.ArchiveDatePolicyItemId
			return (
				<div style={{ width: "100%", textAlign: "center" }}>
					<DLRadio
						eleTestId={id + "-radio"}
						checked={id === selectedRow}
						onChange={() => handleRowClick(id, type)}
						color="primary"
					/>
				</div>
			)
		},
	},
	{
		header: "Actions",
		accessorKey: "actions",
		size: 30,
		cell: (props: any) => {
			const id = props.row.original.ArchiveDatePolicyItemId
			return (
				<div style={{ width: "100%", textAlign: "center" }}>
					<DLButton
						variant="text"
						eleTestId="project-list-context-btn"
						clickHandler={(event: any) =>
							handleClickPoint(event, id, type)
						}
					>
						<Icon path={mdiDotsHorizontal} size={0.8} />
					</DLButton>
				</div>
			)
		},
	},
	{
		header: "Sorting Order",
		accessorKey: "SortOrder",
	},
	{
		header: "Archive Date Policy",
		accessorKey: "DaysString",
	},
	{
		header: "Modified By",
		accessorKey: "ModifiedByUserName",
		cell: renderCustomCell,
	},
	{
		header: "Modified At",
		accessorKey: "ModifiedAtUtc",
	},
	{
		header: type === "active" ? "Activated By" : "Deactivated By",
		accessorKey:
			type === "active" ? "ActivatedByUserName" : "DeActivatedByUserName",
		cell: renderCustomCell,
	},
	{
		header: type === "active" ? "Activated At" : "Deactivated At",
		accessorKey: type === "active" ? "ActivatedAtUtc" : "DeActivatedAtUtc",
	},
]
