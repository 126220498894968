import { applySnapshot, types } from "mobx-state-tree"
import { Responses, ResponseSnackbar } from "../../../../../common-models"
import { initialStore } from "./proj-download-center.provider"
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"
import GetProjDownloadCenterList from "./view-model-actions/get-proj-download-center-list"
import { ConsoleLog } from "../../../../../components/basic-elements"
import { DnTFormatProps } from "../../../../../common-models/types/common-props"
import { formattedDateWithoutTzLabel } from "../../../../../library/converters/date-utc-converter"
import {
	ProjDownloadCenter,
	ProjDownloadCenterZipProgress,
} from "./data-models/proj-download-center.data-model"
import { endOfDay, isBefore, parseISO } from "date-fns"
import GetProjDataMgmtZipFileById from "./view-model-actions/get-proj-data-mgmt-zip-file-by-id"
import RemoveProjDataMgmtZipFileById from "./view-model-actions/remove-proj-data-mgmt-zip-file-by-id"

const ProjDownloadCenterViewModel = types
	.model({
		projDownloadCenterList: types.array(ProjDownloadCenter),
		// ---------- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		setProjDownloadCenterList(projDownloadCenterList: any) {
			self.projDownloadCenterList = projDownloadCenterList
		},
		spliceItemFromList(itemId: number) {
			self.projDownloadCenterList.splice(
				self.projDownloadCenterList.findIndex(
					(item) => item.id === itemId
				),
				1
			)
		},
		updateDeletedItemStatus(itemId: number) {
			const targetItem = self.projDownloadCenterList.find(
				(item) => item.id === itemId
			)
			if (targetItem) {
				targetItem.zipProgress = ProjDownloadCenterZipProgress.Deleted
				targetItem.expiredAt = null
				targetItem.isDownloadable = false
				targetItem.isDeleted = true
			}
		},
	}))
	.actions(GetProjDownloadCenterList)
	.actions(GetProjDataMgmtZipFileById)
	.actions(RemoveProjDataMgmtZipFileById)
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views((self) => ({
		viewFormattedProjDownloadCenterList(
			dntFormat: DnTFormatProps,
			zipTypeId: number
		) {
			const zipType =
				zipTypeId === 0
					? "All"
					: zipTypeId === 1
					? "Project Zip"
					: "Folder Zip"
			// Filter the list based on zipTypeId, unless zipTypeId is 0 (which means "All")
			const filteredList =
				zipTypeId === 0
					? self.projDownloadCenterList
					: self.projDownloadCenterList.filter(
							(item: any) => item.zipType === zipType
					  )
			return filteredList.map((item: any) => {
				const createdAt = item.createdAt
					? formattedDateWithoutTzLabel({
							date: item.createdAt,
							dntFormat,
					  })
					: ""
				const expiredAt = item.expiredAt
					? formattedDateWithoutTzLabel({
							date: item.expiredAt,
							dntFormat,
					  })
					: ""

				// ------ Convert expirationDate to a Date object and set time to 11:59 PM
				// const expirationDateObj1 = item.expiredAt
				// 	? endOfDay(parseISO(item.expiredAt))
				// 	: null
				const expirationDateObj = item.expiredAt
					? parseISO(item.expiredAt)
					: null

				// Determine file status
				const zipStatus =
					expirationDateObj && isBefore(expirationDateObj, new Date())
						? "Expired"
						: "Available"

				// Zip File Size
				const zipFileSize = item.size
					? (item.size / 1024 / 1024).toFixed(2) + " MB"
					: "-"

				return {
					...item,
					createdAt,
					expiredAt,
					zipStatus,
					zipFileSize,
				}
			})
		},
		viewSelectedItemInfo(itemId: number) {
			const targetItem = self.projDownloadCenterList.find(
				(item) => item.id === itemId
			)
			ConsoleLog("viewSelectedItemInfo " + targetItem)
			return targetItem
		},
	}))
	.views(ViewResponseHelper)

export default ProjDownloadCenterViewModel
