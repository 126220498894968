import { flow } from "mobx-state-tree"
import { FileSystemApiTypes } from "../../apis/file-system-api-types"
import { CreateFolderZipExportApiProps } from "../../apis/file-system-download-apis"

const CreateFolderZipExport = (
	self: any,
	apiCreateFolderZipExport: FileSystemApiTypes["apiCreateFolderZipExport"]
) => ({
	createFolderZipExport: flow(function* (
		payload: CreateFolderZipExportApiProps
	) {
		const actionName = "createFolderZipExport"

		try {
			const response = yield apiCreateFolderZipExport(payload)
			return response
		} catch (error) {
			self.handleModelError({
				actionName,
				error,
				openSnackbar: true,
			})

			return false
		}
	}),
})

export default CreateFolderZipExport
