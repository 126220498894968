import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"
import {
	idToNumber,
	IdType,
} from "../../../../../library/converters/id-converter"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - loading, success, fail
 *
 */

const DeactivateProjUser = (self: any) => ({
	deactivateProjUser(userId: string, postSuccess?: () => void) {
		// 0.
		const actionName = "deactivateProjUser"
		// 1.
		// 2.

		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 0,
			color: MessageColorType.green,
			open: true,
		})
		// 3.

		const userNumberId = idToNumber(userId, IdType.user) // here..? or get from the component..??

		self.requestDeactivateProjUser(userNumberId)
			.then((response: any) => {
				// console.log(actionName+ "__response " + response)
				if (response.status === 200 && response.data.code === 1) {
					// update stored list
					self.setProjUserStatus(self.selectedUser, "deactivate")
					self.setSelectedUser("")
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
					})
					postSuccess && postSuccess()
				} else {
					// set fail case respnose
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
						message: response.data.message,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default DeactivateProjUser
