import { types, applySnapshot } from "mobx-state-tree"
// ---------- common models
import Responses from "../../../../common-models/responses"
import ResponseSnackbar from "../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../common-models/common-view-model-actions"
import { initialStore } from "./fs-excel-import.provider"

import {
	ClickPoint,
	ClickPointProps,
} from "../../../../common-models/types/dialog.props"
import { ColumnMapping } from "./data-models/fs-excel-import.data-model"

const FSExcelImportViewModel = types
	.model({
		selectedcolumn: "",
		clickPoint: ClickPoint,
		columnMapping: types.array(ColumnMapping),
		// ---------- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar, // snackbar type 2
	})
	.actions((self) => ({
		setClickPoint(clickPoint: ClickPointProps) {
			self.clickPoint = clickPoint
		},
		setSelectedColumn(column: string) {
			self.selectedcolumn = column
		},
		setColumnMapping(columnId: string, mappedColumn: string) {
			const duplicationCheck = self.columnMapping.find(
				(item) => item.columnId === columnId
			)
			if (duplicationCheck) {
				const targetIndex = self.columnMapping.findIndex(
					(item) => item.columnId === columnId
				)
				self.columnMapping[targetIndex].mappedColumn = mappedColumn
			} else {
				self.columnMapping.push({ columnId, mappedColumn })
			}
		},
	}))
	.views((self) => ({
		getSelectedMappedColumn() {
			return self.columnMapping.find(
				(item) => item.columnId === self.selectedcolumn
			)?.mappedColumn
		},
	}))
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default FSExcelImportViewModel
