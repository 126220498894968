import React from "react"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import { observer } from "mobx-react-lite"
//
import {
	ConsoleLog,
	DLIconButton,
} from "../../../../../components/basic-elements"
//
import Icon from "@mdi/react"
import DLDateRangepicker from "../../../../../components/basic-elements/date-picker/DLDateRangePicker"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import ReactTableV8 from "../../../../../components/basic-elements/tables/DLReactTable"
import { Table, Column } from "@tanstack/react-table"
import { InputFieldForTableV8 } from "../../../../../components/basic-elements/tables/InputFieldForTable"
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { mdiDotsHorizontal } from "@mdi/js"

export default observer(function ForeignExchange({
	partialStore,
	permission,
}: {
	partialStore: any
	permission: PermissionAsObjectProps
}) {
	ConsoleLog(" __________// ForeignExchange //__________ ")

	const store = useRootStore()

	const handleContextMenu = (event: any, id: number) => {
		event.preventDefault()
		partialStore.setClickPoint({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
		})
	}

	const dateFormat = store.global.dateFormat.value

	return (
		<ReactTableV8
			tableColumns={columns(handleContextMenu)}
			data={[]}
			hasPagination={true}
			customFilters={(props: any) =>
				filterComponent({ ...props, dateFormat })
			}
			handleContextMenu={(e: any, row: any) =>
				handleContextMenu(e, row.mapNumber)
			}
			height={store.ui.contentsAreaHeight - 60}
			permission={permission}
			// pageHeader={pageHeader}
			menuId={`${DLProjSubMenus.working_trial_balance}-ForeignExchage`}
			showNewReorderDialog={true}
		/>
	)
})

const filterComponent = ({
	column,
	table,
	dateFormat,
}: {
	column: Column<any>
	table: Table<any>
	dateFormat: string
}) => {
	switch (column.id) {
		case "createdAt":
			const filterValues: any = column.getFilterValue()
			return (
				<DLDateRangepicker
					selectedStartDate={filterValues?.startDate}
					selectedEndDate={filterValues?.endDate}
					eleStartName="startDate"
					eleEndName="endDate"
					onChange={(value: any, name: string) => {
						column.setFilterValue({
							...filterValues,
							[name]: value,
						})
					}}
					format={dateFormat}
				/>
			)

		case "actions":
			return

		default:
			return (
				<InputFieldForTableV8
					columnId={column.id}
					onChange={column.setFilterValue}
					value={column.getFilterValue()}
				/>
			)
	}
}

const columns = (handleContextMenu: any) => {
	return [
		{
			header: "Actions",
			accessorKey: "actions",
			cell: (props: any) => {
				const mapNumber = props.row.original.mapNumber
				return (
					<div className="FR AC JC" style={{ width: "100%" }}>
						<DLIconButton
							aria-label="more"
							clickHandler={(event: any) =>
								handleContextMenu(event, mapNumber)
							}
							eleTestId="act-btn-on-row"
						>
							<Icon path={mdiDotsHorizontal} size={0.8} />
						</DLIconButton>
					</div>
				)
			},
		},
		{
			header: "Entity",
			accessorKey: "entity",
		},
		{
			header: "Account No",
			accessorKey: "accountNo",
		},
		{
			header: "Name",
			accessorKey: "name",
		},
		{
			header: "Type",
			accessorKey: "type",
		},
		{
			header: "Rate Type",
			accessorKey: "rateType",
		},
		{
			header: "Opening Rate",
			accessorKey: "openingRate",
		},
		{
			header: "Current Rate",
			accessorKey: "currentRate",
		},
		{
			header: "Prior Year Rate 1",
			accessorKey: "priorYearRate1",
		},
		{
			header: "Prior Year Rate 2",
			accessorKey: "priorYearRate2",
		},
		{
			header: "Prior Year Rate 3",
			accessorKey: "priorYearRate3",
		},
		{
			header: "Prior Year Rate 4",
			accessorKey: "priorYearRate4",
		},
		{
			header: "Budget Rate 1",
			accessorKey: "budgetRate1",
		},
		{
			header: "Budget Rate 2",
			accessorKey: "budgetRate2",
		},
		{
			header: "Budget Rate 3",
			accessorKey: "budgetRate3",
		},
		{
			header: "Budget Rate 4",
			accessorKey: "budgetRate4",
		},
		{
			header: "Budget Rate 5",
			accessorKey: "budgetRate5",
		},
		{
			header: "Forecast Rate 1",
			accessorKey: "forecastRate1",
		},
		{
			header: "Forecast Rate 2",
			accessorKey: "forecastRate2",
		},
		{
			header: "Forecast Rate 3",
			accessorKey: "forecastRate3",
		},
		{
			header: "Forecast Rate 4",
			accessorKey: "forecastRate4",
		},
		{
			header: "Forecast Rate 5",
			accessorKey: "forecastRate5",
		},
	]
}
