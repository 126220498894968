import React from "react"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import ProjNoPermissionOnMenu from "../../proj-access-control/ProjNoPermissionOnMenu"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { observer } from "mobx-react-lite"
import { ActionStatus } from "@datalobby/types"
import M_PropertiesForTransactionMetadata from "./M_PropertiesForTransactionMetadata"

export default observer(
	function M_PropertiesForTransactionMetadataController() {
		const projStore = useProjStore()
		const menuId = DLProjSubMenus.m_properties_for_transaction_metadata

		const menuAccess = projStore.checkin.checkAccess(menuId)

		return (
			<>
				{menuAccess ? (
					<M_PropertiesForTransactionMetadata
						actionStatus={ActionStatus.standby}
					/>
				) : (
					<ProjNoPermissionOnMenu menuId={menuId} />
				)}
			</>
		)
	}
)
