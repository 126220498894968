import { useCallback } from "react"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider" // Adjust path as needed
import { useHistory } from "react-router-dom"
import { getProjIdFromUrl } from "../../../../library/api-requests/request-get-others"

// Custom hook
export const useProjUserActions = () => {
	const history = useHistory()
	const projStore = useProjStore()
	const userId = projStore.projUsers.selectedUser
	const isOwnUser = projStore.checkin.userId === userId
	const actBy = localStorage.getItem("orgUserId")
	const projId = getProjIdFromUrl()

	const handlePostSuccess = useCallback(() => {
		if (isOwnUser) {
			history.push("/organization/my-page/recent")
			localStorage.removeItem("projTokens")
		}
	}, [isOwnUser, history])

	const handleUserAction = useCallback(
		(actionType: "activate" | "deactivate" | "unassign") => {
			const isActivating = actionType === "activate"
			const isDeactivating = actionType === "deactivate"
			const isUnassigning = actionType === "unassign"

			let confirmationMessage = ""

			if (isUnassigning) {
				confirmationMessage = isOwnUser
					? "Since you are unassigning your own user record, you will be redirected to the Organization View after unassignment and will need to revisit the project."
					: "Do you really want to unassign this user from the project?"
			} else {
				confirmationMessage = isOwnUser
					? `Since you are ${
							isActivating ? "activating" : "deactivating"
					  } your own user record, you will be redirected to the Organization View after ${
							isActivating ? "activation" : "deactivation"
					  } and will need to revisit the project.`
					: `Do you really want to ${
							isActivating ? "activate" : "deactivate"
					  } this user?`
			}

			if (!window.confirm(confirmationMessage)) return

			if (isActivating) {
				projStore.projUsers.activateProjUser(
					{ UserId: userId },
					handlePostSuccess
				)
			} else if (isDeactivating) {
				projStore.projUsers.deactivateProjUser(
					userId,
					handlePostSuccess
				)
			} else if (isUnassigning) {
				if (userId && actBy && projId) {
					// Simplified check
					projStore.projUsers.unAssignProjUser(
						userId,
						actBy,
						projId,
						handlePostSuccess
					)
				} else {
					alert("(Project User Ctx Menu) Props are invalid")
				}
			}
		},
		[
			isOwnUser,
			projStore.projUsers,
			userId,
			handlePostSuccess,
			projId,
			actBy,
		]
	)

	return { handleUserAction, handlePostSuccess }
}
