import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../../../components/basic-elements"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"

const RemoveAMUnarchivedProject = (self: any) => ({
	removeAMUnarchivedProject(projectId: string, cabinetId: string) {
		// 0.
		const actionName = "removeUnarchivedProject"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		if (projectId === undefined || cabinetId === undefined) {
			alert(`${actionName} one of the params is undefined`)
			return
		}
		const clientId = idToNumber(projectId, IdType.project)
		const cabinetNumberId = idToNumber(cabinetId, IdType.cabinet)
		const payload = {
			ClientId: clientId,
			CabinetId: cabinetNumberId,
			Type: "Client",
		}
		self.deleteAmUnarchivedProject(payload)
			.then((response: any) => {
				// if success
				if (response.status === 200 && response.data.Status === 1) {
					ConsoleLog(response)
					self.deleteUnarchivedProject(projectId)
					self.setOpenDeleteProjDialog(false)
					self.resetSelectedProj()
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
						// autoHide: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default RemoveAMUnarchivedProject
