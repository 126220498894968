import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
import { initialDLFileAsFolder } from "../../data-models/initial-file"
import { IdType, idToString } from "../../../../library/converters/id-converter"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { FileScreenDialog } from "../../data-models/dl-file-control-model"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { hasEmptyProps } from "@datalobby/common"
import { ConsoleLog } from "../../../../components/basic-elements"

export type AddFileFolderProps = {
	projId: string
	parentId: string
	title: string
}

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - loading, success, fail
 *
 */

const AddFileFolder = (self: any) => ({
	addFileFolder(props: AddFileFolderProps) {
		// 0.
		const actionName = "addFileFolder"
		// 1.
		// const checkedProps = hasEmptyProps({
		// 	...props,
		// 	parentId: props.parentId === null ? "folderId0" : props.parentId,
		// })
		const checkedProps = hasEmptyProps(props)
		ConsoleLog(["checkedProps", checkedProps])
		if (checkedProps.hasEmptyProps) {
			alert("Empty props exist")
			return
		}
		// 2.
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 0,
			color: MessageColorType.green,
			open: true,
		})
		// 3.
		self.createFileFolder({
			...checkedProps.props,
			menuId: self.storeName,
		})
			.then((response: any) => {
				// console.log(actionName+ "__response " + response)
				if (response.status === 200 && response.data.Status === 1) {
					const fetchedData = response.data
					// const fetchedParent =
					// 	fetchedData.ParentMediaID === 0
					// 		? null
					// 		: convertId(
					// 				fetchedData.ParentMediaID,
					// 				IdType.folder
					// 		  )
					const fetchedId =
						self.storeName === DLProjSubMenus.wp
							? fetchedData.WPRID
							: self.storeName === DLProjSubMenus.pf
							? fetchedData.PermanentFolderID
							: self.storeName === DLOrgSubMenus.proj_templates
							? fetchedData.TempFoldID
							: 0

					// add folder on the stored data
					const { projId, parentId, title } = checkedProps.props
					const newItem = {
						...initialDLFileAsFolder,
						id: idToString(fetchedId, IdType.folder),
						menuId: self.storeName,
						parentId:
							parentId === "folderId0" || parentId === projId
								? null
								: parentId,
						title,
						expanded: false,
						index: 999, // NOTE: How to set correct index...
					}
					ConsoleLog("storeName " + self.storeName)
					ConsoleLog("fetchedId " + fetchedId)
					ConsoleLog([">>> newItem <<<", newItem])
					self.pushFlatItem(newItem)
					if (parentId !== projId && parentId !== null) {
						self.toggleExpanded(parentId, true)
					}

					// initialize selected item
					self.resetSelectedItems()
					// close the dialog
					self.setFileTreeDialogOpen(
						FileScreenDialog.createFolder,
						false
					) // setCreateFolderDialogOpen(false)
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else if (response === false) {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
					})
				} else {
					// set fail case response (200 but fail)
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
						message: response.data.Message,
					})
				}
			})
			.catch((error: Error) => {
				ConsoleLog([actionName, "__error ", error])
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default AddFileFolder
