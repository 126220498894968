import React from "react"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import {
	DLIconButton,
	DLButton,
	DLContextMenuWithButton,
	DLRadio,
} from "../../../../../components/basic-elements"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import Icon from "@mdi/react"
import { mdiDotsHorizontal, mdiMenuDown } from "@mdi/js"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import DLDateRangepicker from "../../../../../components/basic-elements/date-picker/DLDateRangePicker"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import ReactTableV8 from "../../../../../components/basic-elements/tables/DLReactTable"
import { Table, Column } from "@tanstack/react-table"
import BasicCounter from "../../../../../components/app-frame-elements/sub-components/BasicCounter"
import { AddEngTypeBtn } from "../dialogs/AddEngTypeDialog"
import { InputFieldForTableV8 } from "../../../../../components/basic-elements/tables/InputFieldForTable"
import { DLOrgSubMenus } from "../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { dateRangeFilter } from "../../../../../library/react-table-utils"
import { StyledHeader } from "../../org-setup-users/sub-components/OrgSetupUserListTable"
import { DLCheckbox } from "@datalobby/components"
import styled from "styled-components"
import { accessSync } from "fs"

export default observer(function OrgSetupEngTypesTable({
	i18n,
	permission,
}: {
	i18n: DLI18nProps
	permission: PermissionAsObjectProps
}) {
	// default settings
	const store = useRootStore()
	const orgStore = useOrgStore()
	const menuId = DLOrgSubMenus.setup_eng_types
	//
	const handleContextMenu = (event: any, engTypeId: string) => {
		event.preventDefault()
		orgStore.setupEngTypes.setSelectedEngId(engTypeId)
		orgStore.setupEngTypes.setClickPoint({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
		})
	}

	const dntFormat = store.global.getDntFormat
	const selectedEngTypeId = orgStore.setupEngTypes.engagementId

	const handleCheckbox = (id: string) => {
		orgStore.setupEngTypes.setSelectedEngId(id)
	}

	const active = orgStore.setupEngTypes
		.formattedList(dntFormat)
		.filter((i) => i.isActive)
	const inactive = orgStore.setupEngTypes
		.formattedList(dntFormat)
		.filter((i) => !i.isActive)

	const selectedEngDetails =
		orgStore.setupEngTypes.getEngTypeById(selectedEngTypeId)

	const handleActivateDeactivate = () => {
		const isActive = selectedEngDetails?.isActive
		let proceed = window.confirm(
			`Are you sure you want to ${
				isActive ? "deactivate" : "activate"
			} this item?`
		)
		if (!proceed) return

		const userInfo = orgStore.checkin.orgInfo
		const modifiedByUserName = `[${userInfo.userAliasId}] ${userInfo.userName}`

		orgStore.setupEngTypes.editEngType({
			engagementId: orgStore.setupEngTypes.engagementId,
			modifiedByUserName,
			isActive: !isActive,
		})
	}

	const pageHeaderActive = (instance: Table<any>) => {
		// const totalEngTypes = instance.getPreFilteredRowModel().flatRows.length
		// const filteredEngTypes = instance.getFilteredRowModel().flatRows.length
		const isActive = selectedEngDetails?.isActive
		return (
			<StyledHeader>
				<div>{permission.create && <AddEngTypeBtn i18n={i18n} />}</div>
				<div className="FR" style={{ marginTop: 10 }}>
					<DLContextMenuWithButton
						eleTestId="folders-btn"
						menuOptions={[
							{
								label: `Edit`,
								value: "ctx-editEngType",
								available: permission.update,
								disabled: !selectedEngTypeId,
								clickEvent: () =>
									orgStore.setupEngTypes.setOpenEdit(true),
							},
							{
								label: isActive ? "Deactivate" : "Activate",
								value: `ctx-${
									isActive ? "deactivate" : "activate"
								}`,
								available: permission.update,
								disabled: !selectedEngTypeId,
								clickEvent: handleActivateDeactivate,
							},
						]}
						useScroll={false}
						showButton={true}
						showIcon={false}
						btnText="Item Actions"
						btnVariant="outlined"
						btnColor="primary"
						btnSize="regular"
						btnClassName="ctx-menu-styles"
						eleIcon={<Icon path={mdiMenuDown} size={1} />}
					/>
				</div>
				<div
					className="FR AC counter-container"
					style={{ marginTop: 10 }}
				>
					Active Engagement Types ({active.length})
				</div>
			</StyledHeader>
		)
	}
	const pageHeaderInactive = (instance: Table<any>) => {
		return (
			<StyledHeader>
				<div
					className="FR AC counter-container"
					style={{ marginTop: 10 }}
				>
					Inactive Engagement Types ({inactive.length})
				</div>
			</StyledHeader>
		)
	}
	return (
		<StyledTable>
			<div className="active">
				<ReactTableV8
					tableColumns={columns({
						type: "active",
						selectedId: selectedEngTypeId,
						handleCheckbox,
						handleContextMenu,
					})}
					data={active}
					hasPagination={false}
					// customFilters={(props: any) =>
					// 	filterComponent({ ...props, dateFormat })
					// }
					handleContextMenu={(e: any, row: any) =>
						handleContextMenu(e, row.id)
					}
					height={store.ui.contentsAreaHeight / 2}
					permission={permission}
					i18n={i18n}
					pageHeader={pageHeaderActive}
					menuId={menuId}
					onRowClick={(event: any, row: any) =>
						handleCheckbox(row.id)
					}
				/>
			</div>

			<div className="inactive">
				{/* <div className="counter-container">
					Inactive Engagement Types ({inactive.length})
				</div> */}
				<ReactTableV8
					tableColumns={columns({
						type: "inactive",
						selectedId: selectedEngTypeId,
						handleCheckbox,
						handleContextMenu,
					})}
					data={inactive}
					hasPagination={false}
					// showPageSetting={false}
					height={store.ui.contentsAreaHeight / 2}
					permission={permission}
					i18n={i18n}
					pageHeader={pageHeaderInactive}
					menuId="InactiveEngType"
					onRowClick={(event: any, row: any) =>
						handleCheckbox(row.id)
					}
				/>
			</div>
		</StyledTable>
	)
})

const filterComponent = ({
	column,
	table,
	dateFormat,
}: {
	column: Column<any>
	table: Table<any>
	dateFormat: string
}) => {
	switch (column.id) {
		case "createdAt":
			const filterValues: any = column.getFilterValue()
			return (
				<DLDateRangepicker
					selectedStartDate={filterValues?.startDate}
					selectedEndDate={filterValues?.endDate}
					eleStartName="startDate"
					eleEndName="endDate"
					onChange={(value: any, name: string) => {
						column.setFilterValue({
							...filterValues,
							[name]: value,
						})
					}}
					format={dateFormat}
				/>
			)

		case "actions":
			return

		default:
			return (
				<InputFieldForTableV8
					columnId={column.id}
					onChange={column.setFilterValue}
					value={column.getFilterValue()}
				/>
			)
	}
}

const StyledTable = styled.div`
	margin-left: 8px;
	.inactive {
		margin-top: 2rem;
	}
	.counter-container {
		font-size: 1rem;
		font-weight: bold;
		margin-bottom: 4px;
	}
	.pageHeader {
		padding: 0 !important;
	}
	table {
		border: 1px solid rgba(224, 224, 224, 1);
	}
	table > thead > tr > th {
		padding: 0;
		.header {
			min-height: 2rem;
			padding-left: 8px;
			justify-content: center;
		}
	}
	table > tbody > tr > td {
		padding: 8px;
	}
`

const renderCustomCell = (props: any) => {
	const cellId = props.cell.column.id + "-cell"
	return <div data-testid={cellId}>{props.getValue()}</div>
}

// const columns1 = ({
// 	selectedId,
// 	handleCheckbox,
// 	handleContextMenu,
// 	permission,
// 	i18n,
// }: {
// 	selectedId: string
// 	handleCheckbox: (engTypeId: string) => void
// 	handleContextMenu: any
// 	permission: PermissionAsObjectProps
// 	i18n: DLI18nProps
// }) => [
// 	{
// 		header: "",
// 		accessorKey: "id",
// 		size: 20,
// 		enableColumnFilter: false,
// 		cell: (props: any) => {
// 			const id = props.row.original.id
// 			const handleClick = () => {
// 				handleCheckbox(id)
// 			}

// 			return (
// 				<DLCheckbox
// 					eleTestId={`checkbox-${id}`}
// 					clickHandler={() => handleClick()}
// 					color="primary"
// 					isChecked={selectedId === id}
// 				/>
// 			)
// 		},
// 	},
// 	{
// 		header: i18n.twEngTypeName || "Name",
// 		accessorKey: "name",
// 		cell: renderCustomCell,
// 	},
// 	{
// 		header: i18n.twChecklist || "Checklist",
// 		accessorKey: "checklist",
// 		cell: renderCustomCell,
// 	},
// 	{
// 		header: i18n.twCreatedBy || "Created By",
// 		accessorKey: "createdBy",
// 		cell: renderCustomCell,
// 	},
// 	{
// 		header: i18n.twCreatedAt || "Created At",
// 		accessorKey: "createdAt",
// 		filterFn: dateRangeFilter,
// 		cell: renderCustomCell,
// 	},
// 	{
// 		header: "Action",
// 		// show: permission.update || permission.delete,
// 		cell: (props: any) => {
// 			const engTypeId = props.row.original.id

// 			return (
// 				<div className="FR AC JC" style={{ width: "100%" }}>
// 					<DLIconButton
// 						aria-label="more"
// 						clickHandler={(event: any) =>
// 							handleContextMenu(event, engTypeId)
// 						}
// 						eleTestId="ra-ctx-btn-on-row"
// 					>
// 						<Icon path={mdiDotsHorizontal} size={0.8} />
// 					</DLIconButton>
// 				</div>
// 			)
// 		},
// 	},
// ]

const columns = ({
	type,
	selectedId,
	handleCheckbox,
	handleContextMenu,
}: {
	type: string
	selectedId: string
	handleCheckbox: (engTypeId: string) => void
	handleContextMenu: any
}) => [
	{
		header: "Select",
		accessorKey: "id",
		size: 30,
		cell: (props: any) => {
			const id = props.row.original.id
			return (
				<div style={{ width: "100%", textAlign: "center" }}>
					<DLRadio
						eleTestId={id + "-radio"}
						checked={id === selectedId}
						onChange={() => handleCheckbox(id)}
						color="primary"
					/>
				</div>
			)
		},
	},
	{
		header: "Sorting Order",
		accessorKey: "sortOrder",
		size: 30,
	},
	{
		header: "Actions",
		accessorKey: "actions",
		size: 30,
		cell: (props: any) => {
			const id = props.row.original.id
			return (
				<div style={{ width: "100%", textAlign: "center" }}>
					<DLButton
						variant="text"
						eleTestId="project-list-context-btn"
						clickHandler={(event: any) =>
							handleContextMenu(event, id)
						}
					>
						<Icon path={mdiDotsHorizontal} size={0.8} />
					</DLButton>
				</div>
			)
		},
	},
	{
		header: "Engagement Type",
		accessorKey: "name",
		size: 250,
	},
	{
		header: "Created By",
		accessorKey: "createdBy",
	},
	{
		header: "Created At",
		accessorKey: "createdAt",
	},
	{
		header: "Modified By",
		accessorKey: "modifiedBy",
	},
	{
		header: "Modified At",
		accessorKey: "modifiedAt",
	},
	{
		header: type === "active" ? "Activated By" : "Deactivated By",
		accessorKey: type === "active" ? "activatedBy" : "deActivatedBy",
	},
	{
		header: type === "active" ? "Activated At" : "Deactivated At",
		accessorKey: type === "active" ? "activatedAt" : "deActivatedAt",
	},
]
