// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
import { CreateFolderZipExportApiProps } from "../../apis/file-system-download-apis"

const InitiateFolderZipExport = (self: any) => ({
	initiateFolderZipExport(params: CreateFolderZipExportApiProps) {
		// 0.
		const actionName = "initiateFolderZipExport"
		// 1.
		// 2.
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 999,
			color: MessageColorType.green,
			open: true,
			// autoHide: true,
		})
		// 3.
		self.createFolderZipExport(params)
			.then((response: any) => {
				if (response.data.status === 200) {
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						message: "Folder zip export request is successful",
						// autoHide: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
						autoHide: false,
						message: response.data.message,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default InitiateFolderZipExport
