import { CreateProjectMethod } from "../../../../../components/combined-elements/create-project/create-project.data-props"

const DialogRelatedActions = (self: any) => ({
	// Dialog control
	setOpenAddGroupDialog(request?: boolean) {
		self.addGroupDialogOpen = request ?? !self.addGroupDialogOpen
	},
	setOpenEditGroupDialog(request?: boolean) {
		self.editGroupDialogOpen = request ?? !self.editGroupDialogOpen
	},
	setOpenMoveDialog(request?: boolean) {
		self.openMoveDialog = request ?? !self.openMoveDialog
	},
	setOpenCreateProjDialog(request?: boolean) {
		self.openCreateProjDialog = request ?? !self.openCreateProjDialog
	},
	setOpenEditProjDialog(request?: boolean) {
		self.openEditProjDialog = request ?? self.openEditProjDialog
	},
	setOpenDeleteProjDialog(request?: boolean) {
		self.openDeleteProjDialog = request ?? !self.openDeleteProjDialog
	},
	setCreateProjectMethod(from: CreateProjectMethod) {
		self.createProjectMethod = from
	},
	setOpenCreateCabinetDialog(request?: boolean) {
		self.openCreateCabinetDialog = request ?? !self.openCreateCabinetDialog
	},
	setOpenEditCabinetDialog(request?: boolean) {
		self.openEditCabinetDialog = request ?? !self.openEditCabinetDialog
	},
})

export default DialogRelatedActions
