import { types } from "mobx-state-tree"

export const MapNumbersPresentationModel = types.model({
	id: types.string,
	parentId: types.union(types.string, types.null),
	isParent: types.boolean,
	number: types.union(types.string, types.null),
	expanded: types.boolean,
	title: types.string,
	balance: types.union(types.string, types.null),
	pyBalance: types.union(types.string, types.null),
})
