import React from "react"
import { DLContextMenu } from "../../../../../components/basic-elements"
import { observer } from "mobx-react-lite"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { ActionStatus } from "@datalobby/types"
import { MessageColorType } from "../../../../../common-models/enumerations/common-enums"
import { WORKING_TRIAL_BALANCE_TABS } from "../store/data-models/fs-working-trial-balance.data-model"

export default observer(function FsWorkingTrialBalanceCtxMenus({
	permission,
	partialStore,
}: {
	permission: PermissionAsObjectProps
	partialStore: any
}) {
	const getMenuOptions = () => {
		const selectedTab = partialStore.selectedTab
		if (selectedTab === WORKING_TRIAL_BALANCE_TABS.ACCOUNT) {
			return [
				{
					label: `Link Map No to Account`,
					value: "ctx-link-mapNo-to-account",
					available: permission.update,
					clickEvent: () =>
						partialStore.setLinkMapNoToAccountDialogOpen(true),
				},
			]
		} else {
			return []
		}
	}

	const handleClose = () => {
		partialStore.setClickPoint({
			mouseX: null,
			mouseY: null,
		})
	}

	return (
		<DLContextMenu
			eleId="fs-working-trial-balance-ctx-menus"
			clickPoint={{
				mouseX: partialStore.clickPoint.mouseX,
				mouseY: partialStore.clickPoint.mouseY,
			}}
			handleClose={handleClose}
			menuOptions={getMenuOptions()}
			hasDivider={[0]}
		/>
	)
})
