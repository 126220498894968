import React, { useState } from "react"
import { PageContainer } from "../../../../components/app-frame-elements"
import { ActionStatus } from "@datalobby/types"
import styled from "styled-components"
import { FormControl, MenuItem, Select, TextField } from "@material-ui/core"

import { DLButton } from "../../../../components/basic-elements"

const MCreateNewCounterparty = ({ actionStatus }: { actionStatus: any }) => {
	const [newMapNumber, setNewMapNumber] = useState({
		mapNumber: "",
		title: "",
		behaviour: "",
		name: "",
		type: "",
		normalSign: "",
		class: "",
		calculation: "",
		flipType: "",
		mapNoFlip: "",
		units: "",
		gifiCanadaOnly: "",
		ls: "",
		lsFlip: "",
		group2: "",
		group2Flip: "",
		group3: "",
		group3Flip: "",
		group4: "",
		group4Flip: "",
		group5: "",
		group5Flip: "",
		group6: "",
		group6Flip: "",
		group7: "",
		group7Flip: "",
		group8: "",
		group8Flip: "",
		group9: "",
		group9Flip: "",
		group10: "",
		group10Flip: "",
		showCurrencySymbol: "",
		startNewPage: "",
		columnPosition: "",
		underline: "",
		printFeatures: "",
		accumultorTotal: "",
		cashFlowCredits: "",
		cashFlowDebits: "",
		taxEntity: "",
		m3TaxEntity: "",
		taxExportCode: "",
		m3Code: "",
	})

	const handleForm = (name: string, value: any) => {
		setNewMapNumber((prev: any) => ({
			...prev,
			[name]: value,
		}))
	}

	return (
		<PageContainer
			hasToolbar={false}
			pageTools={<div />}
			fullWidth
			isLoading={actionStatus === ActionStatus.loading}
		>
			<StyledNewMapNumber>
				<h3>New Counterparty</h3>
				<div style={{ margin: "15px 0" }}>
					<h4>General</h4>
					<div className="FR" style={{ marginTop: 15, gap: 15 }}>
						<div style={{ width: "50%" }}>
							<div>Map No</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<TextField
									hiddenLabel
									id="mapNumber"
									value={newMapNumber.mapNumber}
									variant="outlined"
									size="small"
									onChange={(e: any) =>
										handleForm("mapNumber", e.target.value)
									}
								/>
							</FormControl>
						</div>
						<div style={{ width: "50%" }}>
							<div>Title</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<TextField
									hiddenLabel
									id="title"
									value={newMapNumber.title}
									variant="outlined"
									size="small"
									onChange={(e: any) =>
										handleForm("title", e.target.value)
									}
								/>
							</FormControl>
						</div>
					</div>
					<div className="FR" style={{ marginTop: 15, gap: 15 }}>
						<div style={{ width: "50%" }}>
							<div>Behaviour</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<Select
									labelId="period-label"
									id="behaviour"
									value={newMapNumber.behaviour}
									variant="outlined"
									displayEmpty
									placeholder="Select Behaviour"
									onChange={(e) =>
										handleForm("behaviour", e.target.value)
									}
								>
									<MenuItem disabled value="">
										Select Behaviour
									</MenuItem>
									<MenuItem value={"normal"}>Normal</MenuItem>
									<MenuItem value={"calculated"}>
										Calculated
									</MenuItem>
									<MenuItem value={"unassignable"}>
										Unassignable
									</MenuItem>
								</Select>
							</FormControl>
						</div>
						<div style={{ width: "50%" }}>
							<div>Name</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<TextField
									hiddenLabel
									id="name"
									value={newMapNumber.name}
									variant="outlined"
									size="small"
									onChange={(e: any) =>
										handleForm("name", e.target.value)
									}
								/>
							</FormControl>
						</div>
					</div>
					<div className="FR" style={{ marginTop: 15, gap: 15 }}>
						<div style={{ width: "50%" }}>
							<div>Type</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<Select
									labelId="adjustment-type-label"
									id="adjustment-type"
									value={newMapNumber.type}
									variant="outlined"
									displayEmpty
									placeholder="Select type"
									onChange={(e) =>
										handleForm("type", e.target.value)
									}
									// fullWidth
								>
									<MenuItem disabled value="">
										Select type
									</MenuItem>
									<MenuItem value={"balance-sheet"}>
										Balance Sheet (B)
									</MenuItem>
									<MenuItem value={"inceome-statement"}>
										Income Statement (I)
									</MenuItem>
									<MenuItem value={"statement-of-cash-flow"}>
										Statement of Cash Flow (S)
									</MenuItem>
									<MenuItem value={"performance"}>
										Performance (P)
									</MenuItem>
								</Select>
							</FormControl>
						</div>
						<div style={{ width: "50%" }}>
							<div>Normal Sign</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<Select
									labelId="period-label"
									id="normalSign"
									value={newMapNumber.normalSign}
									variant="outlined"
									displayEmpty
									placeholder="Select Normal Sign"
									onChange={(e) =>
										handleForm("normalSign", e.target.value)
									}
								>
									<MenuItem disabled value="">
										Select Normal Sign
									</MenuItem>
									<MenuItem value={"debit"}>Debit</MenuItem>
									<MenuItem value={"credit"}>Credit</MenuItem>
								</Select>
							</FormControl>
						</div>
					</div>

					<div className="FR" style={{ marginTop: 15, gap: 15 }}>
						<div style={{ width: "50%" }}>
							<div>Class</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<Select
									labelId="period-label"
									id="class"
									value={newMapNumber.class}
									variant="outlined"
									displayEmpty
									placeholder="Select Class"
									onChange={(e) =>
										handleForm("behaviour", e.target.value)
									}
								>
									<MenuItem disabled value="">
										Select Class
									</MenuItem>
								</Select>
							</FormControl>
						</div>
						<div style={{ width: "50%" }}>
							<div>Calculation</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<TextField
									hiddenLabel
									id="calculation"
									value={newMapNumber.calculation}
									variant="outlined"
									size="small"
									onChange={(e: any) =>
										handleForm(
											"calculation",
											e.target.value
										)
									}
								/>
							</FormControl>
						</div>
					</div>
					<div className="FR" style={{ marginTop: 15, gap: 15 }}>
						<div style={{ width: "50%" }}>
							<div>Flip Type</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<Select
									labelId="period-label"
									id="flipType"
									value={newMapNumber.flipType}
									variant="outlined"
									displayEmpty
									placeholder="Select Flip Type"
									onChange={(e) =>
										handleForm("flipType", e.target.value)
									}
								>
									<MenuItem disabled value="">
										Select Flip Type
									</MenuItem>
								</Select>
							</FormControl>
						</div>
						<div style={{ width: "50%" }}>
							<div>Map No (Flip)</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<Select
									labelId="period-label"
									id="lsGroupNoFlip"
									value={newMapNumber.mapNoFlip}
									variant="outlined"
									displayEmpty
									placeholder="Select L/S/Group No (Flip)"
									onChange={(e) =>
										handleForm(
											"lsGroupNoFlip",
											e.target.value
										)
									}
								>
									<MenuItem disabled value="">
										Select L/S/Group No (Flip)
									</MenuItem>
								</Select>
							</FormControl>
						</div>
					</div>
					<div className="FR" style={{ marginTop: 15, gap: 15 }}>
						<div style={{ width: "50%" }}>
							<div>Units</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<Select
									labelId="period-label"
									id="units"
									value={newMapNumber.units}
									variant="outlined"
									displayEmpty
									placeholder="Select Units"
									onChange={(e) =>
										handleForm("units", e.target.value)
									}
								>
									<MenuItem disabled value="">
										Select Units
									</MenuItem>
								</Select>
							</FormControl>
						</div>
						<div style={{ width: "50%" }}>
							<div>GIFI (Canada only)</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<Select
									labelId="period-label"
									id="gifiCanadaOnly"
									value={newMapNumber.gifiCanadaOnly}
									variant="outlined"
									displayEmpty
									placeholder="Select GIFI (Canada only)"
									onChange={(e) =>
										handleForm(
											"gifiCanadaOnly",
											e.target.value
										)
									}
								>
									<MenuItem disabled value="">
										Select GIFI (Canada only)
									</MenuItem>
								</Select>
							</FormControl>
						</div>
					</div>
				</div>
				<hr />
				<div style={{ margin: "15px 0" }}>
					<h4>FS Groups 1-5 [SubHeader]</h4>
					<div className="FR" style={{ marginTop: 15, gap: 15 }}>
						<div style={{ width: "50%" }}>
							<div>L/S</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<Select
									labelId="period-label"
									id="ls"
									value={newMapNumber.ls}
									variant="outlined"
									displayEmpty
									placeholder="Select L/S"
									onChange={(e) =>
										handleForm("ls", e.target.value)
									}
								>
									<MenuItem disabled value="">
										Select L/S
									</MenuItem>
								</Select>
							</FormControl>
						</div>
						<div style={{ width: "50%" }}>
							<div>L/S (Flip)</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<Select
									labelId="period-label"
									id="lsFlip"
									value={newMapNumber.lsFlip}
									variant="outlined"
									displayEmpty
									placeholder="Select L/S (Flip)"
									onChange={(e) =>
										handleForm("lsFlip", e.target.value)
									}
								>
									<MenuItem disabled value="">
										Select L/S (Flip)
									</MenuItem>
								</Select>
							</FormControl>
						</div>
					</div>
					<div className="FR" style={{ marginTop: 15, gap: 15 }}>
						<div style={{ width: "50%" }}>
							<div>Group 2</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<Select
									labelId="period-label"
									id="group2"
									value={newMapNumber.group2}
									variant="outlined"
									displayEmpty
									placeholder="Select Group 2"
									onChange={(e) =>
										handleForm("group2", e.target.value)
									}
								>
									<MenuItem disabled value="">
										Select Group 2
									</MenuItem>
								</Select>
							</FormControl>
						</div>
						<div style={{ width: "50%" }}>
							<div>Group 2 (Flip)</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<Select
									labelId="period-label"
									id="group2Flip"
									value={newMapNumber.group2Flip}
									variant="outlined"
									displayEmpty
									placeholder="Select Group 2 (Flip)"
									onChange={(e) =>
										handleForm("group2Flip", e.target.value)
									}
								>
									<MenuItem disabled value="">
										Select Group 2 (Flip)
									</MenuItem>
								</Select>
							</FormControl>
						</div>
					</div>
					<div className="FR" style={{ marginTop: 15, gap: 15 }}>
						<div style={{ width: "50%" }}>
							<div>Group 3</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<Select
									labelId="period-label"
									id="group3"
									value={newMapNumber.group3}
									variant="outlined"
									displayEmpty
									placeholder="Select Group 3"
									onChange={(e) =>
										handleForm("group3", e.target.value)
									}
								>
									<MenuItem disabled value="">
										Select Group 3
									</MenuItem>
								</Select>
							</FormControl>
						</div>
						<div style={{ width: "50%" }}>
							<div>Group 3 (Flip)</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<Select
									labelId="period-label"
									id="group3Flip"
									value={newMapNumber.group3Flip}
									variant="outlined"
									displayEmpty
									placeholder="Select Group 3 (Flip)"
									onChange={(e) =>
										handleForm("group3Flip", e.target.value)
									}
								>
									<MenuItem disabled value="">
										Select Group 3 (Flip)
									</MenuItem>
								</Select>
							</FormControl>
						</div>
					</div>
					<div className="FR" style={{ marginTop: 15, gap: 15 }}>
						<div style={{ width: "50%" }}>
							<div>Group 4</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<Select
									labelId="period-label"
									id="group4"
									value={newMapNumber.group4}
									variant="outlined"
									displayEmpty
									placeholder="Select Group 4"
									onChange={(e) =>
										handleForm("group4", e.target.value)
									}
								>
									<MenuItem disabled value="">
										Select Group 4
									</MenuItem>
								</Select>
							</FormControl>
						</div>
						<div style={{ width: "50%" }}>
							<div>Group 4 (Flip)</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<Select
									labelId="period-label"
									id="group4Flip"
									value={newMapNumber.group4Flip}
									variant="outlined"
									displayEmpty
									placeholder="Select Group 4 (Flip)"
									onChange={(e) =>
										handleForm("group4Flip", e.target.value)
									}
								>
									<MenuItem disabled value="">
										Select Group 4 (Flip)
									</MenuItem>
								</Select>
							</FormControl>
						</div>
					</div>
					<div className="FR" style={{ marginTop: 15, gap: 15 }}>
						<div style={{ width: "50%" }}>
							<div>Group 5</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<Select
									labelId="period-label"
									id="group5"
									value={newMapNumber.group5}
									variant="outlined"
									displayEmpty
									placeholder="Select Group 5"
									onChange={(e) =>
										handleForm("group5", e.target.value)
									}
								>
									<MenuItem disabled value="">
										Select Group 5
									</MenuItem>
								</Select>
							</FormControl>
						</div>
						<div style={{ width: "50%" }}>
							<div>Group 5 (Flip)</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<Select
									labelId="period-label"
									id="group5Flip"
									value={newMapNumber.group5Flip}
									variant="outlined"
									displayEmpty
									placeholder="Select Group 5 (Flip)"
									onChange={(e) =>
										handleForm("group5Flip", e.target.value)
									}
								>
									<MenuItem disabled value="">
										Select Group 5 (Flip)
									</MenuItem>
								</Select>
							</FormControl>
						</div>
					</div>
				</div>
				<hr />
				<div style={{ margin: "15px 0" }}>
					<h4>FS Groups 6-10 [SubHeader]</h4>
					<div className="FR" style={{ marginTop: 15, gap: 15 }}>
						<div style={{ width: "50%" }}>
							<div>Group 6</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<Select
									labelId="period-label"
									id="group6"
									value={newMapNumber.group6}
									variant="outlined"
									displayEmpty
									placeholder="Select Group 6"
									onChange={(e) =>
										handleForm("group6", e.target.value)
									}
								>
									<MenuItem disabled value="">
										Select Group 6
									</MenuItem>
								</Select>
							</FormControl>
						</div>
						<div style={{ width: "50%" }}>
							<div>Group 6 (Flip)</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<Select
									labelId="period-label"
									id="group6Flip"
									value={newMapNumber.group6Flip}
									variant="outlined"
									displayEmpty
									placeholder="Select Group 6 (Flip)"
									onChange={(e) =>
										handleForm("group6Flip", e.target.value)
									}
								>
									<MenuItem disabled value="">
										Select Group 6 (Flip)
									</MenuItem>
								</Select>
							</FormControl>
						</div>
					</div>
					<div className="FR" style={{ marginTop: 15, gap: 15 }}>
						<div style={{ width: "50%" }}>
							<div>Group 7</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<Select
									labelId="period-label"
									id="group7"
									value={newMapNumber.group7}
									variant="outlined"
									displayEmpty
									placeholder="Select Group 7"
									onChange={(e) =>
										handleForm("group7", e.target.value)
									}
								>
									<MenuItem disabled value="">
										Select Group 7
									</MenuItem>
								</Select>
							</FormControl>
						</div>
						<div style={{ width: "50%" }}>
							<div>Group 7 (Flip)</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<Select
									labelId="period-label"
									id="group7Flip"
									value={newMapNumber.group7Flip}
									variant="outlined"
									displayEmpty
									placeholder="Select Group 7 (Flip)"
									onChange={(e) =>
										handleForm("group7Flip", e.target.value)
									}
								>
									<MenuItem disabled value="">
										Select Group 7 (Flip)
									</MenuItem>
								</Select>
							</FormControl>
						</div>
					</div>
					<div className="FR" style={{ marginTop: 15, gap: 15 }}>
						<div style={{ width: "50%" }}>
							<div>Group 8</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<Select
									labelId="period-label"
									id="group8"
									value={newMapNumber.group8}
									variant="outlined"
									displayEmpty
									placeholder="Select Group 8"
									onChange={(e) =>
										handleForm("group8", e.target.value)
									}
								>
									<MenuItem disabled value="">
										Select Group 8
									</MenuItem>
								</Select>
							</FormControl>
						</div>
						<div style={{ width: "50%" }}>
							<div>Group 8 (Flip)</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<Select
									labelId="period-label"
									id="group8Flip"
									value={newMapNumber.group8Flip}
									variant="outlined"
									displayEmpty
									placeholder="Select Group 8 (Flip)"
									onChange={(e) =>
										handleForm("group8Flip", e.target.value)
									}
								>
									<MenuItem disabled value="">
										Select Group 8 (Flip)
									</MenuItem>
								</Select>
							</FormControl>
						</div>
					</div>
					<div className="FR" style={{ marginTop: 15, gap: 15 }}>
						<div style={{ width: "50%" }}>
							<div>Group 9</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<Select
									labelId="period-label"
									id="group9"
									value={newMapNumber.group9}
									variant="outlined"
									displayEmpty
									placeholder="Select Group 9"
									onChange={(e) =>
										handleForm("group9", e.target.value)
									}
								>
									<MenuItem disabled value="">
										Select Group 9
									</MenuItem>
								</Select>
							</FormControl>
						</div>
						<div style={{ width: "50%" }}>
							<div>Group 9 (Flip)</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<Select
									labelId="period-label"
									id="group9Flip"
									value={newMapNumber.group9Flip}
									variant="outlined"
									displayEmpty
									placeholder="Select Group 9 (Flip)"
									onChange={(e) =>
										handleForm("group9Flip", e.target.value)
									}
								>
									<MenuItem disabled value="">
										Select Group 9 (Flip)
									</MenuItem>
								</Select>
							</FormControl>
						</div>
					</div>
					<div className="FR" style={{ marginTop: 15, gap: 15 }}>
						<div style={{ width: "50%" }}>
							<div>Group 10</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<Select
									labelId="period-label"
									id="group10"
									value={newMapNumber.group10}
									variant="outlined"
									displayEmpty
									placeholder="Select Group 10"
									onChange={(e) =>
										handleForm("group10", e.target.value)
									}
								>
									<MenuItem disabled value="">
										Select Group 10
									</MenuItem>
								</Select>
							</FormControl>
						</div>
						<div style={{ width: "50%" }}>
							<div>Group 10 (Flip)</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<Select
									labelId="period-label"
									id="group10Flip"
									value={newMapNumber.group10Flip}
									variant="outlined"
									displayEmpty
									placeholder="Select Group 10 (Flip)"
									onChange={(e) =>
										handleForm(
											"group10Flip",
											e.target.value
										)
									}
								>
									<MenuItem disabled value="">
										Select Group 10 (Flip)
									</MenuItem>
								</Select>
							</FormControl>
						</div>
					</div>
				</div>

				<hr />
				<div style={{ margin: "15px 0" }}>
					<h4>Format</h4>
					<div className="FR" style={{ marginTop: 15, gap: 15 }}>
						<div style={{ width: "50%" }}>
							<div>Show currency symbol before balances</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<Select
									labelId="period-label"
									id="showCurrencySymbol"
									value={newMapNumber.showCurrencySymbol}
									variant="outlined"
									displayEmpty
									placeholder="Select"
									onChange={(e) =>
										handleForm(
											"showCurrencySymbol",
											e.target.value
										)
									}
								>
									<MenuItem disabled value="">
										Select
									</MenuItem>
								</Select>
							</FormControl>
						</div>
						<div style={{ width: "50%" }}>
							<div>Start a new page at this point</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<Select
									labelId="period-label"
									id="startNewPage"
									value={newMapNumber.startNewPage}
									variant="outlined"
									displayEmpty
									placeholder="Select"
									onChange={(e) =>
										handleForm(
											"startNewPage",
											e.target.value
										)
									}
								>
									<MenuItem disabled value="">
										Select
									</MenuItem>
								</Select>
							</FormControl>
						</div>
					</div>
					<div className="FR" style={{ marginTop: 15, gap: 15 }}>
						<div style={{ width: "50%" }}>
							<div>Column Position</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<Select
									labelId="period-label"
									id="columnPosition"
									value={newMapNumber.columnPosition}
									variant="outlined"
									displayEmpty
									placeholder="Select Column Position"
									onChange={(e) =>
										handleForm(
											"columnPosition",
											e.target.value
										)
									}
								>
									<MenuItem disabled value="">
										Select Column Position
									</MenuItem>
								</Select>
							</FormControl>
						</div>
						<div style={{ width: "50%" }}>
							<div>Underline</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<Select
									labelId="period-label"
									id="underline"
									value={newMapNumber.underline}
									variant="outlined"
									displayEmpty
									placeholder="Select Underline"
									onChange={(e) =>
										handleForm("underline", e.target.value)
									}
								>
									<MenuItem disabled value="">
										Select Underline
									</MenuItem>
								</Select>
							</FormControl>
						</div>
					</div>
					<div className="FR" style={{ marginTop: 15, gap: 15 }}>
						<div style={{ width: "50%" }}>
							<div>Print Features</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<Select
									labelId="period-label"
									id="printFeatures"
									value={newMapNumber.printFeatures}
									variant="outlined"
									displayEmpty
									placeholder="Select Print Features"
									onChange={(e) =>
										handleForm(
											"printFeatures",
											e.target.value
										)
									}
								>
									<MenuItem disabled value="">
										Select Print Features
									</MenuItem>
									<MenuItem value="n/a">N/A</MenuItem>
									<MenuItem value="credit">Credit</MenuItem>
									<MenuItem value="debit">Debit</MenuItem>
									<MenuItem value="header">Header</MenuItem>
									<MenuItem value="justify">Justify</MenuItem>
									<MenuItem value="skip">Skip</MenuItem>
								</Select>
							</FormControl>
						</div>
					</div>
				</div>
				<hr />
				<div style={{ margin: "15px 0" }}>
					<h4>Account defaults</h4>
					<div className="FR" style={{ marginTop: 15, gap: 15 }}>
						<div style={{ width: "50%" }}>
							<div>Accumulator total</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<Select
									labelId="period-label"
									id="accumultorTotal"
									value={newMapNumber.accumultorTotal}
									variant="outlined"
									displayEmpty
									placeholder="Select"
									onChange={(e) =>
										handleForm(
											"accumultorTotal",
											e.target.value
										)
									}
								>
									<MenuItem disabled value="">
										Select
									</MenuItem>
								</Select>
							</FormControl>
						</div>
						<div style={{ width: "50%" }}>
							<div>Cash flow account to receive credits</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<Select
									labelId="period-label"
									id="cashFlowCredits"
									value={newMapNumber.cashFlowCredits}
									variant="outlined"
									displayEmpty
									placeholder="Select"
									onChange={(e) =>
										handleForm(
											"cashFlowCredits",
											e.target.value
										)
									}
								>
									<MenuItem disabled value="">
										Select
									</MenuItem>
								</Select>
							</FormControl>
						</div>
					</div>
					<div className="FR" style={{ marginTop: 15, gap: 15 }}>
						<div style={{ width: "50%" }}>
							<div>Cash flow account to receive debits</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<Select
									labelId="period-label"
									id="cashFlowDebits"
									value={newMapNumber.cashFlowDebits}
									variant="outlined"
									displayEmpty
									placeholder="Select"
									onChange={(e) =>
										handleForm(
											"cashFlowDebits",
											e.target.value
										)
									}
								>
									<MenuItem disabled value="">
										Select
									</MenuItem>
								</Select>
							</FormControl>
						</div>
					</div>
				</div>
				<hr />
				<div style={{ margin: "15px 0" }}>
					<h4>Tax export codes</h4>
					<div className="FR" style={{ marginTop: 15, gap: 15 }}>
						<div style={{ width: "50%" }}>
							<div>Tax Entity</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<Select
									labelId="period-label"
									id="taxEntity"
									value={newMapNumber.taxEntity}
									variant="outlined"
									displayEmpty
									placeholder="Select Tax Entity"
									onChange={(e) =>
										handleForm("taxEntity", e.target.value)
									}
								>
									<MenuItem disabled value="">
										Select Tax Entity
									</MenuItem>
									<MenuItem value="corporation">
										Corporation
									</MenuItem>
									<MenuItem value="s-corporation">
										S Corporation
									</MenuItem>
									<MenuItem value="partnership">
										Partnership
									</MenuItem>
									<MenuItem value="non-profit">
										Non-Profit
									</MenuItem>
								</Select>
							</FormControl>
						</div>
						<div style={{ width: "50%" }}>
							<div>M3 Tax Entity</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<Select
									labelId="period-label"
									id="m3TaxEntity"
									value={newMapNumber.m3TaxEntity}
									variant="outlined"
									displayEmpty
									placeholder="Select M3 Tax Entity"
									onChange={(e) =>
										handleForm(
											"m3TaxEntity",
											e.target.value
										)
									}
								>
									<MenuItem disabled value="">
										Select M3 Tax Entity
									</MenuItem>
									<MenuItem value="corporation">
										Corporation
									</MenuItem>
									<MenuItem value="s-corporation">
										S Corporation
									</MenuItem>
									<MenuItem value="partnership">
										Partnership
									</MenuItem>
								</Select>
							</FormControl>
						</div>
					</div>
					<div className="FR" style={{ marginTop: 15, gap: 15 }}>
						<div style={{ width: "50%" }}>
							<div>Tax Export Code</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<Select
									labelId="period-label"
									id="taxExportCode"
									value={newMapNumber.taxExportCode}
									variant="outlined"
									displayEmpty
									placeholder="Select Tax Export Code"
									onChange={(e) =>
										handleForm(
											"taxExportCode",
											e.target.value
										)
									}
								>
									<MenuItem disabled value="">
										Select Tax Export Code
									</MenuItem>
								</Select>
							</FormControl>
						</div>
						<div style={{ width: "50%" }}>
							<div>M3 Code</div>
							<FormControl
								style={{ marginTop: 5 }}
								fullWidth
								size="small"
							>
								<Select
									labelId="period-label"
									id="m3Code"
									value={newMapNumber.m3Code}
									variant="outlined"
									displayEmpty
									placeholder="Select M3 Code"
									onChange={(e) =>
										handleForm("m3Code", e.target.value)
									}
								>
									<MenuItem disabled value="">
										Select M3 Code
									</MenuItem>
								</Select>
							</FormControl>
						</div>
					</div>
				</div>
				<div className="FR AC JR" style={{ marginTop: 15 }}>
					<DLButton
						variant="contained"
						eleTestId="save-btn"
						size="regular"
						color="primary"
						clickHandler={() => {}}
					>
						Save
					</DLButton>
				</div>
			</StyledNewMapNumber>
		</PageContainer>
	)
}

export default MCreateNewCounterparty

const StyledNewMapNumber = styled.div`
	padding-left: 1rem;
	padding-right: 1rem;
`
