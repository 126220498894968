import React from "react"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import { observer } from "mobx-react-lite"
//
import {
	ConsoleLog,
	DLIconButton,
} from "../../../../../components/basic-elements"
//
import Icon from "@mdi/react"
import DLDateRangepicker from "../../../../../components/basic-elements/date-picker/DLDateRangePicker"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import ReactTableV8 from "../../../../../components/basic-elements/tables/DLReactTable"
import { Table, Column } from "@tanstack/react-table"
import { InputFieldForTableV8 } from "../../../../../components/basic-elements/tables/InputFieldForTable"
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { mdiDotsHorizontal } from "@mdi/js"

export default observer(function FsListOfTransactionMetadataTable({
	partialStore,
	permission,
}: {
	partialStore: any
	permission: PermissionAsObjectProps
}) {
	ConsoleLog(" __________// FsListOfTransactionMetadataTable //__________ ")

	const store = useRootStore()

	const handleContextMenu = (event: any, id: number) => {
		event.preventDefault()
		partialStore.setClickPoint({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
		})
		partialStore.setSelectedItem(id)
	}

	const dateFormat = store.global.dateFormat.value

	console.log(
		partialStore.viewFsListOfTransactionMetadata(),
		"viewFsListOfTransactionMetadata"
	)

	return (
		<ReactTableV8
			tableColumns={columns(handleContextMenu)}
			data={partialStore.viewFsListOfTransactionMetadata()}
			hasPagination={true}
			customFilters={(props: any) =>
				filterComponent({ ...props, dateFormat })
			}
			handleContextMenu={(e: any, row: any) =>
				handleContextMenu(e, row.mapNumber)
			}
			height={store.ui.contentsAreaHeight - 60}
			permission={permission}
			// pageHeader={pageHeader}
			menuId={DLProjSubMenus.list_of_map_numbers}
			showNewReorderDialog={true}
		/>
	)
})

const filterComponent = ({
	column,
	table,
	dateFormat,
}: {
	column: Column<any>
	table: Table<any>
	dateFormat: string
}) => {
	switch (column.id) {
		case "createdAt":
			const filterValues: any = column.getFilterValue()
			return (
				<DLDateRangepicker
					selectedStartDate={filterValues?.startDate}
					selectedEndDate={filterValues?.endDate}
					eleStartName="startDate"
					eleEndName="endDate"
					onChange={(value: any, name: string) => {
						column.setFilterValue({
							...filterValues,
							[name]: value,
						})
					}}
					format={dateFormat}
				/>
			)

		case "actions":
			return

		default:
			return (
				<InputFieldForTableV8
					columnId={column.id}
					onChange={column.setFilterValue}
					value={column.getFilterValue()}
				/>
			)
	}
}

const columns = (handleContextMenu: any) => {
	return [
		{
			header: "Actions",
			accessorKey: "actions",
			cell: (props: any) => {
				const mapNumber = props.row.original.mapNumber
				return (
					<div className="FR AC JC" style={{ width: "100%" }}>
						<DLIconButton
							aria-label="more"
							clickHandler={(event: any) =>
								handleContextMenu(event, mapNumber)
							}
							eleTestId="erp-ctx-btn-on-row"
						>
							<Icon path={mdiDotsHorizontal} size={0.8} />
						</DLIconButton>
					</div>
				)
			},
		},
		{
			header: "Map Number",
			accessorKey: "mapNumber",
		},
		{
			header: "Title",
			accessorKey: "title",
		},
		{
			header: "Behaviour",
			accessorKey: "behaviour",
		},
		{
			header: "Name",
			accessorKey: "name",
		},
		{
			header: "Type",
			accessorKey: "type",
		},
		{
			header: "Normal Sign",
			accessorKey: "normalSign",
		},
		{
			header: "Class",
			accessorKey: "class",
		},
		{
			header: "Calculation",
			accessorKey: "calculation",
		},
		{
			header: "FlipType",
			accessorKey: "flipType",
		},
		{
			header: "Map No (Flip)",
			accessorKey: "mapNoFlip",
		},
		{
			header: "Units",
			accessorKey: "units",
		},
		{
			header: "GIFI (Canada only)",
			accessorKey: "gifiCanadaOnly",
		},
		{
			header: "L/S",
			accessorKey: "ls",
		},
		{
			header: "L/S (Flip)",
			accessorKey: "lsFlip",
		},
		{
			header: "Group 2-10",
			accessorKey: "group2to10",
		},
		{
			header: "Group 2-10 (Flip)",
			accessorKey: "group2to10Flip",
		},
		{
			header: "Show currency symbol before balances",
			accessorKey: "showCurrencySymbol",
		},
		{
			header: "Start a new page at this point",
			accessorKey: "startNewPage",
		},
		{
			header: "Column Position",
			accessorKey: "columnPosition",
		},
		{
			header: "Underline",
			accessorKey: "underline",
		},
		{
			header: "Print Features",
			accessorKey: "printFeatures",
		},
		{
			header: "Accumulator total",
			accessorKey: "accumultorTotal",
		},
		{
			header: "Cash flow account to receive credits",
			accessorKey: "cashFlowCredits",
		},
		{
			header: "Cash flow account to receive debits",
			accessorKey: "cashFlowDebits",
		},
		{
			header: "Tax Entity",
			accessorKey: "taxEntity",
		},
		{
			header: "M3 Tax Entity",
			accessorKey: "m3TaxEntity",
		},
		{
			header: "Tax Export Code",
			accessorKey: "taxExportCode",
		},
		{
			header: "M3 Code",
			accessorKey: "m3Code",
		},
	]
}
