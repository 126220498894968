import React from "react"
import { useOrgStore } from "../../../../../../stores/org-store/org-store.provider"
import {
	DLIcon,
	DLIconName,
	CommonIconSize,
	FolderAddIcon,
	FileAddIcon,
	IconPlaceholder,
} from "../../../../../../components/basic-elements/icons/common-icons"
import { PermissionAsObjectProps } from "../../../../../../common-models/permission"
import { FileScreenDialog } from "../../../../../../service-modules/file-module/data-models/dl-file-control-model"
import { ConsoleLog } from "../../../../../../components/basic-elements"
import { useRootStore } from "../../../../../../stores/root-store/root-store.provider"
import { OrgI18n } from "../../../../../../common-models/enumerations/translation-sheets"

const ProjTemplateFolderCtxMenuOptions = (
	partialStore: any,
	permission: PermissionAsObjectProps
) => {
	const store = useRootStore()
	const orgStore = useOrgStore()

	const setFileTreeDialogOpen = partialStore.setFileTreeDialogOpen

	const i18n = store.i18n.combineI18n(
		OrgI18n.projTemplates,
		"pt-folder-ctx-menu"
	)

	const handleFolderDownload = () => {
		if (!window.confirm("Do you want to download all as zip?")) {
			return
		} else {
			const folderId = orgStore.orgProjTemplates.selectedFolder
			orgStore.orgProjTemplates.downloadFileFolder({ folderId })
		}
	}

	return [
		{
			label: i18n.twAddFolder || "Add Folder",
			value: "ctx-add-folder",
			available: permission.create,
			clickEvent: () =>
				setFileTreeDialogOpen(FileScreenDialog.createFolder, true),
			icon: <FolderAddIcon />,
		},
		{
			label: i18n.twAddFile || "Add File",
			value: "ctx-add-file",
			available: permission.create,
			clickEvent: () => {
				ConsoleLog(
					"@@@@ open creates file dialog - by proj template folder ctx menu options"
				)
				setFileTreeDialogOpen(FileScreenDialog.createFile, true)
			},

			icon: <FileAddIcon />,
		},
		{
			label: i18n.twEdit || "Edit",
			value: "ctx-edit-folder",
			available: permission.update,
			clickEvent: () =>
				setFileTreeDialogOpen(FileScreenDialog.editFolder, true),
			icon: (
				<DLIcon
					name={DLIconName.edit}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
		},
		// {
		// 	label: "Duplicate Folder",
		// 	value: "ctx-duplicate-folder",
		// 	available: permission.create,
		// 	clickEvent: () => {},
		// 	icon: <IconPlaceholder />,
		// },
		{
			label: "Download Folder",
			value: "ctx-download-folder",
			available: permission.export,
			clickEvent: () => handleFolderDownload(),
			icon: (
				<DLIcon
					name={DLIconName.download}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
		},
		{
			label: "Delete",
			value: "ctx-delete-folder",
			available: permission.delete,
			clickEvent: () => {
				const targetId = orgStore.orgProjTemplates.selectedFolder
				// remove the files of deleted folder on the stored data
				const postAction = () => {
					const list = partialStore.flatList.filter(
						(item: any) => item.parentId != targetId
					)
					partialStore.setUpdatedFlatList(list)
				}
				orgStore.orgProjTemplates.removeFileFolder({
					folderId: targetId,
					postAction,
				})
			},
			icon: (
				<DLIcon
					name={DLIconName.delete}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
		},
	]
}

export default ProjTemplateFolderCtxMenuOptions
