import React from "react"
import { observer } from "mobx-react-lite"
import {
	DLComboBox,
	DLDatepickerWithoutInput,
	DLInputField,
	DLRadioGroup,
	InputWithLabel,
} from "../../../../components/basic-elements"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { OrgI18n } from "../../../../common-models/enumerations/translation-sheets"
import { Button } from "@material-ui/core"
import styled from "styled-components"
import { RECURRENCE_OPTIONS } from "./utils/report-generation-scheduler-utils"
import ReportGenerationSchedulerForm from "./sub-components/ReportGenerationSchedulerForm"

export default observer(function ReportGenerationScheduleController() {
	const store = useRootStore()
	const i18n = store.i18n.combineI18n(OrgI18n.projects, "CreateProjectDialog")
	const dateFormat = store.global.dateFormat.value
	const { id: defaultRecurId, title: defaultRecurTitle } =
		RECURRENCE_OPTIONS[0]

	return <ReportGenerationSchedulerForm />
})
