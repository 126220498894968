import React, { useState } from "react"
import { PageContainer } from "../../../../components/app-frame-elements"
import { ActionStatus } from "@datalobby/types"
import styled from "styled-components"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import ListOfMapNumbersTable from "./sub-components/ListOfMapNumbersTable"
import ListOfMapNumbersCtxMenu from "./sub-components/ListOfMapNumbersCtxMenu"
import { observer } from "mobx-react-lite"

export default observer(function ListOfMapNumbers({
	partialStore,
	permission,
	actionStatus,
}: {
	partialStore: any
	permission: PermissionAsObjectProps
	actionStatus: any
}) {
	return (
		<PageContainer
			hasToolbar={false}
			pageTools={<div />}
			fullWidth
			isLoading={actionStatus === ActionStatus.loading}
		>
			<StyledListOfMapNumbers>
				<ListOfMapNumbersTable
					partialStore={partialStore}
					permission={permission}
				/>
			</StyledListOfMapNumbers>
			{partialStore.clickPoint.mouseX && (
				<ListOfMapNumbersCtxMenu
					permission={permission}
					partialStore={partialStore}
				/>
			)}
		</PageContainer>
	)
})

const StyledListOfMapNumbers = styled.div`
	padding-left: 1rem;
	padding-right: 1rem;
`
