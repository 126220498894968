export const dummyMapNumbersData = [
	{
		id: "folderId1",
		parentId: null,
		isParent: true,
		number: null,
		title: "Balance Sheet",
		expanded: false,
		balance: null,
		pyBalance: null,
	},
	{
		id: "folderId4",
		parentId: "folderId1",
		isParent: true,
		number: null,
		title: "Assets",
		expanded: false,
		balance: null,
		pyBalance: null,
	},
	{
		id: "folderId5",
		parentId: "folderId1",
		isParent: true,
		number: null,
		title: "Equity and liability",
		expanded: false,
		balance: null,
		pyBalance: null,
	},
	{
		id: "folderId15",
		parentId: "folderId4",
		isParent: true,
		number: null,
		title: "Non-current assets",
		expanded: false,
		balance: null,
		pyBalance: null,
	},
	{
		id: "file15",
		parentId: "folderId15",
		isParent: false,
		number: "1.1.1.10",
		title: "Property, plant and equipment, net",
		expanded: false,
		balance: "2,958,210.00",
		pyBalance: "1,754,500.00",
	},
	{
		id: "fileId2",
		parentId: "folderId5",
		isParent: true,
		number: null,
		title: "Equity",
		expanded: false,
		balance: null,
		pyBalance: null,
	},
	{
		id: "fileId3",
		parentId: "folderId6",
		isParent: false,
		number: "1.1.1.20",
		title: "Freehold Fixed assets",
		expanded: false,
		balance: "0.00",
		pyBalance: "0.00",
	},
	{
		id: "folderId2",
		parentId: null,
		isParent: true,
		number: null,
		title: "Income Statement",
		expanded: false,
		balance: "0.00",
		pyBalance: null,
	},
	{
		id: "folderId8",
		parentId: "folderId2",
		isParent: true,
		number: null,
		title: "B.01 Intangible assets",
		expanded: false,
		balance: "0.00",
		pyBalance: null,
	},
	{
		id: "fileId4",
		parentId: "folderId8",
		isParent: false,
		number: "1.1.1.30",
		title: "Freehold Intangible assets",
		expanded: false,
		balance: "0.00",
		pyBalance: "0.00",
	},
	{
		id: "folderId9",
		parentId: "folderId2",
		isParent: true,
		number: null,
		title: "B.02 Tangible fixed assets",
		expanded: false,
		balance: "0.00",
		pyBalance: null,
	},
	{
		id: "fileId5",
		parentId: "folderId8",
		isParent: false,
		number: "1.1.1.40",
		title: "Freehold Tangible assets",
		expanded: false,
		balance: "0.00",
		pyBalance: "0.00",
	},
	{
		id: "folderId20",
		parentId: "folderId2",
		isParent: true,
		number: null,
		title: "B.03 Fixed assets investments",
		expanded: false,
		balance: "0.00",
		pyBalance: null,
	},
	{
		id: "fileId6",
		parentId: "folderId20",
		isParent: false,
		number: "1.1.1.50",
		title: "Freehold Fixed assets",
		expanded: false,
		balance: "0.00",
		pyBalance: "0.00",
	},
]
