export const dummyData = [
	{
		accountNumber: 101,
		description: "Petty cash",
		type: "Balance Sheet",
		ls: "A",
		ratioClass: "QA",
		curP1Balance: 200,
		py1P1Balance: 200,
		py2P1Balance: 100,
	},
	{
		accountNumber: 102,
		description: "Foreign bank",
		type: "Balance Sheet",
		ls: "A",
		ratioClass: "QA",
		curP1Balance: 165072,
		py1P1Balance: 33900,
		py2P1Balance: 100,
	},
	{
		accountNumber: 103,
		description: "Domestic bank",
		type: "Balance Sheet",
		ls: "A",
		ratioClass: "QA",
		curP1Balance: 100000,
		py1P1Balance: 0,
		py2P1Balance: 0,
	},
	{
		accountNumber: 106,
		description: "Accounts receivable",
		type: "Balance Sheet",
		ls: "C",
		ratioClass: "TR",
		curP1Balance: 30000,
		py1P1Balance: 1000,
		py2P1Balance: 850,
	},
	{
		accountNumber: 111,
		description: "Short term investments",
		type: "Balance Sheet",
		ls: "B",
		ratioClass: "QA",
		curP1Balance: 50000,
		py1P1Balance: 700,
		py2P1Balance: 400,
	},
	{
		accountNumber: 116,
		description: "Long term investments",
		type: "Balance Sheet",
		ls: "N",
		ratioClass: "CA",
		curP1Balance: 10000,
		py1P1Balance: 100,
		py2P1Balance: 60,
	},
	{
		accountNumber: 126,
		description: "Deferred charges",
		type: "Balance Sheet",
		ls: "H",
		ratioClass: "IN",
		curP1Balance: 35000,
		py1P1Balance: 1200,
		py2P1Balance: 600,
	},
	{
		accountNumber: 140,
		description: "Inventory",
		type: "Balance Sheet",
		ls: "D",
		ratioClass: "QA",
		curP1Balance: 285,
		py1P1Balance: 285,
		py2P1Balance: 112,
	},
	{
		accountNumber: 142,
		description: "Prepaid Expenses",
		type: "Balance Sheet",
		ls: "L",
		ratioClass: "FD",
		curP1Balance: 10000,
		py1P1Balance: 10000,
		py2P1Balance: 1000,
	},
	{
		accountNumber: 143,
		description: "Other machinery and equipment - cost",
		type: "Balance Sheet",
		ls: "U",
		ratioClass: "FD",
		curP1Balance: 20000,
		py1P1Balance: 20000,
		py2P1Balance: 2000,
	},
	{
		accountNumber: 147,
		description: "Furniture & fixtures - cost",
		type: "Balance Sheet",
		ls: "U",
		ratioClass: "FA",
		curP1Balance: -9760,
		py1P1Balance: -7200,
		py2P1Balance: -400,
	},
	{
		accountNumber: 148,
		description: "Automobiles - cost",
		type: "Balance Sheet",
		ls: "U",
		ratioClass: "QA",
		curP1Balance: 200,
		py1P1Balance: 200,
		py2P1Balance: 100,
	},
	{
		accountNumber: 149,
		description: "Motor vehicles - accumulated depreciation",
		type: "Balance Sheet",
		ls: "U",
		ratioClass: "QA",
		curP1Balance: 200,
		py1P1Balance: 200,
		py2P1Balance: 100,
	},
	{
		accountNumber: 150,
		description: "Leasehold improvements - cost",
		type: "Balance Sheet",
		ls: "U",
		ratioClass: "QA",
		curP1Balance: 200,
		py1P1Balance: 200,
		py2P1Balance: 100,
	},
	{
		accountNumber: 202,
		description: "Short term debt",
		type: "Balance Sheet",
		ls: "BB",
		ratioClass: "QA",
		curP1Balance: 200,
		py1P1Balance: 200,
		py2P1Balance: 100,
	},
	{
		accountNumber: 205,
		description: "Accounts payable",
		type: "Balance Sheet",
		ls: "BB",
		ratioClass: "QA",
		curP1Balance: 200,
		py1P1Balance: 200,
		py2P1Balance: 100,
	},
	{
		accountNumber: 208,
		description: "Accrued liabilities",
		type: "Balance Sheet",
		ls: "BB",
		ratioClass: "QA",
		curP1Balance: 200,
		py1P1Balance: 200,
		py2P1Balance: 100,
	},
	{
		accountNumber: 213,
		description: "Payroll clearing",
		type: "Balance Sheet",
		ls: "BB",
		ratioClass: "QA",
		curP1Balance: 200,
		py1P1Balance: 200,
		py2P1Balance: 100,
	},
	{
		accountNumber: 215,
		description: "Income taxes payable - State/Provincial",
		type: "Balance Sheet",
		ls: "FF",
		ratioClass: "QA",
		curP1Balance: 200,
		py1P1Balance: 200,
		py2P1Balance: 100,
	},
	{
		accountNumber: 216,
		description: "Income taxes payable - Federal",
		type: "Balance Sheet",
		ls: "FF",
		ratioClass: "QA",
		curP1Balance: 200,
		py1P1Balance: 200,
		py2P1Balance: 100,
	},
]
