import React, { useState, useCallback, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../../../../stores/org-store/org-store.provider"
import { DLDialog } from "../../../../../../../components/basic-elements"
import CreateArchiveDatePolicyForm from "./CreateEmailTemplateSetForm"

export default observer(function CreateEmailTemplateSetDialog() {
	const orgStore = useOrgStore()
	const actionName = "addArchiveDatePolicy"
	//
	const [inputs, setInputs] = useState({ title: "", description: "" })
	let inputReady = inputs.title !== ""
	//
	const handleInputs = useCallback((event: any) => {
		let name = event.target.name
		let value = event.target.value
		setInputs((inputs: any) => ({
			...inputs,
			[name]: value,
		}))
	}, [])
	//
	const handleAdd = () => {
		orgStore.projArchDatePolicy.addArchiveDatePolicy(
			inputs.title,
			inputs.description
		)
	}

	useEffect(() => {
		orgStore.projArchDatePolicy.responses.removeResponse(actionName)
	}, [])

	return (
		<DLDialog
			eleTestId="add-arch-date-policy-dialog"
			isOpen={orgStore.projArchDatePolicy.openCreateDialog}
			setIsOpen={orgStore.projArchDatePolicy.setOpenCreateDialog}
			handleAction={handleAdd}
			showCloseBtn={true}
			dialogTitle={"Add Archive Date Policy Set"}
			dialogContents={
				<CreateArchiveDatePolicyForm
					inputs={inputs}
					handleInputs={handleInputs}
					actionName={actionName}
				/>
			}
			showOpenBtn={false}
			cancelBtnText={"Cancel"}
			actionReady={
				inputReady &&
				!orgStore.projArchDatePolicy.isDuplicatedTitle(inputs.title)
			}
			actionBtn={"Add"}
			maxWidth="sm"
			fullWidth={true}
			dialogError={
				orgStore.projArchDatePolicy.responses.getResponse(actionName)
					?.message
			}
			showSpinner={
				orgStore.projArchDatePolicy.getActionStatus(actionName) ===
				"LOADING"
			}
			cannotUpdate={
				orgStore.projArchDatePolicy.getActionStatus(actionName) ===
				"LOADING"
			}
		/>
	)
})
