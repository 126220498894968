import React, { useEffect, useState, useCallback } from "react"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import { observer } from "mobx-react-lite"
import {
	DLSingleSelect,
	InputWithLabel,
	DLRadioGroup,
	DLDialog,
	DLDatepicker,
	ConsoleLog,
} from "../../../../components/basic-elements"
import styled from "styled-components"
import Icon from "@mdi/react"
import { mdiPencil } from "@mdi/js"
import {
	ProjRoles,
	ProjAccess,
	UpdateProjUserProps,
} from "../store/data-models/proj-setup-users.data-models"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import SelectedUserCard from "./SelectedUserCard"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { getProjIdFromUrl } from "../../../../library/api-requests/request-get-others"
import { addTzToDate } from "../../../../library/converters/date-utc-converter"

export default observer(function EditProjUser({ i18n }: { i18n: DLI18nProps }) {
	ConsoleLog(" ___EditProjUser___ ")
	const store = useRootStore()
	const projStore = useProjStore()
	const actionName = "editProjUser"
	const targetUserId = projStore.projUsers.selectedUser
	const projId = getProjIdFromUrl()
	const actBy = localStorage.getItem("orgUserId")

	const timeZone = store.global.timeZone

	const [inputs, setInputs] = useState<UpdateProjUserProps>({
		type: "internal",
		roleId: "",
		userName: "",
		userAliasId: "",
		// readonly: false,
		accessTypeId: ProjAccess.user,
		expDate: "",
		canRollForward: false,
	})

	const userInfo = projStore.projUsers.viewSelectedUserInfo()
	useEffect(() => {
		// reset related response
		projStore.projUsers.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.standby,
		})

		if (userInfo) {
			setInputs({
				type: userInfo.type,
				roleId: userInfo.roleId,
				userName: userInfo.name,
				userAliasId: userInfo.aliasId,
				// readonly: false, // TODO: Where is the readonly property
				accessTypeId: userInfo.accessTypeId,
				expDate: userInfo.expDate, // TODO: What is the expiration Date? (Why there is duplicated date?)
				canRollForward: userInfo.canRollForward,
			})
			if (userInfo.roleId === ProjRoles.qc) {
				if (
					userInfo.accessTypeId !== ProjAccess.projAdmin &&
					userInfo.accessTypeId !== ProjAccess.archAdmin
				) {
					setInputs((inputs) => ({
						...inputs,
						accessTypeId: ProjAccess.projAdmin,
					}))
				}
			}
			if (userInfo.roleId === ProjRoles.ep) {
				setInputs((inputs) => ({
					...inputs,
					accessTypeId: ProjAccess.archAdmin,
				}))
			}
		} else {
			if (projStore.projUsers.openEdit) {
				console.error("No selected user. Please try again")
			}
		}
	}, [projStore.projUsers.openEdit === true])

	const handleInputs = useCallback((event: any) => {
		const name = event.target.name
		let value = event.target.value

		if (value === "true") {
			value = true
		} else if (value === "false") {
			value = false
		}

		if (name === "roleId" && value === ProjRoles.ep) {
			setInputs((inputs) => ({
				...inputs,
				[name]: value,
				accessTypeId: ProjAccess.archAdmin,
				canRollForward: true,
			}))
		} else if (name === "roleId" && value === ProjRoles.qc) {
			setInputs((inputs) => ({
				...inputs,
				[name]: value,
				accessTypeId: ProjAccess.projAdmin,
			}))
		} else if (name === "accessTypeId" && value === ProjAccess.readonly) {
			setInputs((inputs) => ({
				...inputs,
				[name]: value,
				["canRollForward"]: false,
			}))
		} else {
			setInputs((inputs) => ({
				...inputs,
				[name]: value,
			}))
		}
	}, [])

	const handleEditUser = () => {
		const payload = {
			...inputs,
			expDate: inputs.expDate
				? addTzToDate(inputs.expDate, timeZone.uiValue)
				: null,
		}
		if (actBy !== null && projId !== null) {
			projStore.projUsers.editProjUser({
				request: payload,
				userId: targetUserId,
				actBy,
				projId,
			})
		} else {
			alert(`(${actionName}) handleEditUser get invalid props`)
		}
	}

	const handleDateInput = (name: string, date: string) => {
		setInputs((inputs: any) => ({
			...inputs,
			[name]: date,
		}))
	}

	const isNotPdmaReadOnlyUser = !(
		inputs.accessTypeId === ProjAccess.readonly &&
		(userInfo?.projDataMgmtAccessPermissionId === 2 ||
			userInfo?.projDataMgmtAccessPermissionId === 3)
	)

	return (
		<DLDialog
			eleTestId="edit-proj-user-dialog"
			isOpen={projStore.projUsers.openEdit}
			setIsOpen={projStore.projUsers.setOpenEdit}
			handleAction={handleEditUser}
			showOpenBtn={false}
			showCloseBtn={true}
			dialogTitle={
				<div className="FR AC">
					<Icon path={mdiPencil} size={1} />
					<div style={{ marginLeft: 8 }}>
						{i18n.twEditUser || "Edit User"}
					</div>
				</div>
			}
			dialogContents={
				<EditProjUserForm
					inputs={inputs}
					handleInputs={handleInputs}
					handleDateInput={handleDateInput}
					dateFormat={store.global.dateFormat.value}
					i18n={i18n}
					existingAccessTypeId={userInfo?.accessTypeId || ""}
				/>
			}
			cancelBtnText={i18n.twCancel || "Cancel"}
			actionReady={isNotPdmaReadOnlyUser}
			actionBtn={i18n.twSubmit || "Submit"}
			maxWidth="sm"
			fullWidth={true}
			dialogError={
				projStore.projUsers.getActionStatus(actionName) === "FAIL"
					? projStore.projUsers.getMessage(actionName)
					: ""
			}
			showSpinner={
				projStore.projUsers.getActionStatus(actionName) === "LOADING"
			}
		/>
	)
})

const EditProjUserForm = observer(
	({
		inputs,
		handleInputs,
		handleDateInput,
		dateFormat,
		i18n,
		existingAccessTypeId,
	}: {
		inputs: any
		handleInputs: any
		handleDateInput: any
		dateFormat: string
		i18n: DLI18nProps
		existingAccessTypeId: string
	}) => {
		// default settings
		const projStore = useProjStore()

		const targetUser = projStore.projUsers.viewSelectedUserInfo()
		const pdmaReadOnlyUser =
			inputs.accessTypeId === ProjAccess.readonly &&
			(targetUser?.projDataMgmtAccessPermissionId === 2 ||
				targetUser?.projDataMgmtAccessPermissionId === 3)
		return (
			<StyledEditProjUserForm>
				{targetUser && (
					<div className="input-section FR">
						<InputWithLabel
							label={i18n.twSelectedUser || "Selected User"}
						>
							<SelectedUserCard
								name={targetUser.name}
								aliasId={targetUser.aliasId}
								email={targetUser.email}
								title={
									targetUser.orgTitle
										? targetUser.orgTitle
										: ""
								}
							/>
						</InputWithLabel>
					</div>
				)}
				{inputs.type === "internal" ? (
					<>
						<div className="input-section FR">
							<InputWithLabel label="Role" required>
								<DLSingleSelect
									eleTestId="select-project-role"
									eleValue={inputs.roleId}
									eleOnChange={handleInputs}
									eleName="roleId"
									eleFullWidth
									withLabel={false}
									// eleRequired
									placeholder="Select a role in the project"
									optionList={[
										{
											name: "Quality Control",
											value: ProjRoles.qc,
										},
										{
											name: "Engagement Partner",
											value: ProjRoles.ep,
										},
										{
											name: "Concurring Partner",
											value: ProjRoles.cp,
										},
										{ name: "Partner", value: ProjRoles.p },
										{
											name: "Director",
											value: ProjRoles.d,
										},
										{ name: "Manager", value: ProjRoles.m },
										{
											name: "Senior Staff",
											value: ProjRoles.ss,
										},
										{
											name: "Junior Staff",
											value: ProjRoles.js,
										},
									]}
								/>
							</InputWithLabel>
						</div>
						{/* <div className="input-section FR">
				<InputWithLabel label="Set Readonly">
					<DLRadioGroup
						selectedValue={inputs.readonly}
						eleOnChange={handleInputs}
						items={[
							{ value: false, label: "No" },
							{ value: true, label: "Yes" }
						]}
						groupName="readonly"
						itemDirection="row"
					/>
				</InputWithLabel>
			</div> */}
						<div className="input-section FR">
							<InputWithLabel label="Access Type">
								<DLRadioGroup
									selectedValue={inputs.accessTypeId}
									eleOnChange={handleInputs}
									disabled={inputs.roleId === ProjRoles.ep}
									items={[
										{
											value: ProjAccess.archAdmin,
											label: "Archive & Project Admin",
										},
										{
											value: ProjAccess.projAdmin,
											label: "Project Admin",
										},
										{
											value: ProjAccess.user,
											label: "Project User",
											disabled:
												inputs.roleId === ProjRoles.qc,
										},
										{
											value: ProjAccess.readonly,
											label: "Readonly",
											disabled:
												inputs.roleId === ProjRoles.qc,
										},
									]}
									groupName="accessTypeId"
									itemDirection="column"
									formHelpText={
										pdmaReadOnlyUser
											? `Project Data Management access with "Create/Download" or "Download Only" cannot be assigned to a Read-Only user. Please update the access permission to either "View List Only" or "No Access" first, and then change the user's Project Access Type to Read-Only.`
											: undefined
									}
								/>
							</InputWithLabel>
						</div>
						{inputs.roleId === ProjRoles.qc &&
							existingAccessTypeId !== ProjAccess.projAdmin &&
							existingAccessTypeId !== ProjAccess.archAdmin && (
								<div
									className="help-text"
									data-testid="help-msg-1"
								>
									<span>
										QC user must be Project Archive Admin or
										Project Admin. Because this QC user was{" "}
										{existingAccessTypeId} before, update it
										as {inputs.accessTypeId}. This update is
										applied when you click the update
										button.
									</span>
								</div>
							)}
						{inputs.roleId === ProjRoles.ep &&
							existingAccessTypeId !== ProjAccess.archAdmin && (
								<div
									className="help-text"
									data-testid="help-msg-2"
								>
									<span>
										EP user must be Project Archive Admin.
										Because this EP user was{" "}
										{existingAccessTypeId} before, update it
										as Project Archive Admin. This update is
										applied when you click the update
										button.
									</span>
								</div>
							)}
						<div className="input-section FR">
							<InputWithLabel label="Roll Forward">
								<DLRadioGroup
									selectedValue={inputs.canRollForward}
									disabled={
										inputs.accessTypeId ===
											ProjAccess.readonly ||
										inputs.roleId === ProjRoles.ep
									}
									eleOnChange={handleInputs}
									items={[
										{ value: false, label: "Reject" },
										{ value: true, label: "Allow" },
									]}
									groupName="canRollForward"
									itemDirection="row"
									formHelpText={
										inputs.accessTypeId ===
										ProjAccess.readonly
											? "Readonly user cannot use roll-forward"
											: undefined
									}
								/>
							</InputWithLabel>
						</div>
					</>
				) : (
					<>
						<div className="input-section FR">
							<InputWithLabel label="External User Right">
								<DLRadioGroup
									selectedValue={inputs.accessTypeId}
									eleOnChange={handleInputs}
									items={[
										{
											value: ProjAccess.readonly,
											label: "Read Only",
										},
										{
											value: ProjAccess.pbcUpload,
											label: "PBC Upload",
										},
									]}
									groupName="accessTypeId"
									itemDirection="row"
								/>
							</InputWithLabel>
						</div>
						<div className="input-section FR">
							<InputWithLabel
								label={
									i18n.twProjAccessExpiredDate ||
									"Project Access Expired Date"
								}
							>
								<DLDatepicker
									eleTestId="proj-exp-date"
									eleName="expDate"
									// selected={new Date(expectedReportDate)}
									selected={
										inputs.expDate === ""
											? null
											: inputs.expDate
									}
									// eleLabel="Expected Report Date"
									onChange={(date: any) =>
										handleDateInput("expDate", date)
									}
									format={dateFormat}
								/>
							</InputWithLabel>
						</div>
					</>
				)}
			</StyledEditProjUserForm>
		)
	}
)

const StyledEditProjUserForm = styled.div`
	padding-left: 0.5rem;
	.input-section {
		margin-bottom: 1.5rem;
		.label {
			min-width: 9rem;
		}
		.input-area {
			/* width: calc(100% - 9rem); */
			min-width: 20rem;
		}
	}
	.help-text {
		width: 100%;
		color: ${(props) => props.theme.warning};
	}
`
