import React from "react"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import ProjNoPermissionOnMenu from "../../proj-access-control/ProjNoPermissionOnMenu"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { observer } from "mobx-react-lite"
import { ActionStatus } from "@datalobby/types"
import MCreateNewEntity from "./MCreateNewEntity"

export default observer(function MCreateNewEntityController() {
	const projStore = useProjStore()
	const menuId = DLProjSubMenus.m_create_new_map_number

	const menuAccess = projStore.checkin.checkAccess(menuId)

	return (
		<>
			{menuAccess ? (
				<MCreateNewEntity actionStatus={ActionStatus.standby} />
			) : (
				<ProjNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})
