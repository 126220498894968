export const dummyData = [
	{
		id: "1",
		name: "Cash",
		number: "A",
		createdByUserId: 1,
		createdAt: "",
		createdBy: "Data",
		modifiedByUserId: 1,
		modifiedBy: "",
		modifiedAt: "",
		sortOrder: 1,
		isActive: true,
		activatedByUserId: 1,
		activatedBy: "",
		deActivatedBy: "",
		deActivatedByUserId: 1,
		activatedAt: "",
		deActivatedAt: "",
	},
	{
		id: "2",
		number: "B",
		name: "Temporary investments",
		createdByUserId: 1,
		createdAt: "",
		createdBy: "Data",
		modifiedByUserId: 1,
		modifiedBy: "",
		modifiedAt: "",
		sortOrder: 2,
		isActive: true,
		activatedByUserId: 1,
		activatedBy: "",
		deActivatedBy: "",
		deActivatedByUserId: 1,
		activatedAt: "",
		deActivatedAt: "",
	},
	{
		id: "3",
		number: "C",
		name: "Accts. rec., trade & other",
		createdByUserId: 1,
		createdAt: "",
		createdBy: "Data",
		modifiedByUserId: 1,
		modifiedBy: "",
		modifiedAt: "",
		sortOrder: 3,
		isActive: true,
		activatedByUserId: 1,
		activatedBy: "",
		deActivatedBy: "",
		deActivatedByUserId: 1,
		activatedAt: "",
		deActivatedAt: "",
	},
	{
		id: "4",
		number: "D",
		name: "Inventory",
		createdByUserId: 1,
		createdAt: "",
		createdBy: "Data",
		modifiedByUserId: 1,
		modifiedBy: "",
		modifiedAt: "",
		sortOrder: 4,
		isActive: true,
		activatedByUserId: 1,
		activatedBy: "",
		deActivatedBy: "",
		deActivatedByUserId: 1,
		activatedAt: "",
		deActivatedAt: "",
	},
	{
		id: "5",
		number: "E",
		name: "Current loans and advances receivable",
		createdByUserId: 1,
		createdAt: "",
		createdBy: "Data",
		modifiedByUserId: 1,
		modifiedBy: "",
		modifiedAt: "",
		sortOrder: 5,
		isActive: true,
		activatedByUserId: 1,
		activatedBy: "",
		deActivatedBy: "",
		deActivatedByUserId: 1,
		activatedAt: "",
		deActivatedAt: "",
	},
	{
		id: "6",
		number: "H",
		name: "Deferred Charges, Other Assets",
		createdByUserId: 1,
		createdAt: "",
		createdBy: "Data",
		modifiedByUserId: 1,
		modifiedBy: "",
		modifiedAt: "",
		sortOrder: 6,
		isActive: true,
		activatedByUserId: 1,
		activatedBy: "",
		deActivatedBy: "",
		deActivatedByUserId: 1,
		activatedAt: "",
		deActivatedAt: "",
	},
	{
		id: "7",
		number: "L",
		name: "Prepaid expenses & other current assets",
		createdByUserId: 1,
		createdAt: "",
		createdBy: "Data",
		modifiedByUserId: 1,
		modifiedBy: "",
		modifiedAt: "",
		sortOrder: 7,
		isActive: true,
		activatedByUserId: 1,
		activatedBy: "",
		deActivatedBy: "",
		deActivatedByUserId: 1,
		activatedAt: "",
		deActivatedAt: "",
	},
	{
		id: "8",
		number: "M",
		name: "Long term loans",
		createdByUserId: 1,
		createdAt: "",
		createdBy: "Data",
		modifiedByUserId: 1,
		modifiedBy: "",
		modifiedAt: "",
		sortOrder: 8,
		isActive: false,
		activatedByUserId: 1,
		activatedBy: "",
		deActivatedBy: "",
		deActivatedByUserId: 1,
		activatedAt: "",
		deActivatedAt: "",
	},
	{
		id: "9",
		number: "N",
		name: "Long-term investments",
		createdByUserId: 1,
		createdAt: "",
		createdBy: "Data",
		modifiedByUserId: 1,
		modifiedBy: "",
		modifiedAt: "",
		sortOrder: 9,
		isActive: false,
		activatedByUserId: 1,
		activatedBy: "",
		deActivatedBy: "",
		deActivatedByUserId: 1,
		activatedAt: "",
		deActivatedAt: "",
	},
	{
		id: "10",
		number: "U",
		name: "Capital assets",
		createdByUserId: 1,
		createdAt: "",
		createdBy: "Data",
		modifiedByUserId: 1,
		modifiedBy: "",
		modifiedAt: "",
		sortOrder: 10,
		isActive: false,
		activatedByUserId: 1,
		activatedBy: "",
		deActivatedBy: "",
		deActivatedByUserId: 1,
		activatedAt: "",
		deActivatedAt: "",
	},
	{
		id: "11",
		number: "W",
		name: "Intangible assets",
		createdByUserId: 1,
		createdAt: "",
		createdBy: "Data",
		modifiedByUserId: 1,
		modifiedBy: "",
		modifiedAt: "",
		sortOrder: 11,
		isActive: false,
		activatedByUserId: 1,
		activatedBy: "",
		deActivatedBy: "",
		deActivatedByUserId: 1,
		activatedAt: "",
		deActivatedAt: "",
	},
	{
		id: "12",
		number: "Y",
		name: "Other long term assets",
		createdByUserId: 1,
		createdAt: "",
		createdBy: "Data",
		modifiedByUserId: 1,
		modifiedBy: "",
		modifiedAt: "",
		sortOrder: 12,
		isActive: false,
		activatedByUserId: 1,
		activatedBy: "",
		deActivatedBy: "",
		deActivatedByUserId: 1,
		activatedAt: "",
		deActivatedAt: "",
	},
	{
		id: "13",
		number: "10",
		name: "Earnings",
		createdByUserId: 1,
		createdAt: "",
		createdBy: "Data",
		modifiedByUserId: 1,
		modifiedBy: "",
		modifiedAt: "",
		sortOrder: 13,
		isActive: false,
		activatedByUserId: 1,
		activatedBy: "",
		deActivatedBy: "",
		deActivatedByUserId: 1,
		activatedAt: "",
		deActivatedAt: "",
	},
	{
		id: "14",
		number: "AA",
		name: "Notes payable and bank debt",
		createdByUserId: 1,
		createdAt: "",
		createdBy: "Data",
		modifiedByUserId: 1,
		modifiedBy: "",
		modifiedAt: "",
		sortOrder: 14,
		isActive: false,
		activatedByUserId: 1,
		activatedBy: "",
		deActivatedBy: "",
		deActivatedByUserId: 1,
		activatedAt: "",
		deActivatedAt: "",
	},
	{
		id: "15",
		number: "BB",
		name: "Accounts payable & acc. liab.",
		createdByUserId: 1,
		createdAt: "",
		createdBy: "Data",
		modifiedByUserId: 1,
		modifiedBy: "",
		modifiedAt: "",
		sortOrder: 15,
		isActive: false,
		activatedByUserId: 1,
		activatedBy: "",
		deActivatedBy: "",
		deActivatedByUserId: 1,
		activatedAt: "",
		deActivatedAt: "",
	},
]
