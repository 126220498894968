import React, { useEffect } from "react"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import ProjNoPermissionOnMenu from "../../proj-access-control/ProjNoPermissionOnMenu"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { observer } from "mobx-react-lite"
import ListOfMapNumbers from "./ListOfMapNumbers"

export default observer(function ListOfMapNumbersController() {
	const projStore = useProjStore()
	const menuId = DLProjSubMenus.list_of_map_numbers
	const isLocked = projStore.projInfo.projInfo.isLocked
	const isArchived = projStore.projInfo.projInfo.isArchived
	const menuAccess = projStore.checkin.checkAccess(menuId)
	const permission = projStore.checkin.getActionsAsObject(
		menuId,
		isLocked,
		isArchived
	)

	useEffect(() => {
		projStore.listOfMapNumbers.getListOfMapNumbers()
	}, [])

	return (
		<>
			{menuAccess ? (
				<ListOfMapNumbers
					partialStore={projStore.listOfMapNumbers}
					permission={permission}
					actionStatus={projStore.listOfMapNumbers.getActionStatus(
						"getListOfMapNumbers"
					)}
				/>
			) : (
				<ProjNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})
